<template>
	<div class="p-component p-py-2 p-px-4">
		<div class="navigator-header p-mb-3">
			<p class="title p-my-2">{{$t("Squeeze.Reports.ExportedDocuments")}}</p>
		</div>
		<div class="p-chart p-field p-input-filled">
			<Dropdown class="p-my-2 p-mx-2" @change="onChangeYears(years)" v-model="selectedYear" :options="years" optionLabel="name" :filter="true" @show="showDropdownOverlay">
				<template #value="slotProps">
					<div class="country-item country-item-value" v-if="slotProps.value">
						<div>{{slotProps.value.name}}</div>
					</div>
					<span v-else>
                    {{slotProps.placeholder}}
                </span>
				</template>
				<template #option="slotProps">
					<div class="country-item">
						<div>{{slotProps.option.name}}</div>
					</div>
				</template>
			</Dropdown>
			<Dropdown class="p-my-2" @change="onChangeMonths(months)" v-model="selectedMonth" :options="months" optionLabel="name" :filter="true" :placeholder="$t('Squeeze.Reports.PlaceholderMonth')" :showClear="true" @show="showDropdownOverlay">
				<template #value="slotProps">
					<div class="month-item month-item-value" v-if="slotProps.value">
						<div>{{slotProps.value.name}}</div>
					</div>
					<span v-else>
                    {{slotProps.placeholder}}
                </span>
				</template>
				<template #option="slotProps">
					<div class="month-item">
						<div>{{slotProps.option.name}}</div>
					</div>
				</template>
			</Dropdown>
			<canvas id="myChart" ref="canvas"></canvas>
		</div>
	</div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
//import { Chart } from 'chart.js';
import Chart from 'chart.js/auto';
import moment from "moment";
import Dropdown from "primevue/dropdown";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useStore} from "@/store";
import { Report } from '@dex/squeeze-client-ts';
import {showDropdownOverlay} from "@/util/StylesHelper";

interface NameValuePair {
	name: string;
	value: number;
}

interface NameValueOptionalPair {
	name: string;
	value?: number;
}


@Options({
	name: "DocumentReportExportedDocuments",
	components: {
		Chart,
		Dropdown,
	},
	props: {
		months: {
			type: Array,
		},
		years: {
			type: Array,
		},
	},
	watch: {
		'$i18n.locale': function() {
			this.months.splice(0);
			this.getMonths();

			this.selectedMonth = {name: moment().format("MMMM"), value: this.currentMonth};
			this.dataSetLabel = this.selectedMonth.name + ' ' + this.selectedYear.name;
			const presentChart = Chart.getChart("myChart");
			if(presentChart) {
				presentChart.destroy();
				this.createChart();
			}
		},
	},
})
export default class DocumentReportExportedDocuments extends Vue {

	myChart!: Chart;
	currentYear: number = moment().year();
	currentMonth: number | undefined = moment().month() +1;

	selectedMonth: NameValueOptionalPair = {name: moment().format("MMMM"), value: this.currentMonth};
	selectedYear: NameValuePair = {name: this.currentYear.toString(), value: this.currentYear};

	months: NameValuePair[] = [];
	years: NameValuePair[] = [];

	chartData: number[] = [];
	chartLabels: string[] = [];
	dataSetLabel = '';

	/** Store point */
	store = useStore();

	queueAPI = ClientManager.getInstance().squeeze.queue;

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	mounted() {
		this.getChartData();

		this.getMonths();

		for (let i = 0; i < 31; i++) {
			const year = moment().add(-i, 'years').format('YYYY')
			this.years.push({name: year, value: parseInt(year)})
		}
	}

	/** Get all Months in current language */
	getMonths() {
		const months = moment.months();
		for (let i = 0; i < months.length; i++) {
			const month = months[i];
			this.months.push({name: month, value: i +1});
		}
	}

	createChart() {
		const canvas = document.getElementById('myChart') as HTMLCanvasElement;
		const ctx = canvas.getContext('2d');
		const currentColor = window.getComputedStyle(canvas).getPropertyValue('--dex-secondary-dark-color');
		this.myChart = new Chart(ctx, {
			type: 'line',
			data: {
				labels: this.chartLabels,
				datasets: [{
					label: 'Report ' + this.dataSetLabel,
					data: this.chartData,
					borderWidth: 3,
					borderColor: currentColor,
					backgroundColor: currentColor,
				}],
			},
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
		});
	}

	/**
	 * Triggered when a Month is changed
	 * */
	async getChartData() {
		try {
			let queueReportData: Report | null = null;
			if(this.currentMonth != undefined) {
				queueReportData = await this.queueAPI.getQueueReportExportedDocuments(this.currentYear, this.currentMonth);
			} else {
				queueReportData = await this.queueAPI.getQueueReportExportedDocuments(this.currentYear);
			}

			// Build labels for x axis
			const labels: string[] = [];
			if (queueReportData.labels) {
				for (const i of queueReportData.labels) {
					if (queueReportData.labels.length <= 13) {
						labels.push(moment(i, "MM").format("MMM"));
					} else {
						labels.push(String(i));
					}
				}
			}
			this.chartLabels = labels;

			// Build data
			if(queueReportData.datasets && queueReportData.datasets.length > 0) {
				if(queueReportData.datasets[0] && queueReportData.datasets[0].data && queueReportData.datasets[0].data.length > 0) {this.chartData = queueReportData.datasets[0].data;}
			}

			// Build Year and Month format
			if (this.currentMonth == null) {
				this.currentYear = parseInt(moment(this.currentYear, "YYYY").format("YYYY"));
			} else {
				this.currentYear = parseInt(moment(this.currentYear && this.currentMonth, "YYYY MM").format("MMMM YY"));
			}

			// Build Chart
			const presentChart = Chart.getChart("myChart");
			if(presentChart) {
				presentChart.destroy();
				this.createChart();
			} else {
				this.dataSetLabel = this.selectedMonth.name + ' ' + this.selectedYear.name;
				this.createChart();
			}
		} catch (err: any) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.statusText);
		}
	}

	/**
	 * Triggered when a Month is delete
	 * */
	loadYearReport() {
		this.currentYear = this.selectedYear.value;
		this.currentMonth = undefined;
		this.dataSetLabel = this.selectedYear.name;
		this.getChartData();
	}

	loadMonthReport() {
		this.currentYear = this.selectedYear.value;
		this.currentMonth = this.selectedMonth.value;
		this.dataSetLabel = this.selectedMonth.name + ' ' + this.selectedYear.name;
		this.getChartData();
	}

	/**
	 * Triggered when a Month is changed
	 * */
	onChangeMonths() {
		if (this.selectedMonth) {
			this.loadMonthReport();
		} else {
			this.loadYearReport();
		}
	}

	/**
	 * Triggered when a Year is changed
	 * */
	onChangeYears() {
		this.currentYear = this.selectedYear.value;
		this.dataSetLabel = this.selectedYear.name;
		this.getChartData();
	}

}

</script>

<style scoped>

.navigator-header {
	color: var(--dex-primary-color);
}

.title {
	font-size: 2rem;
}

.chip-header {
	font-size: 1.2rem;
}

</style>
