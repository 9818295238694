
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import {User} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import UserForm from "@/apps/administration/components/users/UserForm.vue";
import router from "@/router";
import EntryDialog from "@/components/EntryDialog.vue";
import Message from "primevue/message";
import {useStore} from "@/store";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "UserView",
	components: {
		UserForm, BlockUI, EntryDialog, Message, InputText,
	},
	props: {
		userId: {
			type: Number,
			default: null,
		},
	},
	emits: [],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		const tokenLinkRef = ref<any>()

		/** Show Loading on load data */
		const loading = ref(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** Currently active user */
		const user = reactive<User>({
			login: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
		})

		/** Should the dialog be shown? */
		const showDialog = ref(false);

		/** Should the loading in the dialog be shown? */
		const loadingDialog = ref(false);

		/** Is the Form of the master data invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for public requests */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Show the link to copy token? */
		const showCopyLink = ref(false);

		/** Link for Reset token */
		const tokenLink = ref('');

		/** Triggered on update of attribute-form */
		const onUpdate = (data: User, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(user, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			if (props.userId) {
				loading.value = true;
				userService.getUserById(props.userId)
					.then((data: User) => {
						Object.assign(user, data);
					})
					.catch((response: any) => response.json().then((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		onMounted(() =>{
			reloadData();
		})

		/** Saves an user */
		const saveUser = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loading.value = true;

			if (!user.password) {
				user.password = undefined;
			}

			if (!user.id) {
				userService.addUser(user)
					.then(data => {
						// FIXME: I don't want to parse this json when the Client is fixed
						return data.json()
					})
					.then((data: {id: number}) => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
						router.push({ name: 'UserTabsView', params: { userId: data.id }});
						user.id = data.id;
					}).catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					})).finally(() => {
						loading.value = false;
					});
			} else {
				userService.updateUser(user.id, user).then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
			}
		}

		/** Opens the reset-dialog */
		const openResetDialog = () => {
			showDialog.value = true;
		}

		/**
		 * Send Reset Mail
		 */
		const sendResetMail = () => {
			loadingDialog.value = true;
			publicApi.sendResetPasswortEmail(user.login!).then((data) => {
				if (data.value) {
					showCopyLink.value = true;
					tokenLink.value = data.value;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserManagement.TokenCreated'));
				}
				else {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Login.SendMailSuccess'));
				}
				showDialog.value = false;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
			})
		}

		/** Triggered on click of the button */
		const onClickCopy = () => {
			tokenLinkRef.value.$el.select();
			document.execCommand('copy');
			ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserManagement.LinkCopied'));
		}

		return {
			t,
			toast,
			loading, tokenLinkRef,
			showValidationMessage,
			isValidationInvalid,
			user, showDialog, loadingDialog,
			onUpdate, store, showCopyLink, tokenLink,
			reloadData, sendResetMail, onClickCopy,
			saveUser, openResetDialog,
		};
	},
});

