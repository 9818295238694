<template>
	<div class="p-col-12 p-grid p-mr-0 p-ml-0 p-pb-0 p-pt-3">
		<div class="p-col-8 p-pl-0">
			<h1 class="headline p-component p-m-0">{{ $t('Squeeze.UserSettings.Account.UserAccount') }}</h1>
		</div>
		<div class="p-col-4 p-as-center p-grid p-jc-end">
			<Button class="p-button p-button-rounded p-button-text" icon="mdi mdi-close" @click="clickSettingDialog" />
		</div>
	</div>
	<div class="p-col-12 p-grid nested-grid p-m-0">
		<div class="p-fluid p-component p-formgrid p-grid p-input-filled p-col-12">
			<div class="p-field p-col-12">
				<label for="email">{{ $t('Squeeze.UserSettings.Account.ChangeEmail') }}</label>
				<div class="p-inputgroup">
					<InputText id="email" :placeholder="store.state.user.email" v-model="value.email" :class="{'p-invalid':v$.email.$invalid && showErrorMessage}" @change="onChangeEmail" autofocus maxLength="255" :disabled="loading" style="text-transform: lowercase;" />
					<Button v-tooltip.bottom="$t('Squeeze.General.Save')" icon="mdi mdi-content-save-outline" class="p-button" @click="saveNewEmail" :disabled="loading" />
				</div>
				<small v-if="showErrorMessage" class="p-error">
					<template v-if="v$.email.email.$invalid">
						{{$t('Forms.Val.Email')}}
					</template>
					<template v-else-if="v$.email.$invalid">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Email')}) }}
					</template>
				</small>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {User} from "@dex/squeeze-client-ts";
import InputText from 'primevue/inputtext';
import {email, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useStore} from "@/store";

export default defineComponent({
	name: 'UserAccount',
	components: {
		InputText,
	},
	emits: ['clickSettingDialog'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Show loading? */
		const loading = ref<boolean>(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** Current Object with email of user */
		const value = reactive<User>({
			email: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			email: { required, email},
		}

		/** Show error validation-messages in form? */
		const showErrorMessage = ref(false);

		/** Is the Form invalid? */
		const isValidationInvalid = ref(true);

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog');
		}

		const onChangeEmail = () => {
			isValidationInvalid.value = v$.value.$invalid;
		}

		/** Save the new user email */
		const saveNewEmail = () => {
			if (isValidationInvalid.value) {
				showErrorMessage.value = true;
				return;
			}
			showErrorMessage.value = false;
			if (value.email) {
				loading.value = true;
				userService.putUserEmail(value.email)
					.then(() => {
						store.dispatch("fetchUserData");
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserSettings.Account.SaveEmailSuccess'));
					})
					.catch((error: {message: string; status: number}) => {
						if (error.status === 304) {
							return
						}
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
					})
					.finally(() => {
						loading.value = false;
					});
			}
		}

		onMounted(async () => {
			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			isValidationInvalid.value = v$.value.$invalid;
		});

		return {
			t,
			toast,
			store,
			loading,
			v$,
			value,
			showErrorMessage,
			clickSettingDialog,
			onChangeEmail,
			saveNewEmail,
		};
	},
});

</script>

<style lang="scss" scoped>

.headline {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin-bottom: 1rem;
}

</style>
