
import {defineComponent, nextTick, onMounted, PropType, ref, watch} from "vue";
import {DocumentLocator} from "@dex/squeeze-client-ts";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import {useStore} from "@/store";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "DocumentLocatorTestingForm",
	components: {
		Button, Dropdown,
	},
	props: {
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		'onChangeLocator', 'testLocator', 'openEntryLocator', "openLog",
	],
	setup(props, {emit}) {
		const store = useStore();

		/** Selected Locator */
		const selectedLocator = ref<DocumentLocator | null>(null);

		/** Is editLocator-Button shown? */
		const showEditLocatorButton = ref<boolean>(true);

		/** Get the selected locator for test */
		const onChangeLocator = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit('onChangeLocator', selectedLocator.value, true);
			showEditLocatorButton.value = store.state.isAdminUser;
		}

		/** Test the selected locator */
		const testLocator = () => {
			showEditLocatorButton.value = store.state.isAdminUser;
			if(selectedLocator.value) {
				emit("testLocator", selectedLocator.value)
			}
		}

		/** Show entry for current Locator to editing */
		const openEntryLocator = () => {
			showEditLocatorButton.value = false;
			emit("openEntryLocator", true)
		}

		/** Open Log for current Locator */
		const showLog = () => {
			emit("openLog", selectedLocator.value)
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.locators, () => {
			if (props.locators && props.locators[0] && !selectedLocator.value) {
				selectedLocator.value = props.locators[0];
				return emit("testLocator", selectedLocator.value)
			}
		})

		onMounted(() => {
			showEditLocatorButton.value = store.state.isAdminUser;
		})

		return {
			store,
			selectedLocator,
			showEditLocatorButton,
			onChangeLocator,
			testLocator,
			openEntryLocator,
			showLog,
			showDropdownOverlay,
		}
	},
});
