<template>
	<BlockUI :blocked="blocked">
		<div class="p-fluid p-input-filled p-formgrid p-grid p-pb-2 p-px-2">
			<div class="p-field p-col-4 p-ml-auto">
				<Dropdown v-model="selectedLanguage" :options="languages" optionLabel="description" optionValue="countryCode" :placeholder="$t('Squeeze.Language.Selection')" @change="changeLanguage">
					<template #option="slotProps">
						<div v-tooltip.bottom="!SupportedLangs.includes(slotProps.option.countryCode) ? $t('Squeeze.General.LanguageNotAvailable') : ''">
							<span>{{slotProps.option.description}}</span>
							<i v-if="!SupportedLangs.includes(slotProps.option.countryCode)" class="mdi mdi-alert-outline icon-error"></i>
						</div>
					</template>
				</Dropdown>
			</div>
			<!--div v-show="isLoggedIn" class="p-col-12">
				<p>
					Sie sind bereits eingeloggt. Sie können die Anwendung nutzen, ohne sich erneut einzuloggen.
				</p>
			</div-->
			<div class="p-field p-col-12">
				<label for="username">{{$t('Squeeze.Login.UserName')}}</label>
				<InputText id="username" v-model.trim="username" required="true" @keydown="onKeyDown" ref="usernameInput" autofocus />
			</div>
			<div class="p-field p-col-12">
				<label for="password">{{$t('Squeeze.Login.Password')}}</label>
				<Password id="password" v-model.trim="password" required="true" :feedback="false" @keydown="onKeyDown" />
			</div>
			<div v-if="basicAuthentication" class="p-col-12 p-mt-2">
				<Button label="Login" @click="login()"/>
			</div>
			<div v-if="keyCloakAuthentication" class="p-col-12 p-mt-2">
				<Button label="Login with Keycloak / SSO" @click="loginWithKeyCloak()"/>
			</div>
			<div class="p-col-12 p-mt-2">
				<label for="forgotPassword">
					<router-link :to="{name: 'Recover'}" custom v-slot="{navigate, href}">
						<a :href="href" class="p-component p-link" @click="navigate" >
							{{$t('Squeeze.Login.ForgotPassword')}}
						</a>
					</router-link>
				</label>
			</div>
<!--			<div class="p-col-4">-->
<!--				<Button label="Logout" @click="logout()" :disabled="!isLoggedIn"/> -->
<!--			</div>-->
		</div>
	</BlockUI>
</template>

<script lang="ts">
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import Dropdown from 'primevue/dropdown';
import BlockUI from 'primevue/blockui';
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {DEFAULT_LOCALE, getActiveLanguage, SupportedLangs} from "@/lang";
import {defineComponent, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import {TranslationCountry} from "@dex/squeeze-client-ts";

const activeLanguage = getActiveLanguage();

export default defineComponent({
	name: 'LoginForm',
	components: {
		Password, InputText, Button, BlockUI, Dropdown,
	},
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const { locale } = useI18n({ useScope: 'global' })

		/** Is the user logged in? */
		const isLoggedIn = ref(false);

		/** Name of the user */
		const username = ref('');

		/** Password of the user */
		const password = ref('');

		/** Should the ui be blocked? */
		const blocked = ref(false);

		/** Currently selected language */
		const selectedLanguage = ref<string|null>(null);

		/** Languages for UI */
		const languages = ref<TranslationCountry[]>([]);

		/** Is basic authentication available? */
		const basicAuthentication = ref(false);

		/** Is key clock authentication available? */
		const keyCloakAuthentication = ref(false);

		/** Public API endpoint */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Set current Language */
		const setLanguage = (language: TranslationCountry | undefined) => {
			if (language && language.countryCode) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
			} else {
				locale.value = DEFAULT_LOCALE;
				selectedLanguage.value = DEFAULT_LOCALE;
			}
		}

		/** Check Language by first load */
		const checkLanguage = () => {
			if (localStorage.getItem('language')) {
				const language = languages.value.find(language => language.countryCode ===  localStorage.getItem('language'));
				setLanguage(language);
			} else {
				// Check browser language if no local storage language
				const language = languages.value.find(language => language.countryCode === navigator.language.substring(0, 2));
				setLanguage(language);
				localStorage.setItem('language', locale.value);
			}
		}

		/** Get all languages for UI */
		const getLanguages = () => {
			publicApi.getActiveCountries(true)
				.then((data: TranslationCountry[]) => {
					if (data.length === 0) {
						throw new Error("Missing languages in list!");
					}
					languages.value = data;
				}).catch(() => {
					// set default languages
					languages.value = [
						{description: t('Squeeze.Language.German'), countryCode: 'de'},
						{description: t('Squeeze.Language.English'), countryCode: 'en'},
					];
				}).finally(() => {
					checkLanguage();
				})
		}

		onMounted(async () =>{
			// Clear Session Cookie when login is opened
			if (process.env.NODE_ENV !== "development") {
				ClientManager.getInstance().clearPHPSessionCookie();
			}

			ClientManager.getInstance().resetKeyCloakTokens();

			// check current language before get all languages from api
			if (localStorage.getItem('language')) {
				locale.value = localStorage.getItem('language')!;
			}
			await getLanguages();

			try {
				blocked.value = true;
				await ClientManager.getInstance().setPossibleAuthentications();
				basicAuthentication.value = ClientManager.getInstance().login.basicAvailable;
				keyCloakAuthentication.value = ClientManager.getInstance().login.keyCloakAvailable;

				blocked.value = false;
			}
			catch (err) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				blocked.value = false;
			}
		});

		/** Triggers the login with keycloak */
		const loginWithKeyCloak = async () => {
			blocked.value = true;

			await ClientManager.getInstance().keyCloakLogin();

			/*ClientManager.getInstance().keyCloakLogin(username.value, password.value)
				.then(async () => {
					isLoggedIn.value = await ClientManager.getInstance().isAuthenticatedAtSqueeze();

					if (!isLoggedIn.value) {
						ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), "")
					} else {
						// Fetch user-data if login is successful
						try {
							await store.dispatch("resetStore");
							await store.dispatch("fetchUserData");
							await store.dispatch("fetchFeatureSet");
							await store.dispatch("fetchMigrations");
							await store.dispatch("fetchUserSettings");
							await store.dispatch("fetchSystemInformation");
							emit("loginStatus", true);
						} catch (err: any) {
							// If the user data can't be fetched, the user should login again
							ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
							await router.push({name: "Login", params: {lang: activeLanguage}})
						}
					}
				})
				.catch((error) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error);
				}).finally(() => blocked.value = false);
			 */
		}

		/** Triggers the basic login of the user */
		const login = async () => {
			blocked.value = true;

			try {
				// Later on, login will be more complicated. Right now it is enough to store the username + password in a variable.
				// Upon the next API request, the browser will receive the "usual" PHP session cookie that allows authentication without username + password
				isLoggedIn.value = await ClientManager.getInstance().loginBasic(username.value, password.value);

				if (!isLoggedIn.value) {
					ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), "")
				} else {
					// Fetch user-data if login is successful
					try{
						emit("loginStatus", true);
					}
					catch (err: any) {
						// If the user data can't be fetched, the user should login again
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
						await router.push({name: "Login", params: { lang: activeLanguage}})
					}
				}
			} catch (response) {
				ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), response);
			} finally {
				blocked.value = false;
			}
		}

		/** Logs the user out */
		const logout = () => {
			try {
				blocked.value = true;
				ClientManager.getInstance().clearPHPSessionCookie();
				isLoggedIn.value = false;
				ToastManager.showSuccess(toast, t("Squeeze.Login.LogoutSuccessful"))
				emit("loginStatus", false);
			} catch (e) {
				ToastManager.showError(toast, t("Squeeze.Login.LogoutFailed"), String(e))
			} finally {
				blocked.value = false;
			}
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter") {
				login();
			}
		}


		/** Triggered when a language is selected */
		const changeLanguage = () => {
			const language = languages.value.find(language => language.countryCode === selectedLanguage.value);
			if (language && language.countryCode) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
			}
			getLanguages();
		}

		return { username, password, blocked, selectedLanguage, languages, isLoggedIn,
			basicAuthentication, keyCloakAuthentication, SupportedLangs,
			changeLanguage, onKeyDown, logout, login, loginWithKeyCloak }
	},
})
</script>

<style scoped>
.p-link {
	color: var(--dex-primary-color);
}

::v-deep(.p-blockui.p-component-overlay.p-blockui-leave) {
	display: none;
}

.icon-error {
	color: var(--error-color);
	float: right;
}
</style>
