<template>
	<DataTable
		:value="queueEntries"
		class="p-datatable-sm p-datatable-documents"
		:lazy="true"
		:paginator="true"
		:loading="loading || lockTable"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="paginationInfo.pageSize"
		:totalRecords="paginationInfo.total"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		v-model:selection="selectedRows"
		@row-click="onRowClick"
		@page="onPage($event)"
		:first="(paginationInfo.page * paginationInfo.pageSize)"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		:filterDisplay="stepName !== 'ErrorBasket' ? 'row' : ''"
		v-model:filters="filters"
		@filter="onFilter($event)"
		@sort="onSort($event)"
		:removableSort="true"
		:metaKeySelection="false"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{$t('Squeeze.Queue.Filter.NoEntries')}}
		</template>

		<Column v-if="store.state.scopes.sqzRequeue" selectionMode="multiple" style="min-width: 4rem; max-width: 4rem; justify-content: center;" />
		<Column field="documentId" :header="$t('Squeeze.Queue.Fields.DocID')" style="min-width: 6rem; max-width: 6rem;" filterField="documentId" :showFilterMenu="false" :sortable="true">
			<template #body="slotProps">
				{{ slotProps.data.documentId }}
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 4rem; max-width: 6rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="" />
			</template>
		</Column>
		<Column field="id" :header="$t('Squeeze.Queue.Fields.QueueID')" style="min-width: 6rem; max-width: 6rem;" filterField="id" :showFilterMenu="false" :sortable="true">
			<template #body="slotProps">
				{{ slotProps.data.id }}
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 4rem; max-width: 6rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="" />
			</template>
		</Column>

		<Column field="batchClassId" :header="$t('Squeeze.BatchClasses.BatchClass')" style="min-width: 10rem; max-width: 12rem;" filterField="batchClassId" :showFilterMenu="false" :sortable="true">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getDescriptionFromBatchClass(slotProps.data.batchClassId)">
					{{getDescriptionFromBatchClass(slotProps.data.batchClassId)}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<Dropdown
					v-model="filterModel.value"
					@change="filterCallback()"
					:options="batchClasses"
					placeholder=""
					class="p-column-filter filter-field"
					optionValue="id"
					optionLabel="description"
					:showClear="true"
				/>
				<!--<InputText style="min-width: 5rem; max-width: 12rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="" />-->
			</template>
		</Column>
		<Column field="documentClassId" :header="$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass')" style="min-width: 10rem; max-width: 12rem;" filterField="documentClassId" :showFilterMenu="false" :sortable="true">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getDescriptionFromDocumentClass(slotProps.data.documentClassId)">
					{{getDescriptionFromDocumentClass(slotProps.data.documentClassId)}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<Dropdown
					v-model="filterModel.value"
					@change="filterCallback()"
					:options="documentClasses"
					placeholder=""
					class="p-column-filter filter-field"
					optionValue="id"
					optionLabel="description"
					:showClear="true"
				/>
				<!--<InputText style="min-width: 5rem; max-width: 12rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="" />-->
			</template>
		</Column>

		<Column field="status" :header="$t('Squeeze.Queue.Fields.status')" style="min-width: 10rem; max-width: 15rem;" filterField="status" :showFilterMenu="false" :sortable="true">
			<template #body="slotProps">
				<div class="column-cell p-py-1" :class="'status-badge ' + slotProps.data.workflowContext.status.toLowerCase()">
					{{$t("Squeeze.Queue.States." + slotProps.data.workflowContext.status)}}
				</div>
			</template>
			<template #filter="{filterModel,filterCallback}">
				<Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="" class="p-column-filter filter-field" style="height: 2.111rem;" :showClear="true">
					<template #value="slotProps">
						<span v-if="slotProps.value">{{ $t("Squeeze.Queue.States." + slotProps.value.toUpperCase()) }}</span>
						<span v-else>{{ slotProps.placeholder }}</span>
					</template>
					<template #option="slotProps">
						<span :class="'status-badge ' + slotProps.option.toLowerCase()">{{ $t("Squeeze.Queue.States." + slotProps.option) }}</span>
					</template>
				</Dropdown>
			</template>
		</Column>
		<Column field="errorText" :header="$t('Squeeze.General.Comment')" style="min-width: 10rem;" filterField="errorText" :showFilterMenu="false" :sortable="true">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.workflowContext.errorText">
					{{slotProps.data.workflowContext.errorText}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="" />
			</template>
		</Column>

		<Column field="createdTs" :header="$t('Squeeze.WorkflowContext.createdTs')" style="min-width: 10rem;" :sortable="true">
			<template #body="slotProps">
				{{formatDate(slotProps.data.workflowContext.createdTs)}}
			</template>
		</Column>

		<Column field="modifiedTs" :header="$t('Squeeze.WorkflowContext.modifiedTs')" style="min-width: 10rem;" :sortable="true">
			<template #body="slotProps">
				{{formatDate(slotProps.data.workflowContext.modifiedTs)}}
			</template>
		</Column>

		<!-- Refresh / Delete / Download Zip file -->
		<Column class="buttonColumn" style="min-width: 10rem; max-width: 10rem;">
			<template #body="slotProps">
				<Button v-if="store.state.scopes.sqzAdmin && store.state.featureSet.documentLog" v-tooltip="'Log'" icon="mdi mdi-script-text-outline" class="p-button p-button-rounded p-button-text" type="button" @click="openLogDialog(slotProps.data.documentId)" />
				<Button v-tooltip.bottom="$t('Squeeze.Attachments.ZIPDownlaod')" icon="mdi mdi-folder-download-outline" class="p-button p-button-rounded p-button-text" type="button" @click="downloadAttachment(slotProps.data)" />
				<Button v-tooltip.left="$t('Squeeze.Queue.Requeue.Requeue')"
						icon="mdi mdi-file-refresh-outline"
						class="p-button p-button-rounded p-button-success p-button-text"
						@click="openRequeueMenu($event, slotProps.data)"
						aria-haspopup="true" aria-controls="single_entry_menu"
						:disabled="!store.state.scopes.sqzRequeue"
				/>
				<Menu id="single_entry_menu" ref="singleEntryMenu" :model="steps" :popup="true" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button p-button-rounded p-button-danger p-button-text" @click="deleteDocument(slotProps.data)" :disabled="stepName === 'Delete'" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button v-tooltip="$t('Squeeze.General.Refresh')" type="button" icon="mdi mdi-refresh" class="p-button-text" @click="onReload" :disabled="loading" />
			<Button v-tooltip="$t('Squeeze.Queue.General.DeleteEntries')" type="button" icon="mdi mdi-delete-outline" class="p-button-text" @click="deleteDocuments" :disabled="loading || !selectedRows.length || stepName === 'Delete'" />
			<Button type="button" icon="mdi mdi-file-refresh-outline"
					class="p-button-text"
					:label="$t('Squeeze.Queue.Requeue.Requeue')"
					@click="openMultiRequeueMenu"
					:disabled="!selectedRows.length || !store.state.scopes.sqzRequeue"
					aria-haspopup="true" aria-controls="multi_entry_menu"
			/>
			<Menu id="multi_entry_menu" ref="multiEntryMenu" :model="steps" :popup="true" />
			<Button type="button" icon="mdi mdi-folder-open" class="p-button-text" :label="$t('Squeeze.Queue.General.GoTo')" @click="openGoToMenu" aria-haspopup="true" aria-controls="multi_entry_menu_goto" />
			<Menu id="multi_entry_menu_goto" ref="goToMenu" :model="stepsGoTo" :popup="true" />
			<Button v-if="showBtnClearFilter" :label="$t('Squeeze.General.ClearFilters')" type="button" icon="mdi mdi-filter-off-outline" class="p-button-text" @click="clearFilters"/>
		</template>
		<template #paginatorRight></template>
	</DataTable>

	<!-- Dialog für Felder -->
	<EntryDialog
		:show="showRequeue"
		@onClose="showRequeue = false"
		@onConfirm="confirmRequeue"
		:loading="loading"
		:headerText="$t('Squeeze.Queue.Requeue.Forward')"
		:saveButtonText="$t('Squeeze.General.Yes')"
		:abortButtonText="$t('Squeeze.General.No')"
	>
		<template #content>
			<BlockUI :blocked="loading">
				<div v-if="(requeueStep === 'Validation' || requeueStep === 'Extraction')" class="p-fluid p-formgrid p-grid p-input-filled p-input-filled">
					<Message severity="info" :closable="false">
						<p>{{headerText}}</p>
						<p>{{$t('Squeeze.Queue.Requeue.Information')}}</p>
					</Message>
					<div class="p-field p-col-12">
						<label for="classificationClasses">{{$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.NewDocumentClass')}}</label>
						<Dropdown
							id="classificationClasses"
							v-model="activeDocumentClass"
							:options="classificationClasses"
							optionValue="documentClassId"
							optionLabel="description"
							:placeholder="$t('Squeeze.Queue.Requeue.KeepCurrentDocumentClass')"
							:showClear="true"
						/>
					</div>
				</div>
				<Message v-else severity="info" :closable="false">
					<p>{{headerText}}</p>
				</Message>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
/* eslint max-lines: off */
import {defineComponent, ref, onMounted, PropType, computed, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {BatchClass, BatchClassClassification, DocumentField, ErrorDto, QueueEntry} from "@dex/squeeze-client-ts";
import {DEFAULT_LOCALE} from "@/lang";
import Squeeze from "@/apps/squeeze/App.vue";
import {ToastManager} from "@/singletons/ToastManager";
import {DateTimeOptions} from "@/interfaces/DateTimeOptions";
import {i18n} from "@/main";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from "primevue/tooltip";
import {useStore} from "@/store";
import {DocumentClass} from "@dex/squeeze-client-ts/api";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";

interface MenuItem {
	label: string;
	items: MenuSubItem[];
}

interface MenuSubItem {
	label: string;
	icon: string;
	command: {};
}

export default defineComponent({
	name: "QueueList",
	components: {
		Button, Menu, DataTable, Column, EntryDialog, BlockUI, Dropdown, Message, InputText,
	},
	props: {
		queueEntries: Array,
		loading: Boolean,
		paginationInfo: Object,
		stepName: String,
		batchClasses: {
			type: Array as PropType<BatchClass[]>,
			default: [],
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: [],
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage", "openLogDialog", "deleteDocuments", "goToStep", "onRequeue", "onFilter", "onSort", "clearFilters"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Document Class API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** Requeue steps to choose in menu */
		const steps = ref<MenuItem[]>([]);
		const stepsGoTo = ref<MenuItem[]>([]);

		/** Currently selected row or rows */
		const selection = ref<QueueEntry | null>(null);
		const selectedRows = ref<QueueEntry[]>([]);

		const lockTable = ref<boolean>(false);

		/** Current locale */
		const lang = ref<any>(DEFAULT_LOCALE);

		/** Get $refs of menus */
		const singleEntryMenu = ref(null);
		const multiEntryMenu = ref(null);
		const goToMenu = ref(null);

		/** Is Requeue Dialog visible? */
		const showRequeue = ref<boolean>(false);

		/** Is Requeue Dialog open by single entry? */
		const requeueSingleDocument = ref<boolean>(false);

		/** Name of the next step to requeue */
		const requeueStep = ref<string>('');

		/** Service for getting the all batchClasses */
		const batchClassService = ClientManager.getInstance().squeeze.batchClass;

		/** List of valid classification classes */
		const classificationClasses = ref<BatchClassClassification[]>([]);

		/** Currently active Document-Class */
		const activeDocumentClass = ref<number | undefined>(undefined);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (selectedRows.value.length > 1) {
				return t('Squeeze.Queue.Requeue.ForwardEntries') + ": " + t('Squeeze.Queue.Steps.' + requeueStep.value) + "?";
			}

			return  t('Squeeze.Queue.Requeue.ForwardEntry') + ": "  + t('Squeeze.Queue.Steps.' + requeueStep.value) + "?";
		});

		/** QueueStates for filter selection */
		const statuses =  ref<string[]>([
			'CLARIFICATION', 'ERROR', 'FINISHED', 'INITIAL', 'NEW', 'SUSPEND', 'WAITING', 'WORK',
		]);

		/** Watch prop at set filters object, because props are not allowed to be mutated */
		watch(() => props.filterOptions, () => {
			filters.value = props.filterOptions as any;
		})

		/** Get all DocumentClasses */
		const getClassificationClasses = (batchClassId: number) => {
			batchClassService.getBatchClassClassifications(batchClassId)
				.then(data => {
					classificationClasses.value = data;
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * Triggered when a row is clicked
		 * @param event
		*/
		const onRowClick = (event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) => {
			/* Prevent the row-click in the selection-column with the checkbox
			* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
			* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
			*/
			if (event.originalEvent
				&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/**
		 * Triggered when the next page is selected
		 * @param event
		*/
		const onPage = (event: any) => {
			selectedRows.value = [];
			emit("onPage", event)
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			selectedRows.value = [];
			emit("onReload");
		}

		/**
		 * Opens requeue menu
		 * @param event
		 * @param data
		 */
		const openRequeueMenu = (event: MouseEvent, data: QueueEntry) => {
			selection.value = data;
			const singleMenu = singleEntryMenu.value as any;
			if(singleMenu) {
				singleMenu.toggle(event);
			}
		}

		/**
		 * Opens multi requeue menu
		 * @param event
		 */
		const openMultiRequeueMenu = (event: any) => {
			if (!selectedRows.value.length) {
				return;
			}
			const multiMenu = multiEntryMenu.value as any;
			if(multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Opens the Requeue Menu
		 * @param event
		 */
		const openGoToMenu = (event: any) => {
			const multiMenu = goToMenu.value as any;
			if(multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Requeues selected QueueEntry to specified step
		 * @param step
		 */
		const requeueEntry = (step: string) => {
			if(selection.value && selection.value.documentId) {
				// REQUEUE API ENDPOINT
				lockTable.value = true;
				showRequeue.value = false;
				documentApi.requeueDocument(selection.value.documentId, step, activeDocumentClass.value).then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				}).catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					selection.value = null;
					lockTable.value = false;
					onReload();
				})
			} else {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Select.Entry.One'));
			}
		}

		/**
		 * Requeue the selected entries
		 * @param step
		 */
		const requeueSelectedEntries = (step: string) => {
			if (!selectedRows.value || !selectedRows.value.length) {
				return;
			}

			lockTable.value = true;
			showRequeue.value = false;
			selectedRows.value.reduce((chain, { documentId }) => {
				if (documentId == undefined) {
					return chain;
				}
				return chain.finally(() => documentApi.requeueDocument(documentId, step, activeDocumentClass.value).catch(() => { /* swallow errors */ }))
			}, Promise.resolve()).finally(() => {
				ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				selectedRows.value = [];
				lockTable.value = false;
				onReload();
			});
		}

		const confirmRequeue = () => {
			if (requeueSingleDocument.value === true) {
				requeueEntry(requeueStep.value);
			} else {
				requeueSelectedEntries(requeueStep.value);
			}
		}


		/**
		 * Formats date string to desired locale string
		 * @param dateToFormat
		 */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
					lang.value = i18n.global.locale;
					return date.toLocaleDateString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), options);
				}
			}
			return "";
		}

		/**
		 * arses an amount (string) to locale string
		 * @param value
		 */
		const parseAmounts = (value: string) => {
			value = value.replace(/[^0-9.,]/g, "");

			if(value.indexOf(",") != -1) {
				value = value.replaceAll(".", "").replace(",", ".");
			}

			if(value.length > 0) {
				return parseFloat(value).toLocaleString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), {minimumFractionDigits: 2});
			} else {
				return "0,00";
			}
		}

		/**
		 * Returns field value of passed DocumentField array
		 * @param fields
		 * @param fieldId
		 * @param type
		 */
		const getFieldValue = (fields: DocumentField[], fieldId: number, type: string) => {
			if(fields && fieldId) {
				const fieldValue = fields.find((field) => {
					return field.id === fieldId;
				});

				if(fieldValue != undefined) {
					if(fieldValue.value && fieldValue.value.value) {
						switch(type.toLowerCase()) {
						case "amount": return parseAmounts(fieldValue.value.value);
						case "date": return formatDate(fieldValue.value.value);
						default: return fieldValue.value.value;
						}
					}
				}
			}

			return "";
		}

		/**
		 * Resets the current selections
		 */
		const resetSelection = () => {
			selectedRows.value = [];
		}

		/**
		 * Triggered when another step should be shown
		 * @param step
		 */
		const goToStep = (step: string) => {
			resetSelection();
			emit("goToStep", step)
		}

		/**
		 * Download the current Attachment in QueueTable (as ZIP file)
		 * @param rowData current row
		 */
		const downloadAttachment = (rowData: QueueEntry) => {
			// will return the document id of selected row
			const documentId = rowData.documentId;

			const downloadURL = ClientManager.getInstance().buildDocumentDownloadUrl(documentId as number);
			window.location.href = downloadURL;
		}

		onMounted(() => {
			// Initialize Steps
			steps.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];

			stepsGoTo.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];

			Squeeze.queueSteps.forEach((icon: string, step: string) => {
				steps.value[0].items.push({
					label: t('Squeeze.Queue.Steps.' + step),
					icon: icon,
					command: (ev: { item: any; originalEvent: MouseEvent }) => {
						activeDocumentClass.value = undefined;

						if ((ev.originalEvent.target as HTMLElement).matches('#multi_entry_menu *')) {
							// Get unique batchclass ids from selection
							const checkRows = selectedRows.value.map(row => row.batchClassId).filter((value, index, self) => self.indexOf(value) === index);
							if (checkRows.length > 1 && (step === 'Validation' || step === 'Extraction')) {
								ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Queue.Requeue.MultipleBatchClassError'));
								return;
							}

							showRequeue.value = true;
							requeueStep.value = step;
							requeueSingleDocument.value = false;
						}
						else if ((ev.originalEvent.target as HTMLElement).matches('#single_entry_menu *')) {
							showRequeue.value = true;
							requeueStep.value = step;
							requeueSingleDocument.value = true;
							selectedRows.value = [];
							if (selection.value) {
								getClassificationClasses(selection.value.batchClassId!)
							}
						}
					},
				});

				stepsGoTo.value[0].items.push({
					label: t('Squeeze.Queue.Steps.' + step),
					icon: icon,
					command: () => {
						goToStep(step)
					},
				});
			});

			// Add the delete Step for goto
			stepsGoTo.value[0].items.push({
				label: t('Squeeze.Queue.Requeue.Errors'),
				icon: "mdi mdi-basket-remove-outline",
				command: () => {
					goToStep("ErrorBasket")
				},
			});
		})

		/**
		 * Open the Dialog to show the Log of a Document
		 * @param documentId current documentId of row
		 */
		const openLogDialog = (documentId: number) => {
			emit("openLogDialog", documentId);
		}

		/**
		 * Triggers the delete of a document
		 * @param documentId
		 */
		const deleteDocument = (queueEntry: QueueEntry) => {
			emit("deleteDocuments", [queueEntry]);
		}

		/**
		 * Triggered when multiple entries are selected and these should be deleted
		 */
		const deleteDocuments = () => {
			emit("deleteDocuments", selectedRows.value);
		}

		/**
		 * Gets the Description of a documentClass
		 * @param documentClassId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClass = (documentClassId: number) => {
			if (documentClassId == null || documentClassId === 0) {
				return '-';
			}

			const documentClassDescription = props.documentClasses.find(i => i.id === documentClassId);

			if (!documentClassDescription) {
				return String(documentClassId)
			}

			return documentClassDescription.description
		}

		/**
		 * Gets the Description of a documentClass
		 * @param batchClassId Id of the batchclass to get the Description from
		 * @return Description of the batchclass
		 */
		const getDescriptionFromBatchClass = (batchClassId: number) => {
			if (batchClassId == null || batchClassId === 0) {
				return '-';
			}

			const documentClassDescription = props.batchClasses.find(i => i.id === batchClassId);

			if (!documentClassDescription) { return String(batchClassId)}

			return documentClassDescription.description
		}

		/** Triggered on Filter-Event  */
		const onFilter = (event: any) => {
			showBtnClearFilter.value = event.filters.id.value
				|| event.filters.status.value
				|| event.filters.documentId.value
				|| event.filters.documentClassId.value
				|| event.filters.batchClassId.value
				|| event.filters.errorText.value;

			emit("onFilter", event.filters);
		}

		/** Triggered on sort a column */
		const onSort = (event: { sortField: string; sortOrder: number }) => {
			emit("onSort", event.sortField, event.sortOrder);
		}

		/** Clear filters */
		const clearFilters = () => {
			showBtnClearFilter.value = false;
			emit("clearFilters");
		}

		return {t, toast, store, filters, showBtnClearFilter, steps, selection, selectedRows, lockTable, lang, activeDocumentClass, requeueStep,
			singleEntryMenu, multiEntryMenu, goToMenu, stepsGoTo, headerText, showRequeue, classificationClasses, requeueSingleDocument, statuses,
			downloadAttachment, deleteDocument, deleteDocuments, getDescriptionFromDocumentClass, getDescriptionFromBatchClass,
			onRowClick, onPage, onReload, openRequeueMenu, openMultiRequeueMenu, requeueEntry, requeueSelectedEntries, formatDate, parseAmounts, getFieldValue, openLogDialog,
			resetSelection, openGoToMenu, confirmRequeue, getClassificationClasses, onFilter, onSort, clearFilters,
		};
	},
});

</script>

<style lang="scss" scoped>

// Table header
.p-datatable ::v-deep(.p-datatable-thead tr th) {
	background-color: var(--dex-primary-light-color);
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
	border-bottom-color: var(--dex-primary-light-color);
	color: var(--dex-text-color);
}

.p-datatable ::v-deep(.p-datatable-thead tr td) {
	padding: 0.6rem;
}

.p-datatable ::v-deep(.p-datatable-thead .p-filter-column) {
	padding:0 0 0.2rem 0.2rem;
	color: var(--filter-field-color);
}

.p-datatable ::v-deep(.p-datatable-thead .p-filter-column .p-column-filter-menu-button, .p-column-filter-clear-button) {
	color: var(--filter-field-color);
}

.p-datatable ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}
::placeholder {
	color: var(--filter-field-color);
}

.p-datatable ::v-deep(.p-datatable-tbody tr td) {
	padding: 0.6rem;
}

.status-badge {
	font-weight: bold;
	&.initial {
		border-color: var(--queuestate-initial-primary);
		color: var(--queuestate-initial-darker);
	}

	&.work {
		border-color: var(--queuestate-work-primary);
		color: var(--queuestate-work-darker);
	}

	&.waiting {
		border-color: var(--queuestate-waiting-primary);
		color: var(--queuestate-waiting-darker);
	}

	&.error {
		border-color: var(--queuestate-error-primary);
		color: var(--queuestate-error-darker);
	}

	&.suspend {
		border-color: var(--queuestate-suspend-primary);
		color: var(--queuestate-suspend-darker);
	}

	&.suspend-marked {
		border-color: var(--queuestate-suspend-darker);
		color: var(--queuestate-suspend-darker);
	}

	&.clarification {
		border-color: var(--queuestate-clarification-primary);
		color: var(--queuestate-clarification-darker);
	}

	&.finished {
		border-color: var(--queuestate-finished-primary);
		color: var(--queuestate-finished-darker);
	}
}

.p-datatable ::v-deep(.p-datatable-thead .p-dropdown.filter-field) {
	padding:0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}

	.p-dropdown-label {
		padding: 0 1rem 0 0.4rem;
		color: var(--filter-field-color);
	}
}

// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
	background-color: var(--dex-primary-light-color);
	padding: 0.6rem 0.6rem 0.2rem 0.3rem;
	border-bottom-color: var(--dex-primary-light-color);
	color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th:first-child) {
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

/** Reduce height of edit/delete buttons */
button.p-button.p-button-icon-only.p-button-rounded {
	height: 0rem;
}

input {
	width: 100%;
	border: none;
	border-radius: 0;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

