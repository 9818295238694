
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import Dropdown from 'primevue/dropdown';
import BlockUI from 'primevue/blockui';
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {DEFAULT_LOCALE, getActiveLanguage, SupportedLangs} from "@/lang";
import {defineComponent, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import {TranslationCountry} from "@dex/squeeze-client-ts";

const activeLanguage = getActiveLanguage();

export default defineComponent({
	name: 'LoginForm',
	components: {
		Password, InputText, Button, BlockUI, Dropdown,
	},
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const { locale } = useI18n({ useScope: 'global' })

		/** Is the user logged in? */
		const isLoggedIn = ref(false);

		/** Name of the user */
		const username = ref('');

		/** Password of the user */
		const password = ref('');

		/** Should the ui be blocked? */
		const blocked = ref(false);

		/** Currently selected language */
		const selectedLanguage = ref<string|null>(null);

		/** Languages for UI */
		const languages = ref<TranslationCountry[]>([]);

		/** Is basic authentication available? */
		const basicAuthentication = ref(false);

		/** Is key clock authentication available? */
		const keyCloakAuthentication = ref(false);

		/** Public API endpoint */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Set current Language */
		const setLanguage = (language: TranslationCountry | undefined) => {
			if (language && language.countryCode) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
			} else {
				locale.value = DEFAULT_LOCALE;
				selectedLanguage.value = DEFAULT_LOCALE;
			}
		}

		/** Check Language by first load */
		const checkLanguage = () => {
			if (localStorage.getItem('language')) {
				const language = languages.value.find(language => language.countryCode ===  localStorage.getItem('language'));
				setLanguage(language);
			} else {
				// Check browser language if no local storage language
				const language = languages.value.find(language => language.countryCode === navigator.language.substring(0, 2));
				setLanguage(language);
				localStorage.setItem('language', locale.value);
			}
		}

		/** Get all languages for UI */
		const getLanguages = () => {
			publicApi.getActiveCountries(true)
				.then((data: TranslationCountry[]) => {
					if (data.length === 0) {
						throw new Error("Missing languages in list!");
					}
					languages.value = data;
				}).catch(() => {
					// set default languages
					languages.value = [
						{description: t('Squeeze.Language.German'), countryCode: 'de'},
						{description: t('Squeeze.Language.English'), countryCode: 'en'},
					];
				}).finally(() => {
					checkLanguage();
				})
		}

		onMounted(async () =>{
			// Clear Session Cookie when login is opened
			if (process.env.NODE_ENV !== "development") {
				ClientManager.getInstance().clearPHPSessionCookie();
			}

			ClientManager.getInstance().resetKeyCloakTokens();

			// check current language before get all languages from api
			if (localStorage.getItem('language')) {
				locale.value = localStorage.getItem('language')!;
			}
			await getLanguages();

			try {
				blocked.value = true;
				await ClientManager.getInstance().setPossibleAuthentications();
				basicAuthentication.value = ClientManager.getInstance().login.basicAvailable;
				keyCloakAuthentication.value = ClientManager.getInstance().login.keyCloakAvailable;

				blocked.value = false;
			}
			catch (err) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				blocked.value = false;
			}
		});

		/** Triggers the login with keycloak */
		const loginWithKeyCloak = async () => {
			blocked.value = true;

			await ClientManager.getInstance().keyCloakLogin();

			/*ClientManager.getInstance().keyCloakLogin(username.value, password.value)
				.then(async () => {
					isLoggedIn.value = await ClientManager.getInstance().isAuthenticatedAtSqueeze();

					if (!isLoggedIn.value) {
						ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), "")
					} else {
						// Fetch user-data if login is successful
						try {
							await store.dispatch("resetStore");
							await store.dispatch("fetchUserData");
							await store.dispatch("fetchFeatureSet");
							await store.dispatch("fetchMigrations");
							await store.dispatch("fetchUserSettings");
							await store.dispatch("fetchSystemInformation");
							emit("loginStatus", true);
						} catch (err: any) {
							// If the user data can't be fetched, the user should login again
							ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
							await router.push({name: "Login", params: {lang: activeLanguage}})
						}
					}
				})
				.catch((error) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error);
				}).finally(() => blocked.value = false);
			 */
		}

		/** Triggers the basic login of the user */
		const login = async () => {
			blocked.value = true;

			try {
				// Later on, login will be more complicated. Right now it is enough to store the username + password in a variable.
				// Upon the next API request, the browser will receive the "usual" PHP session cookie that allows authentication without username + password
				isLoggedIn.value = await ClientManager.getInstance().loginBasic(username.value, password.value);

				if (!isLoggedIn.value) {
					ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), "")
				} else {
					// Fetch user-data if login is successful
					try{
						emit("loginStatus", true);
					}
					catch (err: any) {
						// If the user data can't be fetched, the user should login again
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
						await router.push({name: "Login", params: { lang: activeLanguage}})
					}
				}
			} catch (response) {
				ToastManager.showError(toast, t("Squeeze.Login.LoginFailed"), response);
			} finally {
				blocked.value = false;
			}
		}

		/** Logs the user out */
		const logout = () => {
			try {
				blocked.value = true;
				ClientManager.getInstance().clearPHPSessionCookie();
				isLoggedIn.value = false;
				ToastManager.showSuccess(toast, t("Squeeze.Login.LogoutSuccessful"))
				emit("loginStatus", false);
			} catch (e) {
				ToastManager.showError(toast, t("Squeeze.Login.LogoutFailed"), String(e))
			} finally {
				blocked.value = false;
			}
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter") {
				login();
			}
		}


		/** Triggered when a language is selected */
		const changeLanguage = () => {
			const language = languages.value.find(language => language.countryCode === selectedLanguage.value);
			if (language && language.countryCode) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
			}
			getLanguages();
		}

		return { username, password, blocked, selectedLanguage, languages, isLoggedIn,
			basicAuthentication, keyCloakAuthentication, SupportedLangs,
			changeLanguage, onKeyDown, logout, login, loginWithKeyCloak }
	},
})
