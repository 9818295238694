<template>
	<div class="p-p-2 child-content">
		<RolesList
			:roles="roles"
			:loading="loading"
			@onClickDelete="openDeleteDialog"
			@onEntrySelect="onEntrySelect"
			:selectionMode="null"
			:userId="userId"
			@reloadRolesList="reloadRolesList"
		/>
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />

	<!-- Dialog für Felder -->
	<EntryDialog
		:show="showDialog"
		@onClose="showDialog = false, isValidationInvalid = true, showValidationMessage = false"
		@onConfirm="saveRoleForUser"
		:loading="loadingDialog"
		:headerText="headerText"
		:showKeepDialogOpen="true"
	>
		<template #content>
			<BlockUI :blocked="loadingDialog">
				<RoleUserForm
					:role="role"
					:allRoles="allRoles"
					:showErrorMessage="showValidationMessage"
					@update="onUpdate"
				/>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {User, UserRole} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import RolesList from "@/apps/administration/components/roles/RolesList.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import RoleUserForm from "@/apps/administration/components/users/RoleUserForm.vue";

/** Extended Interface for Inherited Rules */
interface InheritedRule extends UserRole {
	isInherited: boolean;
}


export default defineComponent({
	name: "RolesForUserView",
	components: {
		RolesList, DialogDelete, EntryDialog, BlockUI, RoleUserForm,
	},
	props: {
		userId: {
			type: Number,
			default: null,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Service for getting the role-data */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** List of all roles for this user */
		const roles = ref<UserRole[]>([]);

		/** List of all roles available in the system */
		const allRoles = ref<UserRole[]>([]);

		/** Should the Entry-Dialog for roles be shown? */
		const showDialog = ref<boolean>(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the users invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Currently active role */
		const role = reactive<UserRole>({
			title: '',
			description: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.AddEntry', { entryName: t('Squeeze.UserManagement.Role') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (roleSet: UserRole) => {
			Object.assign(role, roleSet);
			showDialog.value = true;
		}

		/** Reloads the data */
		const reloadData = () => {
			if (props.userId) {
				loading.value = true;
				userService.getUserById(props.userId)
					.then((data: User) => {
						roles.value = data.roles!;

						roles.value = data.roles!.map(role => {
							return {...role, isInherited: false};
						}) as InheritedRule[];

						// Add inherited rules
						const inheritedRules = data.inheritedRoles!.map(role => {
							return {...role, isInherited: true};
						}) as InheritedRule[];

						// Combine Arrays
						roles.value = roles.value.concat(inheritedRules);
					})
					.catch((response: any) => response.json().then((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		/** Gets a list of all roles of the system */
		const loadAllRoles = () => {
			roleService.getRoles()
				.then(data => {
					allRoles.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			reloadData();
			loadAllRoles();
		})

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: UserRole) => {
			deleteDialog.value = true;
			Object.assign(role, row);
		}

		/** Saves an user */
		const saveRoleForUser = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			userService.addRoletoUser(props.userId, role.id!).then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					role.id = undefined;
					isValidationInvalid.value = true;
				}
				reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/** Deletes a role */
		const deleteEntry = () => {
			if (role && role.id) {
				loading.value = true;
				userService.deleteRoleFromUser(props.userId, role.id!).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: UserRole, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(role, data)
		}

		/** Triggered on take over roles */
		const reloadRolesList = () => {
			reloadData();
		}

		return {loading, showDialog, headerText, roles, role, allRoles, deleteDialog, roleService,
			isValidationInvalid, showValidationMessage, loadingDialog,
			onUpdate, deleteEntry, openDeleteDialog, onEntrySelect, saveRoleForUser, reloadRolesList};
	},
});

</script>

<style scoped>

.child-content {
	height: calc(100vh - 5.6rem - 3.538rem);
}

</style>
