<template>
	<DataTable
		:value="jobs"
		:loading="loading"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		class="p-datatable-sm"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:autoLayout="true"
		columnResizeMode="fit"
		:scrollable="true"
		scrollHeight="flex"
		@row-dblclick="openEditDialog($event.data)"
		:rowHover="true"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{ $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.System.Jobs') }) }}
		</template>

		<Column field="id" header="ID" style="min-width: 4rem; max-width: 6rem;" />
		<Column field="name" :header="$t('Squeeze.System.Name')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{slotProps.data.name}}
				</div>
			</template>
		</Column>
		<Column field="cronExpression" :header="$t('Squeeze.System.CronExpression')" style="min-width: 10rem;">
			<template #header>
				<Badge class="mdi mdi-comment-question-outline" @click="goToCronGuru" />
			</template>
			<template #body="slotProps">
				<span>{{ slotProps.data.cronExpression }}</span>
			</template>
		</Column>
		<Column field="scriptId" :header="$t('Squeeze.System.Script')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getScriptName(slotProps.data)">
					{{ getScriptName(slotProps.data) }}
				</div>
			</template>
		</Column>
		<Column field="active" :header="$t('Squeeze.System.Active')" style="min-width: 4rem; max-width: 4rem; justify-content: center;">
			<template #body="slotProps">
				{{$t("Squeeze.General.Boolean." + slotProps.data.active)}}
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 9rem; max-width: 9rem;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.System.ShowJobRuns')" icon="mdi mdi-table-eye" class="p-button-lg p-button-rounded p-button-text" @click="openJobRuns(slotProps.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(slotProps.data)" :disabled="!store.state.scopes.sqzJobsManage && store.state.scopes.sqzJobsRead" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" :disabled="!store.state.scopes.sqzJobsManage && store.state.scopes.sqzJobsRead" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button type="button" :label="$t('Squeeze.General.NewEntry')" icon="mdi mdi-plus" @click="onNewEntryClick" :disabled="loading || (!store.state.scopes.sqzJobsManage && store.state.scopes.sqzJobsRead)" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from 'primevue/badge';
import Tooltip from "primevue/tooltip";
import {Job, ScriptDto} from "@dex/squeeze-client-ts";
import {useStore} from "@/store";
import router from "@/router";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "JobManagementList",
	components: {
		DataTable, Column, Badge,
	},
	props: {
		jobs: {
			type: Array as PropType<Job[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		allScripts: {
			type: Array as PropType<ScriptDto[]>,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onClickDelete", "onEntrySelect"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: Job) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				name: '',
				cronExpression: '',
				scriptId: '',
			})
		}

		/**
		 * Triggered when a row is clicked to edit
		 * @param row The row to edit
		 */
		const openEditDialog = (row: Job) => {
			if(!store.state.scopes.sqzJobsManage && store.state.scopes.sqzJobsRead) {
				return
			}
			emit("onEntrySelect", row)
		}

		/**
		 * Get the Name of a Script
		 * @param currentJob
		 */
		const getScriptName = (currentJob: Job) => {
			const job = props.allScripts.find(script => script.id === currentJob.scriptId);

			if (job) {
				return job.name;
			}

			return t('Squeeze.System.ScriptNotFound');
		}

		/** Go to the cronTab guru, when badge clicked */
		const goToCronGuru = () => {
			window.open('https://crontab.guru/');
		}

		/** Triggered to show the Job Runs
		 * @param {Job} jobData
		 */
		const openJobRuns = (jobData: Job) => {
			router.push({ name: 'JobRunsView', params: { jobId: String(jobData.id) }});
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, getScriptName, goToCronGuru, openJobRuns, onChangePage,
		};
	},
});

</script>

<style scoped>

/** Reduce height of edit/delete buttons */
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

::v-deep(.p-badge) {
	background-color: transparent;
	max-width: 1.5rem;
	order: 2;
	cursor: pointer;
}

</style>

