<template>
	<DataTable
		:value="masterDataPermissions"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		:scrollable="true"
		scrollHeight="flex"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rowHover="true"
		@row-dblclick="openEditDialog($event.data)"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.MasterData.Permissions') }) }}
		</template>
		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button p-mr-2" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
		<Column field="roleId" :header="$t('Squeeze.UserManagement.Role')" style="min-width: 10rem;">
			<template #body="slotProps">
				<span class="column-cell" v-tooltip.bottom="getRoleDescription(slotProps.data)">
					{{ getRoleDescription(slotProps.data) }}
				</span>
			</template>
		</Column>
		<Column field="canManage" :header="$t('Squeeze.MasterData.TableManage')" style="min-width: 10rem; justify-content: center;">
			<template #body="slotProps">
				<Checkbox v-model="slotProps.data.canManage" @change="onChangeCheckbox(slotProps.data)" :binary="true" />
			</template>
		</Column>
		<Column field="canEdit" :header="$t('Squeeze.MasterData.EditData')" style="min-width: 10rem; justify-content: center;">
			<template #body="slotProps">
				<Checkbox v-model="slotProps.data.canEdit" @change="onChangeCheckbox(slotProps.data)" :binary="true" />
			</template>
		</Column>
		<!-- Edit / Delete -->
		<Column class="buttonColumn" style="min-width: 7rem; max-width: 7rem;">
			<template #body="slotProps">
				<Button v-tooltip="$t('Squeeze.Locators.Edit')"
						icon="mdi mdi-pencil-outline"
						class="p-button-lg p-button-rounded p-button-success p-button-text"
						@click="openEditDialog(slotProps.data)"
				/>
				<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')"
						icon="mdi mdi-delete-outline"
						class="p-button-lg p-button-rounded p-button-danger p-button-text"
						@click="openDeleteDialog(slotProps.data)"
				/>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import {MasterDataTablePermission, UserRole} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "MasterDataTablePermissionList",
	components: {
		DataTable, Column, Checkbox,
	},
	props: {
		masterDataPermissions: {
			type: Array as PropType<MasterDataTablePermission[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		allRoles: {
			type: Array as PropType<UserRole[]>,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onEntrySelect", "onClickDelete", "onChangeCheckbox"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", 'newEntry', {tableId: 0, roleId: undefined, canManage: false, canEdit: false});
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: MasterDataTablePermission) => {
			emit("onEntrySelect", 'edit', row)
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MasterDataTablePermission) => {
			emit("onClickDelete", row);
		}

		/** Triggered when a checkbox is clicked
		 * @param data
		 */
		const onChangeCheckbox = (data: MasterDataTablePermission) => {
			emit("onChangeCheckbox", data);
		}

		/**
		 * Gets the Description of a Role
		 * @param tablePermission
		 */
		const getRoleDescription = (tablePermission: MasterDataTablePermission) => {
			const role = props.allRoles.find(role => role.id === tablePermission.roleId);

			if (role) {
				return role.description;
			}

			return tablePermission.roleId;
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {t, toast, store, tableListPagination, onNewEntryClick, openEditDialog, openDeleteDialog, onChangeCheckbox, getRoleDescription, onChangePage}
	},
})
</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(td span.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>


