<template>
	<DataTable
		:value="mails"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Imports.EmailAccounts') }) }}
		</template>
		<!--<Column field="id" header="ID" :sortable="false" class="id-column" />-->
		<!--Column field="batchClassId" :header="$t('Squeeze.BatchClasses.BatchClass')" :sortable="false" /-->
		<Column field="documentClassId" :header="$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass')" style="min-width: 12rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getDescriptionFromDocumentClass(slotProps.data.documentClassId)">
					{{getDescriptionFromDocumentClass(slotProps.data.documentClassId)}}
				</div>
			</template>
		</Column>
		<Column field="server" :header="$t('Squeeze.Imports.Server')" style="min-width: 12rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.server">
					{{slotProps.data.server}}
				</div>
			</template>
		</Column>
		<Column field="port" :header="$t('Squeeze.Imports.Port')" style="min-width: 5rem;" />
		<Column field="protocol" :header="$t('Squeeze.Imports.Protocol')" style="min-width: 5rem;" />
		<Column field="encryption" :header="$t('Squeeze.Imports.Encryption')" style="min-width: 10rem;" />
		<Column field="checkCertificate" :header="$t('Squeeze.Imports.CheckCert')" style="min-width: 10rem;">
			<template #body="slotProps">
				{{$t("Squeeze.General.Boolean." + slotProps.data.checkCertificate)}}
			</template>
		</Column>
		<Column field="user" :header="$t('Squeeze.Login.UserName')" style="min-width: 12rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.user">
					{{slotProps.data.user}}
				</div>
			</template>
		</Column>
		<Column field="password" :header="$t('Squeeze.Login.Password')" style="min-width: 7rem;">
			<template #body>
				*****
			</template>
		</Column>
		<Column field="clientId" header="Client ID" style="min-width: 7rem;">
			<template #body="slotProps">
				<div v-if="slotProps.data.clientId" class="column-cell" v-tooltip.bottom="slotProps.data.clientId">
					{{slotProps.data.clientId}}
				</div>
				<div v-else>
					-
				</div>
			</template>
		</Column>
		<Column field="tenantId" header="Tenant ID" style="min-width: 7rem;">
			<template #body="slotProps">
				<div v-if="slotProps.data.tenantId" class="column-cell" v-tooltip.bottom="slotProps.data.tenantId">
					{{slotProps.data.tenantId}}
				</div>
				<div v-else>
					-
				</div>
			</template>
		</Column>
		<Column field="inboxFolder" :header="$t('Squeeze.Imports.Inbox')" style="min-width: 7rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.inboxFolder">
					{{slotProps.data.inboxFolder}}
				</div>
			</template>
		</Column>
		<Column field="validFolder" :header="$t('Squeeze.Imports.Valid')" style="min-width: 7rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.validFolder">
					{{slotProps.data.validFolder}}
				</div>
			</template>
		</Column>
		<Column field="errorFolder" :header="$t('Squeeze.General.Error')" style="min-width: 7rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.errorFolder">
					{{slotProps.data.errorFolder}}
				</div>
			</template>
		</Column>
		<template v-if="showAuthenticateButtonColumn">
			<Column class="buttonColumn" style="min-width: 3rem; max-width: 3rem;">
				<template #body="mail">
					<a v-if="mail.data.protocol === 'microsoft_delegated'" :href="createUrl(mail.data.id)" target="_self">
						<Button icon="mdi mdi-card-account-mail-outline" v-tooltip="$t('Squeeze.Imports.StartAuthentication')" class="p-button-lg p-button-rounded p-button-text" />
					</a>
				</template>
			</Column>
		</template>
		<!-- Bearbeiten / Löschen -->
		<Column class="buttonColumn" style="min-width: 12rem; max-width: 12rem;">
			<template #body="mail">
				<Button icon="mdi mdi-email-check-outline" v-tooltip="$t('Squeeze.Imports.TestMailConnection')" class="p-button-lg p-button-rounded p-button-text" @click="testMailConnection(mail.data)" />
				<Button icon="mdi mdi-email-sync-outline" v-tooltip="$t('Squeeze.Imports.FetchMails')" class="p-button-lg p-button-rounded p-button-text" @click="fetchMails(mail.data)" />
				<Button icon="mdi mdi-pencil-outline" v-tooltip="$t('Squeeze.Locators.Edit')" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(mail.data)" />
				<Button icon="mdi mdi-delete-outline" v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(mail.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {BatchClassClassification, MailImportConfig} from "@dex/squeeze-client-ts";
import ProtocolEnum = MailImportConfig.ProtocolEnum;
import EncryptionEnum = MailImportConfig.EncryptionEnum;
import {useI18n} from "vue-i18n";
import Tooltip from "primevue/tooltip";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";
import {useToast} from "primevue/usetoast";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "Mail",
	components: {
		DataTable, Column,
	},
	props: {
		mails: {
			type: Array as PropType<MailImportConfig[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
		showAuthenticateButtonColumn: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "testMailConnection", "fetchMails",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MailImportConfig) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				batchClassId: undefined,
				documentClassId: 0,
				server: '',
				port: undefined,
				protocol: ProtocolEnum.Imap,
				encryption: EncryptionEnum.Ssl,
				checkCertificate: false,
				user: '',
				password: '',
				clientId: '0',
				tenantId: '0',
				inboxFolder: '',
				validFolder: '',
				errorFolder: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: MailImportConfig) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the Description of a documentClass
		 * @param documentClassId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClass = (documentClassId: number) => {
			if (documentClassId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.documentClasses.find(i => i.documentClassId === documentClassId);

			if (!documentClassDescription) {
				return String(documentClassId)
			}

			return documentClassDescription.description
		}

		/**
		 * Test the Mail Connection of Row
		 * @param row
		 */
		const testMailConnection = (row: MailImportConfig) => {
			emit("testMailConnection", row)
		}

		/**
		 * Fetch E-Mails
		 * @param mail
		 */
		const fetchMails = (mail: MailImportConfig) => {
			emit("fetchMails", mail.id)
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/**
		 * Create the URL to trigger the Authenticate of Microsoft Graph Api Delegated
		 * @param mailId
		 */
		const createUrl = (mailId: number) => {
			return ClientManager.getInstance().getSqueezeBasePath() + '/importers/email/' + mailId + '/authenticate/start';
		}

		return {
			tableListPagination,
			openDeleteDialog,
			onNewEntryClick,
			openEditDialog,
			getDescriptionFromDocumentClass,
			testMailConnection,
			fetchMails,
			onChangePage,
			createUrl,
		}
	},
});

</script>

<style scoped>

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

