<template>
<!--	<div class="card p-shadow-2 p-p-3">
		<div class="card-header p-mb-2">
			{{ className }}
		</div>
		<div>
			<Chip class="chip-secondary" style="margin-right: 1rem">{{ currentCount }}</Chip>
			<template v-if="errorCount > 0">
				<Chip class="chip-danger">{{ errorCount }}</Chip>
			</template>
		</div>
	</div>-->
	<Card class="card">
		<template #title>
			<div style="height: 150px; overflow: hidden;">
				<svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
					<path d="M0.00,92.27 C216.83,150.92 304.30,50 500.00,84 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: rgb(223, 223, 223);"></path>
				</svg>
			</div>
			<div class="p-grid">
				<div v-if="currentCount !== -1" class="p-col-6 current-count">
					{{ currentCount }}
				</div>
				<div v-if="errorCount > 0" class="p-col-4 p-text-right mdi mdi-alert-outline error-count">
					{{ errorCount }}
				</div>
			</div>
		</template>
<!--		<template #content>
			<div style="height: 150px; overflow: hidden; border: 1px solid black;">
				<svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
					<path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" style="stroke: none;fill: #e1efe3;"></path>
				</svg>
			</div>
		</template>-->
		<template #footer>
			<div class="name-overflow" v-tooltip.bottom="className">
				{{ className }}
			</div>
		</template>
	</Card>
</template>

<script lang="ts">
import Card from 'primevue/card';
import Tooltip from "primevue/tooltip";
import useStore from "@/store";
import {defineComponent, watch} from "vue";

export default defineComponent({
	name: "QueueNavigatorCard",
	components: {
		Card,
	},
	props: {
		className: String,
		currentCount: Number,
		errorCount: Number,
	},
	directives: {
		'tooltip': Tooltip,
	},
	setup() {
		/** Store */
		const store = useStore();

		/** Check global fontSize to fix the margin-top of counts
		 * Because we use the card, the count cannot be displayed in any other way and the font size must be observed.
		 */
		const checkFontSize = () => {
			const currentFontSize = store.state.userSettings.viewSettings.fontSize;
			if (currentFontSize === 0.7) {
				document.documentElement.style.setProperty('--element-margin-top', '-9rem');
			} else if (currentFontSize === 0.725) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.75rem');
			} else if (currentFontSize === 0.75) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.5rem');
			} else if (currentFontSize === 0.775) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.25rem');
			} else if (currentFontSize === 0.8) {
				document.documentElement.style.setProperty('--element-margin-top', '-8rem');
			} else if (currentFontSize === 0.825) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.75rem');
			} else if (currentFontSize === 0.85) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.5rem');
			} else if (currentFontSize === 0.875) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.25rem');
			} else {
				document.documentElement.style.setProperty('--element-margin-top', '-7rem');
			}
		}

		checkFontSize();

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => store.state.userSettings.viewSettings.fontSize, () => {
			checkFontSize();
		})
	},
});

/*
@Options({

	watch: {
		'store.state.userSettings.viewSettings.fontSize': function() {
			this.checkFontSize();
		},
	},
})*/

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


/*.card {
	color: var(--dex-primary-color);
	border-radius: .25rem;
	cursor: pointer;
	height: 100%;
	word-wrap: break-word;
	display:flex;
	flex-direction:column;
	justify-content: space-between;
}

.card-header {
	font-size: 1.25rem;
}*/

.card {
	background-color: var(--dex-primary-color);
	color: #fff;
	cursor: pointer;
}

.p-card .p-card-title div div {
	font-size: 2rem;
	font-weight: 100;
}

::v-deep(.p-card-footer) {
	font-size: 1.5rem;
}

::v-deep(div.name-overflow) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--dex-text-color);
}

::v-deep(.p-card-title) {
	margin-top: -1rem;
	margin-left: -1rem;
	margin-right: -1rem;
}

::v-deep(.p-card-content) {
	display: none;
}

::v-deep(.p-card-footer) {
	padding: 0;
}

.current-count {
	margin-top: var(--element-margin-top);
	color: var(--dex-primary-color);
	margin-left: 1rem;
}

.error-count {
	color: var(--error-color);
	margin-top: var(--element-margin-top);
	padding-right: 1.5rem;
	width: 100%;
	font-family: var(--font-family);
}

</style>
