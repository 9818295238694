
import {Options, Vue} from 'vue-class-component';
//import { Chart } from 'chart.js';
import Chart from 'chart.js/auto';
import moment from "moment";
import Calendar from 'primevue/calendar';
import {ClientManager} from "@/singletons/ClientManager";
import {ColorGenerator} from "@/singletons/ColorGenerator";
import {ToastManager} from "@/singletons/ToastManager";

@Options({
	name: "DocumentReportOrigin",
	components: {
		Chart,
		Calendar,
	},
	props: {
		placeholder: {
			type: String,
		},
	},
})
export default class DocumentReportOrigin extends Vue {

	myChart!: Chart;

	selectedDate: string[] = [];

	chartOriginData = {};
	chartOriginLabels: string[] = [];
	placeholder = '';

	/** Current color of client */
	currentClientColor = '';

	queueAPI = ClientManager.getInstance().squeeze.queue;

	mounted() {
		this.getOriginChartData(moment('01.01.', 'DD MM'), moment());
	}

	createOriginChart() {
		const canvas = document.getElementById('myChartOrigin') as HTMLCanvasElement;
		const ctx = canvas.getContext('2d');
		this.currentClientColor = window.getComputedStyle(canvas).getPropertyValue('--dex-secondary-dark-color');
		this.myChart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.chartOriginLabels,
				datasets: this.createOriginData(),
			},
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
		});
	}

	createOriginData() {
		const data = [];
		let tempData = {};
		const dataEntries = Object.entries(this.chartOriginData);
		const hexToHslColor = ColorGenerator.hexToHSL(this.currentClientColor);
		const hslColors = ColorGenerator.generateHslColors(dataEntries.length, hexToHslColor);

		let colorIndex = 0;
		for (const [key, value] of dataEntries) {
			let color = hslColors[colorIndex];
			if (!undefined && !"") {
				colorIndex++;
			} else {
				color = this.currentClientColor;
			}

			const dataFromChartOrigin: any = {value}.value;
			tempData = {
				label: {key}.key,
				data: dataFromChartOrigin[0]['data'],
				borderWidth: 3,
				borderColor: color,
				backgroundColor: color,
			}
			data.push(tempData);
		}
		return data;
	}

	/**
	 * Get Chart Data
	 * */
	async getOriginChartData(startDate: moment.Moment, endDate: moment.Moment) {
		try {
			this.placeholder = startDate.format('DD.MM.YYYY') + ' - ' + endDate.format('DD.MM.YYYY');
			let queueReportOriginData = null;
			queueReportOriginData = await this.queueAPI.getQueueReportOrigin(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

			// Build labels for x axis
			const labels: string[] = [];
			for (const i of queueReportOriginData.labels!) {
				labels.push(String(i));
			}
			this.chartOriginLabels = labels;

			// Build import data
			if(queueReportOriginData.datasets) {
				this.chartOriginData = queueReportOriginData.datasets;
			}

			// Build Chart
			const presentChart = Chart.getChart("myChartOrigin");
			if(presentChart) {
				presentChart.destroy();
				this.createOriginChart();
			} else {
				this.createOriginChart();
			}
		} catch (err: any) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.statusText);
		}
	}

	/**
	 * Triggered when Dates are selected
	 * */
	selectDate() {
		if (this.selectedDate[1]) {
			this.getOriginChartData(moment(this.selectedDate[0], 'LLLL'), moment(this.selectedDate[1], 'LLLL'));
		}
	}

}

