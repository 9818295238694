<template>
	<div class="p-p-2 child-content">
		<DocumentClassExportStates
			:exportStates="exportStates"
			:loading="loading"
			:paginationInfo="pagination"
			@onPage="onPage($event)"
			:allExportInterfaces="allExportInterfaces"
		/>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DocumentClassExportStates from "@/apps/administration/components/documentclasses/DocumentClassExportStates.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {ExportInterfaceDto, PaginationDto} from "@dex/squeeze-client-ts";
import {ExportStatus} from "@dex/squeeze-client-ts/dist/api";
import {TableSettings} from "@/singletons/TableSettings";
import {useRoute} from "vue-router";
import useStore from "@/store";

export default defineComponent({
	name: 'DocumentClassExportStatesSubView',
	components: {DocumentClassExportStates},
	props: {
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		/** Currently active export interface */
		exportId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** DocumentClass API endpoint */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Show loading? **/
		const loading = ref(false);

		/** Object with all export states **/
		const exportStates = ref<ExportStatus[]>([]);

		/** Empty pagination object & Info / Options of pagination  */
		const pagination = reactive<PaginationDto>({pageSize: 25, page: 0, total: 0});

		/** Array with all export interfaces */
		const allExportInterfaces = ref<ExportInterfaceDto[]>([]);

		/** Gets the Document-Classes Export Interface */
		const getExportInterfaces = () => {
			loading.value = true;

			documentClassService.getAllDocumentClassExportInterfaces(props.documentClassId)
				.then(data => {
					allExportInterfaces.value = data;
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		/** Get all export interface states **/
		const getExportInterfaceStates = () => {
			loading.value = true;
			documentClassService.getExportInterfaceStates(props.documentClassId, props.exportId, '', pagination.page, pagination.pageSize)
				.then((data) => {
					if(data.elements) {
						exportStates.value = data.elements;
					}
					Object.assign(pagination, data.pagination);
					pagination.pageSize = TableSettings.getTableListPagination(store, route.name);
				}).catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.ErrorDelete'), t('Squeeze.General.DeleteError') + ": " + error.message);
				}).finally(() => {
					loading.value = false;
					getExportInterfaces();
				})
		}

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/** Triggered when a page is changed */
		const onPage = (event: { page: number; rows: number }) => {
			pagination.page = event.page;
			pagination.pageSize = event.rows;
			getExportInterfaceStates();

			onChangePage(event);
		}

		onMounted(() => {
			getExportInterfaceStates();
		});

		return {
			t, toast, loading, exportStates, pagination, allExportInterfaces, onPage, getExportInterfaces,
		}
	},
})
</script>

<style scoped>

.child-content {
	height: calc(100vh - 5.6rem - 3.5rem);
}

</style>

