<template>
	<div class="p-p-2 full-content">
		<ScriptsList :loading="loading" :allScripts="allScripts" @executeScript="executeScript"/>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ScriptDto} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import ScriptsList from "@/apps/administration/components/system/ScriptsList.vue";

export default defineComponent({
	name: "ScriptsView",
	components: {ScriptsList},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Service for getting all scripting services */
		const scriptingService = ClientManager.getInstance().squeeze.scripting;

		/** List of all scripts */
		const allScripts = ref<ScriptDto[]>([]);

		/** Get all scripts */
		const getAllScripts = () => {
			loading.value = true;
			scriptingService.getScripts()
				.then((data: ScriptDto[]) => {
					allScripts.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Execute the Script
		 * @param script The script to execute
		 */
		const executeScript = (script: ScriptDto) => {
			loading.value = true;
			if(script && script.id) {
				scriptingService.executeScript(script.id)
					.then(() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SuccessExecuteScript'));
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		onMounted(() => {
			getAllScripts();
		})

		return {t, toast, loading, allScripts, executeScript};
	},
});

</script>

<style scoped>

.full-content {
	height: calc(100vh - 5.6rem);
}

</style>


