import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ErrorPage from '../views/ErrorPage.vue';
import * as SqueezeAppRouter from "@/apps/squeeze/router";
import * as AdministrationRouter from "@/apps/administration/router";
import RecoverPassword from "@/views/RecoverPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {store} from "@/store";
import KeyCloakLoginCheck from "@/components/KeyCloakLoginCheck.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "",
		redirect: {
			name: 'SqueezeDashboard',
		},
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			requiresAuth: false,
			translate: true,
		},
	},
	{
		path: "/recover",
		name: "Recover",
		component: RecoverPassword,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/pwreset",
		name: "PasswordReset",
		component: ResetPassword,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/checkLogin",
		name: "KeyCloakLoginCheck",
		component: KeyCloakLoginCheck,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/:reason(.*)',
		name: 'ErrorPage',
		component: ErrorPage,
	},
]

const addRouteFunc = (route: RouteRecordRaw) => routes.push(route);
AdministrationRouter.createRoutes("/app/administration").forEach(addRouteFunc);
SqueezeAppRouter.createRoutes("/app/squeeze").forEach(addRouteFunc);

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
})


router.beforeEach(async (to, from, next) => {
	if (store.state.isLoggedIn) {
		next();
	}
	// Is the Entry-Point does not require a login, simply go there
	else if(to.meta.requiresAuth === false) {
		store.commit("setLogin", false);
		next();
	}
	else {
		// Is the store has not saved the login yet, check by calling an endpoint
		const isLoggedIn = await ClientManager.getInstance().isAuthenticatedAtSqueeze();
		// This is often true, if the cookie is set. If not, check keycloak again
		if (!isLoggedIn) {
			localStorage.setItem("firstUrl", to.fullPath);
			const authorization = localStorage.getItem("authorization");
			store.commit("setLogin", false);
			if (authorization === "bearer") {
				await ClientManager.getInstance().setPossibleAuthentications();
				await ClientManager.getInstance().openKeyCloakLogin();
			} else {
				next({name: 'Login'})
			}
		}
		else {
			store.commit("setLogin", true);
			try{
				const promiseUserData = store.dispatch("fetchUserData");
				const promiseFeatureSet =  store.dispatch("fetchFeatureSet");
				const promiseMigrations =  store.dispatch("fetchMigrations");
				const promiseSystemInformation =  store.dispatch("fetchSystemInformation");

				await Promise.all([promiseUserData, promiseFeatureSet, promiseMigrations, promiseSystemInformation]);

				// UserSettings have to loaded, AFTER the feature-sets have been set. Do not include in parallel execution
				await store.dispatch("fetchUserSettings");
			}
			catch (err: any) {/**/}

			const firstUrl = localStorage.getItem("firstUrl");
			if (firstUrl) {
				localStorage.removeItem("firstUrl");
				next({path: firstUrl});
			} else {
				next()
			}
		}
	}
})

export default router
