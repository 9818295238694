<template>
	<div class="p-p-2 content">
		<LanguagesList :loading="loading" :allLanguages="allLanguages" @onChangeActiveStatus="onChangeActiveStatus" @onEntrySelect="onEntrySelect" />
	</div>

	<EntryDialog
		:show="showEditDialog"
		@onClose="showEditDialog = false, isValidationInvalid = true, showValidationMessage = false"
		@onConfirm="saveLanguage"
		:loading="loadingDialog"
		:headerText="headerText"
		:showKeepDialogOpen="false"
	>
		<template #content>
			<BlockUI :blocked="loadingDialog">
				<LanguageForm
					:language="language"
					:showErrorMessage="showValidationMessage"
					@update="onUpdate"
				/>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {TranslationCountry} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import EntryDialog from "@/components/EntryDialog.vue";
import LanguagesList from "@/apps/administration/components/system/LanguagesList.vue";
import LanguageForm from "@/apps/administration/components/system/LanguageForm.vue";

export default defineComponent({
	name: "LanguagesView",
	components: {
		LanguagesList,
		LanguageForm,
		BlockUI,
		EntryDialog,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Should the Entry-Dialog for translation country be shown? */
		const showEditDialog = ref<boolean>(false);

		/** Is the Form of the users invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.System.Language') }));

		/** Api for job management in system */
		const translationsApi = ClientManager.getInstance().squeeze.translations;

		/** All Languages */
		const allLanguages = ref<TranslationCountry[]>([]);

		/** Entry-Data for translation country */
		const language = reactive<TranslationCountry>({
			countryCode: undefined,
			translatedDescription: undefined,
			translationKey: undefined,
			description: undefined,
			name: undefined,
			active: undefined,
		})

		/** Get all languages */
		const getAllLanguages = () => {
			loading.value = true;
			translationsApi.getActiveCountries(false)
				.then((data: TranslationCountry[]) => {
					allLanguages.value = data;
				}).catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Save current translation country object */
		const saveLanguage = () => {
			loading.value = true;
			loadingDialog.value = true;
			if (language && language.id) {
				translationsApi.putTranslationCountry(language.id, language)
					.then(() => {
						getAllLanguages();
					})
					.catch((response: any) => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
						loadingDialog.value = false;
						showEditDialog.value = false;
					})
			}
		}

		/** Triggered when the active status of a translation country is clicked
		 *  @param {TranslationCountry} rowData Row to Save
		 */
		const onChangeActiveStatus = (rowData: TranslationCountry) => {
			Object.assign(language, rowData);
			saveLanguage();
		}

		/**
		 * Triggered when an entry should be shown
		 * @param rowData Row to change
		 */
		const onEntrySelect = (rowData: TranslationCountry) => {
			Object.assign(language, rowData);
			showEditDialog.value = true;
		}

		/**
		 * Triggered on update of translation country-form
		 * @param data
		 * @param isInvalid
		 */
		const onUpdate = (data: TranslationCountry, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(language, data);
		}

		onMounted(() => {
			getAllLanguages();
		});

		return {
			t,
			loading,
			loadingDialog,
			showEditDialog,
			isValidationInvalid,
			showValidationMessage,
			headerText,
			allLanguages,
			language,
			onChangeActiveStatus,
			saveLanguage,
			onEntrySelect,
			onUpdate,
		};
	},
});

</script>

<style scoped>

.header {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin: 0;
}

</style>
