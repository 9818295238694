
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import {RoleFilterFieldCondition, DocumentField} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import {CompareOperations} from "@/interfaces/CompareOperations";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "FieldConditionsForm",
	components: {
		Dropdown, InputText,
	},
	props: {
		fieldCondition: {
			type: Object as PropType<RoleFilterFieldCondition>,
			required: true,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			required: true,
			default: [],
		},
		showErrorMessage: {
			type: Boolean,
		},
		operators: {
			type: Array as PropType<CompareOperations[]>,
			required: true,
			default: [],
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<RoleFilterFieldCondition>({
			operator: undefined,
			fieldId: undefined,
			id: undefined,
			filterValue: undefined,
		});

		/** Determines the required rules for validation */
		const rules = {
			fieldId: {required},
			operator: {required},
			filterValue: {required},
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldCondition);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldCondition, () => {
			Object.assign(value, props.fieldCondition);
		});

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		};

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
		}
	},
});

