<template>
	<DataTable
		:value="apiKeys"
		:loading="loading"
		class="p-datatable-sm"
		:lazy="true"
		:scrollable="true"
		scrollHeight="flex"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.UserManagement.ApiKey') }) }}
		</template>
		<template #footer>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>

		<Column field="name" :header="$t('Squeeze.UserManagement.Name')" style="min-width: 15rem; max-width: 15rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{slotProps.data.name}}
				</div>
			</template>
		</Column>
		<Column field="value" :header="$t('Squeeze.UserManagement.ApiKey')" style="min-width: 20rem;"/>
		<Column class="buttonColumn" style="min-width: 6rem; max-width: 6rem;">
			<template #body="users">
				<Button icon="mdi mdi-content-copy" v-tooltip="$t('Squeeze.UserManagement.CopyToClipboard')" class="p-button p-button-icon-only p-button-lg p-button-rounded  p-button-text" @click="onClickCopy(users.data.value)" />
				<Button icon="mdi mdi-delete-outline" v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(users.data)" />
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, PropType} from "vue";
import {ApiKey} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "ApiKeyList",
	components: {
		DataTable, Column,
	},
	props: {
		apiKeys: {
			type: Array as PropType<ApiKey[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		selectionMode: {
			type: String,
			default: null,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onRowSelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: ApiKey) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				name: '',
				value: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: ApiKey) => {
			emit("onEntrySelect", row)
		}

		/** Triggered when a row is selected */
		const onRowSelect = (event: any) => {
			emit("onRowSelect", event.data)
		}

		/** Triggered on click of the button to copy */
		const onClickCopy = (apiKey: string) => {
			if(apiKey && navigator && navigator.clipboard) {
				navigator.clipboard.writeText(apiKey).then(
					() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserManagement.ApiKeyCopied'));
					}
				);
			}
		}

		return {
			openDeleteDialog, onNewEntryClick, onRowSelect, onClickCopy, openEditDialog,
		}
	},
});

</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

