<template>
	<div class="big-container p-grid nested-grid">
		<div class="sidebar p-col-3 p-col-fixed p-p-2">
			<div class="p-grid p-flex-column">
				<div class="p-col p-pt-4 p-d-flex p-jc-center">
					<Avatar :label="loggedInUserInitials" class="avatar" size="xlarge" shape="circle" />
				</div>
				<div class="p-col">
					<div class="p-d-flex p-jc-center">
						<p class="userinfo p-text-bold">
							<span>{{store.state.user && store.state.user.firstName}} {{store.state.user && store.state.user.lastName}}</span>
						</p>
					</div>
					<div class="p-d-flex p-jc-center">
						<p class="userinfo email">{{store.state.user && store.state.user.email}}</p>
					</div>
				</div>
				<div class="p-col">
					<Listbox v-model="selectedMenuItem" :options="settingMenuItems" optionLabel="name" optionValue="code"/>
				</div>
			</div>
		</div>
		<div class="main-content p-col-9">
			<UserAccount v-if="selectedMenuItem === 'Account'" @clickSettingDialog="clickSettingDialog" />
			<ViewSettings v-if="selectedMenuItem === 'View'" @clickSettingDialog="clickSettingDialog" />
			<UserApiKeysView v-if="selectedMenuItem === 'ApiKey'" :userId="store.state.user.id" @clickSettingDialog="clickSettingDialog" />
			<SystemInformation v-if="selectedMenuItem === 'SystemInfo'" :showHeadline="true" :systemInformation="store.state.systemInformation.labels" @clickSettingDialog="clickSettingDialog" />
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from 'vue';
import Listbox from 'primevue/listbox';
import Avatar from 'primevue/avatar';
import ViewSettings from "@/components/settingDialog/ViewSettings.vue";
import UserAccount from "@/components/settingDialog/UserAccount.vue";
import {useI18n} from "vue-i18n";
import UserApiKeysView from "@/components/settingDialog/UserApiKeysView.vue";
import SystemInformation from "@/apps/administration/components/system/SystemInformation.vue";
import {useStore} from "@/store";

export default defineComponent({
	name: 'UserSettings',
	components: {SystemInformation, UserApiKeysView, ViewSettings, UserAccount, Listbox, Avatar },
	props: {
		loggedInUserInitials: {
			type: String,
			default: '',
		},
	},
	emits: ['clickSettingDialog'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const store = useStore();

		/** Array of setting-MenuItems */
		const settingMenuItems = computed(() => {
			return [
				{name: t('Squeeze.UserSettings.Account.UserAccount'), code: 'Account'},
				{name: t('Squeeze.UserSettings.Presentation'), code: 'View'},
				{name: t('Squeeze.UserManagement.MyApiKeys'), code: 'ApiKey'},
				{name: t('Squeeze.System.System'), code: 'SystemInfo'},
			]
		});

		/** Currently selected menuItem */
		const selectedMenuItem = ref<string>('View');

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog', false);
		}

		/** Watch on selected menuItem, so that show 'View' ever */
		watch(selectedMenuItem, () => {
			if(!selectedMenuItem.value) {
				selectedMenuItem.value = 'View';
			}
		})

		return { store, settingMenuItems, selectedMenuItem, clickSettingDialog };
	},
});

</script>

<style lang="scss" scoped>

//Container
.big-container {
	color: var(--surface-0);
	margin: 0;
}

// Sidebar
.sidebar {
	min-height: 50vh;
	background-color: var(--primary-color);
}

// Content
.main-content {
	color: black;
}

// User
.avatar {
	background-color: var(--dex-secondary-dark-color);
}

::v-deep(.userinfo) {
	margin:0.25rem;
}

::v-deep(.email) {
	word-wrap:break-word;
	color: #2c3e50;
}

// Listbox
::v-deep(.p-listbox) {
	background-color: transparent;
	border: none;
	margin: 1rem;
}

::v-deep(.p-listbox .p-listbox-list .p-listbox-item) {
	color: var(--surface-0);
}

</style>
