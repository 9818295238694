<template>
	<div class="p-mb-2 p-p-2">
		<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
			<div class="p-field p-col-12">
				<label for="masterDataTableId">{{$t('Squeeze.DocumentClasses.Table')}}</label>
				<Dropdown id="masterDataTableId" v-model="masterDataTableId" :options="masterDataTables" :loading="loading" :filter="true" optionValue="id" optionLabel="description" @change="onChangeMasterDataTable" @show="showDropdownOverlay" />
			</div>
			<div class="p-field p-col-12">
				<label for="masterDataTableColumn">{{$t('Squeeze.MasterData.Column')}}</label>
				<Dropdown id="masterDataTableColumn" v-model="masterDataTableColumnId" :options="masterDataColumns" :loading="loading" :filter="true" optionValue="id" optionLabel="description" @change="onChangeMasterDataTable" @show="showDropdownOverlay" />
			</div>
		</div>
		<div class="p-grid p-jc-end p-p-2">
			<Button :label="$t('Squeeze.General.Save')" @click="saveSourceTable" />
		</div>
		<div v-if="sourceTable.tableId === masterDataTableId" class="p-mt-2 child-content">
			<DBLinkedData
				:DBLinkedDataEntrys="DBLinkedDataEntrys"
				:allLocators="allLocators"
				:masterDataColumns="masterDataColumns"
				:loading="loading"
				:showErrorMessage="showValidationMessage"
				@onClickDelete="openDeleteDialog"
				@onEntrySelect="onEntrySelect"
				@onChangeSortOrder="onChangeSortOrder"
			/>
		</div>
		<Message v-else class="p-m-0" severity="warn" :closable="false">
			<h3>{{ $t('Squeeze.System.Warning') }}</h3>
			{{ $t('Squeeze.Locators.Tabs.WarningMessageDBLinkedData') }}
		</Message>
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />

	<!-- Dialog für Felder -->
	<EntryDialog
		:show="showDialog"
		@onClose="showDialog = false, isValidationInvalid = true, showValidationMessage = false"
		@onConfirm="saveDbLinkLocator"
		:loading="loadingDialog"
		:headerText="headerText"
		:showKeepDialogOpen="!DBLinkedData.id"
	>
		<template #content>
			<BlockUI :blocked="loadingDialog">
				<DBLinkedDataForm
					:DBLinkedData="DBLinkedData"
					:allLocators="allLocators"
					:masterDataColumns="masterDataColumns"
					@update="onUpdate"
					:showErrorMessage="showValidationMessage"
				/>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {
	DbLinkLocatorDetails,
	DocumentLocator, ErrorDto,
	LocatorSourceTable,
	MasterDataColumn,
	MasterDataTable
} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import DBLinkedData from "@/apps/administration/components/locators/tabs/DBLinkedData.vue";
import DBLinkedDataForm from "@/apps/administration/components/locators/tabs/DBLinkedDataForm.vue";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "DBLinkedDataView",
	components: {
		DBLinkedDataForm, DBLinkedData, DialogDelete, EntryDialog, BlockUI, Dropdown, Message,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the DBLinkedDataEntrys invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all DBLinkedDataEntrys */
		const DBLinkedDataEntrys = ref<DbLinkLocatorDetails[]>([]);

		/** Should the Entry-Dialog for DBLinkedDataEntrys be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active DBLinkedData */
		const DBLinkedData = reactive<DbLinkLocatorDetails>({
			id: 0,
			sortOrder: 0,
			locatorId: 0,
			sourceLocatorId: 0,
			dbcolumn: 0,
			stopIfUnique: true,
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** List of all Master data tables */
		const masterDataTables = ref<MasterDataTable[]>([]);

		/** Service for getting the master-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** ID of the masterDataTable */
		const masterDataTableId = ref<number>(0);

		/** ID of the masterDataTableColumn */
		const masterDataTableColumnId = ref<number>(0);

		/** List of all Locators */
		const allLocators = ref<DocumentLocator[]>([]);

		/** List of all masterData columns */
		const masterDataColumns = ref<MasterDataColumn[]>([]);

		/** Currently active SourceTable */
		const sourceTable = reactive<LocatorSourceTable>({
			locatorId: 0,
			tableId: 0,
			tableColumnId: 0,
		});

		/** Get all Locators */
		const getAllLocators = () => {
			locatorService.getAllLocators()
				.then((data: DocumentLocator[]) => {
					allLocators.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all MasterData TableColumns */
		const getMasterDataTableColumns = () => {
			masterDataService.getMasterDataTableColumns(masterDataTableId.value)
				.then((data: MasterDataColumn[]) => {
					masterDataColumns.value = data;
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all masterDataTables */
		const getMasterDataTables = () => {
			loading.value = true;
			masterDataService.getAllMasterDataTables()
				.then((data: MasterDataTable[]) => {
					masterDataTables.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Trigged on update of attribute-form */
		const onUpdate = (data: DbLinkLocatorDetails, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(DBLinkedData, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.DBLinkedData') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: DbLinkLocatorDetails) => {
			Object.assign(DBLinkedData, row);
			// make sure locatorId always right
			DBLinkedData.locatorId = props.locatorId;
			showDialog.value = true;
		}

		/** Reloads the table to get all DBLinkedData */
		const reloadData = () => {
			loading.value = true;
			locatorService.getDbLinkLocatorDetails(props.locatorId)
				.then((data: DbLinkLocatorDetails[]) => {
					// check if masterDataTable not exists
					if(masterDataTableId.value === 0) {
						DBLinkedDataEntrys.value = [];
					} else {
						DBLinkedDataEntrys.value = data;
					}
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Triggered when rows are reordered
		 */
		const onChangeSortOrder = (orderedList: number[]) => {
			loading.value = true;
			locatorService.putDbLinkLocatorDetailsSortOrder(props.locatorId, {elements: orderedList})
				.then(() => {
					reloadData();
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get data of sourceTable */
		const getDataOfSourceTable = async () => {
			loading.value = true;
			locatorService.getSourceTable(props.locatorId)
				.then((data: LocatorSourceTable) => {
					Object.assign(sourceTable, data);
					masterDataTableId.value = sourceTable.tableId;
					masterDataTableColumnId.value = sourceTable.tableColumnId;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
					getMasterDataTableColumns();
				})
		}

		/** Save sourceTable */
		const saveSourceTable = () => {
			loading.value = true;

			// check if masterTable is changed
			if(sourceTable.tableId !== masterDataTableId.value) {
				DBLinkedDataEntrys.value = [];
			}

			sourceTable.tableId = masterDataTableId.value;
			sourceTable.tableColumnId = masterDataTableColumnId.value;

			locatorService.putSourceTable(props.locatorId, sourceTable)
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(async () =>{
			getMasterDataTables();
			getAllLocators();
			await getDataOfSourceTable();
			await reloadData();
		})

		/** Saves an DBLinkedData */
		const saveDbLinkLocator = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let DbLinkLocatorChangePromise = null;

			// make sure locatorId always right
			DBLinkedData.locatorId = props.locatorId;

			if (!DBLinkedData.id) {
				DbLinkLocatorChangePromise = locatorService.postDbLinkLocatorDetails(props.locatorId, DBLinkedData);
			} else {
				DbLinkLocatorChangePromise = locatorService.putDbLinkLocatorDetails(props.locatorId, DBLinkedData.id, DBLinkedData);
			}

			DbLinkLocatorChangePromise.then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					DBLinkedData.id = 0;
					DBLinkedData.sortOrder = undefined;
					DBLinkedData.locatorId = undefined;
					DBLinkedData.sourceLocatorId = undefined;
					DBLinkedData.dbcolumn = undefined;
					DBLinkedData.stopIfUnique = true;

					isValidationInvalid.value = true;
				}
				reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: DbLinkLocatorDetails) => {
			deleteDialog.value = true;
			Object.assign(DBLinkedData, row);
		}

		/** Deletes a DBLinkedData */
		const deleteEntry = () => {
			if (DBLinkedData.id) {
				loading.value = true;
				locatorService.deleteDbLinkLocatorDetail(props.locatorId, DBLinkedData.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Change a masterDataTable */
		const onChangeMasterDataTable = async () => {
			// check if masterTable is changed
			if(sourceTable.tableId !== masterDataTableId.value) {
				DBLinkedDataEntrys.value = [];
			}
			await getMasterDataTableColumns();
			await reloadData();
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, allLocators, masterDataColumns,
			sourceTable,
			showDialog, headerText, DBLinkedDataEntrys, DBLinkedData, deleteDialog, masterDataTables, masterDataTableId, masterDataTableColumnId,
			getAllLocators, deleteEntry, openDeleteDialog, onUpdate, onEntrySelect, saveSourceTable, saveDbLinkLocator, getMasterDataTables, onChangeMasterDataTable,
			onChangeSortOrder, showDropdownOverlay};
	},
});

</script>

<style scoped>

::v-deep(div.p-message-wrapper) {
	padding-top: 0;
	margin-bottom: 1rem;
}

.child-content {
	height: calc(100vh - 24.7rem);
}

</style>

