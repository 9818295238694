
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {User} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import UserList from "@/apps/administration/components/users/UserList.vue";
import router from "@/router";

export default defineComponent({
	name: "UserListView",
	components: {
		UserList, DialogDelete,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** List of all users */
		const users = ref<User[]>([]);

		/** Currently active user */
		const user = reactive<User>({
			login: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Triggered when an entry should be shown */
		const onEntrySelect = () => {
			router.push({ name: 'UserTabsView', params: { userId: 0 }})
		}

		/**
		 * Triggered when a row is clicked
		 * @param row
		 */
		const onRowSelect = (row: User) => {
			if (row.id) {
				router.push({ name: 'UserTabsView', params: { userId: row.id }})
			}
		}

		/** Reloads the table to get all users */
		const reloadData = () => {
			loading.value = true;
			userService.getAllUsers()
				.then(data => {
					users.value = data as User[];
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			reloadData();
		})
		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: User) => {
			deleteDialog.value = true;
			Object.assign(user, row);
		}

		/** Deletes a user */
		const deleteEntry = () => {
			if (user && user.id) {
				loading.value = true;
				userService.deleteUser(user.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {loading, users, user, deleteDialog, userService,
			deleteEntry, openDeleteDialog, onEntrySelect, onRowSelect};
	},
});

