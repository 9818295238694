

import {Options, Vue} from "vue-class-component";
import DocumentClassForm from "@/apps/administration/components/documentclasses/DocumentClassForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import router from "@/router";
import BlockUI from "primevue/blockui";

@Options({
	name: 'DocumentClassSubView',
	components: {
		DocumentClassForm, BlockUI,
	},
	computed: {
		disableSearchIndexButton() {
			// Show button to rebuild index only if not already loading or document class does not yet exist
			return this.loading || !this.documentClassId
		},
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		documentClassId: function() {
			this.getDocumentClass()
		},
		'$i18n.locale': function() {
			this.getTranslateOfSaveButton();
		},
	},
})

export default class DocumentClassSubView extends Vue {

	/** Currently active Document-Class-ID */
	documentClassId?: number;

	/** Service for Document-Classes */
	documentClassService = ClientManager.getInstance().squeeze.documentClass

	/** Currently active Document-Class */
	documentClass: DocumentClass = {
		name: '',
		description: '',
	}

	/** Is the page currently loading it's data? */
	loading = false

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	saveButtonText = ''

	created() {
		this.getTranslateOfSaveButton();
	}

	mounted() {
		this.getDocumentClass()
	}

	getTranslateOfSaveButton() {
		if (!this.documentClassId) {
			this.saveButtonText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.DocumentClass') })
		} else {
			this.saveButtonText = this.$t('Squeeze.General.Save');
		}
	}

	/** Gets the Data of a Document-Class */
	getDocumentClass() {
		if (this.documentClassId) {
			this.loading = true;
			this.documentClassService.getDocumentClassById(this.documentClassId)
				.then(data => {
					this.documentClass = data
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.DocumentClasses.DocumentClass') + ": " + err.statusText)
				});
		}
	}

	/** Triggered on update of attribute-form */
	onUpdate(data: DocumentClass, valid: boolean) {
		this.isInvalid = valid;
		Object.assign(this.documentClass, data);
	}

	async rebuildSearchIndex(): Promise<void> {
		if (!this.documentClassId) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError'))
			return
		}

		this.loading = true;

		try {
			await this.documentClassService.rebuildDocumentClassSearchIndex(this.documentClassId);
		} catch (error) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), error);
		} finally {
			this.loading = false;
		}
	}

	/** Saves a document-class */
	saveDocumentClass(): void {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		let promise;

		this.showErrorMessage = false;
		this.loading = true;

		if (!this.documentClass.id) {
			// Create New Entry
			promise = this.documentClassService.postDocumentClass(this.documentClass)
				.then(data => {
					// FIXME: I don't want to parse this json when the Client is fixed
					return data.json()
				}).then(data => {
					router.push({ name: 'DocumentClassFieldsSubView', params: { documentClassId: data.id}})
				})
		} else {
			// Update Entry
			promise = this.documentClassService.putDocumentClass(this.documentClassId!, this.documentClass)
		}

		promise
			.then(() => ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.CreateSuccess')))
			.catch((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.statusText)
			})
			.finally(() => {
				this.loading = false;
			});
	}

}

