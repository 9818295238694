

interface DataTypes {
	id: string | undefined;
	text: string;
}

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Badge from 'primevue/badge';
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import  {Options, Vue} from "vue-class-component";
import {DocumentField, DocumentLocator, DocumentFieldGroup} from '@dex/squeeze-client-ts';
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import Tooltip from "primevue/tooltip";
import {useStore} from "@/store";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: 'DocumentClassFieldTable',
	components: {
		DataTable, Column, Checkbox, DialogDelete, Badge, InputText, Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array,
			default: [],
		},
		/** All available Locators */
		locators: {
			type: Array,
			default: [],
		},
		/** All Field-Groups for the Document-Class */
		fieldGroups: {
			type: Array,
			default: [],
		},
		fieldGroupId: {
			type: Number,
			default: -1,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox", "onChangeSortOrder", "openFieldsLayout"],
})

export default class DocumentClassFieldTable extends Vue {

	/** Current Vuex-Store */
	store = useStore();

	/** Prop for currently active field-group. Will be returned when emitting openEntryDialog, so this data is available in the parent */
	fieldGroupId!: number

	/** List of all locators **/
	locators!: DocumentLocator[];

	/** List of all Field-Groups **/
	fieldGroups!: DocumentFieldGroup[];

	/** all rows */
	rows!: DocumentField[];

	/** show Delete Dialog? */
	deleteDialog = false;

	/** Data of selected row */
	rowData = {};

	/** Is the Search activated? */
	searchActive = false;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Is Filtering active? */
	isFilterActive: boolean = false;

	/** List of Data-Types */
	dataTypes: DataTypes[] = [];

	mounted() {
		this.dataTypes = [
			{
				id: 'Text',
				text: this.$t('Squeeze.General.Text'),
			},
			{
				id: 'Date',
				text: this.$t('Squeeze.General.Date'),
			},
			{
				id: 'Amount',
				text: this.$t('Squeeze.General.Amount'),
			},
		];
	}

	/**
	 * Opens the Delete Dialog
	 * @param {DocumentField} data Data from the Document-Class that should be deleted
	 */
	openDeleteDialog(data: DocumentField) {
		this.rowData = {...data};
		this.deleteDialog = true;
	}

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentField} data Data to edit
	 */
	openEditDialog(data: DocumentField) {
		if (!data.lookup) {
			data.lookup = {
				active: false,
				allowCustomValues: true,
				tableId: 0,
				resultKeyColumnId: 0,
				searchColumnIds: [],
				resultValueColumnIds: [],
				minInputLength: 1,
			};
		}
		this.rowData = {...data};
		this.$emit("openEntryDialog", this.rowData, this.fieldGroupId)
	}

	/**
	 * Gets the Description of a locator
	 * @param {Number} locatorId Id of the Locator to get the Description from
	 * @return {String} Description of the Locator
	 */
	getDescriptionFromLocator(locatorId?: number) {
		if (!locatorId) {
			return "";
		}

		if (!this.locators) {
			return String(locatorId)
		}

		const locator = this.locators.find(i => i.id === locatorId);

		if (!locator) {
			return String(locatorId)
		}

		return locator.description
	}

	/**
	 * Get the Translation of data type
	 * @param {String} type
	 * @return {String} Current Translation of data type
	 */
	getTranslationFromDataType(type: string) {
		switch (type) {
		case 'Text':
			return this.$t('Squeeze.General.Text');
		case 'Date':
			return this.$t('Squeeze.General.Date');
		case 'Amount':
			return this.$t('Squeeze.General.Amount');
		default:
			return type;
		}
	}

	/**
	 * Gets the Description of a Field Group
	 * @param {Number} fieldGroupId Id of the Locator to get the Description from
	 * @return {String} Description of the Locator
	 */
	getDescriptionFromFieldGroup(fieldGroupId: number) {
		if (!this.fieldGroups) {
			return String(fieldGroupId)
		}

		const fieldGroup = this.fieldGroups.find(i => i.id === fieldGroupId);

		if (!fieldGroup) {
			return String(fieldGroupId)
		}
		return fieldGroup.description;
	}

	/** Emits the "deleteEntry"-Event */
	deleteEntry() {
		this.$emit("deleteEntry", this.rowData)
	}

	/** Triggered when a checkbox is clicked
	 * @param {DocumentField} data Data to edit
	 * @package fieldName Name of the current field
	 */
	onChangeCheckbox(data: DocumentField, fieldName: string) {
		this.$emit("onChangeCheckbox", data, fieldName);
	}

	/**
	 * Triggered when the rows are reordered
	 * @param event
	 */
	onRowReorder(event: any) {
		const orderedList: number[] = [];
		event.value.forEach((documentClass: DocumentField) => {
			orderedList.push(documentClass.id!);
		});

		this.$emit("onChangeSortOrder", orderedList, this.fieldGroupId, this.isFilterActive);
	}

	/** Opens the fields layout */
	openFieldsLayout() {
		this.$emit("openFieldsLayout");
	}

	/** Triggered when a filter has been entered */
	onFilter(event: any) {
		if (event.filteredValue.length < this.rows.length) {
			this.isFilterActive = true;
		} else {
			this.isFilterActive = false;
		}
	}

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"defaultValue": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"locatorId": {value: null, matchMode: FilterMatchMode.EQUALS},
			"subFieldName": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"externalName": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

	beforeMount() {
		this.initFilters();
	}

}
