<template>
  <div>
    <!-- Lösch-Dialog -->
    <Dialog v-model:visible.sync="deleteDialog" :style="{width: '450px'}" :header="headerLabelComponent" :modal="true" @hide="closeDialog()">
      <div class="confirmation-content">
        <i class="mdi mdi-message-alert-outline p-mr-3" style="font-size: 1rem" />
        <span>{{messageLabelComponent}}</span>
      </div>
      <template #footer>
        <Button :label="$t('Squeeze.General.No')" icon="mdi mdi-close" class="p-button-text" @click="closeDialog()"/>
        <Button :label="$t('Squeeze.General.Yes')" icon="mdi mdi-check" class="p-button-text" @click="deleteEntry()" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">

import Dialog from "primevue/dialog";
import  {Options, Vue} from "vue-class-component";

@Options({
	components: {
		Dialog,
	},
	props: {
		showDialog: {
			type: Boolean,
			default: false,
		},
		headerLabel: {
			type: String,
			default: null,
		},
		messageLabel: {
			type: String,
			default: null,
		},
	},
	watch: {
		showDialog: function() {
			// Save showDialog in local variable, because showDialog is not allowed to me mutated inside. React on change from showDialog from parent
			this.deleteDialog = this.showDialog;
		},
	},
	emits: ["onClose", "onConfirm"],
})

export default class DialogDelete extends Vue {

	/** Prop from Show-Dialog */
	showDialog!: boolean;

	/** Save showDialog in local variable, because showDialog is not allowed to me mutated inside. React on change from showDialog from parent */
	deleteDialog = this.showDialog;

	/** Label that can be optionally given from outside the component */
	headerLabel!: string;

	/** Message in Dialog that can be optionally given from outside the component */
	messageLabel!: string;

	/** Component Label of Dialog */
	headerLabelComponent = "";

	/** Component Message of Dialog */
	messageLabelComponent = "";

	mounted() {
		if (!this.headerLabel) {
			this.headerLabelComponent = this.$t('Squeeze.General.DeleteHeader');
		}
		else {
			this.headerLabelComponent = this.headerLabel;
		}

		if (!this.messageLabel) {
			this.messageLabelComponent = this.$t('Squeeze.General.DeleteConfirm');
		}
		else {
			this.messageLabelComponent = this.messageLabel;
		}
	}

	/**
	 * Emit onClose-Event to to parent, when "no" or "close" is clicked
	 */
	closeDialog() {
		this.deleteDialog = false;
		this.$emit("onClose");
	}

	/**
	 * Emit the "onConfirm-Event to parent if "yes" is clicked
	 */
	deleteEntry() {
		this.deleteDialog = false;
		this.$emit("onConfirm");
	}

}
</script>

<style scoped>

</style>
