<template>
	<DocumentFieldTrainingForm
		:documentClassId="documentClassId"
		:documentFields="documentFields"
		:fieldTrainingValue="fieldTrainingValue"
		:fieldTestingValues="fieldTestingValues"
		:tableTrainingValues="tableTrainingValues"
		:documentClassFields="documentClassFields"
		:trainingKeyField="trainingKeyField"
		:showTrainDocument="showTrainDocument"
		:warnAmount="warnAmount"
		:blockButtonTraining="blockButtonTraining"
		:loading="!loaded"
		@onFocusField="onFocusFieldTraining"
		@trainFieldValues="trainFieldValues"
		@onChangeDocumentField="onChangeDocumentsField"
		@testingFieldValues="testingFieldValues"
	/>
	<DocumentFieldTrainingTable
		:fieldTestingValues="fieldTestingValues"
		:tableTrainingValues="tableTrainingValues"
		:clickOnTestingButton="clickOnTestingButton"
		:loaded="loaded"
		@deleteTrainingRow="deleteTrainingRow"
		@changeTrainingRow="changeTrainingRow"
		@onMarkRegion="onMarkRegion"
	/>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import DocumentFieldTrainingForm from "@/apps/squeeze/components/DocumentFieldTrainingForm.vue";
import DocumentFieldTrainingTable from "@/apps/squeeze/components/DocumentFieldTrainingTable.vue";
import {DocumentField, DocumentFieldValue, FieldTraining} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";

interface FieldTrainingWithName extends FieldTraining {
	fieldName?: string;
	dataType?: string;
}

@Options({
	name: "HeadTraining",
	components: {DocumentFieldTrainingForm, DocumentFieldTrainingTable},
	props: {
		documentId: Number,
		documentClassId: Number,
		fieldTrainingValue: Object,
		documentFields: Array,
		trainingKeyField: Object,
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
})
export default class HeadTraining extends Vue {

	/** ID of document */
	documentId!: number;

	/** ID of document class*/
	documentClassId!: number;

	/** Current field training value of form */
	fieldTrainingValue!: FieldTraining;

	/** List of all training values of FieldTraining */
	tableTrainingValues: FieldTrainingWithName[] = [];

	/** List of all testing values of FieldTraining */
	fieldTestingValues: FieldTrainingWithName[] = [];

	/** List of all DocumentFields of document class */
	documentClassFields: DocumentField[] = [];

	/** List with document field values for testing */
	testingValues: DocumentFieldValue = {
		id: 0,
		value: "-",
		boundingBox: {
			page: 1,
			x0: 0,
			y0: 0,
			x1: 0,
			y1: 0,
		},
		confidence: 0,
		subFieldName: "",
		state: "",
		errorText: "",
	}

	trainingKeyField!: DocumentField;

	/** Show Button-Text Train */
	showTrainDocument = true;

	/** Show Testing-Text in table */
	clickOnTestingButton = false;

	/** Show Amount-Warning? */
	warnAmount = false;

	/** Block Training Button? */
	blockButtonTraining = false;

	/** Indicates end of request */
	loaded = false;

	/** Document Class API endpoint */
	documentClassApi = ClientManager.getInstance().squeeze.documentClass;

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	created() {
		this.getDataOfTraining();
	}

	/** get data of training
	 * Promise1 to get all document field values
	 * Promise2 to get all training values for training table
	 * */
	getDataOfTraining() {
		if (!this.trainingKeyField.value!.value) {
			this.loaded = true;
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Viewer.Error.NoCreditorField'));
			return;
		}

		this.loaded = false;

		const promise1 = this.documentClassApi.getAllDocumentClassFields(this.documentClassId);
		const promise2 = this.documentClassApi.getAllDocumentClassFieldTrainings(this.documentClassId,this.trainingKeyField.value!.value);

		// Wait until promises are finished
		Promise.all([promise1, promise2]).then(values => {
			this.documentClassFields = values[0];

			const fieldTrainingsWithName: FieldTrainingWithName[] = values[1];
			fieldTrainingsWithName.sort(function(firstValue: any, secondValue: any) {
				return firstValue.fieldId - secondValue.fieldId;
			})
			this.warnAmount = false;
			fieldTrainingsWithName.forEach((values) => {
				this.documentClassFields.forEach((field) => {
					if (values.fieldId === field.id) {
						values.fieldName = field.description;
						values.dataType = field.dataType;
					}
					if (this.fieldTrainingValue.fieldId === field.id && field.dataType === 'Amount') {
						this.warnAmount = true;
						this.blockButtonTraining = true;
					}
				})
			})

			this.tableTrainingValues = fieldTrainingsWithName;
			this.clickOnTestingButton = false;
		}).catch(response => response.json().then ((err: { message: string }) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
		})).finally(() => {
			this.loaded = true;
		})
	}

	/** Get all field values */
	trainFieldValues() {
		this.fieldTrainingValue.trainingKeyValue = this.trainingKeyField.value!.value!;
		const rowIds: any[] = [];

		if (this.fieldTrainingValue.fieldId !== 0) {
			this.tableTrainingValues.forEach((row) => {
				rowIds.push(row.id);
			})
			if (rowIds.includes(this.fieldTrainingValue.id)) {
				this.documentClassApi.retrainDocumentClassField(this.documentClassId, this.fieldTrainingValue.fieldId!, this.fieldTrainingValue.id!, this.fieldTrainingValue, true)
					.then(() => {
						this.showTrainDocument = true;
						this.getDataOfTraining();
						this.resetForm();
					})

					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
					}))
			} else {
				this.fieldTrainingValue.id = undefined;
				this.documentClassApi.trainDocumentClassField(this.documentClassId, this.fieldTrainingValue.fieldId!, this.fieldTrainingValue, false)
					.then(() => {
						this.getDataOfTraining();
						this.resetForm();
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
					}))
			}
		}
	}

	/** Testing all training values for training table */
	testingFieldValues() {
		this.clickOnTestingButton = true;
		this.loaded = false;
		this.fieldTestingValues = [];
		this.documentApi.testFieldTrainings(this.documentId, this.trainingKeyField.value!.value!)
			.then((data) => {
				data.sort(function(firstValue: any, secondValue: any) {
					return firstValue.fieldId - secondValue.fieldId;
				})
				data.forEach((dataField: FieldTraining & { fieldName?: string; dataType?: string }) => {
					if (!dataField.keyWord) {
						dataField.keyWord = this.testingValues;
					}
					if (!dataField.value) {
						dataField.value = this.testingValues;
					}
					this.documentClassFields.forEach((field) => {
						if (dataField.fieldId === field.id) {
							dataField.fieldName = field.description;
							dataField.dataType = field.dataType;
						}
					})
					const keyWordPage = dataField.keyWord.boundingBox!.page;
					const valuePage = dataField.value.boundingBox!.page;
					dataField.keyWordRegion.page = keyWordPage;
					dataField.valueRegion.page = valuePage;
					this.fieldTestingValues.push(dataField);
				});
			})
			.catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
			.finally(() => {
				this.loaded = true;
			})
	}

	/**
	 * Triggered when a field is focused
	 * @param fieldName
	 */
	onFocusFieldTraining(fieldName: string) {
		this.$emit("onFocusField", fieldName);
	}

	/**
	 * Deletes a row of the training table
	 * @param id
	 */
	deleteTrainingRow(rowData: any) {
		this.documentClassApi.deleteDocumentClassFieldTraining(this.documentClassId, rowData.fieldId, rowData.id)
			.then(() => {
				this.showTrainDocument = true;
				this.resetForm();
				this.getDataOfTraining();
			})
			.catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/**
	 * Change a training value
	 * @param rowData
	 */
	changeTrainingRow(rowData: any) {
		const editingRow = rowData;

		if (editingRow.dataType === "Amount" || editingRow.dataType === "Date") {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Validation.Dialogs.Training'));
			return;
		}

		this.showTrainDocument = false;

		if (editingRow) {
			this.documentClassApi.retrainDocumentClassField(editingRow.documentClassId, editingRow.fieldId, editingRow.id, editingRow, true)
				.then(() => {
					this.fieldTrainingValue.keyWordPattern = editingRow.keyWordPattern;
					this.fieldTrainingValue.fieldId = editingRow.fieldId;
					this.fieldTrainingValue.valuePattern = editingRow.valuePattern;
					this.fieldTrainingValue.id = editingRow.id;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
				}))
		} else {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Training.ChangeTrainingError'));
		}
	}

	/**
	 * Set marker regions by clicking the Testing-Row
	 * @param event
	 */
	onMarkRegion(event: any) {
		const row = event.data;
		if (row.keyWord && row.keyWord.boundingBox) {
			row.keyWord.boundingBox.page = row.keyWordRegion.page;
		}
		const keyWordRegion = row.keyWord.boundingBox;
		if (row.value && row.value.boundingBox) {
			row.value.boundingBox.page = row.valueRegion.page;
		}
		const valueRegion = row.value.boundingBox;
		const markRegions = [keyWordRegion, valueRegion];
		this.$emit("onMarkRegion", markRegions);
	}

	/**
	 * Triggered when the document-field is changed
	 */
	onChangeDocumentsField() {
		this.showTrainDocument = true;
		this.resetForm();
		this.getDataOfTraining();
	}

	/** Resets the form so a new training can be added */
	resetForm() {
		this.fieldTrainingValue.id = undefined;
		this.fieldTrainingValue.keyWordPattern = '';
		this.fieldTrainingValue.valuePattern = '';
	}

}
</script>

<style scoped>

</style>
