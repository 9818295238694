<template>
	<div class="p-p-2 p-ml-2 p-component">
		<h1 class="header p-component">{{$t('Squeeze.System.System')}}</h1>

		<div class="p-fluid p-formgrid p-grid p-p-0">
			<div class="p-field p-col-12 p-pl-0">
				<span class="p-col-3">
					{{ $t('Squeeze.System.Version') }}:
				</span>
				<span class="p-col-3">{{ systemVersion }}</span>
			</div>
			<div class="p-field p-col-12 p-pl-0">
				<span class="p-col-3">
					{{ $t('Squeeze.General.Tenant') }}:
				</span>
				<span class="p-col-3">{{ store.state.systemInformation.tenant }}</span>
			</div>
			<div class="p-field p-col-12 p-pl-0 p-mb-0">
				<span class="p-col-3">
					{{ $t('Squeeze.General.TenantName') }}:
				</span>
				<span class="p-col-3">{{ store.state.systemInformation.tenantName }}</span>
			</div>
		</div>

		<Divider />

		<Accordion :multiple="true" :activeIndex="[]">
			<AccordionTab>
				<template #header>
					<i class="mdi mdi-information-variant"/>
					<h4>{{ $t('Squeeze.System.System') }} {{ $t('Squeeze.System.Info') }}</h4>
				</template>
				<SystemInformation :systemInformation="store.state.systemInformation.labels" />
			</AccordionTab>
			<AccordionTab>
				<template #header>
					<i class="mdi mdi-file-clock-outline"/>
					<h4>{{ $t('Squeeze.System.SystemCleanUp') }}</h4>
				</template>
				<SystemCleanUpConfig :systemCleanUpConfig="systemCleanUpConfig" :loading="loading" />
			</AccordionTab>
			<AccordionTab>
				<template #header>
					<i class="mdi mdi-image-outline"/>
					<h4>{{ $t('Squeeze.System.ImageUpload') }}</h4>
				</template>
				<SystemThemeUploadView />
			</AccordionTab>
			<AccordionTab>
				<template #header>
					<i class="mdi mdi-format-color-fill"/>
					<h4>{{ $t('Squeeze.System.ColorPicker') }}</h4>
				</template>
				<SystemColorPickerView />
			</AccordionTab>
			<AccordionTab>
				<template #header>
					<i class="mdi mdi-file-replace-outline"/>
					<h4>{{ $t('Squeeze.System.CSVTranslations') }}</h4>
				</template>
				<SystemCsvTranslationView />
			</AccordionTab>
			<AccordionTab>
				<template #header>
					<i class="mdi mdi-information-variant"/>
					<h4>{{ $t('Squeeze.System.System') }} {{ $t('Squeeze.System.Info') }}</h4>
				</template>
				<SystemInformation :systemInformation="store.state.systemInformation.labels" />
			</AccordionTab>
		</Accordion>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useStore} from "@/store";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {SystemCleanupConfig} from "@dex/squeeze-client-ts";
import SystemCleanUpConfig from "@/apps/administration/components/system/SystemCleanUpConfig.vue";
import SystemInformation from "@/apps/administration/components/system/SystemInformation.vue";
import Divider from 'primevue/divider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import SystemThemeUploadView from "@/apps/administration/views/squeeze/system/SystemThemeUploadView.vue";
import SystemColorPickerView from "@/apps/administration/views/squeeze/system/SystemColorPickerView.vue";
import SystemCsvTranslationView from "@/apps/administration/views/squeeze/system/SystemCsvTranslationView.vue";

export default defineComponent({
	name: "SystemView",
	components: {
		SystemCsvTranslationView,
		SystemColorPickerView,
		SystemThemeUploadView,
		SystemInformation,
		SystemCleanUpConfig,
		Divider,
		Accordion,
		AccordionTab,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Api for job management in system */
		const systemApi = ClientManager.getInstance().squeeze.system;

		/** String with system version */
		const systemVersion = ref<string | undefined>('');

		/** Object with systemCleanUpConfigs */
		const systemCleanUpConfig = reactive<SystemCleanupConfig>({
			maxDocumentAge:	undefined,
			maxLogAge: undefined,
		});

		/** Get System Version */
		const getSystemVersion = () => {
			loading.value = true;
			systemApi.getVersion()
				.then(data => {
					systemVersion.value = data.initialVersion;
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		/** Get SystemCleanUpConfig */
		const getSystemCleanUpConfig = () => {
			loading.value = true;
			systemApi.getCleanupConfig()
				.then(data => {
					Object.assign(systemCleanUpConfig, data);
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			getSystemVersion();
			getSystemCleanUpConfig();
		});

		return {
			t, toast, store, loading, systemVersion, systemCleanUpConfig, getSystemVersion, getSystemCleanUpConfig,
		};
	},
});

</script>

<style scoped>

.header {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin: 0;
}

::v-deep(.p-divider-solid.p-divider-horizontal:before) {
	border-top-style: solid !important;
}

::v-deep(.p-accordion-tab ) {
	box-shadow: none !important;
	border-bottom: 0.063rem solid #0000001f;
}

::v-deep(.p-accordion-header .p-accordion-header-link) {
	padding-left: 0 !important;
}

::v-deep(.p-accordion-header h4) {
	margin: 0;
	padding-left: 0.25rem;
	font-weight: normal;
}

::v-deep(.p-accordion .p-accordion-tab.p-accordion-tab-active) {
	margin-bottom: 0;
}

</style>
