<template>
	<!-- TODO: Check how to use stacked without massive space-between or table grid blow-out
		responsiveLayout="stack"
		breakpoint="1946px" -->
	<DataTable
		ref="documentList"
		class="p-shadow-2 p-datatable-documents"
		:value="documents"
		:lazy="true"
		:paginator="true"
		:loading="!loaded || lockTable"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="paginationInfo.pageSize"
		:totalRecords="paginationInfo.total"
		columnResizeMode="fit"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="flex"
		v-model:selection="selection"
		:rowHover="true"
		@row-click="onRowClick"
		@page="onPage($event)"
		@filter="onFilter($event.filters)"
		v-model:filters="filters"
		filterDisplay="row"
		sortMode="multiple"
		@sort="onSort($event)"
		:removableSort="true"
		:first="(paginationInfo.page * paginationInfo.pageSize)"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		:metaKeySelection="false"
	>
		<template #loading>
			{{$t('Squeeze.Queue.Filter.Loading')}}
		</template>
		<template #empty>
			{{$t('Squeeze.Queue.Filter.NoEntries')}}
		</template>
		<Column v-if="store.state.scopes.sqzRequeue && ($route.name === 'DocumentListValidation' || $route.name === 'DocumentListValidationWithSearch')" selectionMode="multiple" style="min-width: 4rem; max-width: 4rem; justify-content: center;" />
		<Column field="id" :header="$t('Squeeze.Queue.Fields.id')" style="min-width: 4rem; max-width: 4rem;" :showFilterMenu="false" :sortable="true">
			<template #filter="{filterModel,filterCallback}">
				<InputText style="min-width: 3rem; max-width: 4rem;" class="filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder=""/>
			</template>
		</Column>

		<Column v-if="$route.name !== 'DocumentListValidation' && $route.name !== 'DocumentListValidationWithSearch'" field="step" :header="$t('Squeeze.Queue.Fields.Step')" style="min-width: 10rem; max-width: 10rem;" filterField="queueStep" :showFilterMenu="false">
			<template #body="document">
				<div style="text-align: left!important; white-space:nowrap!important;">
					<template v-if="document.data.workflowContext.step !== 'validation' && document.data.exports && document.data.exports.find(exportData => exportData.interfaceName === 'EAS')">
						{{$t("Squeeze.Queue.StepsList.ARCHIVE")}} <i class="mdi mdi-archive-outline" v-tooltip="getExportsForToolTip(document.data.exports)"></i>
					</template>
					<template v-else>
						{{$t("Squeeze.Queue.StepsList." + document.data.workflowContext.step.toUpperCase())}}
						<template v-if="document.data.exports && document.data.exports.length > 0">
							<i class="mdi mdi-archive-outline" v-tooltip="getExportsForToolTip(document.data.exports)"></i>
						</template>
					</template>
				</div>
			</template>
			<template #filter="{filterModel,filterCallback}">
				<Dropdown v-model="filterModel.value" @change="filterCallback()" :options="steps" placeholder="" class="p-column-filter filter-field" :showClear="true">
					<template #value="document">
						<span v-if="document.value">{{$t("Squeeze.Queue.StepsList." + document.value.toUpperCase())}}</span>
						<span v-else>{{document.placeholder}}</span>
					</template>
					<template #option="document">
						{{$t("Squeeze.Queue.Steps." + document.option)}}
					</template>
				</Dropdown>
			</template>
		</Column>

		<Column field="status" :header="$t('Squeeze.Queue.Fields.status')" style="min-width: 8rem; max-width: 8rem;" filterField="queueStatus" :showFilterMenu="false" :sortable="true">
			<template #body="document">
				<div style="text-align: left!important; white-space:nowrap!important;">
					<span :class="'status-badge ' + document.data.workflowContext.status.toLowerCase()" class="p-py-1">
						{{$t("Squeeze.Queue.States." + document.data.workflowContext.status)}}
					</span>
				</div>
			</template>
			<template #filter="{filterModel,filterCallback}">
				<Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="" class="p-column-filter filter-field" :showClear="true" style="height: 2.16rem;">
					<template #value="document">
						<span v-if="document.value">{{$t("Squeeze.Queue.States." + document.value)}}</span>
						<span v-else>{{document.placeholder}}</span>
					</template>
					<template #option="document">
						<span :class="'status-badge ' + document.option.toLowerCase()">{{$t("Squeeze.Queue.States." + document.option)}}</span>
					</template>
				</Dropdown>
			</template>
		</Column>


		<Column field="create_ts" :header="$t('Squeeze.WorkflowContext.createdTs')" style="min-width: 10rem; max-width: 10rem;" :sortable="true">
			<template #body="document">
				<div style="white-space:nowrap!important;">
					{{formatDate(document.data.createdAt)}}
				</div>
			</template>
		</Column>

		<Column field="errorText" :header="$t('Squeeze.General.Comment')" style="min-width: 10rem; max-width: 10rem; overflow: hidden;" :sortable="true">
			<template #body="document">
				<div class="column-cell" v-tooltip.bottom="document.data.workflowContext.errorText">
					{{document.data.workflowContext.errorText}}
				</div>
			</template>
		</Column>

		<Column v-for="column of fieldColumns" :field="column.name" :filterField="'' + column.id" :header="column.header"  :key="column.id" :style="column.type.toLowerCase() === 'amount' ? 'min-width: 12rem; overflow: hidden;' : 'min-width: 15rem; overflow: hidden;'" :showFilterMenu="true"
				:sortable="true">
			<template #body="document">
				<div :style="column.type.toLowerCase() === 'amount' ? 'text-align:right!important;' : 'text-align:left;'" class="column-cell" v-tooltip.bottom="document.data.fields['' + column.id] && document.data.fields['' + column.id].value ? formatValue(document.data.fields['' + column.id].value, column.type) : ''">
					<template v-if="document.data.fields['' + column.id] && document.data.fields['' + column.id].value">
						{{formatValue(document.data.fields['' + column.id].value, column.type)}}
					</template>
				</div>
			</template>
			<template #filter="{filterModel,filterCallback}">
				<InputText :style="column.type.toLowerCase() === 'amount' ? 'min-width: 12rem; max-width: 12rem;' : 'min-width: 14rem; max-width: 14rem;'" class="filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder=""/>
			</template>
		</Column>

		<template #paginatorLeft>
			<Button type="button" v-tooltip="$t('Squeeze.General.Refresh')" icon="mdi mdi-refresh" class="p-button-text p-ml-r" @click="onReload" :disabled="!loaded" />
			<Button type="button" v-tooltip="$t('Squeeze.General.ClearSearch')" icon="mdi mdi-filter-variant-remove" class="p-button-text p-ml-r p-mr-2" @click="clearSearch" :disabled="!loaded" />
			<InputText style="width: auto;" class="filter-field" type="text" :value="fullText" v-on:input="emit('update:fullText', $event.target.value)" @keydown.enter="onReload" :placeholder="$t('Squeeze.General.Search') + '...'"/>
			<Button v-if="store.state.featureSet.savedDocumentSearches" type="button" v-tooltip="$t('Squeeze.General.SaveSearchList')" icon="mdi mdi-filter-plus" class="p-button-text p-ml-r p-ml-2" @click="showSaveSearch" :disabled="!loaded" />
			<Button v-if="store.state.featureSet.savedDocumentSearches && showSelectSearch" type="button" v-tooltip="$t('Squeeze.General.OpenSearch')" icon="mdi mdi-filter-menu" class="p-button-text p-ml-r" @click="openSelectSearch" :disabled="!loaded" />
			<Button v-if="store.state.scopes.sqzRequeue && ($route.name === 'DocumentListValidation' || $route.name === 'DocumentListValidationWithSearch')" type="button" icon="mdi mdi-file-refresh-outline" class="p-button-text" :label="$t('Squeeze.Queue.Requeue.Requeue')" @click="openMultiRequeueMenu" :disabled="!selection.length" aria-haspopup="true" aria-controls="multi_entry_menu" />
			<Menu v-if="store.state.scopes.sqzRequeue &&  ($route.name === 'DocumentListValidation' || $route.name === 'DocumentListValidationWithSearch')" id="multi_entry_menu" ref="multiEntryMenu" :model="queueEntrySteps" :popup="true" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import {DEFAULT_LOCALE} from "@/lang";
import { i18n } from "@/main";
import {DateTimeOptions} from "@/interfaces/DateTimeOptions";
import {ListDocument} from "@/apps/squeeze/interfaces/ListDocument";
import {DocumentFilterObject, DocumentSortObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {ExportStatus} from "@dex/squeeze-client-ts/dist/api";
import Tooltip from "primevue/tooltip";
import {QueueEntry} from "@dex/squeeze-client-ts";
import {useStore} from "@/store";
import { MenuItem } from '@/shims-prime-vue';
import {useConfirm} from "primevue/useconfirm";
import {computed, defineComponent, onMounted, PropType, ref} from "vue";
import {useI18n} from "vue-i18n";
import Squeeze from "@/apps/squeeze/App.vue";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import Menu from "primevue/menu";

/**
 * @description Uses Elastic Search API endpoint instead of Queue API endpoint
 */
export default defineComponent({
	name: "DocumentList",
	components: {
		DataTable,
		Column,
		InputText,
		Dropdown,
		Menu,
	},
	props: {
		documentClassId: Number,
		documentClassName: String,
		documents: Array as PropType<ListDocument[]>,
		fieldColumns: Array as PropType<DocumentFilterObject[]>,
		loaded: Boolean,
		paginationInfo: Object,
		filterOptions: Object as PropType<DocumentFilterObject>,
		fullText: {
			type: String,
			default: '',
		},
		showSelectSearch: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage", "onFilter", "onSort", "update:fullText", "openSaveSearch", "openSelectSearch", "clearSearch"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const documentList = ref<any>();

		/** Filters of list */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** QueueStates for filter selection */
		const statuses =  ref<string[]>([
			'INITIAL', 'WORK', 'WAITING', 'ERROR', 'SUSPEND', 'CLARIFICATION', 'FINISHED',
		]);

		/** QueueStates for filter selection */
		const steps =  ref<string[]>([
			'Backup', 'Delete', 'SplitDocument', 'Validation',
		]);

		/** List of allowed filters */
		const allowedFilters = computed(() => {
			return [{value: FilterMatchMode.EQUALS, label: t('Squeeze.Queue.Filter.Options.EQUALS')},
				{value: FilterMatchMode.CONTAINS, label: t('Squeeze.Queue.Filter.Options.CONTAINS')},
				{value: FilterMatchMode.STARTS_WITH, label: t('Squeeze.Queue.Filter.Options.STARTS_WITH')},
				{value: FilterMatchMode.ENDS_WITH, label: t('Squeeze.Queue.Filter.Options.ENDS_WITH')}]
		})

		/** Currently-Selected row */
		const selection = ref([]);

		/** Current locale */
		const lang = ref(DEFAULT_LOCALE);

		/** Requeue steps to choose in menu */
		const queueEntrySteps = ref<MenuItem[]>([]);

		/** Queue API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Should the table be locked? */
		const lockTable = ref<boolean>(false);

		/** Ref for menu */
		const multiEntryMenu = ref(null);

		const onFilter = (tableFilters: DocumentFilterObject) => {
			emit("onFilter", tableFilters);
		}

		/**
		 * Triggered when a row is clicked
		 * @param event
		 */
		const onRowClick = (event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) => {
			/* Prevent the row-click in the selection-column with the checkbox
			* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
			* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
			*/
			if (event.originalEvent
				&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/** Triggered when the next page is selected */
		const onPage = (event: any) => {
			emit("onPage", event)
		}

		/** Triggered on sort a column */
		const onSort = (event: { multiSortMeta: DocumentSortObject[] }) => {
			emit("onSort", event.multiSortMeta)
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			emit("onReload");
		}

		/** Opens the dialog for saving/deleting searches */
		const showSaveSearch = () => {
			documentList.value.onFilterApply();
			emit("openSaveSearch");
		}

		/** Opens the Search Selection */
		const openSelectSearch = () => {
			emit("openSelectSearch");
		}

		/** Clears the current search */
		const clearSearch = () => {
			emit("clearSearch");
		}

		/** Formats date string to desired locale string */
		const formatDate = (dateToFormat: string): string => {
			if(dateToFormat && dateToFormat.length) {
				try {
					const dateNumber = Date.parse(dateToFormat);

					if(!isNaN(dateNumber)) {
						const date = new Date(dateToFormat);
						const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
						lang.value = i18n.global.locale;
						return date.toLocaleDateString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), options);
					}
				} catch (err: any) {
					return dateToFormat;
				}
			}
			return dateToFormat;
		}

		/** Formats an amount to locale string (Formats are only defined in the backend) */
		const formatAmounts = (value: string) => {
			value = value.replace(/[^0-9.,-]/g, "");

			if(value.indexOf(",") != -1) {
				value = value.replaceAll(".", "").replace(",", ".");
			}

			if(value.length > 0) {
				return parseFloat(value).toLocaleString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), {minimumFractionDigits: 2});
			} else {
				return "0,00";
			}
		}

		/** Formats given values to data type specific formats */
		const formatValue = (value: string, dataType: string) => {
			if (!value) {
				return "";
			}

			switch(dataType.toLowerCase()) {
			case "amount": return formatAmounts(value);
			//case "date": return formatDate(value);
			default: return value;
			}
		}

		/**
		 * Creates the Tooltip for Exports
		 * @param exports
		 */
		const getExportsForToolTip = (exports: ExportStatus[]) => {
			let toolTip = "";
			exports.forEach(exportData => {
				toolTip += exportData.interfaceName + ":\n " + t("Squeeze.Queue.States." + exportData.status) + "\n " + formatDate(exportData.timeStamp!) + "\n" + exportData.result + "\n\n";
			})

			return toolTip;
		}

		/**
		 * Opens multi requeue menu
		 * @param event
		 */
		const openMultiRequeueMenu = (event: any) => {
			if (!selection.value.length) {
				return;
			}
			const multiMenu = multiEntryMenu.value as any;
			if(multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Requeue the selected entries
		 * @param step
		 */
		const requeueSelectedEntries = (step: string) => {
			if (!selection.value || !selection.value.length) {
				return;
			}

			lockTable.value = true;
			selection.value.reduce((chain, { id }) => {
				if (id == undefined) {
					return chain;
				}
				return chain.finally(() => documentApi.requeueDocument(id, step, undefined).catch(() => { /* swallow errors */ }))
			}, Promise.resolve()).finally(() => {
				ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				selection.value = [];
				lockTable.value = false;
				onReload();
			});
		}

		onMounted(() => {
			queueEntrySteps.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];
			Squeeze.queueSteps.forEach((icon: string, step: string) => {
				queueEntrySteps.value[0].items.push({
					label: t('Squeeze.Queue.Steps.' + step),
					icon: icon,
					command: (ev: { item: any; originalEvent: MouseEvent }) => {
						let messageText = '';
						let acceptFn = null;

						if ((ev.originalEvent.target as HTMLElement).matches('#multi_entry_menu *')) {
							// If entry in multi_menu is clicked (Requeue-Button under table for multi-selection)
							messageText = t('Squeeze.Queue.Requeue.ForwardEntries');
							acceptFn = () => {
								requeueSelectedEntries(step);
							};
						}
						else {
							return;
						}

						confirm.require({
							message: messageText + ": " + t('Squeeze.Queue.Steps.' + step) + " ?",
							header: t('Squeeze.General.Confirmation'),
							icon: 'pi pi-exclamation-triangle',
							accept: acceptFn,
							reject: () => { /**/ },
						});
					},
				});
			});
		})

		return {
			filters, allowedFilters, steps, statuses, store, queueEntrySteps, selection, multiEntryMenu,
			lockTable,
			onReload, onSort, onPage, onRowClick, onFilter, formatDate, emit,
			openMultiRequeueMenu, getExportsForToolTip, formatValue, showSaveSearch, openSelectSearch, clearSearch, documentList,
		}
	},
});
</script>
<style lang="scss" scoped>
// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
  background-color: var(--dex-primary-light-color);
  padding: 0.6rem 0.6rem 0.2rem 0.6rem;
  border-bottom-color: var(--dex-primary-light-color);
  color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr td) {
	padding: 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-filter-column) {
	padding:0 0 0.2rem 0.2rem;
	color: var(--filter-field-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-filter-column .p-column-filter-menu-button, .p-column-filter-clear-button) {
	color: var(--filter-field-color);
}

// @todo Hover sortable icon have to be white!

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
  color: var(--dex-text-color);

  &:hover {
    color: var(--dex-text-color);
  }
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-dropdown.filter-field) {
  margin-left: 0.4rem;
  padding: 0.4rem;
  background-color: transparent;
  color: var(--dex-text-color);
  border-radius: 0;
  border-color: var(--filter-field-color);

  &:focus {
    border-color: rgba(255, 255, 255, 0.8);
  }

  .p-dropdown-label {
    padding: 0 1rem 0 0.4rem;
    color: var(--filter-field-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
  padding: 0.4rem;
  background-color: transparent;
  color: var(--dex-text-color);
  border-radius: 0;
  border-color: var(--dex-text-color);

  &:focus {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

::placeholder {
	color: var(--filter-field-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-tbody tr td) {
	padding: 0.6rem;
}

.status-badge {
	font-weight: bold;
	&.initial {
		border-color: var(--queuestate-initial-primary);
		color: var(--queuestate-initial-darker);
	}

	&.work {
		border-color: var(--queuestate-work-primary);
		color: var(--queuestate-work-darker);
	}

	&.waiting {
		border-color: var(--queuestate-waiting-primary);
		color: var(--queuestate-waiting-darker);
	}

	&.error {
		border-color: var(--queuestate-error-primary);
		color: var(--queuestate-error-darker);
	}

	&.suspend {
		border-color: var(--queuestate-suspend-primary);
		color: var(--queuestate-suspend-darker);
	}

	&.suspend-marked {
		border-color: var(--queuestate-suspend-darker);
		color: var(--queuestate-suspend-darker);
	}

	&.clarification {
		border-color: var(--queuestate-clarification-primary);
		color: var(--queuestate-clarification-darker);
	}

	&.finished {
		border-color: var(--queuestate-finished-primary);
		color: var(--queuestate-finished-darker);
	}
}

::v-deep(button.p-column-filter-menu-button.p-link) {
	display: none;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

