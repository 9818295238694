<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled p-p-2">
		<div class="p-field p-col-12">
			<label for="name">{{ $t('Squeeze.System.Name') }} *</label>
			<InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="(v$.name.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.System.Name')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="file">{{ $t('Squeeze.System.Script') }} *</label>
			<Dropdown
				id="file"
				v-model="value.scriptId"
				:options="allScripts"
				@change="update"
				optionValue="id"
				optionLabel="name"
				:filter="true"
				@show="showDropdownOverlay"
			>
			</Dropdown>
			<small v-if="v$.scriptId.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.System.Script')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<div v-for="interval of intervals" :key="interval.key" class="p-field-radiobutton">
				<RadioButton :id="interval.key" name="interval" :value="interval" v-model="selectedInterval" @change="onChangeRadioButton(interval)"/>
				<label :for="interval.key">{{interval.name}}</label>
				<Badge v-if="interval.key === 'custom'" class="mdi mdi-comment-question-outline" @click="goToCronGuru" />
			</div>
		</div>
		<div class="p-field p-col-12">
			<label for="interval">{{ $t('Squeeze.System.CronExpression') }} *</label>
			<InputText id="interval" v-model="v$.cronExpression.$model" :class="{'p-invalid':v$.cronExpression.$invalid && showErrorMessage && showCustomInput}" @change="update" required="true" :disabled="!showCustomInput" />
			<small v-if="(v$.cronExpression.$invalid && showErrorMessage && showCustomInput && !v$.cronExpression.cronExpressionValidation.$invalid)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.System.CronExpression')}) }}
			</small>
			<small v-if="v$.cronExpression.cronExpressionValidation.$invalid && showErrorMessage && showCustomInput" class="p-error">
				{{ $t('Forms.Val.CronExpression') }}
			</small>
		</div>
		<div class="p-field p-col-3">
			<label for="active">{{ $t('Squeeze.System.Active') }}</label><br/>
			<Checkbox id="active" v-model="value.active" :binary="true" />
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import RadioButton from 'primevue/radiobutton';
import InputText from "primevue/inputtext";
import Badge from 'primevue/badge';
import Checkbox from "primevue/checkbox";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {Job, ScriptDto} from "@dex/squeeze-client-ts";
import {cronExpressionValidation} from "@/util/ValidationHelper";
import {showDropdownOverlay} from "@/util/StylesHelper";

interface CronInterval {
	name: string;
	key: string;
	code: string;
}

export default defineComponent({
	name: "JobManagementForm",
	components: {
		Dropdown,
		RadioButton,
		InputText,
		Badge,
		Checkbox,
	},
	props: {
		jobEntry: {
			type: Object as PropType<Job>,
			default: {},
		},
		allScripts: {
			type: Array as PropType<ScriptDto[]>,
			default: [],
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show custom input? */
		const showCustomInput = ref<boolean>(false);

		/** All predefined intervals */
		const intervals = computed(() => {
			return [
				{name: t('Squeeze.System.CronExpressionDaily'), key: 'daily', code: '0 3 * * *'},
				{name: t('Squeeze.System.CronExpressionEvery15Minutes'), key: 'xMinutes', code: '*/15 * * * *'},
				{name: t('Squeeze.System.CronExpressionSunday'), key: 'sunday', code: '0 12 * * 0'},
				{name: t('Squeeze.General.Custom'), key: 'custom', code: ''},
			]
		});

		/** selected Interval */
		const selectedInterval = ref<CronInterval>(intervals.value[0]);

		/** Current Object of all input-fields */
		const value = reactive<Job>(props.jobEntry);

		/** Determines the required rules for validation */
		const rules = {
			name: { required },
			scriptId: { required },
			cronExpression: { required, cronExpressionValidation },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.jobEntry);
			if(!value.cronExpression) {
				value.cronExpression = intervals.value[0].code;
			} else {
				const intervalIndex = intervals.value.findIndex((interval) => interval.code === value.cronExpression);
				if(intervalIndex && intervalIndex !== -1 || intervalIndex === 0) {
					selectedInterval.value = intervals.value[intervalIndex];
				} else {
					showCustomInput.value = true;
					selectedInterval.value = intervals.value[3];
				}
			}

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.jobEntry, () => {
			Object.assign(value, props.jobEntry);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// when custom Input is true, then transform custom text in cron text
			if(showCustomInput.value) {
				if(value.cronExpression) {
					if(!(value.cronExpression.indexOf(' ') >= 0)) {
						const cronText = value.cronExpression.match(/.{1}/g);
						value.cronExpression = cronText!.join(' ');
					}
				}
			}
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		const onChangeRadioButton = (radioButton: CronInterval) => {
			showCustomInput.value = false;
			switch(radioButton.key) {
			case 'daily': {
				value.cronExpression = radioButton.code;
				break;
			}
			case 'xMinutes': {
				value.cronExpression = radioButton.code;
				break;
			}
			case 'sunday': {
				value.cronExpression = radioButton.code;
				break;
			}
			case 'custom': {
				showCustomInput.value = true;
				value.cronExpression = radioButton.code;
				break;
			}
			}
			update();
		}

		/** Go to the cronTab guru, when badge clicked */
		const goToCronGuru = () => {
			window.open('https://crontab.guru/');
		}

		return {
			t, toast, showCustomInput, selectedInterval, intervals, value, v$, update, onChangeRadioButton, goToCronGuru, showDropdownOverlay,
		};
	},
});

</script>

<style scoped>

::v-deep(.p-badge) {
	background-color: transparent;
	max-width: 1.5rem;
	order: 2;
	cursor: pointer;
	color: var(--text-color);
}

</style>

