<template>
	<div class="p-grid nested-grid p-p-2 p-m-0" style="height: 100vh;">
		<div class="p-col-2 p-col-fixed p-shadow-4 p-p-2">
			<Menu :model="items" class="dex_menu_admin p-mb-2">
				<template #item="{item}">
					<span>{{ item.parentLabel }}</span>
					<router-link v-if="item.to" :to="item.to" class="p-menuitem-link">
						<span class="p-menuitem-icon" :class="item.icon"></span>
						<span class="p-menuitem-text" :class="item.to.name === 'MigrationView' ? 'migration-badge' : ''">
						{{ item.label }}
						<Badge
							v-if="item.to.name === 'MigrationView' && store.state.errorMigrations.length > 0"
							:value="store.state.errorMigrations.length"
							severity="danger"
						/>
						</span>
					</router-link>
				</template>
			</Menu>
		</div>
		<div class="container p-col-10 p-pt-0 p-pr-0 p-pb-0">
			<div class="header p-col-12 p-mb-2 p-p-2">
				<SqueezeBreadcrumb />
			</div>
			<div class="content p-col-12 p-p-0 p-shadow-4">
				<div :class="route.name !== 'QueueEntryView'
				&& route.name !== 'DocumentClassListView'
				&& route.name !== 'DocumentClassFieldsSubView'
				&& route.name !== 'DocumentClassTablesSubView'
				&& route.name !== 'DocumentClassExportInterfaceSubView'
				&& route.name !== 'DocumentClassExportStatesSubView'
				&& route.name !== 'MigrationView'
				&& route.name !== 'MailView'
				&& route.name !== 'ApiDocumentationView'
				&& route.name !== 'UserManagementRolesView'
				&& route.name !== 'UserManagementRolesTabsView'
				&& route.name !== 'UserManagementView'
				&& route.name !== 'UserTabsView'
				&& route.name !== 'MasterDataListView'
				&& route.name !== 'MasterDataView'
				&& route.name !== 'LocatorListView'
				&& route.name !== 'LocatorSubView'
				&& route.name !== 'BatchClassListView'
				&& route.name !== 'ClassificationListView'
				&& route.name !== 'BatchClassAttributesView'
				&& route.name !== 'BatchClassSubView' ? 'scroll-content' : 'full-height-container'">
					<router-view />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import Menu from 'primevue/menu';
import {RouteLocationMatched, useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import SqueezeBreadcrumb from "@/components/SqueezeBreadcrumb.vue";
import Badge from 'primevue/badge';
import {useStore} from "@/store";

export default defineComponent({
	name: 'Administration',
	components: {
		SqueezeBreadcrumb,
		Menu,
		Badge,
	},
	setup() {
		const {t} = useI18n();
		const route = useRoute();
		const store = useStore()

		/** Is Queue item active? */
		const isActiveQueueItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "QueueEntryView" || route.name === "QueueView"))
		})

		/** Is batchClass item active? */
		const isActiveBatchClassItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "BatchClassListView" || route.name === "BatchClassView"))
		})

		/** Is documentClass item active? */
		const isActiveDocumentClassItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "DocumentClassListView" || route.name === "DocumentClassView"))
		})

		/** Is locator item active? */
		const isActiveLocatorItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "LocatorListView" || route.name === "LocatorView"))
		})


		/** Is locator item active? */
		const isMasterDataActive = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "MasterDataListView" || route.name === "MasterDataView"))
		})

		/** Is import mail item active? */
		const isActiveImportMailItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "MailView" || route.name === "Mail"))
		})

		/** Is import mail item active? */
		/*const isActiveImportFileSystemItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "FileSystemView" || route.name === "FileSystem"))
		})*/

		/** Is userManagement Roles item active? */
		/*const isActiveRolesItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "" || route.name === ""))
		})*/

		/** Is userManagement RoleHierarchy item active? */
		/*const isActiveRoleHierarchyItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "" || route.name === ""))
		})*/

		/** Is userManagement User item active? */
		const isActiveUserItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "UserManagementView" || route.name === "UserTabsView"))
		})

		/** Is userManagement Roles item active? */
		const isActiveRolesItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "UserManagementRolesView" || route.name === "UserManagementRolesTabsView"))
		})

		/** Is userManagement RoleHierarchy item active? */
		/*const isActiveRoleHierarchyItem = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "" || route.name === ""))
		})*/

		/** Is System Migration item active? */
		const isActiveMigration = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "MigrationView"));
		})

		/** Is System Check item active? */
		const isActiveSystemCheck = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "CheckView"));
		})

		/** Is System Reset item active? */
		const isActiveSystemResetView = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "SystemResetView"));
		})

		/** Is Scripts item active? */
		const isActiveScripts = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "ScriptsView"));
		})

		/** Is Job Management item active? */
		const isActiveJobManagementView = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "JobManagementView"));
		})

		/** Is Job Management item active (when job runs view is shown)? */
		const isActiveJobRunsView = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "JobRunsView"));
		})

		/** Is System Reset item active? */
		const isActiveApiDocumentation = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "ApiDocumentationView"));
		})

		const isActiveSystemView = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "SystemView"));
		})

		const isActiveLanguagesView = computed(() => {
			return route.matched.some((route: RouteLocationMatched) => (route.name === "LanguagesView"));
		})

		/** Array with the Menu-Document-Items */
		const documentItems = computed(() => {
			const itemsList: any[] = [];

			itemsList.push(
				{
					label: t('Squeeze.Queue.General.Queue'),
					icon: 'mdi mdi-transit-connection-horizontal',
					to: {name: 'QueueEntryView', params: {
						stepName: 'Validation',
					}},
					class: {'active-item': isActiveQueueItem.value},
				}
			)

			if(store.state.scopes.sqzBatchClasses) {
				itemsList.push(
					{
						label: t('Squeeze.BatchClasses.BatchClasses'),
						icon: 'mdi mdi-tag-multiple-outline',
						to: {name: 'BatchClassListView'},
						class: {'active-item': isActiveBatchClassItem.value},
					}
				)
			}

			if(store.state.scopes.sqzDocumentClasses) {
				itemsList.push(
					{
						label: t('Squeeze.DocumentClasses.DocumentClasses'),
						icon: 'mdi mdi-file-outline',
						to: {name: 'DocumentClassListView'},
						class: {'active-item': isActiveDocumentClassItem.value},
					}
				)
			}

			if(store.state.scopes.sqzLocators) {
				itemsList.push(
					{
						label: t('Squeeze.Locators.Locators'),
						icon: 'mdi mdi-tag-multiple-outline',
						to: {name: 'LocatorListView'},
						class: {'active-item': isActiveLocatorItem.value},
					}
				)
			}

			itemsList.push(
				{
					label: t('Squeeze.MasterData.MasterData'),
					icon: 'mdi mdi-database',
					to: {name: 'MasterDataListView'},
					class: {'active-item': isMasterDataActive.value},
				}
			)

			return itemsList;
		});

		/** Array with the Menu-Import-Items */
		const importItems = computed(() => {
			const itemsList: any[] = [];

			if(store.state.scopes.sqzMail) {
				itemsList.push(
					{
						label: t('Squeeze.Imports.Mail'),
						icon: 'mdi mdi-email-outline',
						to: {name: 'MailView'},
						class: {'active-item': isActiveImportMailItem.value},
					}
				)
			}

			/*if(store.state.scopes.sqzFileSystem) {
				itemsList.push(
					{
						label: t('Squeeze.Imports.FileSystem'),
						icon: 'mdi mdi-file-cabinet',
						to: {name: 'FileSystemView'},
						class: {'active-item': isActiveImportFileSystemItem.value},
					}
				)
			}*/

			return itemsList;
		});

		/** Array with the Menu-UserManagement-Items */
		const userManagementItems = computed(() => {
			const itemsList: any[] = [];

			if(store.state.scopes.sqzUsersManage) {
				itemsList.push(
					{
						label: t('Squeeze.UserManagement.User'),
						icon: 'mdi mdi-account',
						to: {name: 'UserManagementView'},
						class: {'active-item': isActiveUserItem.value},
					}
				)
			}

			if(store.state.scopes.sqzRoleManage) {
				itemsList.push(
					{
						label: t('Squeeze.UserManagement.Roles'),
						icon: 'mdi mdi-account-group',
						to: {name: 'UserManagementRolesView'},
						class: {'active-item': isActiveRolesItem.value},
					}
				)
			}

			/*if(store.state.scopes.sqzRoleHierarchy) {
				itemsList.push(
					{
						label: t('Squeeze.UserManagement.RoleHierarchy'),
						icon: 'mdi mdi-file-tree',
						to: {name: 'FileSystemView'},
						class: {'active-item': isActiveRoleHierarchyItem.value},
					}
				)
			}*/

			return itemsList;
		});

		/** Array with the Menu-System-Items */
		const systemItems = computed(() => {
			const itemsList: any[] = [];

			if(store.state.scopes.sqzSystem) {
				itemsList.push(
					{
						label: t('Squeeze.System.System'),
						icon: 'mdi mdi-monitor-star',
						to: {name: 'SystemView'},
						class: {'active-item': isActiveSystemView.value},
					}
				)
			}

			if(store.state.featureSet.translationAdministration) {
				itemsList.push(
					{
						label: t('Squeeze.System.Languages'),
						icon: 'mdi mdi-earth',
						to: {name: 'LanguagesView'},
						class: {'active-item': isActiveLanguagesView.value},
					}
				)
			}

			if(store.state.scopes.sqzScriptsExecute || store.state.scopes.sqzScriptsManage) {
				itemsList.push(
					{
						label: t('Squeeze.System.Scripts'),
						icon: 'mdi mdi-script-text-outline',
						to: {name: 'ScriptsView'},
						class: {'active-item': isActiveScripts.value},
					}
				)
			}

			if(store.state.featureSet.jobManagement && (store.state.scopes.sqzJobsManage || store.state.scopes.sqzJobsRead)) {
				itemsList.push(
					{
						label: t('Squeeze.System.JobManagement'),
						icon: 'mdi mdi-calendar-clock',
						to: {name: 'JobManagementView'},
						class: {'active-item': isActiveJobManagementView.value || isActiveJobRunsView.value},
					}
				)
			}

			if(store.state.scopes.sqzMigrations) {
				itemsList.push(
					{
						label: t('Squeeze.System.Migrations'),
						icon: 'mdi mdi-lan',
						to: {name: 'MigrationView'},
						class: {'active-item': isActiveMigration.value},
					}
				)
			}

			if(store.state.scopes.sqzSystemCheck) {
				itemsList.push(
					{
						label: t('Squeeze.System.SystemCheck'),
						icon: 'mdi mdi-checkbox-marked-circle-outline',
						to: {name: 'CheckView'},
						class: {'active-item': isActiveSystemCheck.value},
					}
				)
			}

			if(store.state.scopes.sqzReset) {
				itemsList.push(
					{
						label: t('Squeeze.System.Reset'),
						icon: 'mdi mdi-delete-empty-outline',
						to: {name: 'SystemResetView'},
						class: {'active-item': isActiveSystemResetView.value},
					}
				)
			}

			itemsList.push(
				{
					label: t('Squeeze.System.ApiDocumentation'),
					icon: 'mdi mdi-api',
					to: {name: 'ApiDocumentationView'},
					class: {'active-item': isActiveApiDocumentation.value},
				}
			)

			return itemsList;
		});

		/** Array with the Menu-Items */
		const items = computed(() => {
			const allItems = [];

			if(documentItems.value.length) {
				allItems.push(
					{
						parentLabel: t('Squeeze.General.Documents'),
						items: documentItems.value,
					}
				)
			}

			if(importItems.value.length) {
				allItems.push(
					{
						parentLabel: t('Squeeze.Imports.Imports'), //icon mdi mdi-import
						items: importItems.value,
					}
				)
			}

			if(userManagementItems.value.length) {
				allItems.push(
					{
						parentLabel: t('Squeeze.UserManagement.UserManagement'), //icon mdi mdi-account-cog
						items: userManagementItems.value,
					}
				)
			}

			if(systemItems.value.length) {
				allItems.push(
					{
						parentLabel: 'System',
						items: systemItems.value,
					}
				)
			}

			return allItems
		})

		return {
			t, route, store, items,
		}
	},
});
</script>

<style lang="scss" scoped>

.content {
	display: flex;
	overflow: hidden;
	flex-flow: column nowrap;
}

.scroll-content {
	flex: 1 1 0;
	align-self: auto;
	height: 100%;
	overflow-y: scroll;
}

.full-height-container {
	height: 100%;
}

.p-col-fixed.p-shadow-4.p-p-2 {
	overflow: auto;
	height: 100%;
}
</style>
<style>

.p-menu.p-component.dex_menu_admin {
	width: 100%;
	border: 0rem;
}

.p-tabview-panel {
	overflow-x: auto;
}

::v-deep(.p-scrollpanel-content) {
	padding: 0 1.125rem 1.25rem 0;
}

/* width & height */
::-webkit-scrollbar {
	width: 0.525rem;
	height: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--dex-primary-light-color);
	border-radius: 0.525rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--dex-primary-color);
}

/* Active Item */
.active-item {
	font-weight: bold;
}

.container {
	display: flex;
	flex-flow: column nowrap;
	height: calc(100vh - 2rem);
}

.header {
	height: 4.063rem;
}

.content {
	height: calc(100vh - 4.063rem - 1.5rem);
}

.migration-badge {
	white-space: nowrap;
}

</style>

