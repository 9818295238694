
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Tooltip from 'primevue/tooltip';
import {computed, defineComponent, nextTick, PropType, reactive, ref} from "vue";
import {MasterDataColumn, PaginationDto} from "@dex/squeeze-client-ts";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import useStore from "@/store";

/** Interface that is used for Key/Value pairs that are needed for inserting/updating entries */
interface KeyValuePair {
	[key: string]: string | undefined;
}

export default defineComponent({
	name: "MasterDataRows",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
		},
		masterDataRows: {
			type: Array as PropType<any[]>,
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
		permissions: {
			type: Object,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onPage", "onFilter", "onChangeInputField", "onSort",
	],
	setup(props, {emit}) {
		/** Use store */
		const store = useStore();

		/** The event.data values to check it */
		const entryDataCheck = reactive<any>({});

		/** Filters of list (Currently not activated) */
		const filters = reactive<DocumentFilterObject>(props.filterOptions!);

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			if (props.permissions) {
				return !props.permissions.permissionEdit && props.permissions.permissionManage
					|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
					|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/** Triggered on Filter-Event  */
		const onFilter = (tableFilters: DocumentFilterObject) => {
			emit("onFilter", tableFilters);
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: KeyValuePair) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			// Empty all values that might be there on new entry click
			const setObject: KeyValuePair = {id: undefined};
			props.masterDataColumns?.forEach(column => {
				if (column.name.toLowerCase() !== "id") {
					setObject[column.name] = "";
				}
			});

			emit("onEntrySelect", setObject)
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: KeyValuePair) => {
			// check if permissions return true, to prevent double click
			if (checkPermissions.value) {
				return
			}
			emit("onEntrySelect", row)
		}

		/** Triggered when the next page is selected */
		const onPage = (event: PaginationDto) => {
			emit("onPage", event);
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2]?.focus();

			// This is needed when a text is clicked directly
			if (event.originalEvent.currentTarget && event.originalEvent.currentTarget.childNodes[2]) {
				event.originalEvent.currentTarget.childNodes[2]?.focus();
			}
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * @param event
		 */
		const onCellCompleteEditing = (event: any) => {
			if(changeInputField.value) {
				emit("onChangeInputField", event.data);
				changeInputField.value = false;
			} else {
				event.preventDefault();
			}
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: any) => {
			if (inputData !== entryDataCheck) {
				changeInputField.value = true;
			}
		}

		/** Triggered on sort a column */
		const onSort = (event: { sortField: string; sortOrder: number }) => {
			emit("onSort", event.sortField, event.sortOrder)
		}

		return {
			store, entryDataCheck, DataTable, Column, filters, changeInputField, checkPermissions,
			openDeleteDialog, onNewEntryClick, openEditDialog, onPage, onFilter, onSort,
			onCellEdit, onCellCompleteEditing, changeEventInInput,
		}
	},
});
