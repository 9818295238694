
import { Options , Vue } from 'vue-class-component';
import Card from "primevue/card";
import draggable from 'vuedraggable'
import {ClientManager} from "@/singletons/ClientManager";
import {
	DocumentSplitStatus,
	FileDto
} from '@dex/squeeze-client-ts';
import {ToastManager} from "@/singletons/ToastManager";
import BlockUI from 'primevue/blockui';

interface UiSplitElement {
	model: FileDto;
	clicked: boolean;
}

@Options({
	name: "DocumentSplit",
	components: {Card, draggable, BlockUI},
	props: {
		documentId: String,
	},
	computed: {
		selectedThumbnails: function() {
			const clickedElements = this.thumbnails.filter((element: UiSplitElement) => element.clicked);
			if(clickedElements) {
				return clickedElements.length;
			} else {
				return 0;
			}
		},
	},
	emits: ["onImageClick", "onEmptyList"],
})
export default class DocumentSplit extends Vue {

	/** ID of Currently active Document */
	documentId!: string

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	/** List with available Thumbnails */
	thumbnails:  UiSplitElement[] = [];

	listSelected:  UiSplitElement[] = [];

	/** Should the Button be shown as loading? */
	loading = false;

	/** Is the Item currently dragged? */
	drag = false;

	/** Drag options. Taken from https://github.com/SortableJS/vue.draggable.next/blob/master/example/components/transition-example-2.vue */
	dragOptions =  {
		animation: 200,
		group: "images",
		disabled: false,
		ghostClass: "ghost",
	};

	created() {
		window.addEventListener('keyup', this.onKeyPress);
	}

	mounted() {
		const promiseSplitStatus = this.documentApi.getDocumentSplitStatus(Number(this.documentId));
		const promiseThumbnails = this.documentApi.getDocumentThubnails(Number(this.documentId), true)

		Promise.all([promiseSplitStatus, promiseThumbnails])
			.then(promises => {
				const splitStatus: DocumentSplitStatus =  promises[0];
				let thumbnails = promises[1];

				thumbnails = thumbnails.filter(thumbnail => splitStatus.unsplitPages?.indexOf(Number(thumbnail.id)) != -1)

				this.thumbnails = thumbnails.map((thumbnail: FileDto) => {
					return {
						model: thumbnail,
						clicked: false,
					}
				})
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			})
	}

	beforeUnmount() {
		window.removeEventListener("keyup", this.onKeyPress)
	}

	/**
	 * Triggered when a key is press on the window
	 * @param event
	 */
	onKeyPress(event: any) {
		if (event.code === "ArrowRight") {
			const clickedElements = this.thumbnails.filter(element => element.clicked);

			if (clickedElements) {
				clickedElements.forEach(element => {
					this.listSelected.push({...element});
				})

				this.thumbnails = this.thumbnails.filter(element => !element.clicked);
			}
		}


		if (event.code === "ArrowLeft") {
			const clickedElements = this.listSelected.filter(element => element.clicked);

			if (clickedElements) {
				clickedElements.forEach(element => {
					const newElement = {...element};
					newElement.clicked = false;
					this.listSelected.push(newElement);
				})

				this.listSelected = this.listSelected.filter(element => !element.clicked);
			}
		}
	}

	/**
	 * Triggered when an element is clicked
	 * @param splitElement
	 */
	clickThumbnail(event: any, splitElement: UiSplitElement) {
		splitElement.clicked = !splitElement.clicked;
		this.$emit("onImageClick", splitElement.model.id);
	}

	/**
	 * Triggered when an element on the right side is clicked
	 * @param splitElement
	 */
	clickThumbnailRight(event: any, splitElement: UiSplitElement) {
		this.$emit("onImageClick", splitElement.model.id);
	}

	/** Moves the Pages to right side */
	movePages() {
		const clickedElements = this.thumbnails.filter(element => element.clicked);

		if (clickedElements) {
			clickedElements.forEach(element => {
				const newElement = {...element};
				newElement.clicked = false;
				this.listSelected.push(newElement);
			})

			this.thumbnails = this.thumbnails.filter(element => !element.clicked);
		}
	}

	/**
	 * Is triggerend when the dragging has ended
	 */
	onDragEnd() {
		this.drag = false;

		// Don't show any element as clicked on the right side
		this.listSelected.forEach(element => {
			element.clicked = false;
		})
	}

	/**
	 * Splits the chosen documents
	 */
	splitDocument() {
		const pageIds = this.listSelected.map(element => {
			return Number(element.model.id)
		})

		const pages: any = {
			pageIds: pageIds,
		};

		this.loading = true;

		this.documentApi.splitDocument(Number(this.documentId), pages)
			.then(data => {
				this.loading = false;
				this.listSelected = [];

				if (this.listSelected.length === 0 && this.thumbnails.length === 0) {
					this.$emit("onEmptyList");
				}
			})
			.catch(response => response.json().then ((err: any) => {
				this.loading = false;
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

}

