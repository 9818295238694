<template>
	<h1 class="p-component header">{{ $t('Squeeze.General.News.News') }}</h1>

	<div v-for="(data, index) in newsData[0]" :key="data.date" class="p-component p-pb-2">
		<span v-if="newsData[0][index -1]">
			<p class="month-text" v-if="new Date(data.date).toLocaleString('default', { month: 'long' }) !== new Date(newsData[0][index -1].date).toLocaleString('default', { month: 'long' })">
				{{ new Date(data.date).toLocaleString('default', { month: 'long' }).toUpperCase() }}
			</p>
		</span>

		<p class="month-text" v-else-if="index === 0 || !index">
			{{ new Date(data.date).toLocaleString('default', { month: 'long' }).toUpperCase() }}
		</p>

		<h3 class="p-component date-header">{{ data.date }}</h3>

		<div v-for="newsGroup in data.allNews" :key="newsGroup.group">
			<span
				:class="[
					{'mdi mdi-star-outline': newsGroup.group.includes('new')},
					{'mdi mdi-rocket-outline': newsGroup.group.includes('improved')},
					{'mdi mdi-android-debug-bridge': newsGroup.group === 'Fixes'},
				]">
				<h4 class="p-component group-header">{{ newsGroup.group }}</h4>
			</span>
			<ul v-for="news in newsGroup.allNewsByGroup" :key="news.title">
				<li>
					<i>{{ news.title }}</i> - {{ news.text }}
				</li>
			</ul>
		</div>

		<span v-if="newsData[0][index +1]">
			<hr v-if="new Date(data.date).toLocaleString('default', { month: 'long' }) !== new Date(newsData[0][index +1].date).toLocaleString('default', { month: 'long' })">
		</span>
	</div>
</template>

<script lang="ts">
import { ClientManager } from '@/singletons/ClientManager';
import { ToastManager } from '@/singletons/ToastManager';
import { useToast } from 'primevue/usetoast';
import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";

interface NewsDto { // TODO: Replace with API model
	date: string;
	group: string;
	title: string;
	text: string;
}

export default defineComponent({
	name: 'News',
	components: {},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const api = ClientManager.getInstance().squeeze.dexp;

		/** Array of fake data */
		const newsData = ref<any[]>([]); // TODO: Add type

		const fetchData = async () => {
			const news = await api.getDexpNews();

			// sort fakeData by date
			news.sort((a: any ,b: any) => {
				return (new Date(b.date) as any) - (new Date(a.date) as any)
			});

			newsData.value.push(news);
		}

		onMounted(() => {
			fetchData().then(() => {
				// this gives an object with dates as keys
				const dateGroups = newsData.value[0].reduce((dateGroups: any, news: any) => {
					if (!dateGroups[news.date]) {
						dateGroups[news.date] = [];
					}
					dateGroups[news.date].push(news);
					return dateGroups;
				}, {});

				const newsGroups = Object.keys(dateGroups).map((date) => {
					return {
						date,
						allNews: dateGroups[date],
					};
				});

				// this gives an object with groupNames as keys
				newsGroups.forEach((dateGroup: any, index: any) => {
					const groupNameGroup = newsGroups[index].allNews.reduce((nameGroup: any, news: any) => {
						if (!nameGroup[news.group]) {
							nameGroup[news.group] = [];
						}
						nameGroup[news.group].push(news);
						return nameGroup;
					}, {});

					dateGroup.allNews = Object.keys(groupNameGroup).map((group) => {
						return {
							group,
							allNewsByGroup: groupNameGroup[group],
						};
					});
				})

				newsData.value.splice(0);
				newsData.value.push(newsGroups);
			}).catch(err => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})
		})

		return { t, newsData };
	},
});

</script>

<style lang="scss" scoped>

.header {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin: 0;
}

.date-header {
	color: var(--dex-primary-dark-color);
	font-weight: bold;
}

.month-text {
	color: #aaa;
}

.mdi, .group-header {
	color: var(--dex-primary-color);
	display: inline;
	font-weight: bold;
}

ul li {
	color: var(--text-color);
}

</style>
