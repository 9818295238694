<template>
	<DataTable
		:value="DBLinkedDataEntrys"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-reorder="onRowReorder"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Locators.Tabs.DBLinkedData') }) }} <!--FIXME: Translation -->
		</template>

		<Column :rowReorder="true" style="min-width: 2rem; max-width: 2rem;" />
		<!--<Column field="id" header="ID" :sortable="false" class="id-column" />-->
		<Column field="sourceLocatorId" :header="$t('Squeeze.Locators.Tabs.SourceLocator')" :sortable="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getDescriptionLocator(slotProps.data.sourceLocatorId)">
					{{ getDescriptionLocator(slotProps.data.sourceLocatorId) }}
				</div>
			</template>
		</Column>
		<Column field="dbcolumn" :header="$t('Squeeze.Locators.Tabs.DBColumn')" :sortable="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getDescriptionDBColumn(slotProps.data.dbcolumn)">
					{{ getDescriptionDBColumn(slotProps.data.dbcolumn) }}
				</div>
			</template>
		</Column>
		<Column field="stopIfUnique" :header="$t('Squeeze.Locators.Tabs.Stop')" :sortable="false" style="min-width: 4rem; max-width: 4rem;">
			<template #body="slotProps">
				{{$t("Squeeze.General.Boolean." + slotProps.data.stopIfUnique)}}
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="DBLinkedData">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(DBLinkedData.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(DBLinkedData.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {DbLinkLocatorDetails, DocumentLocator, MasterDataColumn} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "DBLinkedData",
	components: {
		DataTable, Column,
	},
	props: {
		DBLinkedDataEntrys: {
			type: Array as PropType<DbLinkLocatorDetails[]>,
		},
		allLocators: {
			type: Array as PropType<DocumentLocator[]>,
			required: true,
		},
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onChangeSortOrder",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: DbLinkLocatorDetails) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: 0,
				sortOrder: 0,
				locatorId: 0,
				sourceLocatorId: 0,
				dbcolumn: 0,
				stopIfUnique: true,
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: DbLinkLocatorDetails) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the description of a locator from the source locator
		 * @param sourceLocatorId
		 */
		const getDescriptionLocator = (sourceLocatorId: number) => {
			if (sourceLocatorId == null) {
				return "";
			}

			const locatorDescription = props.allLocators.find(i => i.id === sourceLocatorId);
			if (!locatorDescription) {
				return String(sourceLocatorId)
			}

			return locatorDescription.description
		}

		/**
		 * Gets the description of a database column
		 * @param dbcolumn
		 */
		const getDescriptionDBColumn = (dbcolumn: number) => {
			if (dbcolumn == null) {
				return "";
			}

			const dbColumnDescription = props.masterDataColumns.find(i => i.id === dbcolumn);
			if (!dbColumnDescription) {
				return String(dbcolumn)
			}

			return dbColumnDescription.description
		}

		/**
		 * Triggered when the rows are reordered
		 * @param event
		 */
		const onRowReorder = (event: any) => {
			const orderedList: number[] = [];
			event.value.forEach((locatorDetail: DbLinkLocatorDetails) => {
				orderedList.push(locatorDetail.id!);
			});

			emit("onChangeSortOrder", orderedList);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, getDescriptionLocator, getDescriptionDBColumn, onRowReorder, onChangePage,
		}
	},
});

</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

/*::v-deep(td.id-column) {
	width: 5rem;
}*/

::v-deep(i.p-datatable-reorderablerow-handle.pi.pi-bars) {
	cursor: grab;
}

::v-deep(i.p-datatable-reorderablerow-handle.pi.pi-bars:active) {
	cursor: grabbing;
}

::v-deep(.p-badge) {
	background-color: transparent;
	max-width: 1.5rem;
	order: 2;
}

.mdi {
	line-height: 1.5rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

