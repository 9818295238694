<template>
	<DataTable
		:value="locators"
		:loading="loading"
		class="p-datatable-sm p-datatable-documents"
		:autoLayout="true"
		columnResizeMode="fit"
		v-model:selection="selection"
		selectionMode="single"
		@row-select="onRowSelect"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		:removableSort="true"
		filterDisplay="row"
		v-model:filters="filters"
		@filter="onFilter($event)"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Locators.Locators') }) }}
		</template>
		<template #footer>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="openEntryDialog" />
			<Button v-if="showBtnClearFilter" :label="$t('Squeeze.General.ClearFilters')" type="button" icon="mdi mdi-filter-off-outline" class="p-button p-ml-2" @click="clearFilters"/>
		</template>

		<Column field="id" header="ID" filterField="id" :sortable="true" :showFilterMenu="false" style="min-width: 4rem; max-width: 4rem;">
			<template #body="slotProps">
				{{slotProps.data.id}}
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 3rem; max-width: 3rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="name" filterField="name" :header="$t('Squeeze.Locators.Name')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{slotProps.data.name}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="description" filterField="description" :header="$t('Squeeze.Locators.Description')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
					{{slotProps.data.description}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="locatorType" filterField="locatorType" :header="$t('Squeeze.Locators.LocatorType')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getLocatorTypeDescription(slotProps.data.locatorType)">
					{{ getLocatorTypeDescription(slotProps.data.locatorType) }}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<Dropdown
					v-model="filterModel.value"
					@change="filterCallback()"
					:options="locatorTypes"
					class="p-column-filter filter-field"
					optionValue="value"
					optionLabel="description"
					:showClear="true"
					:filter="true"
					style="min-width: 10rem;"
					@show="showDropdownOverlay"
				/>
			</template>
		</Column>
		<Column field="dataType" filterField="dataType" :header="$t('Squeeze.Locators.DataType')" :sortable="true" :showFilterMenu="false" style="min-width: 7rem; max-width: 7rem;">
			<template #body="slotProps">
				{{slotProps.data.dataType}}
			</template>
			<template #filter="{filterModel, filterCallback}">
				<Dropdown
					v-model="filterModel.value"
					@change="filterCallback()"
					:options="dataTypes"
					class="p-column-filter filter-field"
					optionValue="id"
					optionLabel="text"
					:showClear="true"
					style="min-width: 5rem; max-width: 5rem;"
				/>
			</template>
		</Column>
		<Column field="active" filterField="active" :header="$t('Squeeze.Locators.Active')" :sortable="true" :showFilterMenu="false" style="min-width: 5rem; max-width: 5rem; justify-content: center;">
			<template #body="slotProps">
				<Checkbox @change="onChangeCheckbox(slotProps.data)" v-model="slotProps.data.active" :binary="true" :disabled="true" />
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" />
			</template>
		</Column>

	</DataTable>
	<!-- Lösch-Dialog -->
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry()" />
</template>

<script lang="ts">

interface DataTypes {
	id: string | undefined;
	text: string;
}

import {Options, Vue} from "vue-class-component";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentField, DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import router from "@/router";
import Tooltip from "primevue/tooltip";
import {FilterMatchMode} from "primevue/api";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: 'LocatorsList',
	components: {
		DataTable, Column, Checkbox, DialogDelete, InputText, Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All available Locators */
		locators: {
			type: Array,
			default: [],
		},
		/** All available Locator-Types */
		locatorTypes: {
			type: Array,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox"],
})

export default class LocatorsList extends Vue  {

	/** List of all locators **/
	locators!: DocumentLocator[];

	/** List of all locators **/
	locatorTypes!: LocatorType[];

	/** Should the loading be shown in the table? */
	loading!: boolean;

	/** Data of selected row */
	rowData = {};

	/** show Delete Dialog? */
	deleteDialog = false;

	/** Currently-Selected row */
	selection = null;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Should the clear filter button be shown in the table? */
	showBtnClearFilter: boolean = false;

	/** List of Data-Types */
	dataTypes: DataTypes[] = [];

	mounted() {
		this.dataTypes = [
			{
				id: 'Text',
				text: this.$t('Squeeze.General.Text'),
			},
			{
				id: 'Date',
				text: this.$t('Squeeze.General.Date'),
			},
			{
				id: 'Amount',
				text: this.$t('Squeeze.General.Amount'),
			},
		];
	}

	/** Emits the "deleteEntry"-Event */
	deleteEntry() {
		this.$emit("deleteEntry", this.rowData)
	}

	/** Triggered when a checkbox is clicked
	 * @param {DocumentField} data Data to edit
	 */
	onChangeCheckbox(data: DocumentField) {
		this.$emit("onChangeCheckbox", data)
	}

	/**
	 * Gets the Description of a Locator-Type
	 * @param {number} locatorTypeId Id of the Locatortype
	 * @returns {String} Description of the Locator-Type or Id if not found
	 */
	getLocatorTypeDescription(locatorTypeId: number) {
		if (this.locatorTypes) {
			const locatorType = this.locatorTypes.find(i => i.value === locatorTypeId);
			if (locatorType) {
				return locatorType.description
			}
		}

		return String(locatorTypeId)
	}

	/**
	 * Opens the Delete Dialog
	 * @param {DocumentField} data Data from the Document-Class that should be deleted
	 */
	openDeleteDialog(data: DocumentField) {
		this.rowData = {...data};
		this.deleteDialog = true;
	}

	/** Triggered when a row is selected */
	onRowSelect(event: any) {
		router.push({ name: 'LocatorSubView', params: { locatorId: event.data.id }})
	}

	/** Triggered when the New Entry Button is clicked */
	openEntryDialog() {
		this.$emit("openEntryDialog")
	}

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	/**
	 * Triggered when a filter has been entered
	 * @param event
	 */
	onFilter(event: any) {
		if (event.filteredValue.length < this.locators!.length) {
			this.showBtnClearFilter = true;
		} else {
			this.showBtnClearFilter = false;
		}
	}

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"locatorType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"active": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	beforeMount() {
		this.initFilters();
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

}
</script>

<style lang="scss" scoped>
/** Reduce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-success.p-button-text.p-mr-2 {
	height: 1rem;
}

// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
	background-color: var(--dex-primary-light-color);
	padding: 0.6rem 0.6rem 0.2rem 0.3rem;
	border-bottom-color: var(--dex-primary-light-color);
	color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th:first-child) {
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}

.p-datatable ::v-deep(.p-datatable-thead .p-dropdown.filter-field) {
	padding:0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}

	.p-dropdown-label {
		padding: 0 1rem 0 0.4rem;
		color: var(--filter-field-color);
	}
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
