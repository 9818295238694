<template>
	<DashboardTile v-if="!isQueueDocClassesEmpty" :title="$t('Squeeze.Queue.Steps.Validation')" style="max-height: calc(100vh - 14rem); overflow-y: auto;">
		<QueueNavigator :step="queueStep" :customTiles="customTilesValidation" />
	</DashboardTile>
	<DashboardTile v-if="store.state.featureSet.savedDocumentSearches && customTiles.length > 0" :title="$t('Squeeze.Queue.Steps.Other')" style="max-height: calc(100vh - 14rem); overflow-y: auto;">
		<QueueNavigator :step="emptyQueueStep" :customTiles="customTiles" :isValidation="false" />
	</DashboardTile>
</template>

<script lang="ts">
import DashboardTile from "@/components/DashboardTile.vue";
import QueueNavigator from '@/apps/squeeze/components/QueueNavigator.vue';
import {defineComponent, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentSearchFilterWidget, ErrorDto, QueueStepDto} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useStore} from "@/store";

export default defineComponent({
	name: "QueueNavigatorView",
	components: {
		DashboardTile,
		QueueNavigator,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Queue API endpoint */
		const queueApi = ClientManager.getInstance().squeeze.queue;
		const searchApi = ClientManager.getInstance().squeeze.search;

		/** QueueStep Data */
		const queueStep = reactive<QueueStepDto>({
			name: '',
			count: 0,
			errorCount: 0,
			stepDetails: [],
		});

		/** Is QueueStep DocumentClasses empty */
		const isQueueDocClassesEmpty = ref<boolean>(false);

		/** Interval handle for polling */
		const pollingInterval =  ref<number | undefined>(undefined);

		/** Get QueueStep of Validation */
		const getQueueStepOfValidation = () => {
			queueApi.getQueueStep("Validation")
				.then((step: QueueStepDto) => {
					const stepDocClasses = step.stepDetails.filter(detail => detail.documentClass);
					if(!stepDocClasses.length) {
						isQueueDocClassesEmpty.value = true;
					}
					Object.assign(queueStep, step);
				}).catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error.message);
				})
		}

		const customTilesValidation = ref<DocumentSearchFilterWidget[]>([]);
		const customTiles = ref<DocumentSearchFilterWidget[]>([]);

		const emptyQueueStep = reactive<QueueStepDto>({
			name: '',
			count: 0,
			errorCount: 0,
			stepDetails: [],
		});

		/** Load Custom Tiles */
		const loadCustomTiles = () => {
			// Do not load tiles if feature is disabled
			if (!store.state.featureSet.savedDocumentSearches) {
				return;
			}

			let savedSearch: null|DocumentSearchFilterWidget[] = [];

			searchApi.getSearchWidgets().then((data) => {
				savedSearch = data;
				customTilesValidation.value = savedSearch.filter(search => search.relatedSubject === "validation");
				customTiles.value = savedSearch.filter(search => search.relatedSubject !== "validation");

				if(savedSearch.length > 0) {
					// Sort by description
					customTilesValidation.value.sort((a,b) => {
						if (a.description! > b.description!) {
							return 1;
						}
						return -1;
					});

					customTilesValidation.value.unshift({
						id: -1,
						description: t('Squeeze.Queue.General.ShowAll'),
					})
				}
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			}))
		}

		/** On view ready */
		onMounted(() => {
			getQueueStepOfValidation();
			pollingInterval.value = setInterval(getQueueStepOfValidation, 5000, true);
			loadCustomTiles();
		})

		/*



		onMounted(() => {
			loadCustomTiles()
		});
		 */

		/** On view unmounted */
		onBeforeUnmount(() => {
			// Clear timer handler to stop polling
			clearInterval(pollingInterval.value);
		})

		return {t, toast, queueStep, isQueueDocClassesEmpty, pollingInterval, getQueueStepOfValidation,
			customTilesValidation, emptyQueueStep,
			customTiles, store,
		}
	},

})

</script>

<style lang="scss" scoped>

.container {
	display: flex;
	flex-flow: column nowrap;
	height: 100vh;
}

.header {
	height: 100px;
}

.content {
	height: calc(100vh - 100px - 15px);

	@media only screen and (max-width: 767px) {
		height: 100vh;
	}
}

</style>
