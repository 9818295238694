import {
	DocumentClassApi,
	DocumentApi,
	LocatorApi,
	QueueApi,
	SystemApi,
	ExportApi,
	MasterDataApi,
	BatchClassApi,
	EMailApi,
	UserApi,
	SearchApi,
	ExtractionApi,
	ImportApi,
	RoleApi,
	PublicApi,
	ScriptingApi,
	JobsApi,
	LogApi,
	ThemeApi,
	DEXPApi,
	TrainingApi,
	ValidationApi,
	Configuration,
	User,
	TranslationsApi
} from "@dex/squeeze-client-ts";

import * as Keycloak from 'keycloak-js';
import {KeycloakInitOptions} from "keycloak-js";
import router from "@/router";
import {useI18n} from "vue-i18n";

export enum AuthTypes {
	Basic,
	Bearer,
}

export class ClientManager {

	public readonly login: {
		keyCloakAvailable: boolean;
		basicAvailable: boolean;
		activeAuth: AuthTypes | null;
	}

	/**
	 * Contains API methods to interact with SQUEEZE
	 */
	public readonly squeeze: {
		batchClass: BatchClassApi;
		dexp: DEXPApi;
		document: DocumentApi;
		documentClass: DocumentClassApi;
		email: EMailApi;
		export: ExportApi;
		extraction: ExtractionApi;
		import: ImportApi;
		jobs: JobsApi;
		locator: LocatorApi;
		log: LogApi;
		masterData: MasterDataApi;
		public: PublicApi;
		queue: QueueApi;
		role: RoleApi;
		scripting: ScriptingApi;
		search: SearchApi;
		system: SystemApi;
		theme: ThemeApi;
		user: UserApi;
		training: TrainingApi;
		validation: ValidationApi;
		translations: TranslationsApi;
	};

	/**
	 * Contains configuration related to the SQUEEZE viewer
	 */
	public readonly viewer: {
		basePath: string;
	}

	/**
	 * Contains the configuration for KeyCloak
	 */
	public readonly keycloakOptions: {
		url: string;
		realm: string;
		clientId: string;
		onLoad: string;
		redirectUri: string;
		token: string;
		refreshToken: string;
	}

	/** Objekct for Keycloak */
	public keycloak: any | null = null;

	private readonly sqzConfig: any = {}

	/** Defines an own fetch to be used */
	public customFetch: typeof fetch;

	private constructor(url: string | null, apiKey: string | null) {
		const { locale } = useI18n({ useScope: 'global' });
		let baseUrl = window.location.protocol + "//" + window.location.hostname;

		if (process.env.NODE_ENV !== "development" && window.location.port !== "") {
			baseUrl += ":" + window.location.port;
		}

		if (url) {
			baseUrl = url;
		}

		this.sqzConfig = {
			basePath: `${baseUrl}/api/v2`,
			// username: "",
			// password: "",
		} as Configuration;

		if (apiKey) {
			this.sqzConfig.apiKey = apiKey;
		}

		/**
		 * This is a customized fetched, that is extended when using keycloak
		 * @param input
		 * @param requestInit
		 */
		this.customFetch = async (input: any, requestInit?: RequestInit) => {
			if (!requestInit) {
				requestInit = {};
			}

			if (!requestInit.headers) {
				requestInit.headers = new Headers();
			}

			// Always set Authorization Header if the Mode is development
			if (process.env.NODE_ENV === "development" && this.sqzConfig.username && this.sqzConfig.password) {
				const headers = new Headers();
				headers.set("Authorization", 'Basic ' + btoa(this.sqzConfig.username + ":" + this.sqzConfig.password));
				requestInit.headers = headers;
			}

			if (this.sqzConfig.apiKey) {
				const headers = new Headers();
				headers.set('X-API-KEY', this.sqzConfig.apiKey);
				requestInit.headers = headers;
			}

			if (this.login.activeAuth === AuthTypes.Bearer) {
				const headers = new Headers();
				headers.set("Authorization", 'Bearer ' + this.keycloakOptions.token);
				requestInit.headers = headers;
			}

			// Always add Translation-Headers, if is translatable. Also the countries endpoint always have to be translated
			if((router.currentRoute.value.meta && router.currentRoute.value.meta.translate) || (input && input.indexOf("/public/translations/countries?onlyActive=true") != -1)) {
				const headers = new Headers(requestInit.headers);
				headers.set("X-SQZ-LANG", locale.value);
				headers.set("X-SQZ-TRANSLATE", '1');
				requestInit.headers = headers;
			}

			// set response type from Response to any, so that return can be undefined
			const response: any = await fetch(input, requestInit);
			if (response.status === 401) {
				// redirect to Login page when unauthorized
				if (router.currentRoute.value.name !== "Login") {
					await router.push({name: "Login"});
				}
				return response;
			} else {
				return response;
			}
		};

		this.login = {
			keyCloakAvailable: false,
			basicAvailable: false,
			activeAuth: null,
		}

		this.squeeze = {
			batchClass: new BatchClassApi(this.sqzConfig, undefined, this.customFetch),
			dexp: new DEXPApi(this.sqzConfig, undefined, this.customFetch),
			document: new DocumentApi(this.sqzConfig, undefined, this.customFetch),
			documentClass: new DocumentClassApi(this.sqzConfig, undefined, this.customFetch),
			email: new EMailApi(this.sqzConfig, undefined, this.customFetch),
			export: new ExportApi(this.sqzConfig, undefined, this.customFetch),
			extraction: new ExtractionApi(this.sqzConfig, undefined, this.customFetch),
			import: new ImportApi(this.sqzConfig, undefined, this.customFetch),
			jobs: new JobsApi(this.sqzConfig, undefined, this.customFetch),
			locator: new LocatorApi(this.sqzConfig, undefined, this.customFetch),
			log: new LogApi(this.sqzConfig, undefined, this.customFetch),
			masterData: new MasterDataApi(this.sqzConfig, undefined, this.customFetch),
			public: new PublicApi(this.sqzConfig, undefined, this.customFetch),
			queue: new QueueApi(this.sqzConfig, undefined, this.customFetch),
			role: new RoleApi(this.sqzConfig, undefined, this.customFetch),
			scripting: new ScriptingApi(this.sqzConfig, undefined, this.customFetch),
			search: new SearchApi(this.sqzConfig, undefined, this.customFetch),
			system: new SystemApi(this.sqzConfig, undefined, this.customFetch),
			theme: new ThemeApi(this.sqzConfig, undefined, this.customFetch),
			user: new UserApi(this.sqzConfig, undefined, this.customFetch),
			training: new TrainingApi(this.sqzConfig, undefined, this.customFetch),
			validation: new ValidationApi(this.sqzConfig, undefined, this.customFetch),
			translations: new TranslationsApi(this.sqzConfig, undefined, this.customFetch),
		}

		this.viewer = {
			basePath: baseUrl,
		}

		let clientURL = baseUrl;
		if (window.location.port !== "") {
			clientURL += ":" + window.location.port;
		}

		this.keycloakOptions = {
			token: '',
			url: '',
			realm: '',
			clientId: 'dexp-frontend',
			onLoad: 'login-required',
			redirectUri: `${clientURL}/ui/checkLogin`,
			refreshToken: '',
		}
	}

	// region SQUEEZE Client
	/**
	 * Triggers Login with basic authentication.
	 * @param user
	 * @param pwd
	 */
	public async loginBasic(user: string, pwd: string): Promise<boolean> {
		this.setSqueezeCredentials(user, pwd);
		const isLoggedIn = await this.isAuthenticatedAtSqueeze();
		this.setLoginType(AuthTypes.Basic);
		localStorage.setItem("authorization", "basic");

		// Delete User-data from Client-Code after the login has been tried. Only do that on production-builds
		if (process.env.NODE_ENV !== "development") {
			this.unsetSqueezeCredentials();
		}

		return isLoggedIn;
	}

	/**
	 * Sets the User Credentials
	 * @param user
	 * @param pwd
	 * @private
	 */
	private setSqueezeCredentials(user: string, pwd: string) {
		this.sqzConfig.username = user;
		this.sqzConfig.password = pwd;
	}

	/**
	 * Deletes the User Credentials
	 * @private
	 */
	private unsetSqueezeCredentials() {
		delete this.sqzConfig.username;
		delete this.sqzConfig.password;
	}

	public getSqueezeCredentials() {
		return {
			username: this.sqzConfig.username as string,
			password: this.sqzConfig.password as string,
		};
	}

	public getSqueezeBasePath(): string {
		return this.sqzConfig.basePath;
	}

	public hasPHPSessionCookie(): boolean {
		const cookies = this.getCookies();
		return cookies.some(c => c.indexOf("PHPSESSID=") !== -1);
	}

	public clearPHPSessionCookie(): void {
		const cookies = this.getCookies();
		const cookie = cookies.find(c => c.indexOf("PHPSESSID=") !== -1);

		if (cookie) {
			// Clear cookie by setting expires date to the past
			document.cookie = "PHPSESSID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
		}
		// document.cookie = "PHPSESSID=862c55c74c954bdece68d5906e23ef8f;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/"
	}

	private getCookies(): string[] {
		if (document.cookie == null) {
			return [];
		}

		return document.cookie.split(";").map(str => str.trim());
	}

	/**
	 * Tests if if using any SQUEEZE API endpoint is possible.
	 */
	public async isAuthenticatedAtSqueeze(): Promise<boolean> {
		return await (this.squeeze.system.getVersion()
			.then(() => {
				return true;
			})
			.catch((err: Response) => {
				if (err.status == 401)
					return false;
				else throw err;
			}));
	}

	/**
	 * Indicates whether currently logged in user has the admin role.
	 */
	public async isAdminUser(): Promise<boolean> {
		return await (this.squeeze.user.getLoggedInUser()
			.then((user: User) => {
				const userRoles = user.roles;

				if(userRoles && userRoles.length > 0) {
					for(const role of userRoles) {
						if(role.id === 1) {
							return true;
						}
					}
				}

				return false;
			})
			.catch((err: Response) => {
				if (err.status == 401)
					return false;
				else throw err;
			}))
	}

	/**
	 * Build Document Download URL
	 * @param documentId
	 */
	public buildDocumentDownloadUrl(documentId: number) {
		return this.sqzConfig.basePath + '/documents/' + documentId + '/attachments.zip';
	}

	// endregion

	// region Singleton

	private static instance: ClientManager;

	public static getInstance() {
		if(this.instance == null) {
			this.instance = new ClientManager(null, null);
		}

		return this.instance;
	}

	public static initInstance(url: string | null, apiKey: string | null) {
		if(this.instance == null) {
			this.instance = new ClientManager(url, apiKey);
		}

		return this.instance;
	}

	// endregion

	// Keycloak Region
	/**
	 * Gets and sets the possible authentication-types
	 */
	public async setPossibleAuthentications() {
		const basePath = this.sqzConfig.basePath;
		await fetch(`${basePath}/system/openapi`)
			.then(response => response.json())
			.then(async (result) => {
				if (!result.components && result.message) {
					throw result.message;
				}

				const schemes = result.components.securitySchemes;
				if (schemes["OpenID Connect"]) {
					await this.setKeyClockTokenEndpoint(schemes["OpenID Connect"].openIdConnectUrl)
					this.login.keyCloakAvailable = true;
				}

				if (schemes.Basic) {
					this.login.basicAvailable = true;
				}
			})
			.catch((error) => {
				throw Error (error);
			});
	}

	// REGION Keycloak
	/**
	 * Sets the Token-Endpoint for Keycloak
	 * @param openIdConnectUrl
	 * @private
	 */
	private async setKeyClockTokenEndpoint(openIdConnectUrl: string) {
		const urlSplit = openIdConnectUrl.split("/realms/");
		if (urlSplit[0] && urlSplit[1]) {
			const url = urlSplit[0];
			const realm = urlSplit[1].substr(0, urlSplit[1].indexOf("/"));
			this.keycloakOptions.url = url;
			this.keycloakOptions.realm = realm;
		}
	}

	/** Interval handle for polling refresh of the token */
	refreshTokenInterval: number | undefined;
	/**
	 */
	public async keyCloakLogin() {
		this.login.activeAuth = AuthTypes.Bearer;
		localStorage.setItem("authorization", "bearer");
		await this.openKeyCloakLogin();
	}

	/**
	 * Init Key Cloak an open login Dialog
	 */
	public async openKeyCloakLogin() {
		if (!this.keycloak) {
			this.keycloak = (Keycloak as any)(this.keycloakOptions);
		}

		if (!this.keycloak) {
			return;
		}

		return this.keycloak.init({ onLoad: this.keycloakOptions.onLoad, redirectUri: this.keycloakOptions.redirectUri, checkLoginIframe: false } as KeycloakInitOptions);
	}

	/**
	 * Checks if the Key Cloak session is valid. If it is, the token will be refreshed automatically
	 */
	public async checkKeyCloakLogin() {
		this.login.activeAuth = AuthTypes.Bearer;
		localStorage.setItem("authorization", "bearer");
		this.login.keyCloakAvailable = true;
		if (!this.keycloak) {
			this.keycloak = (Keycloak as any)(this.keycloakOptions);
		}

		if (!this.keycloak) {
			return;
		}

		return this.keycloak.init({ onLoad: this.keycloakOptions.onLoad, checkLoginIframe: false } as KeycloakInitOptions).then((auth: boolean) => {
			this.keycloakOptions.token = this.keycloak.token;

			//Token Refresh
			setInterval(() => {
				this.keycloak.updateToken(70).then((refreshed: boolean) => {
					if (refreshed) {
						this.keycloakOptions.token = this.keycloak.token;
					}
				}).catch(() => {/**/});
			}, 10000)

			return auth;
		})
	}

	/**
	 * Triggers an logout at keycloak
	 */
	public async keycloakLogout() {
		if (!this.keycloak) {
			return;
		}
		const logoutOptions = { redirectUri: this.keycloakOptions.redirectUri  };
		window.location.href = this.keycloak.createLogoutUrl(logoutOptions);
	}

	/** Sets the used Authorization type */
	public setLoginType(loginType: AuthTypes) {
		this.login.activeAuth = loginType;
	}

	/** Resets all keycloak token data */
	public resetKeyCloakTokens() {
		this.keycloakOptions.token = "";
		this.keycloakOptions.refreshToken = "";
		localStorage.removeItem("authorization");
		localStorage.removeItem("fullPath");
	}

}
