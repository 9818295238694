
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import {MasterDataTablePermission, UserRole} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "MasterDataTablePermissionList",
	components: {
		DataTable, Column, Checkbox,
	},
	props: {
		masterDataPermissions: {
			type: Array as PropType<MasterDataTablePermission[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		allRoles: {
			type: Array as PropType<UserRole[]>,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onEntrySelect", "onClickDelete", "onChangeCheckbox"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", 'newEntry', {tableId: 0, roleId: undefined, canManage: false, canEdit: false});
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: MasterDataTablePermission) => {
			emit("onEntrySelect", 'edit', row)
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MasterDataTablePermission) => {
			emit("onClickDelete", row);
		}

		/** Triggered when a checkbox is clicked
		 * @param data
		 */
		const onChangeCheckbox = (data: MasterDataTablePermission) => {
			emit("onChangeCheckbox", data);
		}

		/**
		 * Gets the Description of a Role
		 * @param tablePermission
		 */
		const getRoleDescription = (tablePermission: MasterDataTablePermission) => {
			const role = props.allRoles.find(role => role.id === tablePermission.roleId);

			if (role) {
				return role.description;
			}

			return tablePermission.roleId;
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {t, toast, store, tableListPagination, onNewEntryClick, openEditDialog, openDeleteDialog, onChangeCheckbox, getRoleDescription, onChangePage}
	},
})
