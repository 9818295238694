<template>
	<div class="p-p-2">
		<BlockUI :blocked="loading">
			<LocatorForm
				:locator="locator"
				:locators="locators"
				:locatorId="locatorId"
				:locatorTypes="locatorTypes"
				:showErrorMessage="showErrorMessage"
				:showSingleLinedForm="showSingleLinedForm"
				@update="onUpdate"
			/>
			<div class="p-grid p-jc-end p-p-2 p-field">
				<Button :label="$t('Squeeze.General.Save')" type="button" icon="mdi mdi-content-save-outline" class="p-button" @click="saveLocator" />
			</div>
		</BlockUI>
	</div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import LocatorForm from "@/apps/administration/components/locators/LocatorForm.vue";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import BlockUI from "primevue/blockui";
import router from "@/router";

@Options({
	name: 'LocatorSubView',
	components: {
		LocatorForm, BlockUI,
	},
	props: {
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onLocatorChange',
	],
	watch: {
		locatorId: function() {
			this.getLocator();
		},
	},
})

export default class LocatorSubView extends Vue {

	/** Currently active Locator */
	locatorId!: number

	/** All Document-Class-Export-Interfaces */
	locator?: any = { }

	/** List of all locators **/
	locators?: DocumentLocator[] = [];

	/** List of all locators **/
	locatorTypes?: LocatorType[] = [];

	/** Service for Document-Classes */
	locatorService = ClientManager.getInstance().squeeze.locator

	/** Is the page currently loading it's data? */
	loading = false;

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	mounted() {
		this.getLocator()
	}

	/**
	 * Gets the Document-Classes
	 */
	getLocator() {
		const handleErr = (msg: string, err: any) => ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t(msg) + ": " + err);
		this.loading = true;

		let promise1 = undefined;
		if (this.locatorId) {
			promise1 = this.locatorService.getLocatorById(this.locatorId)
				.then(data => this.locator = data)
				.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))
		}

		const promise2 = this.locatorService.getLocatorTypes()
			.then((data: any) => this.locatorTypes = data)
			.catch((err: any) => handleErr('Squeeze.Locators.ErrorLoadLocatorTypes', err));

		const promise3 = this.locatorService.getAllLocators()
			.then(data => {
				// add defaultLocator to locators
				const defaultLocator: any = {
					id: 0,
					name: 'NoLocator',
					description: this.$t('Squeeze.Locators.NoLocator'),
				}
				this.locators = data
				this.locators.unshift(defaultLocator);
			})
			.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))

		// Wait until promises are finished
		Promise.all([promise1, promise2, promise3]).then(() => this.loading = false);
	}

	/** Saves a Locator */
	saveLocator() {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		let promise;

		this.showErrorMessage = false;
		this.loading = true;

		// Some old entries do have an empty string as type, which is the same as "Text", but can't be saved as such
		if (this.locator.dataType === "" || this.locator.locatorType === 2) {
			//the Locator-Type "Invoice Amounts" only allows "Text"
			this.locator.dataType = "Text";
		}

		if (!this.locator.id) {
			// Create New Entry
			promise = this.locatorService.postLocator(this.locator)
				.then(data => {
					// FIXME: I don't want to parse this json when the Client is fixed
					return data.json()
				}).then(data => {
					router.push({ name: 'LocatorSubView', params: { locatorId: data.id}})
					this.locator.id = data.id;
				}).then(() => ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.CreateSuccess')))
		} else {
			// Update Entry
			promise = this.locatorService.putLocatorById(this.locatorId!, this.locator)
				.then(() => ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.SaveSuccess')))
		}

		promise
			.catch((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.statusText)
			})
			.finally(() => {
				this.loading = false;
			});
	}

	/** Triggered on update of attribute-form */
	onUpdate(data: DocumentLocator, valid: boolean) {
		this.isInvalid = valid;
		Object.assign(this.locator, data);
		this.$emit("onLocatorChange", this.locator);
	}

}
</script>

<style scoped>

</style>
