

interface DataTypes {
	id: string | undefined;
	text: string;
}

import {Options, Vue} from "vue-class-component";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentField, DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import router from "@/router";
import Tooltip from "primevue/tooltip";
import {FilterMatchMode} from "primevue/api";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: 'LocatorsList',
	components: {
		DataTable, Column, Checkbox, DialogDelete, InputText, Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All available Locators */
		locators: {
			type: Array,
			default: [],
		},
		/** All available Locator-Types */
		locatorTypes: {
			type: Array,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox"],
})

export default class LocatorsList extends Vue  {

	/** List of all locators **/
	locators!: DocumentLocator[];

	/** List of all locators **/
	locatorTypes!: LocatorType[];

	/** Should the loading be shown in the table? */
	loading!: boolean;

	/** Data of selected row */
	rowData = {};

	/** show Delete Dialog? */
	deleteDialog = false;

	/** Currently-Selected row */
	selection = null;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Should the clear filter button be shown in the table? */
	showBtnClearFilter: boolean = false;

	/** List of Data-Types */
	dataTypes: DataTypes[] = [];

	mounted() {
		this.dataTypes = [
			{
				id: 'Text',
				text: this.$t('Squeeze.General.Text'),
			},
			{
				id: 'Date',
				text: this.$t('Squeeze.General.Date'),
			},
			{
				id: 'Amount',
				text: this.$t('Squeeze.General.Amount'),
			},
		];
	}

	/** Emits the "deleteEntry"-Event */
	deleteEntry() {
		this.$emit("deleteEntry", this.rowData)
	}

	/** Triggered when a checkbox is clicked
	 * @param {DocumentField} data Data to edit
	 */
	onChangeCheckbox(data: DocumentField) {
		this.$emit("onChangeCheckbox", data)
	}

	/**
	 * Gets the Description of a Locator-Type
	 * @param {number} locatorTypeId Id of the Locatortype
	 * @returns {String} Description of the Locator-Type or Id if not found
	 */
	getLocatorTypeDescription(locatorTypeId: number) {
		if (this.locatorTypes) {
			const locatorType = this.locatorTypes.find(i => i.value === locatorTypeId);
			if (locatorType) {
				return locatorType.description
			}
		}

		return String(locatorTypeId)
	}

	/**
	 * Opens the Delete Dialog
	 * @param {DocumentField} data Data from the Document-Class that should be deleted
	 */
	openDeleteDialog(data: DocumentField) {
		this.rowData = {...data};
		this.deleteDialog = true;
	}

	/** Triggered when a row is selected */
	onRowSelect(event: any) {
		router.push({ name: 'LocatorSubView', params: { locatorId: event.data.id }})
	}

	/** Triggered when the New Entry Button is clicked */
	openEntryDialog() {
		this.$emit("openEntryDialog")
	}

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	/**
	 * Triggered when a filter has been entered
	 * @param event
	 */
	onFilter(event: any) {
		if (event.filteredValue.length < this.locators!.length) {
			this.showBtnClearFilter = true;
		} else {
			this.showBtnClearFilter = false;
		}
	}

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"locatorType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"active": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	beforeMount() {
		this.initFilters();
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

}
