
import {
	computed,
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {LocatorValueList} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import Dropdown from "primevue/dropdown";
import ValueListForm from "@/apps/administration/components/locators/tabs/ValueListForm.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "ValueListView",
	components: {
		DialogDelete, EntryDialog, Dropdown, ValueListForm, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onListChange",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Value invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Should the Entry-Dialog for value be shown? */
		const showDialog = ref<boolean>(false);

		/** If of the currently active value-list item */
		const valueListEntryDropzone = ref<LocatorValueList>({});

		/** All values from value-list */
		const fullValueList = ref<LocatorValueList[]>([]);

		/** Currently active value-list item */
		const valueListEntry = reactive<LocatorValueList>({})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (valueListEntry.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Locators.Value') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Value') });
		});

		/** Trigged on update of value list attribute */
		const onUpdate = (data: LocatorValueList, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(valueListEntry, data)
		}

		/** Reloads the table */
		const reloadData = () => {
			loading.value = true;
			locatorService.getLocatorValueList(props.locatorId)
				.then(data => {
					if (data[0] && !valueListEntryDropzone.value.id) {
						valueListEntryDropzone.value = data[0];
					}

					fullValueList.value = data;
					emit("onListChange", valueListEntryDropzone.value);
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get Type of current locator on load */
		onMounted(() =>{
			reloadData();
		})

		/** Initializes the values for a new entry */
		const openNewEntry = () => {
			valueListEntry.id = undefined;
			valueListEntry.value = "";
			valueListEntry.description = "";
			showDialog.value = true;
		}


		/** Open Edit Dialog */
		const openEditDialog = () => {
			Object.assign(valueListEntry, valueListEntryDropzone.value);
			showDialog.value = true;
		}

		/** Saves a value */
		const saveValueListItem = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let valueListPromise = null;

			// make sure locatorId always right
			valueListEntry.locatorId = props.locatorId;

			if (!valueListEntry.id) {
				valueListPromise = locatorService.postLocatorValueList(props.locatorId, valueListEntry);
			} else {
				valueListPromise = locatorService.putLocatorValueList(props.locatorId, valueListEntry.id, valueListEntry);
			}

			valueListPromise.then(() => {
				valueListEntryDropzone.value = valueListEntry;
				showDialog.value = false;
				reloadData();
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Triggered on change of attribute-form
		 * @param row
		 * @param isInvalid
		 */
		const onChangeValueList = () => {
			emit("onListChange", valueListEntryDropzone.value);
		}

		/** Deletes a value-list-entry */
		const deleteEntry = () => {
			if (valueListEntryDropzone.value.id) {
				loading.value = true;
				locatorService.deleteLocatorValueList(props.locatorId, valueListEntryDropzone.value.id).then(() => {
					valueListEntryDropzone.value = {};
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, showDialog,
			headerText, deleteDialog, valueListEntry, fullValueList, valueListEntryDropzone,
			openNewEntry, openEditDialog, onUpdate,
			deleteEntry, onChangeValueList, saveValueListItem};
	},
});

