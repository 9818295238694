<template>
	<DataTable
		:value="searchValues"
		:loading="loading"
		class="p-datatable-sm editable-cells-table"
		:autoLayout="true"
		columnResizeMode="fit"
		editMode="cell"
		@cell-edit-init="onCellEdit"
		@cell-edit-complete="onCellComplete"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Locators.Tabs.SearchValues') }) }}
		</template>

		<Column field="searchValue" :header="$t('Squeeze.Locators.Tabs.SearchValue')" style="min-width: 10rem;" class="search-value-column">
			<template #editor="searchValue">
				<Textarea v-model="searchValue.data.searchValue" :autoResize="true" rows="1" cols="100" />
			</template>
		</Column>
		<Column style="min-width: 16rem; max-width: 16rem; justify-content: end;">
			<template #body="searchValue">
				<small v-if="isInvalidSearchValue(searchValue.data)" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.SearchValue')}) }}
				</small>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="regex">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(regex.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(regex.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, nextTick, onMounted, PropType, reactive} from "vue";
import {LocatorSearchValue} from "@dex/squeeze-client-ts";
import Textarea from 'primevue/textarea';
import {helpers} from "@vuelidate/validators";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "SearchValues",
	components: {
		DataTable, Column, Textarea,
	},
	props: {
		searchValues: {
			type: Array as PropType<LocatorSearchValue>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect", 'onChangeSearchValue',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<LocatorSearchValue>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: LocatorSearchValue) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {searchValue: '', id: 0, locatorId: 0})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: LocatorSearchValue) => {
			emit("onEntrySelect", row)
		}

		/** Check the Search Value is invalid */
		const isInvalidSearchValue = (row: LocatorSearchValue) => {
			return !helpers.req(row.searchValue)
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * (change-Event in editing cell doesn't work in firefox)
		 * @param event
		 */
		const onCellComplete = (event: any) => {
			if (event.type === 'outside') {
				emit("onChangeSearchValue", event.data, isInvalidSearchValue(event.data));
			} else if (entryDataCheck.searchValue !== event.data.searchValue) {
				emit("onChangeSearchValue", event.data, isInvalidSearchValue(event.data));
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, entryDataCheck, DataTable, Column, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, isInvalidSearchValue, onCellEdit, onCellComplete, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(td.id-column) {
	width: 5rem;
}

::v-deep(textarea.p-inputtextarea.p-inputtext.p-component.p-filled.p-inputtextarea-resizable) {
	margin-bottom: -4px;
}

::v-deep(td.search-value-column.p-editable-column.p-cell-editing) {
	padding: 0;
}

::v-deep(textarea.p-inputtextarea.p-inputtext.p-component.p-filled.p-inputtextarea-resizable) {
	border: none;
	border-radius: 0;
}

</style>
