
import {
	computed,
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import MasterDataTableForm from "@/apps/administration/components/masterdata/MasterDataTableForm.vue";
import BlockUI from "primevue/blockui";
import DialogDelete from "@/components/DialogDelete.vue";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import router from "@/router";
import {useStore} from "@/store";

export default defineComponent({
	name: "MasterDataTableView",
	components: {
		MasterDataTableForm, BlockUI, DialogDelete,
	},
	props: {
		masterDataTableId: {
			type: Number,
			default: null,
		},
		permissions: {
			type: Object,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const saveLoading = ref(false);

		/** Api for master data */
		const masterDataApi = ClientManager.getInstance().squeeze.masterData;

		/** Object of a master data table */
		const masterDataTable = reactive<MasterDataTable>({name: '', description: ''});

		/** Show error validation-messages in form? */
		const showErrorMessage = ref(false);

		/** Is the Form of the master data invalid? */
		const isValidationInvalid = ref(true);

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			// check if user has the scope to can added a new entry
			if (store.state.scopes.sqzMasterDataManage) {
				return false
			} else if (props.permissions) {
				return props.permissions.permissionEdit && !props.permissions.permissionManage
					|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
					|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/** Should the delete dialog be shown? */
		const deleteDialog = ref<boolean>(false);

		/** Triggered on update of attribute-form */
		const onUpdate = (data: MasterDataTable, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(masterDataTable, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			if (props.masterDataTableId) {
				masterDataApi.getMasterDataTableById(props.masterDataTableId)
					.then((data: MasterDataTable) => {
						Object.assign(masterDataTable, data);
					})
					.catch((response: any) => response.json().then((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		onMounted(() =>{
			reloadData();
		})

		/** Saves a master data table */
		const saveMasterDataTable = () => {
			if (isValidationInvalid.value) {
				showErrorMessage.value = true;
				return;
			}
			showErrorMessage.value = false;
			saveLoading.value = true;

			if (!masterDataTable.id) {
				masterDataTable.id = 0;
				masterDataTable.columns = [];
				masterDataApi.postMasterDataTable(masterDataTable)
					.then(data => {
						// FIXME: I don't want to parse this json when the Client is fixed
						return data.json()
					})
					.then((data) => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
						router.push({ name: 'MasterDataView', params: { masterDataTableId: data.id }});
					})
					.catch(response => response.json().then ((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						saveLoading.value = false;
					})
			} else {
				masterDataApi.putMasterDataTable(props.masterDataTableId, masterDataTable).then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					saveLoading.value = false;
				})
			}
		}

		/** Opens the Delete-Dialog */
		const openDeleteDialog = () => {
			deleteDialog.value = true;
		}

		/**
		 * Deletes and entry
		 */
		const deleteEntry = () => {
			loading.value = true;

			if (props.masterDataTableId) {
				masterDataApi.deleteMasterDataTable(props.masterDataTableId).then(() => {
					router.push({ name: 'MasterDataListView'});
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {
			t,
			toast,
			store,
			loading,
			saveLoading,
			showErrorMessage,
			isValidationInvalid,
			masterDataTable,
			checkPermissions,
			deleteDialog,
			onUpdate,
			reloadData,
			saveMasterDataTable,
			openDeleteDialog,
			deleteEntry,
		};
	},
});

