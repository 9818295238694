<template>
	<div class="p-component p-fluid p-formgrid p-grid">
		<div class="p-col-2">
			<p style="font-size: 1rem;">{{$t("Squeeze.DocumentClasses.Locator")}}</p>
		</div>
		<div class="p-field p-input-filled" :class="showEditLocatorButton ? 'p-col-4' : 'p-col-6'">
			<Dropdown
				v-model="selectedLocator"
				:options="locators"
				optionLabel="description"
				dataKey="id"
				:loading="loading"
				:filter="true"
				:placeholder="$t('Squeeze.Locators.Placeholder')"
				@change="onChangeLocator"
				@show="showDropdownOverlay"
			/>
		</div>
		<div class="p-col-4">
			<Button :label="$t('Squeeze.Locators.Testing')" @click="testLocator" />
		</div>
		<div v-if="showEditLocatorButton" class="p-col-2">
			<Button class="mdi mdi-pencil-outline" @click="openEntryLocator" v-tooltip="$t('Squeeze.Locators.EditLocator')" />
		</div>
		<div class="p-col-4 p-mb-2">
			<Button :label="$t('Squeeze.Locators.ShowLog')" @click="showLog" />
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, ref, watch} from "vue";
import {DocumentLocator} from "@dex/squeeze-client-ts";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import {useStore} from "@/store";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "DocumentLocatorTestingForm",
	components: {
		Button, Dropdown,
	},
	props: {
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		'onChangeLocator', 'testLocator', 'openEntryLocator', "openLog",
	],
	setup(props, {emit}) {
		const store = useStore();

		/** Selected Locator */
		const selectedLocator = ref<DocumentLocator | null>(null);

		/** Is editLocator-Button shown? */
		const showEditLocatorButton = ref<boolean>(true);

		/** Get the selected locator for test */
		const onChangeLocator = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit('onChangeLocator', selectedLocator.value, true);
			showEditLocatorButton.value = store.state.isAdminUser;
		}

		/** Test the selected locator */
		const testLocator = () => {
			showEditLocatorButton.value = store.state.isAdminUser;
			if(selectedLocator.value) {
				emit("testLocator", selectedLocator.value)
			}
		}

		/** Show entry for current Locator to editing */
		const openEntryLocator = () => {
			showEditLocatorButton.value = false;
			emit("openEntryLocator", true)
		}

		/** Open Log for current Locator */
		const showLog = () => {
			emit("openLog", selectedLocator.value)
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.locators, () => {
			if (props.locators && props.locators[0] && !selectedLocator.value) {
				selectedLocator.value = props.locators[0];
				return emit("testLocator", selectedLocator.value)
			}
		})

		onMounted(() => {
			showEditLocatorButton.value = store.state.isAdminUser;
		})

		return {
			store,
			selectedLocator,
			showEditLocatorButton,
			onChangeLocator,
			testLocator,
			openEntryLocator,
			showLog,
			showDropdownOverlay,
		}
	},
});
</script>

<style scoped>

::v-deep(.p-button) {
	margin-right: .5rem;
}

::v-deep(.mdi span.p-button-label) {
	display: none;
}

</style>




