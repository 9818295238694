import {ToastServiceMethods} from "primevue/toastservice";
import {ErrorDto} from "@dex/squeeze-client-ts";

export class ToastManager {

	public static defaultPosition = "br";
	private static defaultLife = 5000;

	/**
	 * Shows an error message with the Toast-Service
	 * @param {ToastServiceMethods} toast Toast-Service to call the Toast with
	 * @param {string} summary Summary to show (see Toast in PrimeVue)
	 * @param {string|Response} err Error to show. If it is a Response, get the message from it
	 */
	public static showError(toast: ToastServiceMethods, summary: string, err: string|Response = "") {
		if (typeof err === "string") {
			this.show("error", toast, summary, err);
		} else if (typeof err.json === "function") {
			err.json().then((errorResponse: ErrorDto) => {
				this.show("error", toast, summary, errorResponse.message);
			});
		} else {
			this.show("error", toast, summary, String(err));
		}
	}

	/**
	 * Shows a warning message with the Toast-Service
	 * @param {ToastServiceMethods} toast Toast-Service to call the Toast with
	 * @param {string} summary Summary to show (see Toast in PrimeVue)
	 * @param {string} err Warning to show
	 */
	public static showWarning(toast: ToastServiceMethods, summary: string, err = "") {
		this.show("warn", toast, summary, err);
	}

	/**
	 * Shows an success  message with the Toast-Service
	 * @param {ToastServiceMethods} toast Toast-Service to call the Toast with
	 * @param {string} summary Summary to show (see Toast in PrimeVue)
	 * @param {string} msg Message to show
	 */
	public static showSuccess(toast: ToastServiceMethods, summary: string, msg = "") {
		this.show("success", toast, summary, msg);
	}

	/**
	 * Shows an success  message with the Toast-Service
	 * @param {ToastServiceMethods} toast Toast-Service to call the Toast with
	 * @param {string} summary Summary to show (see Toast in PrimeVue)
	 * @param {string} msg Message to show
	 */
	public static showInfo(toast: ToastServiceMethods, summary: string, msg = "") {
		this.show("info", toast, summary, msg);
	}

	/**
	 * @param severity
	 * @param toast
	 * @param summary
	 * @param msg
	 */
	private static show(severity: string, toast: ToastServiceMethods, summary: string, msg = "") {
		toast.add({severity: severity, summary: summary, detail: msg, life: this.defaultLife, group: this.defaultPosition});
	}

}
