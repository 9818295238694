<template>
	<DataTable
    :value="substitutions"
    :loading="loading"
    class="p-datatable-sm editable-cells-table"
    :autoLayout="true"
    columnResizeMode="fit"
    editMode="cell"
    @cell-edit-init="onCellEdit"
    @cell-edit-complete="onCellComplete"
    :paginator="true"
    :rows="tableListPagination.pageSize"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    :rowsPerPageOptions="[25,50,100]"
    :currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
    :scrollable="true"
    scrollHeight="flex"
	@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Locators.Tabs.Substitution') }) }}
		</template>

		<!--<Column field="id" header="ID" :sortable="false" />-->
		<Column field="orgChar" :header="$t('Squeeze.Locators.Tabs.OrgChar')" class="column-char">
			<template #editor="substitutions">
				<InputText maxLength="1" v-model="substitutions.data.orgChar" />
			</template>
		</Column>
		<Column field="newChar" :header="$t('Squeeze.Locators.Tabs.NewChar')" class="column-char" >
			<template #editor="substitutions">
				<InputText maxLength="1" v-model="substitutions.data.newChar" />
			</template>
		</Column>
		<Column style="min-width: 10rem; justify-content: end;">
			<template #body="substitutions">
				<small v-if="isInvalidSubstitution(substitutions.data)" class="p-error">
					{{ $t('Forms.Val.MissingRequiredFields', { fieldOne: $t('Squeeze.Locators.Tabs.OrgChar'), fieldTwo: $t('Squeeze.Locators.Tabs.NewChar')}) }}
				</small>
			</template>
		</Column>
    <!-- Delete & Edit -->
    <Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="substitutions">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(substitutions.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(substitutions.data)" />
			</template>
		</Column>

    <template #paginatorLeft>
      <Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
    </template>
    <template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, nextTick, onMounted, PropType, reactive} from "vue";
import {LocatorSubstitution} from "@dex/squeeze-client-ts";
import InputText from "primevue/inputtext";
import { helpers } from '@vuelidate/validators'
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "Substitutions",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		substitutions: {
			type: Array as PropType<LocatorSubstitution[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onChangeInputField",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<LocatorSubstitution>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: LocatorSubstitution) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {id: 0, orgChar: '', newChar: ''})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: LocatorSubstitution) => {
			emit("onEntrySelect", row)
		}

		/** Check the Substitution is invalid */
		const isInvalidSubstitution = (row: LocatorSubstitution) => {
			return (!helpers.req(row.orgChar) || !helpers.req(row.newChar));
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * (change-Event in editing cell doesn't work in firefox)
		 * @param event
		 */
		const onCellComplete = (event: any) => {
			if (event.type === 'outside') {
				emit("onChangeInputField", event.data, isInvalidSubstitution(event.data));
			} else if (entryDataCheck.newChar !== event.data.newChar || entryDataCheck.orgChar !== event.data.orgChar) {
				emit("onChangeInputField", event.data, isInvalidSubstitution(event.data));
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, entryDataCheck, DataTable, Column, openDeleteDialog, onNewEntryClick, openEditDialog, isInvalidSubstitution, helpers, onCellEdit, onCellComplete, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

input {
	width: 100%;
	border: none;
	border-radius: 0;
}

::v-deep(td.column-char.p-editable-column.p-cell-editing) {
	padding: 0;
}

::v-deep(.column-char) {
	width: 30%;
}

</style>
