<template>
	<div class="p-p-2 content">
		<RolesList :roles="roles" :loading="loading" @onClickDelete="openDeleteDialog" @onEntrySelect="onEntrySelect" />
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import DialogDelete from "@/components/DialogDelete.vue";
import RolesList from "@/apps/administration/components/roles/RolesList.vue";
import router from "@/router";
import {UserRole} from "@dex/squeeze-client-ts";

/** Ids of Roles that shouldn't be deleted! */
export const AdminRoleIds = [
	1, 2, 3, 4,
]

export default defineComponent({
	name: "RolesListView",
	components: {
		RolesList,
		DialogDelete,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Service for getting the role-data */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** List of all roles */
		const roles = ref<UserRole[]>([]);

		/** Should the Entry-Dialog for roles be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active role */
		const role = reactive<UserRole>({
			title: '',
			description: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.UserManagement.Roles') }));

		/**
		 * Triggered when an entry should be shown
		 * @param role
		 */
		const onEntrySelect = (role: UserRole) => {
			router.push({ name: 'UserManagementRolesTabsView', params: { roleId: role.id! }});
		}

		/** Reloads the table to get all roles */
		const reloadData = () => {
			loading.value = true;
			roleService.getRoles()
				.then(data => {
					roles.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			reloadData();
		})

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: UserRole) => {
			deleteDialog.value = true;
			Object.assign(role, row);
		}

		/** Deletes a role */
		const deleteEntry = () => {
			if (role && role.id) {
				// Don't allow deleting of default roles
				if (AdminRoleIds.includes(role.id)) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.UserManagement.DefaultRolesDeleteError'));
					return;
				}

				loading.value = true;
				roleService.deleteRole(role.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {
			loading,
			showDialog,
			headerText,
			roles,
			role,
			deleteDialog,
			roleService,
			deleteEntry,
			openDeleteDialog,
			onEntrySelect,
		};
	},
});

</script>

<style scoped>

</style>
