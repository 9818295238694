
import DocumentClassExportInterface from "@/apps/administration/components/documentclasses/DocumentClassExportInterface.vue";
import {ExportInterfaceDocumentationDto, ExportInterfaceDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import ExportInterfaceForm from "@/apps/administration/components/documentclasses/ExportInterfaceForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: 'DocumentClassExportInterfaceSubView',
	components: {DocumentClassExportInterface, ExportInterfaceForm, EntryDialog, DialogDelete},
	props: {
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		/** All Document-Class-Export-Interfaces */
		const rows = ref<ExportInterfaceDto[]>([]);

		/** List of all available Export-Interfaces */
		const allExportInterfaces = ref<ExportInterfaceDocumentationDto[]>([]);

		/** Service for Document-Classes */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass

		/** Export API endpoint */
		const exportApi = ClientManager.getInstance().squeeze.export;

		/** Show loading? **/
		const loading = ref(false);

		/** Should the Entry-Dialog for Export-Interfaces be shown? */
		const showDialogExportInterface = ref(false);

		/** Should the Delete-Dialog for Export-Interfaces be shown? */
		const showDeleteDialogExportInterface = ref(false);

		/** Message To Show in Entry-Dialogs */
		const message = ref('')

		/** Text of the header in Entry-Dialog */
		const headerText = ref('');

		/** Entry-Data for Document-Class-Fields */
		const exportInterfaceEntry: ExportInterfaceDto = reactive({
			documentClassId: props.documentClassId,
			interfaceName: '',
			configuration: {},
		})

		/** Show the loading for Save-Button? */
		const loadingSave = ref(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref(false);

		/** Triggered the valid of form */
		const isInvalid = ref(true);

		/**
		 * Gets the Document-Classes
		 */
		const getExportInterfaces = () => {
			loading.value = true

			const promise1 = documentClassService.getSupportedExportInterfacesForClass(props.documentClassId).then(data => {
				allExportInterfaces.value = data;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			}))

			const promise2 = documentClassService.getAllDocumentClassExportInterfaces(props.documentClassId).then(data => {
				rows.value = data;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			}))

			// Wait until promises are finished
			Promise.all([promise1, promise2]).then(() => loading.value = false);
		}

		onMounted(() => {
			getExportInterfaces();
		});

		/**
		 *  Open the current Export Interface Dialog
		 *  @param rowData
		 */
		const openCurrentExportInterfaceDialog = (rowData: ExportInterfaceDto) => {
			if (rowData.id) {
				// transform sharepoint export folder structure in array
				if (rowData.interfaceName === "SharePoint" && rowData.configuration.SharePointExportFolderStructure) {
					rowData.configuration.SharePointExportFolderStructure = rowData.configuration.SharePointExportFolderStructure.split(',');
				}

				headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.ExportInterface') })
			} else {
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.ExportInterface') })
				rowData.documentClassId = props.documentClassId;
				rowData.configuration = {};
			}

			Object.assign(exportInterfaceEntry, rowData);
			message.value = ""
			showDialogExportInterface.value = true
		}

		/**
		 * Saves an Export-Interface
		 * @param reloadData Should Table be reloaded after Saving (Don't do this after clicking a checkbox)?
		 */
		const saveExportInterface = (reloadData: boolean) => {
			if (isInvalid.value && reloadData) {
				showErrorMessage.value = true;
				return;
			}

			showErrorMessage.value = false;
			message.value = ''
			message.value = "error"

			loading.value = true;

			// transform sharepoint export folder structure in a string
			if (exportInterfaceEntry.interfaceName === "SharePoint" && Array.isArray(exportInterfaceEntry.configuration.SharePointExportFolderStructure) && exportInterfaceEntry.configuration.SharePointExportFolderStructure) {
				exportInterfaceEntry.configuration.SharePointExportFolderStructure = exportInterfaceEntry.configuration.SharePointExportFolderStructure.join(',');
			}

			if (exportInterfaceEntry && exportInterfaceEntry.id) {
				documentClassService.putDocumentClassExportInterface(props.documentClassId, exportInterfaceEntry.id, exportInterfaceEntry)
					.then(() => {
						if (reloadData) {
							getExportInterfaces();
						}
						showDialogExportInterface.value = false;
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						// If the data should not be reloaded, that means there is no Edit-Dialog and the checkbox is used, therefore show a toast
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						if (!reloadData) {
							// Undo the current changes, if there is an error
							getExportInterfaces()
						}
					})).finally(() => loading.value = false)
			} else {
				documentClassService.postDocumentClassExportInterface(props.documentClassId, exportInterfaceEntry)
					.then(() => {
						if (reloadData) {
							getExportInterfaces();
						}

						showDialogExportInterface.value = false;
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					})).finally(() => loading.value = false)
			}
		}


		/** Is trigged when a checkbox in the Field-Table is clicked. When such a checkbox is clicked, simply save the entry
		 *  @param {DocumentFieldDto} rowData Row to Save
		 */
		const onChangeCheckBox = (rowData: ExportInterfaceDto) => {
			Object.assign(exportInterfaceEntry, rowData);
			saveExportInterface(false)
		}

		/** Triggered on update of attribute-form field */
		const onUpdateExportInterface = (data: ExportInterfaceDto, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(exportInterfaceEntry, data);
		}

		/**
		 * Opens the Dialog for Deletion of an Export Interface
		 * @param rowData Row to Delete
		 */
		const openDeleteExportInterface = (rowData: ExportInterfaceDto) =>  {
			Object.assign(exportInterfaceEntry, rowData);
			showDeleteDialogExportInterface.value = true;
		}


		/**
		 * Deletes an Export-Interface
		 */
		const deleteExportInterface = () => {
			loading.value = true;
			if (exportInterfaceEntry && exportInterfaceEntry.id) {
				documentClassService.deleteDocumentClassExportInterface(props.documentClassId, exportInterfaceEntry.id)
					.then(() =>{
						getExportInterfaces();
						showDeleteDialogExportInterface.value = false
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					});
			}
		}

		/**
		 * Test the current Export Interface
		 * @param rowId Current row id
		 */
		const testExportInterface = (rowId: number) => {
			loading.value = true;

			exportApi.testExportConfigInterface(rowId)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.DocumentClasses.SuccessExportInterfaceTest'));
				})
				.catch(response => response.json().then((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(()=> {
					loading.value = false;
				})
		}

		return {
			allExportInterfaces, loadingSave, headerText, exportInterfaceEntry, message, showDeleteDialogExportInterface,
			rows, loading, showDialogExportInterface, showErrorMessage,
			onUpdateExportInterface, saveExportInterface, testExportInterface,
			openCurrentExportInterfaceDialog, onChangeCheckBox, deleteExportInterface, openDeleteExportInterface,
		}
	},
})
