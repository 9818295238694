<template>
	<DataTable :value="masterDataColumns"
		:loading="loading"
		class="p-datatable-sm editable-cells-table"
		:autoLayout="true"
		columnResizeMode="fit"
		editMode="cell"
		@cell-edit-init="onCellEdit"
		@cell-edit-complete="onCellCompleteEditing"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.MasterData.Columns') }) }}
		</template>

		<Column field="name" :header="$t('Squeeze.MasterData.Name')" class="column-cell" style="min-width: 10rem;">
			<template #body="masterDataColumn">
				<div class="column-cell" v-tooltip.bottom="masterDataColumn.data.name">
					{{ masterDataColumn.data.name }}
				</div>
			</template>
			<template #editor="masterDataColumn" v-if="!checkPermissions">
				<InputText v-model="masterDataColumn.data.name" @dblclick="openEditDialog(masterDataColumn.data)" @keyup="changeEventInInput(masterDataColumn.data)"/>
			</template>
		</Column>
		<Column field="description" :header="$t('Squeeze.MasterData.Description')" class="column-cell" style="min-width: 10rem;">
			<template #body="masterDataColumn">
				<div class="column-cell" v-tooltip.bottom="masterDataColumn.data.description">
					{{ masterDataColumn.data.description }}
				</div>
			</template>
			<template #editor="masterDataColumn" v-if="!checkPermissions">
				<InputText v-model="masterDataColumn.data.description" @dblclick="openEditDialog(masterDataColumn.data)" @keyup="changeEventInInput(masterDataColumn.data)"/>
			</template>
		</Column>
		<Column field="sourceColumn" :header="$t('Squeeze.MasterData.SourceColumn')" class="column-cell" style="min-width: 10rem;">
			<template #body="masterDataColumn">
				<div class="column-cell" v-tooltip.bottom="masterDataColumn.data.sourceColumn">
					{{ masterDataColumn.data.sourceColumn }}
				</div>
			</template>
			<template #editor="masterDataColumn" v-if="!checkPermissions">
				<InputText v-model="masterDataColumn.data.sourceColumn" @dblclick="openEditDialog(masterDataColumn.data)" @keyup="changeEventInInput(masterDataColumn.data)"/>
			</template>
		</Column>
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="masterDataColumn">
				<small v-if="isInvalidMasterDataColumn(masterDataColumn.data)" class="p-error column-cell">
					<div v-if="!masterDataColumn.data.name" v-tooltip.bottom="$t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Name')})">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Name')}) }}
					</div>
					<div v-if="!masterDataColumn.data.description" v-tooltip.bottom="$t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Description')})">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Description')}) }}
					</div>
					<div v-if="!masterDataColumn.data.sourceColumn" v-tooltip.bottom="$t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.SourceColumn')})">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.SourceColumn')}) }}
					</div>
				</small>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="masterDataColumn">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(masterDataColumn.data)" :disabled="checkPermissions" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(masterDataColumn.data)" :disabled="checkPermissions" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button p-mr-2" @click="onNewEntryClick" :disabled="checkPermissions" />
			<Button :label="$t('Squeeze.MasterData.CreateResetTable')" type="button" icon="mdi mdi-refresh" class="p-button refreshButton" @click="resetTable" :disabled="checkPermissions" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref} from "vue";
import {MasterDataColumn} from "@dex/squeeze-client-ts";
import {helpers} from "@vuelidate/validators";
import Tooltip from "primevue/tooltip";
import useStore from "@/store";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "MasterDataColumns",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		permissions: {
			type: Object,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onResetClick", "onChangeInputField",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<MasterDataColumn>({name: '', description: '', sourceColumn: ''});

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			if (props.permissions) {
				return props.permissions.permissionEdit && !props.permissions.permissionManage
					|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
					|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MasterDataColumn) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {id: undefined, name: '', description: '', sourceColumn: ''})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: MasterDataColumn) => {
			// check if permissions return true, to prevent double click
			if (checkPermissions.value) {
				return
			}
			emit("onEntrySelect", row)
		}

		/** Triggered when the Reset-Button is clicked */
		const resetTable = () => {
			emit("onResetClick");
		}

		/** Check the MasterDataColumn is invalid */
		const isInvalidMasterDataColumn = (row: MasterDataColumn) => {
			return (!helpers.req(row.name) || !helpers.req(row.description) || !helpers.req(row.sourceColumn));
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * @param event
		 */
		const onCellCompleteEditing = (event: any) => {
			if(changeInputField.value) {
				emit("onChangeInputField", event.data, isInvalidMasterDataColumn(event.data));
				changeInputField.value = false;
			} else {
				event.preventDefault();
			}
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: MasterDataColumn) => {
			if (inputData !== entryDataCheck) {
				changeInputField.value = true;
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, entryDataCheck, changeInputField, DataTable, Column, checkPermissions,
			openDeleteDialog, onNewEntryClick, openEditDialog, resetTable, isInvalidMasterDataColumn, onCellEdit, onCellCompleteEditing, changeEventInInput, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

input {
	width: 100%;
	border: none;
	border-radius: 0;
}

::v-deep(td.column-cell.p-editable-column.p-cell-editing) {
	padding: 0;
}

::v-deep(td.column-cell) {
	width: 20%;
}

.refreshButton {
	background-color: var(--error-color);
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
