
import { defineComponent, onMounted, reactive, ref } from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import {ToastManager} from "@/singletons/ToastManager";
import DataTable from "@/components/DexDataTable.vue";
import Column from 'primevue/column';
import {PaginationDto} from "@dex/squeeze-client-ts";
import {DocumentSpan} from "@dex/squeeze-client-ts/api";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: 'OCRWords',
	components: {
		DataTable,
		Column,
	},
	props: {
		documentId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		'onMarkWord',
	],

	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** Indicates end of request */
		const loaded = ref(false);

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Document Class API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Empty pagination object & Info / Options of pagination  */
		const pagination = reactive<PaginationDto>({});

		/** Empty element array */
		const allElements = ref<DocumentSpan[]>([]);

		/** Get all Document Spans */
		const getDocumentSpans = () => {
			loaded.value = false;
			documentApi.getDocumentSpans(props.documentId, pagination.page, pagination.pageSize)
				.then((documentSpans) => {
					allElements.value = documentSpans.elements as DocumentSpan[];
					Object.assign(pagination, documentSpans.pagination)
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loaded.value = true;
				})
		}

		/** Triggered when the next page is selected */
		const onPage = (event: {page: number; rows: number}) => {
			pagination.page = event.page;
			pagination.pageSize = event.rows;

			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name as string + '/ocrWords', event.rows);
			getDocumentSpans();
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			getDocumentSpans();
		}

		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		onMounted(() => {
			// Initialize pagination info / options
			pagination.pageSize = TableSettings.getTableListPagination(store, route.name as string + '/ocrWords');
			pagination.page = 0;
			pagination.total = 0;

			getDocumentSpans();
		});

		return {
			t,
			toast,
			store,
			documentApi,
			loaded,
			allElements,
			pagination,
			getDocumentSpans,
			onPage,
			onReload,
			onMarkWord,
		};
	},
});

