
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import QueueTimeline from "@/apps/squeeze/components/QueueTimeline.vue";
import {BatchClass, DocumentClass, ErrorDto, PaginationDto, QueueEntry} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import QueueList from "@/apps/administration/components/queue/QueueList.vue";
import Dialog from 'primevue/dialog';
import LogView from "@/apps/administration/views/squeeze/log/LogView.vue";
import {RouteLocationRaw, useRoute} from "vue-router";
import router from "@/router";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import QueueDeleteForm from "@/apps/administration/components/queue/QueueDeleteForm.vue";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {TableSettings} from "@/singletons/TableSettings";
import useStore from "@/store";
import Message from "primevue/message";

interface QueueFilter {
	queueId: number | undefined;
	documentId: number | undefined;
	batchClassId: number | undefined;
	documentClassId: number | undefined;
	status: string | undefined;
	errorText: string | undefined;
}

export default defineComponent({
	name: "QueueEntryView",
	components: {
		QueueList,
		QueueTimeline,
		Dialog,
		LogView,
		EntryDialog,
		BlockUI,
		QueueDeleteForm,
		Message,
	},
	props: {
		stepName: {
			type: String,
		},
		status: {
			type: String,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** Is loading? */
		const loading = ref(false);

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Info / Options of pagination */
		const paginationOption = reactive<PaginationDto>({
			pageSize: 25,
			page: 0,
			total: 0,
		});

		// $t('Squeeze.Queue.General.DeleteDocument')

		/** List of all QueueEntries in QueueStep Validation */
		const queueEntries = ref<QueueEntry[]>([]);

		/** Queue API endpoint */
		const queueApi = ClientManager.getInstance().squeeze.queue;

		/** Service for Document-Classes */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass

		/** Service for Batch-Classes */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current queueStep */
		const queueStep = ref<string>('Validation');

		/** Current queueStatus */
		const queueStatus = ref<string>('!ERROR');

		/** Is Log visible? */
		const showLog = ref<boolean>(false);

		/** Is Delete Document Dialog visible? */
		const showDelete = ref<boolean>(false);

		/** Current Document ID for log output */
		const currentDocumentId = ref<number | null>(null);

		/** Comment for delete of document */
		const comment = ref<string>('');

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Is the Form of the migrations invalid? */
		const isValidationInvalid = ref(true);

		/** List with the entries to delete */
		const queueEntriesDelete = ref<QueueEntry[]>([]);

		/** Component of the  QueueEntryView */
		const queueEntryRef = ref<InstanceType<typeof QueueList>>();

		/** Array with the document classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** Array with the batch-classes */
		const batchClasses = ref<BatchClass[]>([]);

		/** Show Error Message dialog? */
		const showErrorMessages = ref(false);
		const errorMessages = ref<string[]>([])

		/** Current filter */
		const filter = ref<QueueFilter>({
			documentId: undefined,
			documentClassId: undefined,
			batchClassId: undefined,
			status: undefined,
			queueId: undefined,
			errorText: undefined,
		});

		/** Default sorting of table */
		const tableSort = ref({
			fieldName: "id",
			sort: "ASC",
		});

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (queueEntriesDelete.value.length > 1) {
				return t('Squeeze.Queue.General.DeleteDocuments', { count: queueEntriesDelete.value.length});
			}
			return t('Squeeze.Queue.General.DeleteDocument');
		});

		/** Filters of queueEntry-Status */
		const filters = ref<DocumentFilterObject>({
			"documentId": {value: null, matchMode: FilterMatchMode.EQUALS},
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"batchClassId": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"documentClassId": {value: null, matchMode: FilterMatchMode.EQUALS},
			"status": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"errorText": {value: null, matchMode: FilterMatchMode.CONTAINS},
		});


		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			loading.value = true;
			documentClassService.getAllDocumentClasses()
				.then(data => {
					documentClasses.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Reloads the data */
		const getAllBatchClasses = () => {
			loading.value = true;
			batchClassApi.getAllBatchClasses().then(data => {
				batchClasses.value = data;
				loading.value = false;
			}).catch((err) => {
				loading.value = false
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.statusText);
			});
		}

		/** Loads all queue entries in clicked queue step */
		const getAllQueueStepEntries = () => {
			loading.value = true;
			queueEntries.value = [];

			// Error Basket is a special case
			if (queueStep.value === "ErrorBasket") {
				queueApi.getQueueEntries("ERROR", paginationOption.page, paginationOption.pageSize)
					.then(result => {
						const entries = result.elements;
						Object.assign(paginationOption, result.pagination);
						paginationOption.pageSize = TableSettings.getTableListPagination(store, route.name);

						if (entries && entries.length > 0) {
							queueEntries.value = entries;
						}
					})
					.catch((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
					})
					.finally(() => {
						loading.value = false;
					})
			}
			else if (queueStatus.value === 'ERROR') {
				queueApi.getQueueStepEntries(queueStep.value, filter.value.queueId, filter.value.documentId, 'ERROR', filter.value.documentClassId, filter.value.batchClassId, filter.value.errorText, tableSort.value.fieldName, tableSort.value.sort, paginationOption.page, paginationOption.pageSize)
					.then(result => {
						const entries = result.elements;
						Object.assign(paginationOption, result.pagination);
						paginationOption.pageSize = TableSettings.getTableListPagination(store, route.name);

						if (entries && entries.length > 0) {
							queueEntries.value = entries;
						}
					})
					.catch((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
					})
					.finally(() => {
						loading.value = false;
					})
			} else if (queueStatus.value === '!ERROR') {
				queueApi.getQueueStepEntries(queueStep.value, filter.value.queueId, filter.value.documentId, filter.value.status, filter.value.documentClassId, filter.value.batchClassId, filter.value.errorText, tableSort.value.fieldName, tableSort.value.sort, paginationOption.page, paginationOption.pageSize)
					.then(result => {
						const entries = result.elements;
						Object.assign(paginationOption, result.pagination);
						paginationOption.pageSize = TableSettings.getTableListPagination(store, route.name);

						if (entries && entries.length > 0) {
							queueEntries.value = entries;
						}
					})
					.catch((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
					})
					.finally(() => {
						loading.value = false;
					})
			}
		}

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/** Triggered when a page is changed in the QueueView */
		const onPage = (event: any) => {
			paginationOption.page = event.page;
			paginationOption.pageSize = event.rows;
			getAllQueueStepEntries();

			onChangePage(event);
		}

		const onReload = () => {
			comment.value = "";
			queueEntriesDelete.value = [];

			// Resets the Selection within the QueueList
			queueEntryRef.value?.resetSelection();
			getAllQueueStepEntries();
		}

		onMounted(() => {
			// Initialize pagination info / options
			paginationOption.pageSize = 25;
			paginationOption.page = 0;
			paginationOption.total = 0;

			// check optional props
			if(props.stepName) {
				queueStep.value = props.stepName;
			}
			if(props.status) {
				queueStatus.value = props.status;
			}

			if (route.query.page) {
				paginationOption.page = Number(route.query.page);
				router.replace({query: undefined});
			}

			getAllDocumentClasses();
			getAllQueueStepEntries();
			getAllBatchClasses();
		})

		/**
		 * Click on queue step
		 * @param stepName
		 * @param status
		 */
		const onClickQueueStep = (stepName: string, status: string) => {
			queueStep.value = stepName;
			queueStatus.value = status;
			paginationOption.page = 0;

			// Resets the Selection within the QueueList
			queueEntryRef.value?.resetSelection();

			getAllQueueStepEntries();
		}

		/**
		 * Open the Dialog to show the Log of a Document
		 * @param documentId current documentId of row
		 */
		const openLogDialog = (documentId: number) => {
			currentDocumentId.value = documentId;
			showLog.value = true;
		}

		/**
		 * Triggered on Row Selection
		 * @param data
		 */
		const onRowSelect = (data: QueueEntry) => {
			const stepName = route.params.stepName;
			loading.value = true;
			documentApi.getDocumentById(data.documentId!).then(document => {
				const route: RouteLocationRaw = {
					name: 'ValidateEntry',
					params: {
						documentId: document.id as any,
						documentClassId: document.documentClassId as any,
					}, query: {
						parent: 'QueueList',
						target: stepName,
						page: paginationOption.page,
					}};
				router.push(route);
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: { comment: string }, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			comment.value = data.comment;
		}

		/**
		 * Show the Delete-Dialog
		 * @param queueEntries
		 */
		const showDeleteDialog = (queueEntries: QueueEntry[]) => {
			showDelete.value = true;
			queueEntriesDelete.value = queueEntries;
		}

		/**
		 * Delete the selected entries
		 */
		const deleteDocuments = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}

			loading.value = true;
			errorMessages.value = [];
			queueEntriesDelete.value.reduce((chain, { documentId }) => {
				if (documentId == undefined) {
					return chain;
				}
				return chain.finally(() => documentApi.deleteDocumentById(documentId, comment.value).catch(async (err) => {
					if (typeof err.json === "function") {
						await err.json().then((errorResponse: ErrorDto) => {
							errorMessages.value.push(errorResponse.message + " (" + t('Squeeze.Queue.General.DocumentID') +": "  + documentId + ")");
						});
					} else {
						errorMessages.value.push(String(err) + " (" + t('Squeeze.Queue.General.DocumentID') +": "  + documentId + ")");
					}
				}))
			}, Promise.resolve()).finally(() => {
				if (errorMessages.value.length > 0) {
					showErrorMessages.value = true;
				} else {
					ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.General.DeleteSuccess'));
				}
				loading.value = false;
				queueEntriesDelete.value = [];
				showDelete.value = false;
				onReload();
			});
		}

		/**
		 * Goes to a specific step
		 * @param stepName
		 */
		const goToStep = (stepName: string) => {
			queueStep.value = stepName;
			queueStatus.value = "!ERROR";
			paginationOption.page = 0;

			// push current queue step in routing
			router.push({ name: 'QueueEntryView', params: { stepName: queueStep.value, status: queueStatus.value }});

			getAllQueueStepEntries();
		}

		/** Triggered when a filter has been entered */
		const onFilter = (tableFilters: DocumentFilterObject) => {
			paginationOption.page = 0;
			filter.value.documentId = tableFilters.documentId.value;
			filter.value.queueId = tableFilters.id.value;
			filter.value.batchClassId = tableFilters.batchClassId.value;
			filter.value.documentClassId = tableFilters.documentClassId.value;
			filter.value.status = tableFilters.status.value;
			filter.value.errorText = tableFilters.errorText.value;
			getAllQueueStepEntries();
		}

		/**
		 * Triggered when a search if fired
		 * @param fieldName
		 * @param sortDirection
		 */
		const onSort = (fieldName: string, sortDirection: number) => {
			if (fieldName) {
				const sortOrder = sortDirection === -1 ? "DESC": "ASC";
				tableSort.value.sort = sortOrder;
				tableSort.value.fieldName = fieldName;
			} else {
				tableSort.value.sort = "ASC";
				tableSort.value.fieldName = "id";
			}
			getAllQueueStepEntries();
		}

		/** Clear filters */
		const clearFilters = () => {
			filters.value = JSON.parse(JSON.stringify({
				"documentId": {value: null, matchMode: FilterMatchMode.EQUALS},
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"batchClassId": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"documentClassId": {value: null, matchMode: FilterMatchMode.EQUALS},
				"status": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"errorText": {value: null, matchMode: FilterMatchMode.CONTAINS},
			}));

			filter.value.documentId = "" as any;
			filter.value.queueId = "" as any;
			filter.value.batchClassId = "" as any;
			filter.value.documentClassId = "" as any;
			filter.value.status = "" as any;
			filter.value.errorText = "" as any;

			getAllQueueStepEntries();
		}

		return {t, toast, loading, paginationOption, queueEntries, queueStep, showLog, currentDocumentId, documentClasses, batchClasses,
			showDelete, comment, showValidationMessage, headerText, queueEntriesDelete, queueEntryRef, filters, tableSort,
			showErrorMessages, errorMessages,
			onUpdate, showDeleteDialog, onChangePage,
			onPage, onReload, onClickQueueStep, openLogDialog, onRowSelect, deleteDocuments, goToStep, onFilter, onSort, clearFilters};
	},
});

