
import { Options , Vue } from 'vue-class-component';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import {ClientManager} from "@/singletons/ClientManager";
import {useStore} from "@/store";

@Options({
	name: "DialogLocked",
	components: {
		Dialog,
		Dropdown,
		InputText,
		Card,
	},
	props: {
		showDialog: Boolean,
		lockingUserId: {
			type: Number,
			default: null,
		},
	},
	watch: {
		showDialog: function() {
			this.showDialogComponent = this.showDialog;
		},
		lockingUserId: function() {
			this.reloadUserData();
		},
	},
	emits: ["closeDialog", "unlockDocument", "backToOverview", "showOnly"],
})
export default class DialogLocked extends Vue {

	/** Should the dialog be shown? */
	showDialog!: boolean;

	/** Id of the blocking user */
	lockingUserId!: number;

	/** V-Model for dialog show */
	showDialogComponent = false;

	/** Text of the header */
	headerText = "";

	/** Name of the current user */
	userName = "mir!";

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Current Vuex-Store */
	store = useStore();

	/** Is user a admin? */
	isUserAdmin: boolean = false;

	mounted() {
		this.headerText = this.$t('Squeeze.Validation.Dialogs.Locked.DocumentLocked')
		if(this.store.state.isAdminUser) {
			this.isUserAdmin = true;
		} else {
			this.isUserAdmin = false;
		}
	}

	onCloseDialog() {
		this.$emit("closeDialog")
	}

	reloadUserData() {
		if (this.lockingUserId) {
			this.userApi.getUserById(this.lockingUserId).then(data => {
				this.userName = data.firstName + " " + data.lastName;
				this.headerText = this.$t('Squeeze.Validation.Dialogs.Locked.DocumentLockedBy', { userName: this.userName })
			})
		}
	}

}

