<template>
	<DataTable
		:value="fieldConditions"
		:loading="loading"
		class="p-datatable-sm editable-cells-table"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.UserManagement.FieldCondition') }) }}
		</template>

		<Column v-if="false" field="id" header="ID" :sortable="false" class="id-column" style="min-width: 4rem; max-width: 4rem;" />
		<Column field="fieldId" :header="$t('Squeeze.UserManagement.Field')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getDocumentFieldDescription(slotProps.data)">
					{{ getDocumentFieldDescription(slotProps.data) }}
				</div>
			</template>
		</Column>
		<Column field="operator" :header="$t('Squeeze.UserManagement.Operator')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getOperatorDescription(slotProps.data)">
					{{ getOperatorDescription(slotProps.data) }}
				</div>
			</template>
		</Column>
		<Column field="filterValue" :header="$t('Squeeze.UserManagement.Value')" style="min-width: 4rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.filterValue">
					{{ slotProps.data.filterValue }}
				</div>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="fieldCondition">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(fieldCondition.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(fieldCondition.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {DocumentField, RoleFilterFieldCondition} from "@dex/squeeze-client-ts";
import {CompareOperations} from "@/interfaces/CompareOperations";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "FieldConditions",
	components: {
		DataTable, Column,
	},
	props: {
		fieldConditions: {
			type: Array as PropType<RoleFilterFieldCondition[]>,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
		operators: {
			type: Array as PropType<CompareOperations[]>,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<RoleFilterFieldCondition>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: RoleFilterFieldCondition) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {operator: 'eq', fieldId: undefined, id: undefined, filterValue: ''})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: RoleFilterFieldCondition) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the Description of a Field
		 * @param row
		 */
		const getDocumentFieldDescription = (row: RoleFilterFieldCondition): string => {
			const field = props.documentClassFields?.find(field => field.id === row.fieldId);

			if (field) {
				return field.description!;
			}

			return String(row.fieldId);
		}

		/**
		 * Gets the Description of a Operator
		 * @param row
		 */
		const getOperatorDescription = (row: RoleFilterFieldCondition): string => {
			const operator = props.operators?.find((op: CompareOperations) => op.id === row.operator);
			if (operator) {
				return operator.description;
			}

			return String(row.operator!);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			entryDataCheck, DataTable, Column, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, getDocumentFieldDescription,
			getOperatorDescription, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Reduce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(td.id-column) {
	width: 5rem;
}

</style>
