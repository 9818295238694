
import {defineComponent} from 'vue';
import {ToastManager} from "@/singletons/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "SystemInformation",
	components: {},
	props: {
		showHeadline: {
			type: Boolean,
			default: false,
		},
		systemInformation: {
			type: Object,
		},
	},
	emits: [
		"clickSettingDialog",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Triggered on click to copy a system information */
		const onClickCopyToClipboard = (key: string, value: string) => {
			if(key && value && navigator && navigator.clipboard) {
				navigator.clipboard.writeText(key + ': ' + value)
					.then(() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SystemInformationCopied'));
					});
			}
		}

		/** Triggered on click event to copy all system information */
		const onCopyAllClick = () => {
			const labels = props.systemInformation;
			if (labels && navigator.clipboard) {
				const text = Object
					.keys(labels)
					.map(key => {
						return `${key}: ${labels[key]}`;
					})
					.join("\n");

				navigator.clipboard.writeText(text)
					.then(() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.AllSystemInformationCopied'));
					});
			}
		}

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog');
		}

		return {
			t,
			toast,
			onClickCopyToClipboard,
			onCopyAllClick,
			clickSettingDialog,
		};
	},
});

