import {ToastManager} from "@/singletons/ToastManager";
import {UserApi} from "@dex/squeeze-client-ts";
import {Store} from "vuex";

export interface TableListSettings {
	pageSize?: number;
	page?: number;
	pageCount?: number;
}

export class TableSettings {

	/**
	 * Get current rows per page in table settings
	 * @param store
	 * @param routeName
	 */
	public static getTableListPagination(store: any, routeName: any): number {
		const tableSettings: any = {};
		try {
			// get table settings object
			if (store.state.userSettings.tableSettings.length) {
				Object.assign(tableSettings, JSON.parse(store.state.userSettings.tableSettings))
			}
		} catch(error: unknown) {
			return 25;
		}

		// set current table rows per page
		if (tableSettings[routeName] as TableListSettings) {
			return tableSettings[routeName].pageSize;
		} else {
			return 25;
		}
	}

	/**
	 * Save table list pagination in table settings
	 * @param t
	 * @param toast
	 * @param store
	 * @param userApi
	 * @param routeName
	 * @param rows
	 */
	public static saveTableListPagination(t: any, toast: any, store: Store<any>, userApi: UserApi, routeName: any, rows: number) {
		const tableSettings: any = {};
		let currentRows: number = 25;

		try {
			// get table settings object
			if (store.state.userSettings.tableSettings.length) {
				Object.assign(tableSettings, JSON.parse(store.state.userSettings.tableSettings))

				// set current rows
				if (tableSettings[routeName] && tableSettings[routeName].pageSize) {
					currentRows = tableSettings[routeName].pageSize;
				}

				if (rows === 25) {
					// remove the table setting property from Object
					delete tableSettings[routeName];
				} else {
					// set current rows per page
					tableSettings[routeName] = { pageSize: rows };
				}
			} else {
				// set current rows per page
				tableSettings[routeName] = { pageSize: rows };
			}

			store.state.userSettings.tableSettings = JSON.stringify(tableSettings);

			// check if current rows same as new rows
			if (currentRows === rows) {
				return;
			}

			// save the changed userSettings
			const userSettingsSave = JSON.parse(JSON.stringify(store.state.userSettings));
			userSettingsSave.tableSettings = JSON.stringify(tableSettings);
			userSettingsSave.viewSettings = JSON.stringify(store.state.userSettings.viewSettings);
			userApi.putAllUserProperties(userSettingsSave)
				.catch((response: Response) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}));
		} catch(error: unknown) {
			// Reset store on error
			store.dispatch("resetStore");
			return;
		}
	}

}
