<template>
	<div class="p-col-12 p-grid p-mr-0 p-ml-0 p-pb-0 p-pt-3">
		<div class="p-col-8 p-pl-0">
			<h1 class="headline p-component p-m-0">{{$t('Squeeze.UserSettings.ViewSettings.ViewSettings')}}</h1>
		</div>
		<div class="p-col-4 p-as-center p-grid p-jc-end">
			<Button class="p-button p-button-rounded p-button-text" icon="mdi mdi-close" @click="clickSettingDialog" />
		</div>
	</div>
	<div class="p-col-12 p-grid nested-grid p-m-0">
		<div class="p-col-12 p-text-bold">{{$t('Squeeze.UserSettings.ViewSettings.Validation')}}</div>

		<div class="p-grid p-col-12">
			<div class="p-col-9">
				<span class="p-p-2 p-d-block">{{$t('Squeeze.UserSettings.ViewSettings.TightValidationLayout')}}</span>
				<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.SpaceBetweenValidationFields')}}</span>
			</div>
			<div class="p-col-3 p-text-center p-as-center">
				<InputSwitch v-model="userSettings.viewSettings.smallDistanceBetweenFields" @change="setUserSettings"/>
			</div>
		</div>
		<div class="p-grid p-col-12">
			<div class="p-col-9">
				<span class="p-p-2 p-d-block">{{ $t('Squeeze.UserSettings.ViewSettings.FontSize') }}</span>
				<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.FontSizeDescription')}} <b>{{ userSettings.viewSettings.fontSize }}</b></span>
			</div>
			<div class="p-col-3 p-text-center p-as-center">
				<Slider v-model="userSettings.viewSettings.fontSize" :step="0.025" :min="0.7" :max="1" @change="onChangeFontSize" @slideend="onSlideEnd" />
			</div>
		</div>
	</div>
	<!-- Viewer Settings -->
	<div class="p-col-12 p-grid nested-grid p-m-0">
		<div class="p-col-12 p-text-bold">Viewer</div>

		<div class="p-grid p-col-12">
			<div class="p-col-9">{{$t('Squeeze.UserSettings.ViewSettings.NewViewer')}}</div>
			<div class="p-col-3 p-text-center">
				<InputSwitch v-model="userSettings.viewSettings.v2Viewer" @change="setUserSettings" :disabled="(!store.state.featureSet.v2Viewer && store.state.featureSet.v1Viewer) || (!store.state.featureSet.v1Viewer && store.state.featureSet.v2Viewer) || (!store.state.featureSet.v1Viewer && !store.state.featureSet.v2Viewer)"/>
			</div>
		</div>

		<div v-if="store.state.featureSet.v2Viewer && store.state.userSettings.viewSettings.v2Viewer" class="p-grid p-col-12 p-ml-0">
			<div class="p-col-12" style="margin-left: -0.5rem">Highlighting</div>
			<!-- Draw -->
			<div class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.FillSelection')}}</span>
					<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.FillSelectionDescription')}}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<InputSwitch v-model="userSettings.viewSettings.viewerSettings.highlightOptions.draw" @change="onChangeDraw"/>
				</div>
			</div>
			<div v-if="userSettings.viewSettings.viewerSettings.highlightOptions.draw" class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.SelectionColor')}}</span>
					<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.SelectionColorDescription')}}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<input type="color" :value="userSettings.viewSettings.viewerSettings.highlightOptions.drawColor" @change="onChangeDrawColor($event.target.value)" class="input-color">
				</div>
			</div>
			<!-- Border -->
			<div class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.Border')}}</span>
					<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.BorderDescription')}}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<InputSwitch v-model="userSettings.viewSettings.viewerSettings.highlightOptions.border" @change="onChangeBorder"/>
				</div>
			</div>
			<div v-if="userSettings.viewSettings.viewerSettings.highlightOptions.border" class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.BorderColor')}}</span>
					<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.BorderColorDescription')}}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<input type="color" :value="userSettings.viewSettings.viewerSettings.highlightOptions.borderColor" @change="onChangeBorderColor($event.target.value)" class="input-color">
				</div>
			</div>
			<div v-if="userSettings.viewSettings.viewerSettings.highlightOptions.border" class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.BorderStyle')}}</span>
					<span class="p-p-2">{{$t('Squeeze.UserSettings.ViewSettings.ViewerSettings.HighlightOptions.BorderStyleDescription')}}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<InputSwitch v-model="isBorderStyleDashed" @change="onChangeBorderStyle(isBorderStyleDashed)"/> <!--FIXME: PrimeVue version 3.7.0 -> true- and false-Value -->
				</div>
			</div>
		</div>

		<!-- Viewer Zoom -->
		<div class="p-grid p-col-12 p-ml-0">
			<div class="p-col-12" style="margin-left: -0.5rem">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.Zoom') }}</div>
			<!-- Full width -->
			<div class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.FullWidth') }}</span>
					<span class="p-p-2">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.FullWidthDescription') }}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<InputSwitch v-model="userSettings.viewSettings.viewerSettings.zoomOptions.fullWidth" @change="onChangeZoom('fullWidth')" />
				</div>
			</div>
			<!-- Full height -->
			<div class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.FullHeight') }}</span>
					<span class="p-p-2">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.FullHeightDescription') }}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<InputSwitch v-model="userSettings.viewSettings.viewerSettings.zoomOptions.fullHeight" @change="onChangeZoom('fullHeight')" />
				</div>
			</div>
			<!-- Custom Zoom -->
			<div class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.Custom') }}</span>
					<span class="p-p-2">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.CustomDescription') }}</span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<InputSwitch v-model="userSettings.viewSettings.viewerSettings.zoomOptions.customZoom" @change="onChangeZoom('custom')" />
				</div>
			</div>
			<div v-if="userSettings.viewSettings.viewerSettings.zoomOptions.customZoom" class="p-grid p-col-12">
				<div class="p-col-9">
					<span class="p-p-2 p-d-block">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.SliderValue') }}</span>
					<span class="p-p-2">{{ $t('Squeeze.UserSettings.ViewSettings.ViewerSettings.ZoomOptions.SliderValueDescription') }} <b>{{ userSettings.viewSettings.viewerSettings.zoomOptions.customSlider.toFixed(2) }}</b></span>
				</div>
				<div class="p-col-3 p-text-center p-as-center">
					<Slider v-model="userSettings.viewSettings.viewerSettings.zoomOptions.customSlider" :step="0.05" :min="0.5" :max="1" @slideend="onSlideEnd" />
				</div>
			</div>
		</div>
	</div>

	<div class="p-grid p-col-12 p-jc-end">
		<Button :label="$t('Squeeze.UserSettings.RestoreDefaultViewerSettings')" class="p-button-text p-button-plain" @click="openConfirmDialog" />
	</div>

	<!--Restore default Settings-Dialog -->
	<DialogDelete :showDialog="confirmDialog" @onClose="confirmDialog = false" :headerLabel="$t('Squeeze.UserSettings.RestoreDefaultViewerSettings')" :messageLabel="$t('Squeeze.UserSettings.MessageRestoreDefaultViewerSettings')" @onConfirm="setDefaultSettings" />
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import InputSwitch from 'primevue/inputswitch';
import Slider from 'primevue/slider';
import DialogDelete from "@/components/DialogDelete.vue";
import { useStore } from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import {UserSettings} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: 'ViewSettings',
	components: {
		InputSwitch,
		Slider,
		DialogDelete,
	},
	emits: ['clickSettingDialog'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Object with user settings */
		const userSettings = reactive<any>({
			viewSettings: {
				smallDistanceBetweenFields: false,
				fontSize: 0,
				v2Viewer: true,
				viewerSettings: {
					highlightOptions: {
						drawColor: '',
						draw: false,
						borderColor: '',
						borderStyle: '',
						border: false,
					},
					zoomOptions: {
						fullWidth: false,
						fullHeight: false,
						customZoom: false,
						customSlider: 0,
					},
				},
			},
		});

		/** Boolean for border style is dashed */
		const isBorderStyleDashed = ref<boolean>(false);

		/** Confirm dialog */
		const confirmDialog = ref<boolean>(false);

		onMounted(() => {
			// get user settings from store
			if (typeof store.state.userSettings.viewSettings === 'string' || store.state.userSettings.viewSettings instanceof String) {
				store.state.userSettings.viewSettings = JSON.parse(store.state.userSettings.viewSettings as any);
				Object.assign(userSettings, store.state.userSettings);
			} else {
				Object.assign(userSettings, store.state.userSettings);
			}

			// check if borderStyle dashed
			if(userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderStyle === 'dashed') {
				isBorderStyleDashed.value = true;
			} else {
				isBorderStyleDashed.value = false;
			}
		})

		/** Set userSettings in store and save it */
		const setUserSettings = () => {
			store.commit('setUserSettings', userSettings);

			// stringify the current user settings
			const currentUserSettings: UserSettings = {
				viewSettings: JSON.stringify(userSettings.viewSettings),
				tableSettings: userSettings.tableSettings,
			};

			userApi.putAllUserProperties(currentUserSettings)
				.catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 501) {
						localStorage.setItem('userSettings', JSON.stringify(userSettings));
						return;
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				}));
		}

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog');
		}

		/** On Change the font size */
		const onChangeFontSize = () => {
			localStorage.setItem('fontSize', JSON.stringify(userSettings!.viewSettings!.fontSize));
			document.documentElement.style.setProperty("--global-font-size", userSettings!.viewSettings!.fontSize + 'rem');
		}

		/** On slide end of the font size or custom slider */
		const onSlideEnd = () => {
			setUserSettings();
		}

		/** On Change the draw */
		const onChangeDraw = () => {
			if(!userSettings.viewSettings!.viewerSettings!.highlightOptions!.draw && !userSettings!.viewSettings!.viewerSettings!.highlightOptions!.border) {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.border = true;
			}
			setUserSettings();
		}

		/** On Change the border */
		const onChangeBorder = () => {
			if(!userSettings!.viewSettings!.viewerSettings!.highlightOptions!.draw && !userSettings!.viewSettings!.viewerSettings!.highlightOptions!.border) {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.draw = true;
			}
			setUserSettings();
		}

		/** On Change the draw color in Viewer
		 * @param drawColor
		 */
		const onChangeDrawColor = (drawColor: string) => {
			userSettings!.viewSettings!.viewerSettings!.highlightOptions!.drawColor = drawColor;
			setUserSettings();
		}

		/** On Change the border color in Viewer
		 * @param borderColor
		 */
		const onChangeBorderColor = (borderColor: string) => {
			userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderColor = borderColor;
			setUserSettings();
		}

		/** On Change the border style in Viewer
		 * @param borderStyleDashed
		 */
		const onChangeBorderStyle = (borderStyleDashed: boolean) => {
			if(borderStyleDashed) {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderStyle = 'dashed';
			} else {
				userSettings!.viewSettings!.viewerSettings!.highlightOptions!.borderStyle = 'solid';
			}
			setUserSettings();
		}

		/** Open the confirm Dialog to restore the default settings  */
		const openConfirmDialog = () => {
			confirmDialog.value = true;
		}

		/** Set default settings in viewer */
		const setDefaultSettings = () => {
			userSettings!.viewSettings!.viewerSettings = {
				highlightOptions: {
					drawColor: '#ffff00',
					draw: true,
					borderColor: '#ffff00',
					borderStyle: 'solid',
					border: false,
				},
				zoomOptions: {
					fullWidth: true,
					fullHeight: false,
					customZoom: false,
					customSlider: 1,
				},
			}
		}

		/** On Change the Zoom options
		 * @param zoomInput
		 */
		const onChangeZoom = (zoomInput: string) => {
			switch(zoomInput) {
			case 'fullWidth': {
				if (userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth) {
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight = false;
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom = false;
				}
				break;
			}
			case 'fullHeight': {
				if (userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight) {
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth = false;
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom = false;
				}
				break;
			}
			case 'custom': {
				if (userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom) {
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth = false;
					userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight = false;
				}
				break;
			}
			}

			// when all zoomOptions false
			if (!userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullHeight && !userSettings!.viewSettings!.viewerSettings!.zoomOptions!.customZoom) {
				userSettings!.viewSettings!.viewerSettings!.zoomOptions!.fullWidth = true;
			}
			setUserSettings();
		}

		return { store, userSettings, isBorderStyleDashed, confirmDialog, clickSettingDialog, onChangeFontSize, onSlideEnd, onChangeDraw, onChangeBorder, onChangeDrawColor, onChangeBorderColor, onChangeBorderStyle, openConfirmDialog, setDefaultSettings, onChangeZoom, setUserSettings };
	},
});

</script>

<style lang="scss" scoped>

.headline {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin-bottom: 1rem;
}

::v-deep(.p-selectbutton .p-button.p-highlight) {
	background-color: var(--dex-secondary-dark-color);
}

::v-deep(.p-selectbutton .p-button:focus.p-highlight) {
	background-color: var(--dex-secondary-dark-color);
}

::v-deep(.p-inputswitch .p-inputswitch-slider:before) {
	background: dimgrey;
}

.input-color {
	appearance: none;
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	background-color: transparent;
	border: none;
}

.input-color::-webkit-color-swatch {
	border-radius: 50%;
	border: none;
}

</style>
