<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field" :class="showSingleLinedForm ? 'p-col-12' : 'p-col-6'">
			<label for="name">{{$t('Squeeze.Locators.Name')}} *</label>
			<InputText id="name" v-model.trim="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" required="true" autofocus />
			<small v-if="v$.name.required.$invalid && showErrorMessage && !v$.name.technicalValidation.$invalid" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Name')}) }}
			</small>
			<small v-if="v$.name.technicalValidation.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: $t('Squeeze.Locators.Name')}) }}
			</small>
			<small v-else-if="!!value.id">
				{{ $t('Squeeze.Admin.WarnRename') }}
			</small>
		</div>
		<div class="p-field" :class="showSingleLinedForm ? 'p-col-12' : 'p-col-6'">
			<label for="description">{{$t('Squeeze.Locators.Description')}} *</label>
			<InputText id="description" v-model.trim="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Description')}) }}
			</small>
		</div>
		<div class="p-field" :class="showSingleLinedForm ? 'p-col-12' : 'p-col-6'">
			<label for="locatorType">{{$t('Squeeze.Locators.LocatorType')}} *</label>
			<Dropdown id="locatorType" style="min-width: 250px" v-model="v$.locatorType.$model" :options="locatorTypes" :class="{'p-invalid':v$.locatorType.$invalid && showErrorMessage}" :filter="true" optionValue="value" optionLabel="description" :editable="false" @change="update" required="true" @show="showDropdownOverlay" />
			<small v-if="v$.locatorType.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.LocatorType')}) }}
			</small>
		</div>
		<div class="p-field" :class="showSingleLinedForm ? 'p-col-12' : 'p-col-6'">
			<template v-if="locator.locatorType !== 2">
				<label for="dataType">{{$t('Squeeze.Locators.DataType')}}</label>
				<Dropdown id="dataType" style="min-width: 250px" v-model="value.dataType" :options="dataTypes" optionValue="id" optionLabel="text" @change="update"  />
			</template>
		</div>
		<div class="p-field" :class="showSingleLinedForm ? 'p-col-12' : 'p-col-6'">
			<label for="searchPages">{{$t('Squeeze.Locators.SearchPages')}}</label>
			<Dropdown id="searchPages" style="min-width: 250px" v-model="value.searchPages" :options="searchPages" :filter="false" optionValue="id" optionLabel="description" :editable="false" @change="update"  />
		</div>

		<div class="p-field" :class="showSingleLinedForm ? 'p-col-12' : 'p-col-6'">
			<label for="sourceLocatorId">{{$t('Squeeze.Locators.SourceLocatorId')}}</label>
			<Dropdown id="sourceLocatorId" style="min-width: 250px" v-model="value.sourceLocatorId" :options="locators" :filter="true" optionValue="id" optionLabel="description" :editable="false" @change="update" @show="showDropdownOverlay" />
		</div>

		<div class="p-field p-col-6">
			<label for="ignoreWhiteSpaces">{{$t('Squeeze.Locators.IgnoreWhiteSpaces')}}</label><br/>
			<Checkbox id="ignoreWhiteSpaces" @change="update" v-model="value.ignoreWhiteSpaces" :binary="true" />
		</div>
		<div class="p-field p-col-6">
			<label for="active">{{$t('Squeeze.Locators.Active')}}</label><br/>
			<Checkbox id="active" @change="update" v-model="value.active" :binary="true" />
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import {useI18n} from "vue-i18n";
import {technicalValidation} from "@/util/ValidationHelper";
import {showDropdownOverlay} from "@/util/StylesHelper";

interface DataTypes {
	id: string | undefined;
	text: string;
}

export default defineComponent({
	name: 'LocatorForm',
	components: {
		InputText, Dropdown, Checkbox,
	},
	props: {
		/** All available Locators */
		locator: {
			type: Object as PropType<DocumentLocator>,
			default: {},
		},
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** All available Locator-Types */
		locatorTypes: {
			type: Array as PropType<LocatorType[]>,
			default: [],
		},
		/** All available Locators */
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
		},
		showErrorMessage: {
			type: Boolean,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["openEntryDialog", "deleteEntry", 'update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Array with the Search-Pages-Dropzone */
		const searchPages = computed(() => {
			return [
				{
					id: 0,
					description: t('Squeeze.Locators.EverySite'),
				},
				{
					id: 1,
					description: t('Squeeze.Locators.FirstSite'),
				},
				{
					id: 2,
					description: t('Squeeze.Locators.LastSite'),
				},
			]
		});

		/** List of Data-Types */
		const dataTypes = computed(() => {
			return [
				{
					id: 'Text',
					text: t('Squeeze.General.Text'),
				},
				{
					id: 'Date',
					text: t('Squeeze.General.Date'),
				},
				{
					id: 'Amount',
					text: t('Squeeze.General.Amount'),
				},
			] as DataTypes[]
		});

		/** Current Object of all input-fields */
		const value = reactive<DocumentLocator>({
			name: '',
			description: '',
			active: true,
			searchPages: 0,
			sourceLocatorId: 0,
			dataType: 'Text',
			ignoreWhiteSpaces: true,
			locatorType: undefined,
		});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
			locatorType: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.locator);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.locator, async () => {
			Object.assign(value, props.locator);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			//value.locatorType = props.locator.type;
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			searchPages,
			dataTypes,
			value,
			props,
			v$,
			InputText,
			update,
			showDropdownOverlay,
		}
	},
});
</script>

<style scoped>

</style>

