
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import TabMenu from "@/components/DexTabMenu.vue";
import {useI18n} from "vue-i18n";
import BlockUI from "primevue/blockui";

export interface LogItem {
	timestamp: string;
	text: string;
}

export default defineComponent({
	name: "Log",
	components: {
		BlockUI,
	},
	props: {
		logs: {
			type: Array as PropType<LogItem[]>,
			default: [],
		},
		loading: {
			type: Boolean,
		},
		showSort: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { locale } = useI18n({ useScope: 'global' });

		const logsInternal = ref<LogItem[]>(props.logs);

		/** Sort-Order */
		const sortOrder = ref(1); // -1 desc, 1 asc

		/** Sort the logs by timestamp */
		const sortLogs = () => {
			logsInternal.value.sort((a,b) => {
				if (new Date(a.timestamp) > new Date(b.timestamp)) {
					return 1 * sortOrder.value;
				}
				return -1 * sortOrder.value;
			});
		}

		onMounted(() => {
			sortLogs();
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.logs, () => {
			logsInternal.value = props.logs;
			sortLogs();
		})

		/**
		 * Change the sort order to the opposite direction
		 */
		const changeSortOrder = () => {
			sortOrder.value = sortOrder.value * -1;
			sortLogs();
		}

		/** Formats date string to desired locale string */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options) + ":" + date.getMilliseconds();
				}
			}
			return dateToFormat;
		}

		return {
			TabMenu, logsInternal, sortOrder, sortLogs, changeSortOrder, formatDate,
		}
	},
});
