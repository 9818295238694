
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, reactive, ref} from "vue";
import {BatchClass, BatchClassSetting} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "BatchClassAttributes",
	components: {
		DataTable, Column,
	},
	props: {
		attributes: {
			type: Array,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<BatchClassSetting>({});

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: BatchClass) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {settingName: '', value: '', schema: {}}, false)
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {BatchClass} data Data to edit
		 */
		const openEditDialog = (data: BatchClassSetting) => {
			emit("onEntrySelect", data, true)
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: BatchClassSetting) => {
			if (inputData !== entryDataCheck) {
				changeInputField.value = true;
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			DataTable, Column, store, tableListPagination, entryDataCheck, openDeleteDialog, onNewEntryClick, openEditDialog, changeEventInInput, onChangePage,
		}
	},
});
