<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="login">{{$t('Squeeze.Login.Login')}} *</label>
			<InputText id="login" v-model="value.login" :class="{'p-invalid':v$.login.$invalid && showErrorMessage}" @change="update" :disabled="user.id" />
			<small v-if="v$.login.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Login.Login')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="firstName">{{$t('Squeeze.UserManagement.Firstname')}} *</label>
			<InputText id="firstName" v-model="value.firstName" :class="{'p-invalid':v$.firstName.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.firstName.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Firstname')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="lastName">{{$t('Squeeze.UserManagement.Lastname')}} *</label>
			<InputText id="lastName" v-model="value.lastName" :class="{'p-invalid':v$.lastName.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.lastName.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Lastname')}) }}
			</small>
		</div>
		<div class="p-field p-col-6" :disabled="user.id">
			<label for="email">{{$t('Squeeze.UserManagement.Email')}} *</label>
			<InputText id="email" v-model="value.email" :class="{'p-invalid':v$.email.$invalid && showErrorMessage}" @change="update" :disabled="!store.state.isAdminUser" style="text-transform: lowercase;" />
			<small v-if="showErrorMessage" class="p-error">
				<template v-if="v$.email.email.$invalid">
					{{$t('Forms.Val.Email')}}
				</template>
				<template v-else-if="v$.email.$invalid">
					{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Email')}) }}
				</template>
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="password">{{$t('Squeeze.Login.Password')}} *</label>
			<Password id="password" v-model="value.password" :class="{'p-invalid':v$.password.$invalid && showErrorMessage}" @change="update" :feedback="false" toggleMask :disabled="user.id" />
			<small v-if="v$.password.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Login.Password')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Password from 'primevue/password';
import {User} from "@dex/squeeze-client-ts";
import {email, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useStore} from "@/store";

export default defineComponent({
	name: "UserForm",
	components: {
		InputText, Password,
	},
	props: {
		userId: {
			type: Number,
			default: 0,
		},
		user: {
			type: Object as PropType<User>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<User>({
			login: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
		});

		/** Determines the required rules for validation */
		let rules = {
			login: { required },
			firstName: { required },
			lastName: { required },
			email: { required, email},
			password: { },
		}

		if (!props.userId) {
			rules = {
				login: { required },
				firstName: { required },
				lastName: { required },
				email: { required, email},
				password: { required },
			}
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.user);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.user, async () => {
			Object.assign(value, props.user);
			await nextTick();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			store,
			loading,
			value,
			props,
			v$,
			InputText,
			Password,
			update,
		}
	},
});

</script>

<style scoped>

</style>
