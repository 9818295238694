<template>
	<Dialog
		:header="$t(locales.header)"
		v-model:visible="showDialog"
		:breakpoints="{'960px': '75vw', '640px': '100vw'}"
		:style="{width: '30vw'}"
		@hide="closeDialog"
		:modal="true"
	>
		<div class="p-grid" style="height: 18.125rem">
			<div v-if="options.length > 0" class="p-col-12">
				<p>{{$t(locales.notice.selectReason)}}</p>
				<Dropdown v-model="selectedOption" :options="options" optionLabel="label" :placeholder="$t(locales.selectPlaceholder)" style="width: 100%;" />
			</div>
			<div class="p-col-12 p-field p-input-filled">
				<p>{{$t(locales.notice.comment)}}</p>
				<InputText type="text"
							v-model="v$.comment.$model"
							style="width: 100%;"
							:disabled="selectedOption.value !== 'misc' && options.length > 0"
							:class="{'p-invalid':v$.comment.$invalid && confirmed}"
				/>
				<small v-if="(v$.comment.$invalid && confirmed) || v$.comment.$pending.$response" class="p-error">{{ $t('Squeeze.Validation.Dialogs.General.MissingComment') }}</small>
			</div>
		</div>
		<template #footer>
			<Button :label="$t(locales.buttons.abort)" @click="showDialog = false" icon="mdi mdi-close" class="p-button-text"/>
			<Button :label="$t(locales.buttons.confirm)" @click="confirm" icon="mdi mdi-check" autofocus />
		</template>
	</Dialog>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
//import {ToastManager} from "@/singletons/ToastManager";
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {Document} from "@dex/squeeze-client-ts/api";
import {PropType} from "vue";

interface Option {
	label: string;
	value: string;
}

interface Locales {
	header: string;
	notice: {
		selectReason: string;
		comment: string;
	};
	selectPlaceholder: string;
	buttons: {
		confirm: string;
		abort: string;
	};
}

@Options({
	name: "DialogOptionsConfirm",
	components: {
		Dialog,
		Dropdown,
		InputText,
	},
	props: {
		show: Boolean,
		options: Array,
		locales: {
			header: String,
			notice: {
				selectReason: String,
				comment: String,
			},
			selectPlaceholder: String,
			buttons: {
				confirm: String,
				abort: String,
			},
		},
		currentDocument: {
			type: Object as PropType<Document>,
			default: {},
		},
	},
	validations: {
		comment: { required },
	},
	watch: {
		show: function() {
			// Save show in local variable, because show is not allowed to me mutated inside. React on change from show from parent
			this.showDialog = this.show;

			// check if current document has an error text and if status is suspend
			if (this.currentDocument?.workflowContext?.errorText && this.currentDocument?.workflowContext?.status === "SUSPEND") {
				this.comment = this.currentDocument.workflowContext.errorText;
			} else {
				this.comment = "";
				this.confirmed = false;
			}
		},
	},
	emits: ["onClose", "onConfirm"],
})
export default class DialogOptionsConfirm extends Vue {

	/** Comment for confirmation */
	comment = "";

	v$ = useVuelidate();

	confirmed = false;

	/** Indicates if the dialog is displayed */
	showDialog = false;

	/** Reasons to choose from while deleting or suspending a document */
	options: Option[] = [];

	/** Selected option of options (dropdown) */
	selectedOption: Option = {
		label: "",
		value: "",
	};

	/** Localization for dialog text */
	locales: Locales = {
		header: "HEADER",
		notice: {
			selectReason: "NOTICE_SELECT_REASON",
			comment: "NOTICE_COMMENT",
		},
		selectPlaceholder: "SELECT_PLACEHOLDER",
		buttons: {
			confirm: "BUTTON_CONFIRM",
			abort: "BUTTON_ABORT",
		},
	};

	/** Emits confirmation event with comment */
	confirm() {
		this.confirmed = true;
		if(this.options.length > 0 && this.selectedOption.value !== 'misc') {
			this.comment = this.selectedOption.label;
		}

		if(this.comment.length > 0) {
			this.$emit("onConfirm", this.comment);
		}
	}

	/** Closes the dialog */
	closeDialog() {
		this.showDialog = false;
		this.$emit("onClose");
	}

}
</script>

<style scoped>

</style>
