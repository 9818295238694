<template>
	<TabView v-model:activeIndex="activeTab" lazy>
		<!-- "lazy" property of TabPanel would reload every tab on every click, that's why it's not used here. -->
		<TabPanel v-for="tab in items" :key="tab.label" :header="tab.label">
			<!-- with v-if the tabs are only loaded then the tab has been clicked. Another click will not trigger another reload, therefore it's only loaded once -->
			<component :is="tab.to" :masterDataTableId="masterDataTableId" :permissions="masterDataTablePermissions"></component>
		</TabPanel>
	</TabView>
</template>

<script lang="ts">
import TabMenu from "@/components/DexTabMenu.vue";
import {MenuItem} from "@/shims-prime-vue";
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import MasterDataTableView from "@/apps/administration/views/squeeze/mastedata/MasterDataTableView.vue";
import MasterDataColumnsView from "@/apps/administration/views/squeeze/mastedata/tabs/MasterDataColumnsView.vue";
import MasterDataPermissionView from "@/apps/administration/views/squeeze/mastedata/tabs/MasterDataPermissionView.vue";
import MasterDataUpdateView from "@/apps/administration/views/squeeze/mastedata/tabs/MasterDataUpdateView.vue";
import MasterDataRowsView from "@/apps/administration/views/squeeze/mastedata/tabs/MasterDataRowsView.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import useStore from "@/store";

interface MasterDataPermissions {
	permissionManage: boolean | undefined;
	permissionEdit: boolean | undefined;
}

export default defineComponent({
	name: "MasterDataView",
	components: {
		TabMenu, TabView, TabPanel,
	},
	props: {
		masterDataTableId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Use store */
		const store = useStore();

		/** Currently active tab */
		const activeTab = ref(0);

		/** Array of the already loaded tabs. The first tab is always active */
		const loadedTabs = ref<number[]>([0]);

		/** Api for master data */
		const masterDataApi = ClientManager.getInstance().squeeze.masterData;

		/** Current masterData table permissions */
		const masterDataTablePermissions = reactive<MasterDataPermissions>({permissionManage: undefined, permissionEdit: undefined});

		/** Is masterDataTable ID loading? */
		const loading = ref<boolean>(true);

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.MasterData.General'), to: MasterDataTableView},
			]

			if (props.masterDataTableId && masterDataTablePermissions && !loading.value) {
				if(masterDataTablePermissions.permissionManage && !masterDataTablePermissions.permissionEdit) {
					itemsList.push({label: t('Squeeze.MasterData.Columns'), to: MasterDataColumnsView});
					itemsList.push({label: t('Squeeze.MasterData.Permission'), to: MasterDataPermissionView});
				} else if (masterDataTablePermissions.permissionEdit && !masterDataTablePermissions.permissionManage) {
					itemsList.push({label: t('Squeeze.MasterData.Upload'), to: MasterDataUpdateView});
					itemsList.push({label: t('Squeeze.MasterData.Data'), to: MasterDataRowsView});
				} else if (masterDataTablePermissions.permissionEdit && masterDataTablePermissions.permissionManage) {
					itemsList.push({label: t('Squeeze.MasterData.Columns'), to: MasterDataColumnsView});
					itemsList.push({label: t('Squeeze.MasterData.Permission'), to: MasterDataPermissionView});
					itemsList.push({label: t('Squeeze.MasterData.Upload'), to: MasterDataUpdateView});
					itemsList.push({label: t('Squeeze.MasterData.Data'), to: MasterDataRowsView});
				} else {
					itemsList.push({label: t('Squeeze.MasterData.Columns'), to: MasterDataColumnsView});

					if (store.state.featureSet.masterDataTablePermissions && masterDataTablePermissions.permissionManage || (masterDataTablePermissions.permissionEdit && !masterDataTablePermissions.permissionManage)) {
						itemsList.push({label: t('Squeeze.MasterData.Permission'), to: MasterDataPermissionView});
					}
					if (store.state.scopes.sqzAdmin || store.state.featureSet.masterDataTablePermissions && masterDataTablePermissions.permissionEdit) {
						itemsList.push({label: t('Squeeze.MasterData.Upload'), to: MasterDataUpdateView});
					}

					itemsList.push({label: t('Squeeze.MasterData.Data'), to: MasterDataRowsView});
				}
			}

			return itemsList;
		});

		/** Get masterData table by ID */
		const getMasterDataTableId = () => {
			loading.value = true;
			masterDataApi.getMasterDataTableById(props.masterDataTableId)
				.then((data: MasterDataTable) => {
					masterDataTablePermissions.permissionManage = data.mayManage;
					masterDataTablePermissions.permissionEdit = data.mayEdit;
				})
				.catch((response: any) => response.json().then((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			if (props.masterDataTableId !== 0) {
				getMasterDataTableId();
			}
		})

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.masterDataTableId, () => {
			getMasterDataTableId();
		})

		return {items, t, activeTab, loadedTabs, masterDataTablePermissions};
	},
});

</script>

<style scoped>

::v-deep(.p-tabview-panels) {
	padding: 0 !important;
	margin-top: 0.5rem;
}

</style>
