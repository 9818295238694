import {BoundingBox} from "@dex/squeeze-client-ts";

export interface BoundingBoxOld {
	bottom: number;
	left: number;
	right: number;
	top: number;
	page: string;
}

export class ViewerClient {

	/** Window with the viewer */
	contentWindow: Window

	/** Current listener-function */
	listener: any

	/**
	 * @param contentWindow Window with the Viewer
	 */
	public constructor(contentWindow: Window) {
		this.contentWindow = contentWindow;

		/*	.bind(this) will change the signature. So always assign the function to a var after binding "this" to
		 	using function "bind" API so that same var can be used in removeListener.
		 	https://stackoverflow.com/questions/10444077/javascript-removeeventlistener-not-working
		*/
		this.listener = this.receiveMessage.bind(this);
		window.addEventListener("message", this.listener, true);
	}

	/**
	 * Called when a user right clicks on the document to select a value.
	 * Also called when drag and drop is performed with a right click.
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public onSelectArea: (value: string, boundingBox: BoundingBoxOld) => void = () => {};

	/**
	 * Called when the "field-training"-button is clicked
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public onOpenFieldTraining: () => void = () => {};

	/**
	 * Called when the "field-training"-button is clicked
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public onOpenItemTraining: () => void = () => {};

	/**
	 * Called when the "training-list"-button is clicked
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public onOpenFieldTrainingList: () => void = () => {};

	/** Receives the window-messages */
	public receiveMessage(event: any) {
		let data = event.data;

		if (typeof data === "string") {
			try {
				data = JSON.parse(data);
			} catch (err) {
				// Don't to anything if the data cannot be parsed
				return;
			}
		}

		if (data.message) {
			const message = data.message;

			switch(message) {
			case "SelectedArea": {
				// TODO: Map data.mouse to the BoundingBox of generated client? left = x0, ...
				this.onSelectArea(data.words.join(" "), data.coordinates);
				break;
			}
			case "OpenFieldTraining":
				this.onOpenFieldTraining();
				break;
			case "OpenItemTraining":
				this.onOpenItemTraining();
				break;
			case "OpenFieldTrainingList":
				this.onOpenFieldTrainingList();
				break;
			default:
				break;
			}
		}
	}

	/**
	 * Removes the Message-Event-Listener
	 */
	public removeListener() {
		window.removeEventListener("message", this.listener, true);
	}

	/**
	 * Marks a field in the viewer
	 * @param fieldName Name of the field to mark
	 */
	public markField(fieldName: string) {
		const data = {
			message: '',
			fieldName: '',
		};

		data.message = "markFieldByName";
		data.fieldName = fieldName;

		this.contentWindow.postMessage(JSON.stringify(data), "*");
	}

	/**
	 * Activates column marking. When a user drags an area in the viewer, it will now mark a column instead of a normal bounding box.
	 */
	public activateColumnMarking() {
		this.contentWindow.postMessage("activateMarkColumnWidth", '*');
	}

	/**
	 * Deaktivates column marking. See {@link activateColumnMarking}
	 */
	public deactivateColumnMarking() {
		this.contentWindow.postMessage("deactivateMarkColumnWidth", '*');
	}

	/**
	 * Marks a region in the viewer
	 * @param boundingBox
	 */
	public markRegion(boundingBox: BoundingBox) {
		const data = {
			message: '',
			data: {},
		}
		data.message = "markByCoordinates";
		data.data = boundingBox;
		this.contentWindow.postMessage(JSON.stringify(data), '*');
	}

	/**
	 * Marks regions in the viewer
	 * @param regions
	 */
	public markRegions(regions: BoundingBox[]) {
		const data = {
			message: '',
			data: [] as BoundingBox[],
		}
		data.message = "markRegions";
		data.data = regions;
		this.contentWindow.postMessage(JSON.stringify(data), '*');
	}

	/**
	 * Opens the fie field-training
	 * @param trainingKey Key for the Training (often Creditor-ID)
	 */
	public openFieldTraining(trainingKey: string) {
		const data = {
			message: '',
			keyValue: '',
			fieldId: '',
		}
		data.message = "toggleTrainingDisplayState";
		data.keyValue = trainingKey;
		data.fieldId = "";
		this.contentWindow.postMessage(JSON.stringify(data), '*');
	}

	/**
	 * Opens the fie item-training
	 * @param trainingKey Key for the Training (often Creditor-ID)
	 */
	public openItemTraining(trainingKey: string) {
		const data = {
			message: '',
			keyValue: '',
			fieldId: '',
		}
		data.message = "toggleItemTrainingDisplayState";
		data.keyValue = trainingKey;
		data.fieldId = "";
		this.contentWindow.postMessage(JSON.stringify(data), '*');
	}

	/**
	 * Opens the fie item-training
	 * @param trainingKey Key for the Training (often Creditor-ID)
	 */
	public openFieldTrainingList(trainingKey: string) {
		const data = {
			message: '',
			keyValue: '',
			fieldId: '',
		}
		data.message = "toggleTrainedFieldsDisplayState";
		data.keyValue = trainingKey;
		data.fieldId = "";
		this.contentWindow.postMessage(JSON.stringify(data), '*');
	}

	/**
	 * Opens a Page in the View
	 * @param page Page to open
	 */
	public goToPage(page: number) {
		const data = {
			message: '',
			page: 0,
		}
		data.message = "goToPage";
		data.page = page;
		this.contentWindow.postMessage(JSON.stringify(data), '*');
	}

	/** Scroll page up */
	public pageUp() {
		this.contentWindow.postMessage('sqzViewerPageUp', '*');
	}

	/** Scroll page down */
	public pageDown() {
		this.contentWindow.postMessage('sqzViewerPageDown', '*');
	}

	/** Optimized height */
	public toggleFullHeight() {
		this.contentWindow.postMessage('sqzViewerFullHeight', '*');
	}

	/** Optimized width */
	public toggleFullWidth() {
		this.contentWindow.postMessage('sqzViewerFullWidth', '*');
	}

}
