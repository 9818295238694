<template>
	<div class="p-mb-3">
		<span>{{ $t('Squeeze.System.ImageUploadDescription') }}</span>
	</div>
	<SystemThemeUploadForm :selectedFiles="files" @fileUploader="fileUploader" @deleteThemeFile="deleteThemeFile" />

	<div class="p-grid">
		<div class="p-col-12">
			<span>{{ $t('Squeeze.System.CurrentImages') }}</span>
		</div>

		<div v-if="loadingLogo" class="p-col-2 container-image">
			<i class="mdi mdi-spin mdi-loading" style="fontSize: 5rem"></i>
		</div>
		<div v-else class="p-col-2 container-image">
			<img :src="urlClientLogo" alt="Client Logo" class="image">
			<div class="image-overlay">{{ $t('Squeeze.System.LoginLogoImage') }}</div>
		</div>

		<div v-if="loadingLogo" class="p-col-2 container-image">
			<i class="mdi mdi-spin mdi-loading" style="fontSize: 5rem"></i>
		</div>
		<div v-else class="p-col-2 container-image">
			<img :src="urlClientBackground" alt="Background Image" class="image">
			<div class="image-overlay">{{ $t('Squeeze.System.LoginBackgroundImage') }}</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import {UploadFile} from "@/shims-prime-vue";
import SystemThemeUploadForm from "@/apps/administration/components/system/SystemThemeUploadForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";

export default defineComponent({
	name: "SystemThemeUploadView",
	components: {
		SystemThemeUploadForm,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Used File-Type for Upload */
		const fileType = ref('png');

		/** Api for Theme */
		const themeApi = ClientManager.getInstance().squeeze.theme;

		/** Show loading of logo? */
		const loadingLogo = ref(false);

		/** Show loading of background image? */
		const loadingBackgroundImage = ref(false);

		/** URL of client background image */
		const urlClientBackground = ref<string>('');

		/** URL of client logo */
		const urlClientLogo = ref<string>('');

		/** Get background image of client */
		const getBackgroundImage = () => {
			loadingBackgroundImage.value = true;
			urlClientBackground.value = '';
			themeApi.getThemeFile('loginBackground.jpg')
				.then((data: any) => {
					urlClientBackground.value = data.url;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loadingBackgroundImage.value = false;
				});
		}

		/** Get Logo of client */
		const getLogo = () => {
			loadingLogo.value = true;
			urlClientLogo.value = '';
			themeApi.getThemeFile('loginLogo.png')
				.then((data: any) => {
					urlClientLogo.value = data.url;
				})
				.catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loadingLogo.value = false;
				});
		}

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @param fileType
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: UploadFile, fileType: string) => {
			const body = new FormData();
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() +
				"/dexp-sqz/theme/files/" + fileType, {
				method: "POST",
				body: body,
			});

			if (response.status !== 200 && response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error("Unexpected status " + responseJSON.message);
			}
		}

		/** Uploads the files from the file-uploader
		 * @param filesSend
		 * @param fileTypeSet
		 */
		const fileUploader = (filesSend: UploadFile[], fileTypeSet: string) => {
			files.value = filesSend;
			fileType.value = fileTypeSet;

			manualFileUpload(files.value[0], (fileType.value === 'png' ? 'loginLogo.png' : 'loginBackground.jpg'))
				.then(() => {
					files.value[0].uploadFinished = true;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SuccessDataUpload'));
				})
				.catch(err => {
					files.value[0].error = true;
					files.value[0].errorText = err.message;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})
				.finally(() => {
					files.value[0].loading = false;
					files.value = [...files.value];

					if (fileType.value === 'png') {
						getLogo();
					} else {
						getBackgroundImage();
					}
				});
		}

		/** Delete the current theme file
		 * @param fileType
		 */
		const deleteThemeFile = (fileType: string) => {
			themeApi.deleteThemeFile(fileType === 'png' ? 'loginLogo.png' : 'loginBackground.jpg')
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SuccessDeleteImage'));
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})
				.finally(() => {
					if (fileType === 'png') {
						getLogo();
					} else {
						getBackgroundImage();
					}
				});
		}

		onMounted(() => {
			getBackgroundImage();
			getLogo();
		})

		return {
			t,
			toast,
			files,
			urlClientBackground,
			urlClientLogo,
			loadingLogo,
			loadingBackgroundImage,
			fileUploader,
			deleteThemeFile,
			getBackgroundImage,
			getLogo,
		}
	},
})
</script>

<style scoped>

::v-deep(.p-scrollpanel-bar.p-scrollpanel-bar-y) {
	display: none;
}

.container-image {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.image {
	display: block;
	width: 100%;
	height: auto;
}

.image-overlay {
	position: absolute;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	transition: .5s ease;
	opacity: 0;
	color: white;
	padding: 0.5rem;
	text-align: center;
}

.container-image:hover .image-overlay {
	opacity: 1;
}

</style>
