
/* eslint max-lines: off */
import {defineComponent, ref, onMounted, PropType, computed, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {BatchClass, BatchClassClassification, DocumentField, ErrorDto, QueueEntry} from "@dex/squeeze-client-ts";
import {DEFAULT_LOCALE} from "@/lang";
import Squeeze from "@/apps/squeeze/App.vue";
import {ToastManager} from "@/singletons/ToastManager";
import {DateTimeOptions} from "@/interfaces/DateTimeOptions";
import {i18n} from "@/main";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from "primevue/tooltip";
import {useStore} from "@/store";
import {DocumentClass} from "@dex/squeeze-client-ts/api";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";

interface MenuItem {
	label: string;
	items: MenuSubItem[];
}

interface MenuSubItem {
	label: string;
	icon: string;
	command: {};
}

export default defineComponent({
	name: "QueueList",
	components: {
		Button, Menu, DataTable, Column, EntryDialog, BlockUI, Dropdown, Message, InputText,
	},
	props: {
		queueEntries: Array,
		loading: Boolean,
		paginationInfo: Object,
		stepName: String,
		batchClasses: {
			type: Array as PropType<BatchClass[]>,
			default: [],
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: [],
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage", "openLogDialog", "deleteDocuments", "goToStep", "onRequeue", "onFilter", "onSort", "clearFilters"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useStore();

		/** Document Class API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** Requeue steps to choose in menu */
		const steps = ref<MenuItem[]>([]);
		const stepsGoTo = ref<MenuItem[]>([]);

		/** Currently selected row or rows */
		const selection = ref<QueueEntry | null>(null);
		const selectedRows = ref<QueueEntry[]>([]);

		const lockTable = ref<boolean>(false);

		/** Current locale */
		const lang = ref<any>(DEFAULT_LOCALE);

		/** Get $refs of menus */
		const singleEntryMenu = ref(null);
		const multiEntryMenu = ref(null);
		const goToMenu = ref(null);

		/** Is Requeue Dialog visible? */
		const showRequeue = ref<boolean>(false);

		/** Is Requeue Dialog open by single entry? */
		const requeueSingleDocument = ref<boolean>(false);

		/** Name of the next step to requeue */
		const requeueStep = ref<string>('');

		/** Service for getting the all batchClasses */
		const batchClassService = ClientManager.getInstance().squeeze.batchClass;

		/** List of valid classification classes */
		const classificationClasses = ref<BatchClassClassification[]>([]);

		/** Currently active Document-Class */
		const activeDocumentClass = ref<number | undefined>(undefined);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (selectedRows.value.length > 1) {
				return t('Squeeze.Queue.Requeue.ForwardEntries') + ": " + t('Squeeze.Queue.Steps.' + requeueStep.value) + "?";
			}

			return  t('Squeeze.Queue.Requeue.ForwardEntry') + ": "  + t('Squeeze.Queue.Steps.' + requeueStep.value) + "?";
		});

		/** QueueStates for filter selection */
		const statuses =  ref<string[]>([
			'CLARIFICATION', 'ERROR', 'FINISHED', 'INITIAL', 'NEW', 'SUSPEND', 'WAITING', 'WORK',
		]);

		/** Watch prop at set filters object, because props are not allowed to be mutated */
		watch(() => props.filterOptions, () => {
			filters.value = props.filterOptions as any;
		})

		/** Get all DocumentClasses */
		const getClassificationClasses = (batchClassId: number) => {
			batchClassService.getBatchClassClassifications(batchClassId)
				.then(data => {
					classificationClasses.value = data;
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * Triggered when a row is clicked
		 * @param event
		*/
		const onRowClick = (event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) => {
			/* Prevent the row-click in the selection-column with the checkbox
			* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
			* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
			*/
			if (event.originalEvent
				&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/**
		 * Triggered when the next page is selected
		 * @param event
		*/
		const onPage = (event: any) => {
			selectedRows.value = [];
			emit("onPage", event)
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			selectedRows.value = [];
			emit("onReload");
		}

		/**
		 * Opens requeue menu
		 * @param event
		 * @param data
		 */
		const openRequeueMenu = (event: MouseEvent, data: QueueEntry) => {
			selection.value = data;
			const singleMenu = singleEntryMenu.value as any;
			if(singleMenu) {
				singleMenu.toggle(event);
			}
		}

		/**
		 * Opens multi requeue menu
		 * @param event
		 */
		const openMultiRequeueMenu = (event: any) => {
			if (!selectedRows.value.length) {
				return;
			}
			const multiMenu = multiEntryMenu.value as any;
			if(multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Opens the Requeue Menu
		 * @param event
		 */
		const openGoToMenu = (event: any) => {
			const multiMenu = goToMenu.value as any;
			if(multiMenu) {
				multiMenu.toggle(event);
			}
		}

		/**
		 * Requeues selected QueueEntry to specified step
		 * @param step
		 */
		const requeueEntry = (step: string) => {
			if(selection.value && selection.value.documentId) {
				// REQUEUE API ENDPOINT
				lockTable.value = true;
				showRequeue.value = false;
				documentApi.requeueDocument(selection.value.documentId, step, activeDocumentClass.value).then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				}).catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					selection.value = null;
					lockTable.value = false;
					onReload();
				})
			} else {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Select.Entry.One'));
			}
		}

		/**
		 * Requeue the selected entries
		 * @param step
		 */
		const requeueSelectedEntries = (step: string) => {
			if (!selectedRows.value || !selectedRows.value.length) {
				return;
			}

			lockTable.value = true;
			showRequeue.value = false;
			selectedRows.value.reduce((chain, { documentId }) => {
				if (documentId == undefined) {
					return chain;
				}
				return chain.finally(() => documentApi.requeueDocument(documentId, step, activeDocumentClass.value).catch(() => { /* swallow errors */ }))
			}, Promise.resolve()).finally(() => {
				ToastManager.showSuccess(toast, t('Squeeze.Queue.Requeue.Success'), t('Squeeze.Queue.Steps.' + step));
				selectedRows.value = [];
				lockTable.value = false;
				onReload();
			});
		}

		const confirmRequeue = () => {
			if (requeueSingleDocument.value === true) {
				requeueEntry(requeueStep.value);
			} else {
				requeueSelectedEntries(requeueStep.value);
			}
		}


		/**
		 * Formats date string to desired locale string
		 * @param dateToFormat
		 */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
					lang.value = i18n.global.locale;
					return date.toLocaleDateString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), options);
				}
			}
			return "";
		}

		/**
		 * arses an amount (string) to locale string
		 * @param value
		 */
		const parseAmounts = (value: string) => {
			value = value.replace(/[^0-9.,]/g, "");

			if(value.indexOf(",") != -1) {
				value = value.replaceAll(".", "").replace(",", ".");
			}

			if(value.length > 0) {
				return parseFloat(value).toLocaleString(lang.value.toLowerCase() + '-' + lang.value.toUpperCase(), {minimumFractionDigits: 2});
			} else {
				return "0,00";
			}
		}

		/**
		 * Returns field value of passed DocumentField array
		 * @param fields
		 * @param fieldId
		 * @param type
		 */
		const getFieldValue = (fields: DocumentField[], fieldId: number, type: string) => {
			if(fields && fieldId) {
				const fieldValue = fields.find((field) => {
					return field.id === fieldId;
				});

				if(fieldValue != undefined) {
					if(fieldValue.value && fieldValue.value.value) {
						switch(type.toLowerCase()) {
						case "amount": return parseAmounts(fieldValue.value.value);
						case "date": return formatDate(fieldValue.value.value);
						default: return fieldValue.value.value;
						}
					}
				}
			}

			return "";
		}

		/**
		 * Resets the current selections
		 */
		const resetSelection = () => {
			selectedRows.value = [];
		}

		/**
		 * Triggered when another step should be shown
		 * @param step
		 */
		const goToStep = (step: string) => {
			resetSelection();
			emit("goToStep", step)
		}

		/**
		 * Download the current Attachment in QueueTable (as ZIP file)
		 * @param rowData current row
		 */
		const downloadAttachment = (rowData: QueueEntry) => {
			// will return the document id of selected row
			const documentId = rowData.documentId;

			const downloadURL = ClientManager.getInstance().buildDocumentDownloadUrl(documentId as number);
			window.location.href = downloadURL;
		}

		onMounted(() => {
			// Initialize Steps
			steps.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];

			stepsGoTo.value = [{
				label: t('Squeeze.Queue.General.Steps'),
				items: [],
			}];

			Squeeze.queueSteps.forEach((icon: string, step: string) => {
				steps.value[0].items.push({
					label: t('Squeeze.Queue.Steps.' + step),
					icon: icon,
					command: (ev: { item: any; originalEvent: MouseEvent }) => {
						activeDocumentClass.value = undefined;

						if ((ev.originalEvent.target as HTMLElement).matches('#multi_entry_menu *')) {
							// Get unique batchclass ids from selection
							const checkRows = selectedRows.value.map(row => row.batchClassId).filter((value, index, self) => self.indexOf(value) === index);
							if (checkRows.length > 1 && (step === 'Validation' || step === 'Extraction')) {
								ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Queue.Requeue.MultipleBatchClassError'));
								return;
							}

							showRequeue.value = true;
							requeueStep.value = step;
							requeueSingleDocument.value = false;
						}
						else if ((ev.originalEvent.target as HTMLElement).matches('#single_entry_menu *')) {
							showRequeue.value = true;
							requeueStep.value = step;
							requeueSingleDocument.value = true;
							selectedRows.value = [];
							if (selection.value) {
								getClassificationClasses(selection.value.batchClassId!)
							}
						}
					},
				});

				stepsGoTo.value[0].items.push({
					label: t('Squeeze.Queue.Steps.' + step),
					icon: icon,
					command: () => {
						goToStep(step)
					},
				});
			});

			// Add the delete Step for goto
			stepsGoTo.value[0].items.push({
				label: t('Squeeze.Queue.Requeue.Errors'),
				icon: "mdi mdi-basket-remove-outline",
				command: () => {
					goToStep("ErrorBasket")
				},
			});
		})

		/**
		 * Open the Dialog to show the Log of a Document
		 * @param documentId current documentId of row
		 */
		const openLogDialog = (documentId: number) => {
			emit("openLogDialog", documentId);
		}

		/**
		 * Triggers the delete of a document
		 * @param documentId
		 */
		const deleteDocument = (queueEntry: QueueEntry) => {
			emit("deleteDocuments", [queueEntry]);
		}

		/**
		 * Triggered when multiple entries are selected and these should be deleted
		 */
		const deleteDocuments = () => {
			emit("deleteDocuments", selectedRows.value);
		}

		/**
		 * Gets the Description of a documentClass
		 * @param documentClassId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClass = (documentClassId: number) => {
			if (documentClassId == null || documentClassId === 0) {
				return '-';
			}

			const documentClassDescription = props.documentClasses.find(i => i.id === documentClassId);

			if (!documentClassDescription) {
				return String(documentClassId)
			}

			return documentClassDescription.description
		}

		/**
		 * Gets the Description of a documentClass
		 * @param batchClassId Id of the batchclass to get the Description from
		 * @return Description of the batchclass
		 */
		const getDescriptionFromBatchClass = (batchClassId: number) => {
			if (batchClassId == null || batchClassId === 0) {
				return '-';
			}

			const documentClassDescription = props.batchClasses.find(i => i.id === batchClassId);

			if (!documentClassDescription) { return String(batchClassId)}

			return documentClassDescription.description
		}

		/** Triggered on Filter-Event  */
		const onFilter = (event: any) => {
			showBtnClearFilter.value = event.filters.id.value
				|| event.filters.status.value
				|| event.filters.documentId.value
				|| event.filters.documentClassId.value
				|| event.filters.batchClassId.value
				|| event.filters.errorText.value;

			emit("onFilter", event.filters);
		}

		/** Triggered on sort a column */
		const onSort = (event: { sortField: string; sortOrder: number }) => {
			emit("onSort", event.sortField, event.sortOrder);
		}

		/** Clear filters */
		const clearFilters = () => {
			showBtnClearFilter.value = false;
			emit("clearFilters");
		}

		return {t, toast, store, filters, showBtnClearFilter, steps, selection, selectedRows, lockTable, lang, activeDocumentClass, requeueStep,
			singleEntryMenu, multiEntryMenu, goToMenu, stepsGoTo, headerText, showRequeue, classificationClasses, requeueSingleDocument, statuses,
			downloadAttachment, deleteDocument, deleteDocuments, getDescriptionFromDocumentClass, getDescriptionFromBatchClass,
			onRowClick, onPage, onReload, openRequeueMenu, openMultiRequeueMenu, requeueEntry, requeueSelectedEntries, formatDate, parseAmounts, getFieldValue, openLogDialog,
			resetSelection, openGoToMenu, confirmRequeue, getClassificationClasses, onFilter, onSort, clearFilters,
		};
	},
});

