
import {Options, Vue} from "vue-class-component";
import LocatorList from "@/apps/administration/components/locators/LocatorList.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import router from "@/router";

@Options({
	name: 'LocatorsListView',
	components: {
		LocatorList,
	},
})

export default class LocatorsListView extends Vue  {

	/** Service for getting the locator-data */
	locatorService = ClientManager.getInstance().squeeze.locator;

	/** List of all locators **/
	locators?: DocumentLocator[] = [];

	/** List of all locators **/
	locatorTypes?: LocatorType[] = [];

	/** Should the loading be shown in the table? */
	loading = false;

	mounted() {
		this.getLocators()
	}

	getLocators() {
		this.loading = true

		const handleErr = (msg: string, err: any) => ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t(msg) + ": " + err);

		const promiseAllLocators = this.locatorService.getAllLocators()
			.then(data => this.locators = data)
			.catch(response => response.json().then ((err: any) => {
				handleErr('Squeeze.Locators.ErrorLoadLocators', err.message)
			}))

		const promiseLocatorTypes = this.locatorService.getLocatorTypes()
			.then((data: any) => this.locatorTypes = data)
			.catch(response => response.json().then ((err: any) => {
				handleErr('Squeeze.Locators.ErrorLoadLocatorTypes', err.message)
			}))

		// Wait until promises are finished
		Promise.all([promiseAllLocators, promiseLocatorTypes]).then(() => this.loading = false);
	}

	/** Opens the LocatorSubView-Component */
	openEntryDialog() {
		router.push({ name: 'LocatorSubView', params: { locatorId: 0}})
	}

	/** Deletes an entry */
	deleteEntry(rowData: any) {
		if (!rowData) {
			return
		}
		this.loading = true;
		const id = rowData.id;
		this.locatorService.deleteLocatorById(id as any).then(() => {
			this.getLocators();
		}).catch(err => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.ErrorDelete'), this.$t('Squeeze.General.DeleteError') + ": " + err)
		}).finally(() => {
			this.loading = false;
		});
	}

}
