
import {defineComponent, PropType, ref} from "vue";
import {JobRun, PaginationDto} from "@dex/squeeze-client-ts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from 'primevue/dropdown';
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: 'JobRunsList',
	components: {
		DataTable,
		Column,
		Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All job runs */
		jobRuns: {
			type: Array as PropType<JobRun>,
			default: [],
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onPage",
		"onFilter",
	],
	setup(props, {emit}) {
		const { locale } = useI18n({ useScope: 'global' });

		/** QueueStates for filter selection */
		const statuses =  ref<string[]>([
			'ERROR', 'CREATED', 'STARTED', 'FINISHED',
		]);

		/** Filters of list */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** Triggered when the next page is selected */
		const onPage = (event: PaginationDto) => {
			emit("onPage", event);
		}

		/** Triggered when the tableFilter is selected */
		const onFilter = (tableFilter: any) => {
			emit("onFilter", tableFilter);
		}

		/** Formats date string to desired locale string */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options) + ":" + date.getMilliseconds();
				}
			}
			return dateToFormat;
		}

		return {
			statuses, filters, onPage, onFilter, formatDate,
		}
	},
})
