<template>
	<DataTable :value="masterDataRows"
		class="p-datatable-sm editable-cells-table p-datatable-documents"
		:loading="loading"
		columnResizeMode="fit"
		:lazy="true"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="paginationInfo.pageSize"
		:totalRecords="paginationInfo.total"
		:paginator="true"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		editMode="cell"
		@cell-edit-init="onCellEdit"
		@cell-edit-complete="onCellCompleteEditing"
		@row-dblclick="openEditDialog($event.data)"
		filterDisplay="row"
		v-model:filters="filters"
		@filter="onFilter($event.filters)"
		:scrollable="true"
		scrollHeight="flex"
		@sort="onSort($event)"
		:removableSort="true"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.MasterData.Data') }) }}
		</template>

		<Column v-for="column of masterDataColumns" :field="column.name" :filterField="'' + column.id" :header="column.description" :key="column.id" class="column-cell"
				:style="[
					masterDataColumns.length > 1 && column.description !== 'ID' ? 'min-width: 12.5rem;': 'min-width: 4rem; max-width: 6rem;',
					masterDataColumns.length <= 1 && column.description === 'ID' ? 'max-width: 100%;' : '',
				]"
				:showFilterMenu="true"
				:sortable="true">
			<template #body="{ data }">
				<div class="column-cell" v-tooltip.bottom="data[column.name]">
					{{data[column.name]}}
				</div>
			</template>
			<template  #editor="{ data }" v-if="!checkPermissions">
				<InputText v-model="data[column.name]" v-tooltip.bottom="data[column.name]" @dblclick="openEditDialog(data)" @keyup="changeEventInInput(data)"/>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="flex: 0 0 4rem" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="" />
			</template>
		</Column>
		<!-- Delete / Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="masterDataRow">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(masterDataRow.data)" :disabled="checkPermissions" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(masterDataRow.data)" :disabled="checkPermissions" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button p-mr-2" @click="onNewEntryClick" :disabled="checkPermissions" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Tooltip from 'primevue/tooltip';
import {computed, defineComponent, nextTick, PropType, reactive, ref} from "vue";
import {MasterDataColumn, PaginationDto} from "@dex/squeeze-client-ts";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import useStore from "@/store";

/** Interface that is used for Key/Value pairs that are needed for inserting/updating entries */
interface KeyValuePair {
	[key: string]: string | undefined;
}

export default defineComponent({
	name: "MasterDataRows",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
		},
		masterDataRows: {
			type: Array as PropType<any[]>,
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
		permissions: {
			type: Object,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onPage", "onFilter", "onChangeInputField", "onSort",
	],
	setup(props, {emit}) {
		/** Use store */
		const store = useStore();

		/** The event.data values to check it */
		const entryDataCheck = reactive<any>({});

		/** Filters of list (Currently not activated) */
		const filters = reactive<DocumentFilterObject>(props.filterOptions!);

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			if (props.permissions) {
				return !props.permissions.permissionEdit && props.permissions.permissionManage
					|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
					|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/** Triggered on Filter-Event  */
		const onFilter = (tableFilters: DocumentFilterObject) => {
			emit("onFilter", tableFilters);
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: KeyValuePair) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			// Empty all values that might be there on new entry click
			const setObject: KeyValuePair = {id: undefined};
			props.masterDataColumns?.forEach(column => {
				if (column.name.toLowerCase() !== "id") {
					setObject[column.name] = "";
				}
			});

			emit("onEntrySelect", setObject)
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: KeyValuePair) => {
			// check if permissions return true, to prevent double click
			if (checkPermissions.value) {
				return
			}
			emit("onEntrySelect", row)
		}

		/** Triggered when the next page is selected */
		const onPage = (event: PaginationDto) => {
			emit("onPage", event);
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2]?.focus();

			// This is needed when a text is clicked directly
			if (event.originalEvent.currentTarget && event.originalEvent.currentTarget.childNodes[2]) {
				event.originalEvent.currentTarget.childNodes[2]?.focus();
			}
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * @param event
		 */
		const onCellCompleteEditing = (event: any) => {
			if(changeInputField.value) {
				emit("onChangeInputField", event.data);
				changeInputField.value = false;
			} else {
				event.preventDefault();
			}
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: any) => {
			if (inputData !== entryDataCheck) {
				changeInputField.value = true;
			}
		}

		/** Triggered on sort a column */
		const onSort = (event: { sortField: string; sortOrder: number }) => {
			emit("onSort", event.sortField, event.sortOrder)
		}

		return {
			store, entryDataCheck, DataTable, Column, filters, changeInputField, checkPermissions,
			openDeleteDialog, onNewEntryClick, openEditDialog, onPage, onFilter, onSort,
			onCellEdit, onCellCompleteEditing, changeEventInInput,
		}
	},
});
</script>
<style lang="scss" scoped>
// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
	background-color: var(--dex-primary-light-color);
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
	border-bottom-color: var(--dex-primary-light-color);
	color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr td) {
	padding: 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-filter-column) {
	padding:0 0 0.2rem 0.2rem;
	color: var(--filter-field-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-filter-column .p-column-filter-menu-button, .p-column-filter-clear-button) {
	color: var(--filter-field-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}
::placeholder {
	color: var(--filter-field-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-tbody tr td) {
	padding: 0.6rem;
}
</style>
<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

input {
	width: 100%;
	border: none;
	border-radius: 0;
}

::v-deep(td.column-cell.p-editable-column.p-cell-editing) {
	padding: 0 !important;
}

::v-deep(td.column-cell) {
	padding: 0 !important;
}

::v-deep(td span) {
	padding-left: 0.75rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

::v-deep(button.p-column-filter-menu-button.p-link) {
	display: none;
}

::v-deep(.column-cell) {
	padding-left: 0.75rem;
}

</style>
