<template>
	<div class="p-mb-2 p-p-2">
		<BlockUI :blocked="loading || saveLoading">
			<SearchForLineItemsForm
				:lineItemLocatorValue="lineItemLocatorValue"
				:loading="loading"
				:positionTables="documentTables"
				:showErrorMessage="showErrorMessage"
				:documentClasses="documentClasses"
				@update="onUpdate"
			/>

			<div class="p-fluid p-formgrid p-grid p-jc-end p-p-2">
				<div class="p-field p-col-3">
					<Button :label="$t('Squeeze.General.Save')" type="button" icon="mdi mdi-content-save-outline" class="p-button" :loading="loading || saveLoading" @click="saveLineItem" />
				</div>
			</div>
		</BlockUI>
	</div>
</template>

<script lang="ts">
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {ErrorDto, DocumentTable, LineItemLocatorDetails, DocumentClass} from "@dex/squeeze-client-ts";
import BlockUI from "primevue/blockui"
import SearchForLineItemsForm from "@/apps/administration/components/locators/tabs/SearchForLineItemsForm.vue";

export default defineComponent({
	name: "SearchForLineItemsView",
	components: {
		SearchForLineItemsForm, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const saveLoading = ref(false);

		/** Show error validation-messages in form? */
		const showErrorMessage = ref(false);

		/** Is the Form of the Keywords invalid? */
		const isValidationInvalid = ref(true);

		/** List of all Master data tables */
		const documentTables = ref<DocumentTable[]>([]);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Service for getting the master-data */
		const documentClassService= ClientManager.getInstance().squeeze.documentClass;

		/** List of all values from RegEx */
		const lineItemLocatorValue = reactive<LineItemLocatorDetails>({});

		/** All Document-Classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: LineItemLocatorDetails, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(lineItemLocatorValue, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			locatorService.getLineItemLocatorDetails(props.locatorId)
				.then((data: LineItemLocatorDetails) => {
					if (data.tableId === 0) {
						data.tableId = undefined;
					}
					Object.assign(lineItemLocatorValue, data);
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all documentTables */
		const getPositionTables = () => {
			loading.value = true;
			documentClassService.getDocumentClassTablesGlobal()
				.then(data => {
					documentTables.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		const getAllDocumentClasses = () => {
			documentClassService.getAllDocumentClasses().then(data => {
				documentClasses.value = data
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			}))
		}

		/** Get all 'documentTables' and 'value from RegEx' of current locator on load */
		onMounted(() =>{
			reloadData();
			getPositionTables();
			getAllDocumentClasses();
		})

		/** Saves an Value from RegEx */
		const saveLineItem = () => {
			if (isValidationInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			showErrorMessage.value = false;
			saveLoading.value = true;

			// make sure locatorId always right
			lineItemLocatorValue.locatorId = props.locatorId;

			locatorService.putLineItemLocatorDetails(props.locatorId, lineItemLocatorValue)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					saveLoading.value = false;
				})
		}

		return {loading, saveLoading, showErrorMessage, isValidationInvalid, documentTables, lineItemLocatorValue, documentClasses, onUpdate, saveLineItem, getPositionTables};
	},
});

</script>

<style scoped>

</style>
