
import {Options, Vue} from "vue-class-component";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {LocatorSetting} from "@dex/squeeze-client-ts";
import LocatorAttributes from "@/apps/administration/components/locators/LocatorAttributes.vue";
import LocatorAttributeForm from "@/apps/administration/components/locators/LocatorAttributeForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import BlockUI from 'primevue/blockui';

@Options({
	name: 'LocatorAttributesSubView',
	components: {
		LocatorAttributes, LocatorAttributeForm, EntryDialog, DialogDelete, BlockUI,
	},
	props: {
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['onLocatorChange'],
})

export default class LocatorAttributesSubView extends Vue {

	/** Currently active Locator */
	locatorId!: number

	/** List of all locators **/
	locatorSettings?:  LocatorSetting[] = [];

	/** One Entry of Locator Settings */
	entryFieldAttribute?: LocatorSetting;

	/** List of all valid locators-setting **/
	supportedLocatorSettings?: LocatorSetting[] = []

	/** Service for Document-Classes */
	locatorService = ClientManager.getInstance().squeeze.locator

	/** Should the loading be shown in the table? */
	loading = false

	/** Should the Edit-Dialog be shown? */
	showEditDialog = false

	/** Message To Show in Entry-Dialogs */
	message = ''

	/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
	messageType = 'none'

	/** Text of the header in Entry-Dialog */
	headerText = ''

	/** show Delete Dialog? */
	showDeleteDialog = false

	/** Should the display-name be shown as disabled? */
	disableSetting = false

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	mounted() {
		this.getLocatorAttributes()
	}

	/**
	 * Gets the Locator-Attributes
	 */
	getLocatorAttributes() {
		this.loading = true
		const handleErr = (msg: string, err: any) => ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t(msg) + ": " + err);

		// Get Supported Locator Settings for current locator
		this.locatorService.getLocatorById(this.locatorId)
			.then((data) => {
				this.locatorService.getSupportedLocatorTypeSettings(data.locatorType!)
					.then((data) => {
						this.supportedLocatorSettings = data;
					}).catch(response => response.json().then ((err: any) => {
						handleErr('Squeeze.Locators.ErrorLoadLocatorsAttributes', err.message)
					}))
			})
			.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))

		this.locatorService.getLocatorSettings(this.locatorId)
			.then((data: any) => {
				this.loading = false
				this.locatorSettings = data
			})
			.catch(response => response.json().then ((err: any) => {
				this.loading = false
				handleErr('Squeeze.Locators.ErrorLoadLocatorsAttributes', err.message)
			}))
	}

	/**
	 * Opens the Delete-Dialog
	 */
	openDeleteDialog(locatorSetting: LocatorSetting) {
		this.entryFieldAttribute = locatorSetting
		this.showDeleteDialog = true
	}

	/**
	 * Opens the Edit-Dialog
	 * @param locatorSetting Locator-Attribute to show in the Edit-Dialog
	 */
	openEditDialog(locatorSetting: LocatorSetting) {
		this.entryFieldAttribute = locatorSetting
		this.entryFieldAttribute.locatorId = this.locatorId;
		if (this.entryFieldAttribute.setting && this.entryFieldAttribute.setting !== "") {
			this.disableSetting = true;
			this.headerText = this.$t('Squeeze.General.ChangeEntry', { entryName: this.$t('Squeeze.Locators.LocatorAttribute') })
		}
		else {
			this.disableSetting = false;
			this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.Locators.LocatorAttribute') })
		}
		this.showEditDialog = true
	}

	/**
	 * Saves a Locator Attribute
	 */
	saveLocatorAttribute() {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		this.showErrorMessage = false;
		this.loading = true;

		if (this.entryFieldAttribute && this.entryFieldAttribute.id) {
			this.locatorService.putLocatorSetting(this.locatorId, this.entryFieldAttribute.id, this.entryFieldAttribute)
				.then(() => {
					this.showEditDialog = false
					this.getLocatorAttributes()
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.SaveError'), err.message)
				}))
				.finally(() => {
					this.loading = false;
				})
		} else {
			this.locatorService.postLocatorSetting(this.locatorId, this.entryFieldAttribute)
				.then(() => {
					this.showEditDialog = false
					this.getLocatorAttributes()
					ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.CreateSuccess'))
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.SaveError'), err.message)
				}))
				.finally(() => {
					this.loading = false;
				})
		}
	}

	/** Deletes a Locator-Attribute */
	deleteLocatorAttribute() {
		this.loading = true;
		if (this.entryFieldAttribute && this.entryFieldAttribute.id) {
			this.locatorService.deleteLocatorSetting(this.locatorId, this.entryFieldAttribute.id, this.entryFieldAttribute).then(() => {
				this.getLocatorAttributes()
				this.showDeleteDialog = false
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.ErrorDelete'), this.$t('Squeeze.General.DeleteError') + ": " + err.message)
			})).finally(() => {
				this.loading = false;
			});
		}
	}

	/** Triggered on update of attribute-form */
	onUpdate(data: LocatorSetting, valid: boolean) {
		this.isInvalid = valid;
		Object.assign(this.entryFieldAttribute, data);
	}

}
