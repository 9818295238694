<template>
	<div class="p-component sidenav" :class="{'sidenav-open': opened}">
		<div class="sidenav-block p-mt-4">
			<div>
				<router-link :to="{ name: 'SqueezeDashboard', params: 'de'}" style="text-decoration: none; color: white;">
					<i v-if="!opened" class="mdi mdi-blur" style="padding-bottom: 2.4rem; color: var(--dex-primary-color);"></i>
					<h1 v-else class="p-text-bold" style="margin: 0 0 1rem 0">DEX</h1>
				</router-link>
			</div>
			<div class="links">
				<router-link
					v-for="link in accessibleLinks" :key="link.to" :to="link.to"
					v-slot="{ isActive, isExactActive }"
				>
					<span :class="
						[
							(link.label === 'Admin' && isActive) && opened ? 'link-active-border' : '',
							(link.label !== 'Admin' && isExactActive) && opened ? 'link-active-border' : '',
							'link'
						]"
					>
						<i v-if="link.label === 'Admin' && store.state.errorMigrations.length > 0" :class="[(link.label === 'Admin' && isActive) || (link.label !== 'Admin' && isExactActive) ? 'link-active-icon' : '', link.icon]" v-badge.danger></i>
						<i v-else :class="[(link.label === 'Admin' && isActive) || (link.label !== 'Admin' && isExactActive) ? 'link-active-icon' : '', link.icon]"></i>
						<transition name="fade">
							<p v-if="opened">{{link.label}}</p>
						</transition>
					</span>
				</router-link>
			</div>
			<Button @click="openUploadDialog" :label="opened ? $t('Squeeze.General.Upload') : ''" icon="mdi mdi-cloud-upload-outline" class="upload-button p-button-text" :style="opened ? 'padding-left: 0;' : ''" />

			<!--Button v-if="customActions.length" :label="opened ? $t('Squeeze.CustomAction.Actions') : ''" icon="mdi mdi-developer-board" class="upload-button p-button-text" @click="toggleCustomActions" type="button" aria-haspopup="true" aria-controls="overlay_tmenu" /-->
			<!--TieredMenu id="overlay_tmenu" ref="customActionsMenu" :model="customActions" :popup="true" /-->
		</div>

		<div class="sidenav-block p-mb-4">
			<!--div class="links">
				<router-link v-for="endLink in endLinks" :key="endLink.to" :to="endLink.to" class="isDisabled p-disabled">
						<i :class="endLink.icon"></i>
						<transition name="fade">
							<p v-if="opened" class="link-active-text">{{endLink.label}}</p>
						</transition>
				</router-link>
			</div-->
			<Avatar :label="loggedInUserInitials" @click="toggleUserInfo" :class="[opened ? 'avatar-opened' : 'avatar-closed', 'avatar']" size="large" shape="circle" :haspopup="true" aria-controls="overlay_panel" />
			<OverlayPanel ref="op" id="overlay_panel">
				<div class="p-grid p-flex-column">
					<div class="p-col">
						<div class="p-d-flex p-jc-between">
							<div>
								<p class="userinfo p-text-bold">
									<span>{{store.state.user && store.state.user.firstName}} {{store.state.user && store.state.user.lastName}}</span>
								</p>
								<p class="userinfo email">{{store.state.user && store.state.user.email}}</p>
								<p class="userinfo client-title">{{ store.state.systemInformation.tenantName }}</p>
							</div>
							<div class="p-as-center">
								<Button v-if="store.state.featureSet.newsSidebar" v-tooltip="$t('Squeeze.General.News.News')" class="p-button p-button-rounded p-button-text" icon="mdi mdi-bell-outline" @click="showNewsSidebar = !showNewsSidebar" />
								<Button v-tooltip="$t('Squeeze.UserSettings.Settings')" class="p-button p-button-rounded p-button-text" icon="mdi mdi-cog" @click="showSettingDialog = !showSettingDialog" />
							</div>
						</div>
					</div>
					<div class="p-col">
						<Dropdown class="p-ml-auto language-dropdown" v-model="selectedLanguage" :options="languages" optionLabel="description" optionValue="countryCode" :placeholder="$t('Squeeze.Language.Selection')" @change="changeLanguage">
							<template #option="slotProps">
								<div v-tooltip.bottom="!SupportedLangs.includes(slotProps.option.countryCode) ? $t('Squeeze.General.LanguageNotAvailable') : ''">
									<span>{{slotProps.option.description}}</span>
									<i v-if="!SupportedLangs.includes(slotProps.option.countryCode)" class="mdi mdi-alert-outline icon-error"></i>
								</div>
							</template>
						</Dropdown>
					</div>
					<div class="p-col">
						<Button class="p-button-sm p-mb-2 p-button-outlined logout-button" :label="$t('Squeeze.Login.ChangePassword')" icon="mdi mdi-lock-reset" @click="openResetPasswordDialog"/>
						<Button class="p-button-sm p-button-outlined logout-button" :label="$t('Squeeze.Login.Logout')" icon="mdi mdi-logout-variant" @click="logout"/>
					</div>
				</div>
			</OverlayPanel>
			<Button @click="onCollapseSidebar" class="p-button-sm p-mt-3" :label="opened ? $t('Squeeze.General.SidenavButton') : ''" :icon="opened ? 'mdi mdi-chevron-double-left' : 'mdi mdi-chevron-double-right'"/>
		</div>
	</div>

	<EntryDialog
		:show="showDialog"
		@onClose="showDialog = false"
		:headerText="$t('Squeeze.Login.ChangePassword')"
		:showSaveButton="false"
		:showAbortButton="false"
	>
		<template #content>
			<ResetPasswordForm @onClickSend="resetPassword" :loading="loadingDialog" />
		</template>
	</EntryDialog>

	<EntryDialog
		:show="showUploadDialog"
		@onClose="showUploadDialog = false, $emit('isUploadDialogShown', false);"
		:headerText="$t('Squeeze.General.Upload')"
		:showSaveButton="false"
		:showAbortButton="false"
	>
		<template #content>
			<DocumentUpload/>
		</template>
	</EntryDialog>

	<Dialog v-model:visible="showSettingDialog" style="width: 60rem;" contentClass="setting-dialog" :modal="true" :showHeader="false">
		<UserSettings :loggedInUserInitials="loggedInUserInitials" @clickSettingDialog="clickSettingDialog"/>
	</Dialog>

	<Sidebar v-model:visible="showNewsSidebar" position="right" class="p-sidebar-md" :modal="true" :showCloseIcon="true" dismissable>
		<News/>
	</Sidebar>
</template>

<script lang="ts">
import {RouteLocationRaw} from "vue-router";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Avatar from 'primevue/avatar';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Sidebar from 'primevue/sidebar';
import {DEFAULT_LOCALE, getActiveLanguage, SupportedLangs} from "@/lang";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {computed, defineComponent, onMounted, PropType, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import {useStore} from "@/store";
import ResetPasswordForm from "@/components/ResetPasswordForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import DocumentUpload from "@/apps/squeeze/components/DocumentUpload.vue";
const activeLanguage = getActiveLanguage();
import BadgeDirective from 'primevue/badgedirective';
import UserSettings from "@/views/UserSettings.vue";
import News from "@/views/News.vue";
//import TieredMenu from 'primevue/tieredmenu';
import {ScriptDto, TranslationCountry} from "@dex/squeeze-client-ts";

export interface Link {
	label: string;
	icon: string;
	isAdminRoute: boolean;
	to: RouteLocationRaw;
}

export interface CustomAction extends ScriptDto {
	label?: string;
	icon?: string;
	command?: any;
}

export default defineComponent({
	name: "VerticalNavbar",
	components: {
		UserSettings,
		News,
		Button,
		Avatar,
		OverlayPanel,
		Dialog,
		Dropdown,
		ResetPasswordForm,
		EntryDialog,
		DocumentUpload,
		Sidebar,
	},
	props: {
		links: {
			type: Array as PropType<Link[]>,
			default: [],
		},
	},
	directives: {
		badge: BadgeDirective,
	},
	emits: ['onCollapseSidebar', 'userLoggedOut', 'isUploadDialogShown'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		const { locale } = useI18n({ useScope: 'global' })
		const store = useStore();
		const op = ref<InstanceType<typeof OverlayPanel>>()

		const opened = ref(false);
		const endLinks= ref<Link[]>([
			{
				label: "Upload",
				icon: "mdi mdi-cloud-upload-outline",
				isAdminRoute: false,
				to: {
					name: "Home",
					params: { lang: activeLanguage },
				},
			},
		]);

		/** Currently selected language */
		const selectedLanguage = ref<string | null>(null);

		/** Languages for UI */
		const languages = ref<TranslationCountry[]>([]);

		/** Access Links for UI */
		const accessibleLinks = computed(() => {
			if(store.state.scopes.sqzAdminView) {
				return props.links;
			} else {
				return props.links!.filter(link => !link.isAdminRoute);
			}
		});

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Public API endpoint */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Validation API endpoint */
		//const validationApi = ClientManager.getInstance().squeeze.validation;

		/** Currently logged in user initials */
		const loggedInUserInitials = ref ("");

		/** Currently logged in user is administrator? */
		const loggedInUserIsAdmin = ref(false);

		/** Should the dialog be shown? */
		const showDialog = ref(false);

		/** Should the dialog upload be shown? */
		const showUploadDialog = ref(false);

		/** Should the dialog of settings be shown? */
		const showSettingDialog = ref<boolean>(false);

		/** Should the sidebar of news be shown? */
		const showNewsSidebar = ref<boolean>(false);

		/** Should the loading be shown in the dialog? */
		const loadingDialog = ref(false);

		/** Menu item for custom actions */
		const customActions = ref<ScriptDto[]>([]);

		/** Ref of custom action menu */
		const customActionsMenu = ref();

		/** Returns the initials of first and last name*/
		const getInitialsOfName = (firstName: string | undefined, lastName: string | undefined) => {
			let initials = "";
			if(firstName && firstName.length > 0) {
				initials = firstName.slice(0,1).toUpperCase();
			}

			if(lastName && lastName.length > 0) {
				initials += lastName.slice(0,1).toUpperCase();
			}
			return initials;
		}

		/** Set current Language */
		const setLanguage = (language: TranslationCountry | undefined) => {
			if (language && language.countryCode) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
			} else {
				locale.value = DEFAULT_LOCALE;
				selectedLanguage.value = DEFAULT_LOCALE;
			}
		}

		/** Check Language by first load */
		const checkLanguage = () => {
			if (localStorage.getItem('language')) {
				const language = languages.value.find(language => language.countryCode ===  localStorage.getItem('language'));
				setLanguage(language);
			} else {
				// Check browser language if no local storage language
				const language = languages.value.find(language => language.countryCode === navigator.language.substring(0, 2));
				setLanguage(language);
				localStorage.setItem('language', locale.value);
			}
		}

		/** Get all languages for UI */
		const getLanguages = () => {
			publicApi.getActiveCountries(true)
				.then((data: TranslationCountry[]) => {
					if (data.length === 0) {
						throw new Error("Missing languages in list!");
					}
					languages.value = data;
				}).catch(() => {
					// set default languages
					languages.value = [
						{description: t('Squeeze.Language.German'), countryCode: 'de'},
						{description: t('Squeeze.Language.English'), countryCode: 'en'},
					];
				}).finally(() => {
					checkLanguage();
				})
		}

		/** Get Custom Actions */
		/*const getCustomActions = () => {
			validationApi.getCustomValidationActions()
				.then((scripts: ScriptDto[]) => {
					const customScripts: CustomAction[] = scripts.filter(script => script.type === 'custom-global');
					if (customScripts) {
						// get custom action as menuItem object
						const allActions: CustomAction[] = [];
						customScripts.map((action: CustomAction) => {
							allActions.push({
								label: action.description,
								icon: 'mdi mdi-square-small',
								command: () => { console.log(action.name) },
							})
						})

						customActions.value = allActions;
					}
				}).catch((response: any) => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}));
		}*/

		onMounted(() => {
			userApi.getLoggedInUser()
				.then((user) => {
					loggedInUserInitials.value = getInitialsOfName(user.firstName, user.lastName);
					return ClientManager.getInstance().isAdminUser();
				}).then((isAdminUser) => {
					loggedInUserIsAdmin.value = isAdminUser
				}).catch((response: any) => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))

			// check current language before get all languages from api
			if (localStorage.getItem('language')) {
				locale.value = localStorage.getItem('language')!;
			}
			getLanguages();
			//getCustomActions();
		})

		/** Triggered when a row is selected */
		const onCollapseSidebar = () => {
			opened.value = !opened.value;
			emit("onCollapseSidebar");
		}

		/**
		 * Toggle custom actions
		 * @param event
		 */
		const toggleCustomActions = (event: any) => {
			customActionsMenu.value.toggle(event);
		}

		/** Toggle user info at avatar */
		const toggleUserInfo = (event: any) => {
			op.value?.toggle(event);
		}

		/** Triggered when a language is selected */
		const changeLanguage = () => {
			const language = languages.value.find(language => language.countryCode === selectedLanguage.value);
			if (language && language.countryCode && locale.value !== selectedLanguage.value) {
				locale.value = language.countryCode;
				selectedLanguage.value = language.countryCode;
				location.reload();
			}
		}

		/** Triggered when clicked for user logout */
		const logout = () => {
			userApi.logout()
				.then(() => {
					ToastManager.showSuccess(toast, t("Squeeze.Login.LogoutSuccessful"))
					store.commit("setLogin", false);
					ClientManager.getInstance().clearPHPSessionCookie();
					ClientManager.getInstance().resetKeyCloakTokens();
				})
				.catch((err) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
				})
				.finally(() => {
					store.dispatch("resetStore");
					emit('userLoggedOut');
					router.push({name: "Login", params: { lang: activeLanguage}})
				});
		}

		/**
		 * Opens the Password Dialog
		 */
		const openResetPasswordDialog = () => {
			showDialog.value = true;
		}

		/** Sends a mail to reset the password to a specific user */
		const resetPassword = (password: string) => {
			loadingDialog.value = true;
			userApi.resetPasswordByUser(store.state.user!.id!, password).then(() => {
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Login.ResetSuccess'));
				showDialog.value = false;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
			})
		}

		/** Click on close button in Setting-Dialog */
		const clickSettingDialog = (closeSettingDialog: boolean) => {
			showSettingDialog.value = closeSettingDialog;
		}

		/** Triggered the click on upload button */
		const openUploadDialog = () => {
			showUploadDialog.value = true;
			emit('isUploadDialogShown', true);
		}

		return {
			opened, op, endLinks, accessibleLinks, loggedInUserInitials, selectedLanguage, languages, showDialog, showSettingDialog, showNewsSidebar, showUploadDialog, loadingDialog, customActions, customActionsMenu, store, SupportedLangs,
			openResetPasswordDialog, resetPassword, onCollapseSidebar, toggleUserInfo, changeLanguage, logout, clickSettingDialog, openUploadDialog, toggleCustomActions,
		}
	},
});
</script>

<style lang="scss" scoped>

.sidenav {
	height: 100%;
	width: 4.688rem;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow-x: hidden;
	transition: 0.5s;
	background-color: var(--surface-800);
	color: white;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: stretch;
}

.sidenav-open {
	width: 9.375rem;
}

.sidenav-block {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.link {
	padding: 0.25rem 0;
}

.link-active-icon {
	color: var(--dex-primary-light-color);
}

.link-active-border {
	color: var(--dex-primary-light-color);
	border-left: 0.25rem  var(--dex-primary-light-color) solid;
	transition: border-left 0.25s ease-out;
	padding-left: 0.5rem;
}

.avatar-opened {
	transition: 0.5s ease-out;
}

.avatar-closed {
  transition: 0.5s ease-out;
}

.avatar {
  background-color: var(--dex-secondary-dark-color);

  &:hover {
    cursor: pointer;
  }
}

::v-deep(span.p-avatar-text) {
  color: var(--dex-text-color);
}

::v-deep(.userinfo) {
  margin: 0.25rem;
}

::v-deep(.email) {
  word-wrap: break-word;
  color: #2c3e50;
}

::v-deep(.p-overlaypanel-content) {
	padding:0.5rem!important;
}

.links {
	margin: 0;

	a, h1 {
		display: block;
		text-decoration: none;

		// FIXME: Remove hardcoded and replace with inverted of background color
		color: white;

		&:hover {
			// TODO: Use theme color
			//color: #f1f1f1;
			color: var(--dex-primary-color);
		}
	}

	a {
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
	}

	p {
		display: inline-block;
		margin: 0;
		padding-left: 0.3rem;
	}
}

.language-dropdown {
	display: flex;
	padding-bottom: 0;
}

::v-deep(.p-dropdown .p-inputtext) {
	padding: 0.5rem 0.5rem;
}

.logout-button {
	width: 100%;
}

// Upload Button
.p-button.p-button-text.upload-button {
	color: var(--surface-a);
	background-color: transparent;
}

.p-button.p-button-text:hover {
	color: var(--primary-color) !important;
	background-color: transparent !important;
}

.p-button.p-button-text:focus {
	color: var(--primary-color) !important;
	background-color: transparent !important;
}

// Disable endLinks
.isDisabled {
	color: currentColor;
	cursor: not-allowed;
	opacity: 0.5;
}

// Transition
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

::v-deep(.p-badge) {
  min-width: 0.5rem;
  height: 0.5rem;
}

.client-title {
  opacity: 0.5;
}

::v-deep(button.p-button.p-component.p-button-sm.p-mt-3) {
  color: var(--dex-text-color);
}


.icon-error {
	color: var(--error-color);
	float: right;
}

</style>
