<template>
	<DataTable
		:value="roles"
		:loading="loading"
		class="p-datatable-sm editable-cells-table"
		:autoLayout="true"
		columnResizeMode="fit"
		v-model:selection="selection"
		:selectionMode="selectionMode"
		@row-select="onRowSelect($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.UserManagement.Roles') }) }}
		</template>

		<Column field="id" header="ID" :sortable="false" class="id-column" style="min-width: 4rem; max-width: 6rem;" />
		<Column field="title" :header="$t('Squeeze.BatchClasses.Name')" :sortable="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.title">
					{{slotProps.data.title}}
				</div>
			</template>
		</Column>
		<Column field="description" :header="$t('Squeeze.BatchClasses.Description')" :sortable="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
					{{slotProps.data.description}}
				</div>
			</template>
		</Column>
		<Column field="isInherited" :header="''" :sortable="false" style="min-width: 4rem; max-width: 6rem;">
			<template #body="user">
				<template v-if="user.data.isInherited">
					{{ $t('Squeeze.UserManagement.Inherited') }}
				</template>
			</template>
		</Column>
		<!-- Delete -->
		<Column class="buttonColumn" style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="role">
				<Button :class="{ invisible: role.data.isInherited }"
						:disabled="AdminRoleIds.includes(role.data.id) && selectionMode"
						icon="mdi mdi-delete-outline"
						v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')"
						class="p-button-lg p-button-rounded p-button-danger p-button-text"
						@click="openDeleteDialog(role.data)"
				/>
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
			<Button v-if="route.name === 'UserTabsView' && userId !== 1" :label="$t('Squeeze.UserManagement.TakeOverRoles')" type="button" icon="mdi mdi-account-key-outline" class="p-button-text" @click="showTakeOverRolesDialog = true" />
		</template>
		<template #paginatorRight></template>
	</DataTable>

	<EntryDialog
		:show="showTakeOverRolesDialog"
		@onClose="showTakeOverRolesDialog = false"
		@onConfirm="executeTakeOverRoles"
		:loading="loadingTakeOverRoles"
		:headerText="headerTextOfTakeOverRoles"
		:showKeepDialogOpen="false"
	>
		<template #content>
			<UserTakeOverRolesView :userId="userId" :usersTakeOverRoles="usersTakeOverRoles" :showErrorMessage="showValidationMessage" @update="onUpdate"  />
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import EntryDialog from "@/components/EntryDialog.vue";
import {defineComponent, onMounted, PropType, reactive, ref} from "vue";
import {UserRole} from "@dex/squeeze-client-ts";
import {AdminRoleIds} from "@/apps/administration/views/squeeze/roles/RolesListView.vue";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import UserTakeOverRolesView from "@/apps/administration/views/squeeze/users/tabs/UserTakeOverRolesView.vue";
import {useRoute} from "vue-router";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {useToast} from "primevue/usetoast";
import useStore from "@/store";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "RolesList",
	components: {
		DataTable, Column, EntryDialog, UserTakeOverRolesView,
	},
	props: {
		roles: {
			type: Array as PropType<UserRole[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		selectionMode: {
			type: String,
			default: 'single',
		},
		userId: {
			type: Number,
			default: null,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "reloadRolesList",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Currently-Selected row */
		const selection = ref(null);

		/** Show the loading for Save-Button of take over roles? */
		const loadingTakeOverRoles = ref(false);

		/** Should the Entry-Dialog for take over roles be shown? */
		const showTakeOverRolesDialog = ref<boolean>(false);

		/** Text of the header in take over roles Entry-Dialog */
		const headerTextOfTakeOverRoles = ref<string>(t('Squeeze.UserManagement.TakeOverRoles'));

		/** Is the Form invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Object with current users for take over roles */
		const usersTakeOverRoles = reactive<any>({
			userIdFrom: null,
			userIdTo: props.userId,
		});

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: UserRole) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				title: '',
				description: '',
			})
		}

		/** Triggered when a row is selected */
		const onRowSelect = (data: UserRole) => {
			emit("onEntrySelect", data)
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: any, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(usersTakeOverRoles, data);
		}

		/**
		 * Execute the take over roles
		 * @param keepDialogOpen
		 */
		const executeTakeOverRoles = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingTakeOverRoles.value = true;

			userService.transferUserRoles(usersTakeOverRoles.userIdFrom, usersTakeOverRoles.userIdTo)
				.then(() => {
					if (!keepDialogOpen) {
						showTakeOverRolesDialog.value = false;
					}
					else {
						isValidationInvalid.value = true;
					}
					// Empty value
					usersTakeOverRoles.userIdFrom = null;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserManagement.SuccessRolesTransfer'));
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingTakeOverRoles.value = false;
					emit("reloadRolesList");
				})
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userService, route.name, event.rows);
		}

		return {
			t,
			toast,
			route,
			store,
			tableListPagination,
			selection,
			loadingTakeOverRoles,
			showTakeOverRolesDialog,
			headerTextOfTakeOverRoles,
			isValidationInvalid,
			showValidationMessage,
			usersTakeOverRoles,
			openDeleteDialog,
			onNewEntryClick,
			onRowSelect,
			AdminRoleIds,
			onUpdate,
			executeTakeOverRoles,
			onChangePage,
		}
	},
});

</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

/*::v-deep(td.id-column) {
	width: 5rem;
}*/
.invisible {
	visibility: hidden;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>

