
import {Options, Vue} from 'vue-class-component';
//import { Chart } from 'chart.js';
import Chart from 'chart.js/auto';
import moment from "moment";
import Dropdown from "primevue/dropdown";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useStore} from "@/store";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: "DocumentReportProcessed",
	components: {
		Chart,
		Dropdown,
	},
	props: {
		months: {
			type: Array,
		},
		years: {
			type: Array,
		},
	},
	watch: {
		'$i18n.locale': function() {
			this.months.splice(0);
			this.getMonths();

			this.selectedMonth = {name: moment().format("MMMM"), value: this.currentMonth};
			this.dataSetLabel = this.selectedMonth.name + ' ' + this.selectedYear.name;
			const presentChart = Chart.getChart("myChartProcessed");
			if(presentChart) {
				presentChart.destroy();
				this.createChart();
			}
		},
	},
})
export default class DocumentReportProcessed extends Vue {

	myChartProcessed!: Chart;
	currentYear: number = moment().year();
	currentMonth: number | undefined = moment().month() +1;

	selectedMonth = {name: moment().format("MMMM"), value: this.currentMonth};
	selectedYear = {name: this.currentYear.toString(), value: this.currentYear};

	months: any[] = [];
	years: any[] = [];

	chartData: number[] = [];
	chartLabels: string[] = [];
	dataSetLabel = '';

	/** Store point */
	store = useStore();

	queueAPI = ClientManager.getInstance().squeeze.queue;

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	mounted() {
		this.getChartData();

		this.getMonths();

		for (let i = 0; i < 31; i++) {
			const year = moment().add(-i, 'years').format('YYYY')
			this.years.push({name: year, value: parseInt(year)})
		}
	}

	/** Get all Months in current language */
	getMonths() {
		const months = moment.months();
		for (let i = 0; i < months.length; i++) {
			const month = months[i];
			this.months.push({name: month, value: i +1});
		}
	}

	createChart() {
		const canvas = document.getElementById('myChartProcessed') as HTMLCanvasElement;
		const ctx = canvas.getContext('2d');
		const currentColor = window.getComputedStyle(canvas).getPropertyValue('--dex-secondary-dark-color');
		this.myChartProcessed = new Chart(ctx, {
			type: 'line',
			data: {
				labels: this.chartLabels,
				datasets: [{
					label: 'Report ' + this.dataSetLabel,
					data: this.chartData,
					borderWidth: 3,
					borderColor: currentColor,
					backgroundColor: currentColor,
				}],
			},
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
		});
	}

	/**
	 * Triggered when a Month is changed
	 * */
	async getChartData() {
		try {
			let queueReportData = null;
			if(this.currentMonth != undefined) {
				queueReportData = await this.queueAPI.getQueueReportProcessed(this.currentYear, this.currentMonth);
			} else {
				queueReportData = await this.queueAPI.getQueueReportProcessed(this.currentYear);
			}

			// Build labels for x axis
			const labels: string[] = [];
			for (const i of queueReportData.labels!) {
				if (queueReportData.labels!.length <= 13) {
					labels.push(moment(i, "MM").format("MMM"));
				} else {
					labels.push(String(i));
				}
			}
			this.chartLabels = labels;

			// Build data
			if(queueReportData.datasets && queueReportData.datasets.length > 0) {
				if(queueReportData.datasets[0] && queueReportData.datasets[0].data && queueReportData.datasets[0].data.length > 0) {this.chartData = queueReportData.datasets[0].data;}
			}

			// Build Year and Month format
			if (this.currentMonth == null) {
				this.currentYear = parseInt(moment(this.currentYear, "YYYY").format("YYYY"));
			} else {
				this.currentYear = parseInt(moment(this.currentYear && this.currentMonth, "YYYY MM").format("MMMM YY"));
			}

			// Build Chart
			const presentChart = Chart.getChart("myChartProcessed");
			if(presentChart) {
				presentChart.destroy();
				this.createChart();
			} else {
				this.dataSetLabel = this.selectedMonth.name + ' ' + this.selectedYear.name;
				this.createChart();
			}
		} catch (err: any) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.statusText);
		}
	}

	/**
	 * Triggered when a Month is delete
	 * */
	loadYearReport() {
		this.currentYear = this.selectedYear.value;
		this.currentMonth = undefined;
		this.dataSetLabel = this.selectedYear.name;
		this.getChartData();
	}

	loadMonthReport() {
		this.currentYear = this.selectedYear.value;
		this.currentMonth = this.selectedMonth.value;
		this.dataSetLabel = this.selectedMonth.name + ' ' + this.selectedYear.name;
		this.getChartData();
	}

	/**
	 * Triggered when a Month is changed
	 * */
	onChangeMonths() {
		if (this.selectedMonth) {
			this.loadMonthReport();
		} else {
			this.loadYearReport();
		}
	}

	/**
	 * Triggered when a Year is changed
	 * */
	onChangeYears() {
		this.currentYear = this.selectedYear.value;
		this.dataSetLabel = this.selectedYear.name;
		this.getChartData();
	}

}

