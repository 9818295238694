<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="interfaceName">{{$t('Squeeze.DocumentClasses.Type')}} *</label>
			<Dropdown
				id="interfaceName"
				style="min-width: 15.625rem"
				v-model="value.interfaceName"
				:options="allExportInterfaces"
				optionValue="id"
				optionLabel="name"
				:filter="true"
				:class="{'p-invalid':v$.interfaceName.$invalid && showErrorMessage}"
				@change="update"
				:disabled="!!value.id"
				@show="showDropdownOverlay"
			/>
			<small v-if="(!v$.interfaceName.$model && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Type')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.DocumentClasses.Description')}} *</label>
			<InputText id="description" v-model.trim="value.description" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="v$.description.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Description')}) }}
			</small>
		</div>
		<template v-for="(property, index) in getExportInterfaceProperties(value.interfaceName)"  v-bind:key="index">
			<div class="p-field" :class="property.id === 'SharePointExportFolderStructure' ? 'p-col-12' : 'p-col-6'">
				<label :for="property.id" v-if="property.optional">{{property.name}}</label>
				<label :for="property.id" v-else>{{property.name}} *</label>

				<InputText v-if="property.type === 'string' && property.optional" :id="property.id" v-model.trim="value.configuration[property.id]" @change="update"  />
				<InputText v-else-if="property.type === 'string' && !property.optional" :id="property.id" v-model.trim="value.configuration[property.id]" @change="update" />

				<Password v-if="property.type === 'password'" :id="property.id" v-model.trim="value.configuration[property.id]" :feedback="false" @change="update" toggleMask />
				<Dropdown
					v-if="property.type === 'select' && property.optional && property.id !== 'SharePointExportFolderStructure'" style="min-width: 15.625rem"
					v-model.trim="value.configuration[property.id]"
					:options="property.options"
					optionValue="id"
					optionLabel="name"
					@change="update"
				/>
				<Dropdown
					v-else-if="property.type === 'select' && !property.optional && property.id !== 'SharePointExportFolderStructure'" style="min-width: 15.625rem"
					v-model.trim="value.configuration[property.id]"
					:options="property.options"
					optionValue="id"
					optionLabel="name"
					@change="update"
				/>
				<MultiSelect
					v-else-if="property.id === 'SharePointExportFolderStructure'"
					style="min-width: 15.625rem"
					v-model="value.configuration[property.id]"
					:options="property.options"
					optionValue="id"
					optionLabel="name"
					display="chip"
					:filter="true"
					:closeIcon="true"
					@change="update"
					@show="showDropdownOverlay"
				/>
				<small v-if="!property.optional && (!helpers.req(value.configuration[property.id])) && showErrorMessage" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: property.name}) }}
				</small>
			</div>
		</template>
		<div class="p-field p-col-12">
			<label for="active">{{$t('Squeeze.DocumentClasses.Active')}}</label><br/>
			&nbsp;&nbsp;<Checkbox id="active" v-model="value.active" :binary="true" />
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {ExportInterfaceDocumentationDto, ExportInterfaceDto} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Password from "primevue/password";
import MultiSelect from "primevue/multiselect";
import {ExportInterfacePropertyDocumentationDto} from "@dex/squeeze-client-ts/api";
import { helpers } from '@vuelidate/validators';
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: 'ExportInterfaceForm',
	components: {
		Checkbox, InputText, Dropdown, Password, MultiSelect,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<ExportInterfaceDto>,
			default: {},
			required: true,
		},
		allExportInterfaces: {
			type: Array as PropType<ExportInterfaceDocumentationDto[]>,
			default: [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['onChangeCheckbox', 'update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<ExportInterfaceDto>(props.fieldEntry);

		/** Determines the required rules for validation */
		const rules = {
			interfaceName: { required },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/**
		 * Gets the properties for an Export-Interface
		 * @param name Name of the Export-Interface
		 */
		const getExportInterfaceProperties = (name: string) => {
			const exportInterface = props.allExportInterfaces.find(i => i.id === name);

			if (exportInterface) {
				return exportInterface.properties;
			}

			return [];
		}

		/**
		 * Checks if the the Export-Interfaces Values are valid. Because of the generous structure, this can't be done
		 * with vuelidate
		 */
		const areExportInterfaceValuesInValid = (): boolean => {
			const properties = getExportInterfaceProperties(value.interfaceName);
			let isInvalid = false
			properties?.forEach((property: ExportInterfacePropertyDocumentationDto) => {
				// Only non-optional-values can be invalid
				if (!property.optional) {
					// Use Vuelidates default-required-check here
					if (!helpers.req(value.configuration[property.id!])) {
						isInvalid = true;
					}
				}
			})

			return isInvalid;
		}

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid || areExportInterfaceValuesInValid());
		});

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid || areExportInterfaceValuesInValid());
		}

		return {
			t,
			v$,
			value,
			getExportInterfaceProperties,
			update,
			areExportInterfaceValuesInValid,
			helpers,
			showDropdownOverlay,
		}
	},
});
</script>

<style scoped>

</style>

