
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import {defineComponent, nextTick, onMounted, PropType, reactive} from "vue";
import {LocatorKeyword} from "@dex/squeeze-client-ts";
import {helpers} from "@vuelidate/validators";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "KeyWords",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		keywords: {
			type: Array as PropType<LocatorKeyword[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onChangeKeyword",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<LocatorKeyword>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: LocatorKeyword) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {id: 0, locatorId: 0, keyword: ''})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: LocatorKeyword) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Check the keyword is invalid
		 * @param row The row to check if invalid
		 */
		const isInvalidKeyword = (row: LocatorKeyword) => {
			return !helpers.req(row.keyword)
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * (change-Event in editing cell doesn't work in firefox)
		 * @param event
		 */
		const onCellComplete = (event: any) => {
			if (event.type === 'outside') {
				emit("onChangeKeyword", event.data, isInvalidKeyword(event.data));
			} else if (entryDataCheck.keyword !== event.data.keyword) {
				emit("onChangeKeyword", event.data, isInvalidKeyword(event.data));
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, entryDataCheck, openDeleteDialog, onNewEntryClick, openEditDialog, isInvalidKeyword, onCellEdit, onCellComplete, onChangePage,
		}
	},
});

