<template>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-6 p-jc-end">
			<SelectButton v-model="activeOption" :options="options" optionLabel="value" dataKey="value" aria-labelledby="single">
				<template #option="slotProps">
					<i :class="slotProps.option.icon"></i>
					{{ slotProps.option.value }}
				</template>
			</SelectButton>
		</div>
	</div>
	<SystemCsvTranslationImport
		v-if="activeOption.value === 'Import'"
		:showErrorMessage="showErrorMessage"
		:selectedFiles="files"
		:fileTypes="fileTypes"
		@update="onUpdate"
		@fileUploader="fileUploader"
	/>
	<SystemCsvTranslationExport
		v-if="activeOption.value === 'Export'"
		:fileTypes="fileTypes"
		@startTranslationFileDownload="startTranslationFileDownload"
	/>

	<EntryDialog
		:show="showWarningDialog"
		@onClose="showWarningDialog = false"
		@onConfirm="startFileUpload"
		:headerText="$t('Squeeze.System.CreateResetTranslations')"
		:saveButtonText="$t('Squeeze.General.Execute')"
	>
		<template #content>
			<b>{{$t('Squeeze.General.Attention')}}</b>: {{ $t('Squeeze.System.CreateResetTranslationsConfirm') }}
		</template>
	</EntryDialog>

	<Dialog :header="$t('Squeeze.General.Info')" v-model:visible="showImportErrors" :modal="true">
		<Message
			:closable="false"
			severity="info"
		>
			{{ $t('Squeeze.General.SuccessButWithErrors') }}<br/>
			{{ $t('Squeeze.General.CountOfErrors', {errorNumber: errorCount}) }}
		</Message>
		<Message
			:closable="false"
			severity="error"
		>
			<template v-for="error in importErrors" v-bind:key="error">
				{{ error }}<br/>
			</template>
		</Message>
	</Dialog>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import SelectButton from 'primevue/selectbutton';
import SystemCsvTranslationImport from "@/apps/administration/components/system/SystemCsvTranslationImport.vue";
import SystemCsvTranslationExport from "@/apps/administration/components/system/SystemCsvTranslationExport.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import { UploadFile } from "@/shims-prime-vue";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "SystemCsvTranslationView",
	components: {
		SelectButton,
		SystemCsvTranslationImport,
		SystemCsvTranslationExport,
		EntryDialog,
		Dialog,
		Message,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Current active option */
		const activeOption = ref({icon: 'mdi mdi-file-export-outline', value: 'Export'});

		/** All options of a file */
		const options = ref([
			{icon: 'mdi mdi-file-export-outline', value: 'Export'},
			{icon: 'mdi mdi-file-import-outline', value: 'Import'},
		]);

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Used CSV-Separator */
		const csvSeparator = ref(';');

		/** Skip error lines? */
		const skipError = ref<boolean>(false);

		/** List with Available File-Types */
		const fileTypes = [
			{
				id: 'csv',
				description: t('Squeeze.System.CSV'),
			},
		];

		/** Used File-Type for Upload */
		const fileType = ref('csv');

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Should the reset dialog be shown? */
		const showWarningDialog = ref<boolean>(false);

		/** Should the Import Error Dialog be shown? */
		const showImportErrors = ref<boolean>(false);

		/** List of errors of import */
		const importErrors = ref<string[]>([]);

		/** Count of errors */
		const errorCount = ref<number>(0);

		/** Api for translations */
		const translationsApi = ClientManager.getInstance().squeeze.translations;

		/**
		 * Triggered on update of translation country-form
		 * @param data
		 * @param isInvalid
		 */
		const onUpdate = (data: any, isInvalid: boolean) => {
			showErrorMessage.value = isInvalid;
			csvSeparator.value = data.csvSeparator;
			skipError.value = data.skipErrors;
			fileType.value = data.fileType;
		}

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @param fileType
		 * @param csvSeparator
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: UploadFile, fileType: string, csvSeparator: string) => {
			const body = new FormData();
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() +
				"/translations/import?format=" + fileType + "&csv-separator=" + encodeURIComponent(csvSeparator) + "&skipErrors=" + skipError.value, {
				method: "POST",
				body: body,
			});

			if (response.status !== 200 && response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error("Unexpected status " + responseJSON.message);
			} else {
				const responseJSON = await response.json();
				if (responseJSON.errorLines.length > 0) {
					importErrors.value = responseJSON.errorLines;
					errorCount.value =  responseJSON.errorLineAmount;
					showImportErrors.value = true;
				}
			}
		}

		/**
		 * Starts the File upload for the given files
		 */
		const startFileUpload = () => {
			showWarningDialog.value = false;
			files.value
				.forEach((file: any, index: number) => {
					const idx = index;
					files.value[idx].error = false;
					files.value[idx].errorText = "";
					files.value[idx].loading = true;
					files.value = [...files.value];

					manualFileUpload(file, fileType.value, csvSeparator.value)
						.then(() => {
							files.value[idx].uploadFinished = true;
							ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SuccessDataUpload'));
						})
						.catch(err => {
							files.value[idx].error = true;
							files.value[idx].errorText = err.message;
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})
						.finally(() => {
							files.value[idx].loading = false;
							files.value = [...files.value];
						});
				})
		}

		/** Uploads the files from the file-uploader
		 * @param filesSend
		 */
		const fileUploader = (filesSend: UploadFile[]) => {
			files.value = filesSend;
			showWarningDialog.value = true;
		}

		/** Export/Download the translation file
		 * @param fileType
		 */
		const startTranslationFileDownload = (fileType: string) => {
			loading.value = true;
			translationsApi.getTranslationsExport(fileType.toUpperCase())
				.then((response: Response) => {
					window.open(response.url);
				})
				.catch((response: Response) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		return {
			t,
			toast,
			loading,
			activeOption,
			options,
			files,
			fileTypes,
			showErrorMessage,
			showWarningDialog,
			showImportErrors,
			importErrors,
			errorCount,
			onUpdate,
			manualFileUpload,
			startFileUpload,
			fileUploader,
			startTranslationFileDownload,
		}
	},
})
</script>

<style scoped>

</style>
