<template>
	<Log :logs="logs" :loading="loading" />
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import TabMenu from "@/components/DexTabMenu.vue";
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {ErrorDto} from "@dex/squeeze-client-ts/api";
import Log, {LogItem} from "@/components/Log.vue";

export default defineComponent({
	name: "LogView",
	components: {
		Log,
	},
	props: {
		documentId: {
			type: Number,
		},
	},
	setup(props) {
		/** Batch-Class-Api */
		const logApi = ClientManager.getInstance().squeeze.log;

		const {t} = useI18n();
		const toast = useToast();

		const { locale } = useI18n({ useScope: 'global' })

		/** Show loading in table? */
		const loading = ref(false);

		/** Array with all logs */
		const logs = ref<LogItem[]>([]);

		/** Get log entries for document */
		const getLogs = () => {
			if (!props.documentId) {
				return;
			}
			loading.value = true;

			logApi.getDocumentLog(props.documentId).then(response => {
				response.text().then(data => {
					if (data.length != 0) {
						const texts = data.split("\n");
						logs.value = texts.map(log => {
							if (log.includes("\\n")) {
								let firstText = '';
								let moreText = '';
								log.split("\\n").forEach((logText: string, index: number) => {
									if (index === 0) {
										firstText = log.split("\\n")[0].substr(log.split("\\n")[0].indexOf(' ')+1) + '\n';
									} else {
										moreText += logText + '\n';
									}
								});
								return {timestamp: log.substr(0,log.indexOf(' ')), text: firstText + moreText }
							}
							return {timestamp: log.substr(0,log.indexOf(' ')), text: log.substr(log.indexOf(' ')+1)}
						})
					}
				});
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		onMounted(() => {
			getLogs();
		});

		/** Formats date string to desired locale string */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options) + ":" + date.getMilliseconds();
				}
			}
			return dateToFormat;
		}

		return {
			TabMenu, loading, logs, getLogs, formatDate,
		}
	},
});
</script>

<style scoped>

.timestamp {
	color: orange;
}

.p-col {
  word-break: break-all;
}

</style>
