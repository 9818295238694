
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {DocumentField, RoleFilterFieldCondition} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import FieldConditions from "@/apps/administration/components/roles/FieldConditions.vue";
import FieldConditionsForm from "@/apps/administration/components/roles/FieldConditionsForm.vue";

export default defineComponent({
	name: "RoleFieldConditionsView",
	components: {
		DialogDelete, EntryDialog, FieldConditions, FieldConditionsForm, BlockUI,
	},
	props: {
		roleId: {
			type: Number,
			default: 0,
			required: true,
		},
		filterId: {
			type: Number,
			default: null,
		},
		documentClassId: {
			type: Number,
			default: null,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const fieldConditionsForm = ref<InstanceType<typeof FieldConditionsForm>>();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Value invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the role-data */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** Document-Class-Api */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** List of all Document-Classes */
		const documentClassFields = ref<DocumentField[]>([]);

		/** List of all Filter Conditions */
		const fieldConditions = ref<RoleFilterFieldCondition[]>([]);

		/** Should the Entry-Dialog for regexes be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active Field Condition */
		const fieldCondition = reactive<RoleFilterFieldCondition>({
			operator: undefined,
			fieldId: undefined,
			id: undefined,
			filterValue: undefined,
		});

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Trigger on update of attribute-form */
		const onUpdate = (data: RoleFilterFieldCondition, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(fieldCondition, data);
		}

		/** Array with all valid operators */
		const operators = computed(() => {
			return [
				{
					id: "eq",
					description: t('Squeeze.Operators.Equals'),
				},
				{
					id: "lt",
					description: t('Squeeze.Operators.LowerThan'),
				},
				{
					id: "gt",
					description: t('Squeeze.Operators.GreaterThan'),
				},
				{
					id: "ne",
					description: t('Squeeze.Operators.NotEqual'),
				},
				{
					id: "ge",
					description: t('Squeeze.Operators.GreaterOrEqual'),
				},
				{
					id: "le",
					description: t('Squeeze.Operators.LowerOrEqual'),
				},
			]
		});

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (fieldCondition.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.UserManagement.FieldCondition') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.UserManagement.FieldCondition') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: RoleFilterFieldCondition) => {
			Object.assign(fieldCondition, row);

			// make sure locatorId is always right
			fieldCondition.filterId = props.filterId;

			showDialog.value = true;
		}

		/** Reloads the table */
		const reloadData = () => {
			if (!props.roleId || !props.filterId) {
				return;
			}

			loading.value = true;
			roleService.getRoleFilterFieldConditions(props.roleId, props.filterId)
				.then(data => {
					fieldConditions.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all Document Class Fields */
		const getAllDocumentClassFields = () => {
			if (props.documentClassId) {
				documentClassApi.getAllDocumentClassFields(props.documentClassId)
					.then(fields => {
						documentClassFields.value = fields;
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
			}
		};

		/** When the Value from the value list changes, reload the data */
		watch(() => props.filterId, () => {
			getAllDocumentClassFields();
			reloadData();
		})

		/** Get Type of current locator on load */
		onMounted(() =>{
			getAllDocumentClassFields();
			reloadData();
		})

		/** Saves an Regex */
		const saveEntry = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let filterConditionPromise = null;

			// make sure locatorId is always right
			fieldCondition.filterId = props.filterId;

			if (!fieldCondition.id) {
				filterConditionPromise = roleService.postRoleFilterFieldCondition(props.roleId, props.filterId, fieldCondition);
			} else {
				filterConditionPromise = roleService.putRoleFilterFieldCondition(props.roleId, props.filterId, fieldCondition.id, fieldCondition);
			}

			filterConditionPromise.then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					fieldCondition.id = undefined;
					fieldCondition.fieldId = undefined;
					fieldCondition.filterValue = undefined;
					fieldConditionsForm.value?.$el.querySelector('input.p-inputtext').focus();
				}
				reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: RoleFilterFieldCondition) => {
			deleteDialog.value = true;
			Object.assign(fieldCondition, row);
		}

		/** Deletes a Regex */
		const deleteEntry = () => {
			if (fieldCondition && fieldCondition.id) {
				loading.value = true;

				roleService.deleteRoleFilterFieldCondition(props.roleId, props.filterId, fieldCondition.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, fieldConditionsForm,
			showDialog, headerText, deleteDialog, fieldConditions, fieldCondition,
			documentClassFields, operators,
			deleteEntry, openDeleteDialog, onUpdate, onEntrySelect, saveEntry};
	},
});

