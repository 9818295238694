
import Panel from 'primevue/panel';
import LoginForm from "@/components/LoginForm.vue";
import router from "@/router";
import {ToastManager} from "@/singletons/ToastManager";
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {SystemInfoDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "Login",
	components: {
		Panel, LoginForm,
	},
	emits: [
		"loginStatus", // Called when the user is logged in
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Object with System Information */
		const publicSystemInformation = reactive<SystemInfoDto>({});

		/** Service for getting the public-data */
		const publicService = ClientManager.getInstance().squeeze.public;

		/** Service for getting the theme-data */
		const themeService = ClientManager.getInstance().squeeze.theme;

		/** URL of client background image */
		const urlClientBackground = ref<string>('');

		/** Styles of background Image in login */
		const loginBackgroundImageStyles = computed(() => {
			return {
				'background-image': 'url('+urlClientBackground.value+')',
				'height': '100%',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover',
			}
		})

		/** URL of client logo */
		const urlClientLogo = ref<string>('');

		/** Styles of logo image in login */
		const loginLogoStyles = computed(() => {
			return {
				'background-image': 'url('+urlClientLogo.value+')',
				'width': '100%',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'contain',
				'max-width': '10rem',
				'max-height': '10rem',
				'min-height': '4rem',
			}
		})

		/**
		 * Receives the login-status from the Form
		 * @param isLoggedIn Is the User Logged in?
		 */
		const loginStatus = (isLoggedIn: boolean) => {
			emit("loginStatus", isLoggedIn);

			if (isLoggedIn) {
				router.push({ name: 'SqueezeDashboard'});
			}
		}

		/** Get all public System Information */
		const getPublicSystemInformation = () => {
			publicService.getPublicSystemInformation()
				.then(data => {
					Object.assign(publicSystemInformation, data);
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get background image of client */
		const getBackgroundImage = () => {
			themeService.getThemeFile('loginBackground.jpg')
				.then(data => {
					urlClientBackground.value = data.url;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get Logo of client */
		const getLogo = () => {
			loading.value = true;
			themeService.getThemeFile('loginLogo.png')
				.then(data => {
					urlClientLogo.value = data.url;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			getPublicSystemInformation();
			getBackgroundImage();
			getLogo();
		})

		return { loading, publicSystemInformation, urlClientBackground, loginBackgroundImageStyles, urlClientLogo, loginLogoStyles, loginStatus }
	},

})
