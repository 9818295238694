<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="name">{{$t('Squeeze.MasterData.Name')}} *</label>
			<InputText id="name" v-model="value.name"
               :class="{
                'p-invalid': v$.name.$invalid && showErrorMessage,
                'readonly': checkPermissions
                }"
               @change="update"
               required="true"
               autofocus
               :readonly="checkPermissions"
      />
			<small v-if="v$.name.required.$invalid && showErrorMessage && !v$.name.technicalValidation.$invalid" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Name')}) }}
			</small>
			<small v-if="v$.name.technicalValidation.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: $t('Squeeze.MasterData.Name')}) }}
			</small>
			<small v-else-if="!!value.id">
				{{ $t('Squeeze.Admin.WarnRename') }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.MasterData.Description')}} *</label>
			<InputText id="description"
                 v-model="v$.description.$model"
                 :class="{
                  'p-invalid': v$.description.$invalid && showErrorMessage,
                  'readonly': checkPermissions
                  }"
                 @change="update"
                 required="true"
                 :readonly="checkPermissions"
      />
			<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Description')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {technicalValidation} from "@/util/ValidationHelper";
import {useStore} from "@/store";

export default defineComponent({
	name: "MasterDataTableForm",
	components: {
		InputText,
	},
	props: {
		masterDataTable: {
			type: Object as PropType<MasterDataTable>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
		permissions: {
			type: Object,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Use Store */
		const store = useStore();

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			// check if user has the scope to can added a new entry
			if (store.state.scopes.sqzMasterDataManage) {
				return false
			} else if (props.permissions) {
				return props.permissions.permissionEdit && !props.permissions.permissionManage
				|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
				|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/** Current Object of all input-fields */
		const value = reactive<MasterDataTable>({name: '', description: ''});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.masterDataTable);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.masterDataTable, async () => {
			Object.assign(value, props.masterDataTable);

			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on keyup in input-field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			store,
			checkPermissions,
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

.readonly {
	color: #2b2b2b;
	opacity: 0.5;
}

</style>
