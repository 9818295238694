<template>
	<Timeline :value="queueSteps" align="alternate" class="customized-timeline p-px-4" layout="horizontal">
		<template #marker="slotProps">
			<template v-if="loaded">
				<QueueTimelineElement :step="slotProps.item" :allDocumentClasses="allDocumentClasses" @onClickQueueStep="onClickQueueStep" />
			</template>
			<template v-else>
				<Skeleton width="6rem" height="2.25rem" borderRadius="1rem" />
			</template>
		</template>
	</Timeline>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import {ClientManager} from "@/singletons/ClientManager";
import QueueTimelineElement from "@/apps/squeeze/components/QueueTimelineElement.vue";
import Timeline from 'primevue/timeline';
import Squeeze from "@/apps/squeeze/App.vue";
import {QueueStepDto} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import Skeleton from 'primevue/skeleton';

@Options({
	name: "QueueTimeline",
	components: {
		Timeline,
		QueueTimelineElement,
		Skeleton,
	},
	props: {
		allDocumentClasses: Array,
	},
	emits: ['onClickQueueStep'],
})
export default class QueueTimeline extends Vue {

	/** List of all document classes in QueueStep */
	queueSteps: QueueStepDto[] = [];

	/** Queue API endpoint */
	queueApi = ClientManager.getInstance().squeeze.queue;

	/** Indicates end of request */
	loaded = false;

	/** Interval handle for polling */
	pollingInterval: number | undefined;

	/** on page mounted */
	mounted() {
		Squeeze.queueSteps.forEach((icon: string, step: string) => {
			this.queueSteps.push({
				"name": step,
			} as any);
		});

		this.loaded = true;
		this.reloadData();
		this.pollingInterval = setInterval(this.reloadData, 5000, true);
	}

	/** Is triggered before an Component unmounts. Available since Vue 3.0 */
	beforeUnmount() {
		// Clear timer handler to stop polling
		clearInterval(this.pollingInterval);
	}

	/** reloads data of view */
	reloadData(polling = false) {
		// Load QueueSteps
		if(!polling) this.loaded = false;
		this.queueApi.getQueueSteps().then((steps: QueueStepDto[]) => {
			this.queueSteps = steps;
		}).catch(err => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.statusText);
		}).finally(() => {
			if(!polling) this.loaded = true;
		});
	}

	/**
	 * Click on queue step (emitted)
	 * @param stepName
	 * @param status
	 */
	onClickQueueStep(stepName: string, status: string) {
		this.$emit('onClickQueueStep', stepName, status);
	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
