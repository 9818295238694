
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import {defineComponent, PropType, ref} from "vue";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import {useStore} from "@/store";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "primevue/api";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";

export default defineComponent({
	name: "MasterDataTableList",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		masterDataTables: {
			type: Array as PropType<MasterDataTable[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Vuex Store */
		const store = useStore();

		/** Currently-Selected row */
		const selection = ref(null);

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MasterDataTable) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", 0)
		}

		/** Triggered when a row is selected */
		const onRowSelect = (event: any) => {
			emit("onEntrySelect", event.data.id)
		}

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			if (event.filteredValue.length < props.masterDataTables!.length) {
				showBtnClearFilter.value = true;
			} else {
				showBtnClearFilter.value = false;
			}
		}

		/** Clear filters */
		const clearFilters = () => {
			initFilters();
		}

		initFilters();

		return {
			t, toast, store, DataTable, Column, filters, showBtnClearFilter, initFilters, openDeleteDialog, onRowSelect, selection, onNewEntryClick, onFilter, clearFilters,
		}
	},
});
