
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BarcodeForm from "@/apps/administration/components/locators/tabs/BarcodeForm.vue";
import BlockUI from "primevue/blockui";
import {BarcodeLocatorDetails, BarcodeType} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "BarcodeView",
	components: {
		BarcodeForm, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const saveLoading = ref(false);

		/** List of all barcode types */
		const barcodeTypes = ref<BarcodeType[]>([]);

		/** Service for getting the extraction */
		const extractionService = ClientManager.getInstance().squeeze.extraction;

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Object of a barcode */
		const barcode = reactive<BarcodeLocatorDetails>({});

		/** Show error validation-messages in form? */
		const showErrorMessage = ref(false);

		/** Is the Form of the Keywords invalid? */
		const isValidationInvalid = ref(true);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: BarcodeLocatorDetails, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(barcode, data)
		}

		/** Get all barcode types */
		const getAllBarcodeTypes = () => {
			loading.value = true;
			extractionService.getBarcodeTypes()
				.then((data: BarcodeType[]) => {
					barcodeTypes.value = data;
				})
				.catch((response: any) => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			locatorService.getBarcodeLocatorDetails(props.locatorId)
				.then((data: BarcodeLocatorDetails) => {
					Object.assign(barcode, data);
				})
				.catch((response: any) => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			getAllBarcodeTypes();
			reloadData();
		})

		/** Saves a Barcode */
		const saveBarcode = () => {
			if (isValidationInvalid.value) {
				showErrorMessage.value = true;
				return;
			}
			showErrorMessage.value = false;
			saveLoading.value = true;

			// make sure locatorId always right
			barcode.locatorId = props.locatorId;

			locatorService.putBarcodeLocatorDetails(props.locatorId, barcode)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					saveLoading.value = false;
				})
		}

		return {
			t,
			toast,
			loading,
			saveLoading,
			barcodeTypes,
			extractionService,
			locatorService,
			barcode,
			showErrorMessage,
			isValidationInvalid,
			onUpdate,
			getAllBarcodeTypes,
			reloadData,
			saveBarcode,
		};
	},
});

