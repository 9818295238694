
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {BatchClassClassification} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import {ToastManager} from "@/singletons/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import ClassificationList from "@/apps/administration/components/batchclasses/ClassificationList.vue";
import {DocumentClass} from "@dex/squeeze-client-ts/api";
import EntryDialog from "@/components/EntryDialog.vue";
import ClassificationForm from "@/apps/administration/components/batchclasses/ClassificationForm.vue";
import Dialog from "primevue/dialog";
import BatchClassTraining from "@/apps/squeeze/components/BatchClassTraining.vue";

export default defineComponent({
	name: "ClassificationListView",
	components: {
		ClassificationForm, ClassificationList, DialogDelete, EntryDialog, Dialog, BatchClassTraining,
	},
	props: {
		batchClassId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		/** Batch-Class-Api */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		/** Document-Class-Api */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** List of all classifications */
		const classifications = ref<BatchClassClassification[]>([]);

		/** List of all Document-Classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** Currently active classification */
		const classification = reactive<BatchClassClassification>({});

		/** Show loading in table? */
		const loading = ref<boolean>(false);

		/** Should the Entry-Dialog for classifications be shown? */
		const showDialog = ref<boolean>(false);

		/** Show the loading for Save-Button of classifications? */
		const loadingDialog = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		const {t} = useI18n();
		const toast = useToast();

		/** List of all Document-Classes they not selected */
		const checkDocumentClass = ref<DocumentClass[]>([]);

		/** Triggered when (all) field values are valid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref(true);

		/** Show Training dialog? */
		const showTrainingDialog = ref(false);

		/** Show Testing dialog? */
		const showTestingDialog = ref(false);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: any, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(classification, data);
		}

		/** Text of Dialog for Training */
		const headerTrainingText = ref(t('Squeeze.BatchClasses.TrainClassification'));

		/** Text of Dialog for Testing */
		const headerTestingText = ref(t('Squeeze.BatchClasses.TestClassification'));

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;

			const promise1 = documentClassApi.getAllDocumentClasses();
			const promise2 = batchClassApi.getBatchClassClassifications(props.batchClassId);

			// Wait until promises are finished
			Promise.all([promise1, promise2]).then(values => {
				documentClasses.value = values[0] as DocumentClass[];
				classifications.value = values[1] as BatchClassClassification[];
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/**
		 * Gets all Classification-Classes on Mounted
		 */
		onMounted(() =>{
			reloadData();
		});

		/** Triggered when entry button clicked */
		const onEntrySelect = () => {
			checkDocumentClass.value = documentClasses.value;

			if (classifications.value) {
				documentClasses.value.forEach(documentClass => {
					const classification = classifications.value.find(classification => classification.documentClassId === documentClass.id);
					if(classification) {
						checkDocumentClass.value = checkDocumentClass.value.filter(filterDocClass => filterDocClass.id !== classification.documentClassId)
					}
				})
			}

			if (checkDocumentClass.value.length === 0) {
				ToastManager.showInfo(toast, t('Squeeze.General.Info'), t('Squeeze.DocumentClasses.InfoAllSelected'));
				return
			}

			classification.id = undefined;
			classification.documentClassId = undefined;
			showDialog.value = true;
			headerText.value = t('Squeeze.BatchClasses.NewClassification');
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: BatchClassClassification) => {
			deleteDialog.value = true;
			Object.assign(classification, row);
		}

		/**
		 * @param row Row to open the Training with
		 */
		const openTraining = (row: BatchClassClassification) => {
			headerTrainingText.value = t('Squeeze.BatchClasses.TrainClassification') + ": " + row.description
			showTrainingDialog.value = true;
			Object.assign(classification, row);
		}

		/**
		 * @param row Row to open the Testing with
		 */
		const openTesting = (row: BatchClassClassification) => {
			showTestingDialog.value = true;
			Object.assign(classification, row);
		}

		/** Deletes the selected entry */
		const deleteEntry = () => {
			loading.value = true;
			if (classification.batchClassId && classification.id) {
				batchClassApi.deleteBatchClassClassificationById(classification.batchClassId, classification.id).then(() => {
					reloadData();
				}).catch((err) => {
					loading.value = false;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.statusText);
				})
			}
		}

		/** Saves an classification */
		const saveClassification = () => {
			showErrorMessage.value = false;
			if (!isInvalid.value) {
				loadingDialog.value = true;
				batchClassApi.postBatchClassClassification(props.batchClassId, classification)
					.then(() => {
						reloadData();
						showDialog.value = false;
					}).catch(response => response.json().then ((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					})).finally(() => {
						loadingDialog.value = false;
					})
			} else {
				showErrorMessage.value = true;
			}
		}

		return {
			classifications,
			classification,
			loading,
			deleteDialog,
			documentClasses,
			showDialog,
			loadingDialog,
			headerText,
			checkDocumentClass,
			showErrorMessage,
			isInvalid,
			showTrainingDialog,
			showTestingDialog,
			headerTrainingText,
			headerTestingText,
			openDeleteDialog,
			deleteEntry,
			reloadData,
			onEntrySelect,
			onUpdate,
			saveClassification,
			openTraining,
			openTesting,
		}
	},
})
