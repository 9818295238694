<template>
	<router-view/>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({
	name: "Squeeze",
})
export default class Squeeze extends Vue {

	public static queueSteps = new Map([
		["InitProcess", "mdi mdi-file-outline"],
		["ImageOptimization", "mdi mdi-file-image-outline"],
		["BarcodeExtraction", "mdi mdi-barcode-scan"],
		["OCR", "mdi mdi-file-eye-outline"],
		["Classification", "mdi mdi-file-tree-outline"],
		["Extraction", "mdi mdi-file-document-outline"],
		["Validation", "mdi mdi-file-check-outline"],
		["Export", "mdi mdi-file-move-outline"],
		["Backup", "mdi mdi-archive-outline"],
		["Delete", "mdi mdi-trash-can-outline"],
	]);

}
</script>

<style scoped lang="scss">
</style>
