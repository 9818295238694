
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {DbLinkLocatorDetails, DocumentLocator, MasterDataColumn} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useStore from "@/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";

export default defineComponent({
	name: "DBLinkedData",
	components: {
		DataTable, Column,
	},
	props: {
		DBLinkedDataEntrys: {
			type: Array as PropType<DbLinkLocatorDetails[]>,
		},
		allLocators: {
			type: Array as PropType<DocumentLocator[]>,
			required: true,
		},
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onChangeSortOrder",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: DbLinkLocatorDetails) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: 0,
				sortOrder: 0,
				locatorId: 0,
				sourceLocatorId: 0,
				dbcolumn: 0,
				stopIfUnique: true,
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: DbLinkLocatorDetails) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the description of a locator from the source locator
		 * @param sourceLocatorId
		 */
		const getDescriptionLocator = (sourceLocatorId: number) => {
			if (sourceLocatorId == null) {
				return "";
			}

			const locatorDescription = props.allLocators.find(i => i.id === sourceLocatorId);
			if (!locatorDescription) {
				return String(sourceLocatorId)
			}

			return locatorDescription.description
		}

		/**
		 * Gets the description of a database column
		 * @param dbcolumn
		 */
		const getDescriptionDBColumn = (dbcolumn: number) => {
			if (dbcolumn == null) {
				return "";
			}

			const dbColumnDescription = props.masterDataColumns.find(i => i.id === dbcolumn);
			if (!dbColumnDescription) {
				return String(dbcolumn)
			}

			return dbColumnDescription.description
		}

		/**
		 * Triggered when the rows are reordered
		 * @param event
		 */
		const onRowReorder = (event: any) => {
			const orderedList: number[] = [];
			event.value.forEach((locatorDetail: DbLinkLocatorDetails) => {
				orderedList.push(locatorDetail.id!);
			});

			emit("onChangeSortOrder", orderedList);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, getDescriptionLocator, getDescriptionDBColumn, onRowReorder, onChangePage,
		}
	},
});

