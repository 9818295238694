<template>
	<div class="p-p-2">
		<BlockUI :blocked="loading">
			<BatchClassForm :batchClass="batchClass" :showErrorMessage="showErrorMessage" @update="onUpdate" />
		</BlockUI>
		<div class="p-fluid p-formgrid p-grid">
			<div class="p-offset-9"></div>
			<div class="p-field p-col-3">
				<Button :disabled="loading" :label="saveButtonText" type="button" icon="mdi mdi-content-save-outline" class="p-button" @click="saveBatchClass" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import TabMenu from "@/components/DexTabMenu.vue";
import {useI18n} from "vue-i18n";
import BlockUI from "primevue/blockui";
import {BatchClass} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import BatchClassForm from "@/apps/administration/components/batchclasses/BatchClassForm.vue";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import router from "@/router";

export default defineComponent({
	name: "BatchClassSubView",
	components: {
		BlockUI, BatchClassForm,
	},
	props: {
		batchClassId: {
			type: Number,
		},
	},
	setup(props) {
		/** Batch-Class-Api */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		const {t} = useI18n();
		const toast = useToast();

		/** Object of batchClass */
		const batchClass = reactive<BatchClass>({ name: "", description: ""})

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Show loading in table? */
		const loading = ref(false);

		/** Triggered the valid of form */
		const isInvalid = ref(true);

		/**  Translations of the Save-Button */
		const saveButtonText = computed(() => {
			// If there no batch-class id, it's a new entry
			if (!props.batchClassId) {
				return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.BatchClasses.BatchClass') })
			}

			return t('Squeeze.General.Save');
		})

		/** Get all Batch-Classes */
		const getBatchClass = () => {
			if (!props.batchClassId) {
				return;
			}

			loading.value = true;
			batchClassApi.getBatchClassById(props.batchClassId).then(data => {
				Object.assign(batchClass, data);
			}).finally(() => {
				loading.value = false;
			})
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.batchClassId, (first, second) => {
			getBatchClass();
		})

		/** Triggered on update of attribute-form */
		const onUpdate = (data: BatchClass, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(batchClass, data);
		}

		/**
		 * Saves or creates a Batch-Class
		 */
		const saveBatchClass = () => {
			if (!isInvalid.value) {
				loading.value = true;
				if (batchClass.id) {
					batchClassApi.putBatchClass(batchClass.id, batchClass)
						.then(() => {
							ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
						}).catch(response => response.json().then ((err: any) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}))
						.finally(() => {
							loading.value = false;
						})
				}
				else {
					batchClass.id = 0; // FIXME: Currently the Post need a batchclass-id
					batchClassApi.postNewBatchClass(batchClass)
						.then(data => {
							// FIXME: I don't want to parse this json when the Client is fixed
							return data.json()
						})
						.then((data) => {
							ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
							router.push({ name: 'BatchClassView', params: { batchClassId: data.id }});
						})
						.catch(response => response.json().then ((err: any) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}))
						.finally(() => {
							loading.value = false;
						})
				}
			} else {
				showErrorMessage.value = true;
			}
		}

		onMounted(() => {
			getBatchClass();
		});

		return {
			TabMenu, getBatchClass, batchClass, loading, onUpdate, saveButtonText, saveBatchClass, isInvalid, showErrorMessage,
		}
	},
});
</script>

<style scoped>

</style>
