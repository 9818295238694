<template>
	<div class="title">
		<Chip v-tooltip="step.errorCount && !store.state.isAdminUser ? $t('Squeeze.Queue.ErrorMessageByErrorCount') : $t('Squeeze.Queue.Steps.' + step.name)" class="p-shadow-4">
			<span :class="(step.name === 'Validation' && step.count > 0) || (store.state.scopes.sqzRequeue && step.count > 0) || ($route.name === 'QueueEntryView' && !store.state.scopes.sqzRequeue && step.count > 0) ? 'count-chip' : 'entry-chip'" @click="onClickQueueStep(step.name, '!ERROR')">
				<transition name="flip" mode="out-in">
					<i :key="step.count" :class="icon" style="fontSize: 1rem"></i>
				</transition>
				<div class="p-my-2 p-pl-3" style="display:inline" >
					<transition name="flip" mode="out-in">
						<p :key="step.count" class="p-m-0">{{step?.count == null ? "?" : step.count}}</p>
					</transition>
				</div>
			</span>

			<span :class="(step.name === 'Validation' && step.count > 0) || (store.state.scopes.sqzRequeue && step.count > 0) || ($route.name === 'QueueEntryView' && !store.state.scopes.sqzRequeue && step.count > 0) ? 'count-chip' : 'entry-chip'" @click="onClickQueueStep(step.name, 'ERROR')">
				<transition name="flip" mode="out-in" v-if="step.errorCount">
					<i :key="step.errorCount" class="mdi mdi-progress-alert error-icon"></i>
				</transition>
				<div v-if="step.errorCount" class="p-my-2 p-pl-3 error-count">
					<transition name="flip" mode="out-in">
						<p :key="step.errorCount" class="p-m-0">{{step?.errorCount == null ? "?" : step.errorCount}}</p>
					</transition>
				</div>
			</span>
		</Chip>
	</div>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import Chip from 'primevue/chip';
import {QueueStepDto} from "@dex/squeeze-client-ts";
import Squeeze from "@/apps/squeeze/App.vue";
import router from "@/router";
import {ToastManager} from "@/singletons/ToastManager";
import {DocumentClass} from "@dex/squeeze-client-ts/api";
import {useStore} from "@/store";

@Options({
	name: "QueueTimelineElement",
	components: {
		Chip,
	},
	props: {
		step: Object,
		allDocumentClasses: Array,
	},
	emits: ['onClickQueueStep'],
})
export default class QueueTimelineElement extends Vue {

	/** QueueStep */
	step!: QueueStepDto;

	/** All documentClasses */
	allDocumentClasses!: DocumentClass[];

	/** Set QueueStep icon */
	icon = "mdi mdi-spin mdi-loading";

	/** Store point */
	store = useStore();

	mounted() {
		const stepIcon = Squeeze.queueSteps.get(this.step.name);
		if(stepIcon) this.icon = stepIcon;
	}

	/** Click on queue step */
	onClickQueueStep(stepName: string, status: string) {
		// check if the stepName is clickable
		if(!this.store.state.scopes.sqzRequeue && this.$route.name !== 'QueueEntryView' && stepName !== 'Validation') {
			return
		}

		if(!this.store.state.scopes.sqzRequeue && this.$route.name === 'QueueView' && stepName === 'Validation') {
			return
		}

		// check step errorCount or count
		if (this.step.errorCount >= 1 || this.step.count >= 1) {
			// check route
			if (router.currentRoute.value.fullPath.includes('/app/administration/queue')) {
				router.push({ name: 'QueueEntryView', params: { stepName: stepName, status: status }});
				this.$emit('onClickQueueStep', stepName, status);
			} else if (this.store.state.scopes.sqzRequeue && stepName !== 'Validation') {
				router.push({ name: 'QueueEntryView', params: { stepName: stepName, status: status }});
			} else {
				if(this.allDocumentClasses.length > 1) {
					router.push({ name: 'QueueView'});
				} else {
					const documentClassId = this.allDocumentClasses[0].id;
					router.push({name: "DocumentListValidation", params: {documentClassId: (documentClassId as any)}});
				}
			}
		} else {
			ToastManager.showInfo(this.$toast, this.$t('Toasts.Title.Info'), this.$t('Squeeze.Queue.Filter.NoEntries'))
		}
	}

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.p-chip {
	background-color: var(--dex-secondary-dark-color);
	color: var(--dex-text-color);
}

.flip-enter-active {
	transition: all .3s ease;
}
.flip-leave-active {
	transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.flip-enter, .flip-leave-to {
	transform: rotateY(360deg);
}

.error-icon {
	fontSize: 1rem;
	color: var(--queuestate-error-darker);
	margin-left: 1rem;
}

.error-count {
	display: inline;
	color: var(--queuestate-error-darker);
}

.count-chip {
	display: contents;
	cursor: pointer;
}

.entry-chip {
	display: contents;
}

</style>
