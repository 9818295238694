

import {Options, Vue} from "vue-class-component";
import DocumentClassFieldTable from "@/apps/administration/components/documentclasses/DocumentClassFieldTable.vue";
import {DocumentField, DocumentFieldGroup, DocumentLocator} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import DialogDelete from "@/components/DialogDelete.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import EntryDialog from "@/components/EntryDialog.vue";
import FieldGroupForm from "@/apps/administration/components/documentclasses/FieldGroupForm.vue";
import FieldForm from "@/apps/administration/components/documentclasses/FieldForm.vue";
import TableFieldForm from "@/apps/administration/components/documentclasses/TableColumnForm.vue";
import {ToastManager} from "@/singletons/ToastManager";
import {ref} from "vue";
import DocumentClassFieldsLayoutView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassFieldsLayoutView.vue";
import useStore from "@/store";

type FieldsPerGroupCollection = Record<number, DocumentField[]>;

@Options({
	name: 'DocumentClassFieldsSubView',
	components: {
		DocumentClassFieldsLayoutView,
		DocumentClassFieldTable,
		DialogDelete, TabView, TabPanel, TableFieldForm,
		EntryDialog, FieldGroupForm, FieldForm,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		fieldsPerFieldGroup() {
			const fgs = this.fieldGroups as DocumentFieldGroup[];

			const fieldsPergroup: FieldsPerGroupCollection = fgs.reduce((acc, fg) => {
				acc[fg.id || -1] = [];
				return acc;
			}, {} as FieldsPerGroupCollection);

			(this.documentClassFields || []).reduce((acc: FieldsPerGroupCollection, field: DocumentField) => {
				acc[field.fieldGroupId || -1] = [...(acc[field.fieldGroupId || -1] || []), field];
				return acc;
			}, fieldsPergroup)

			return fieldsPergroup;
		},
	},
})

export default class DocumentClassFieldsSubView extends Vue {

	documentClassId!: number;

	/** Vuex Store */
	store = useStore();

	/** Component of the current form */
	fieldFormElement: any = ref<any>();

	/** Service for getting the documentclass-data */
	documentClassService = ClientManager.getInstance().squeeze.documentClass;

	/** Service for getting the locator-data */
	locatorService = ClientManager.getInstance().squeeze.locator;

	/** Currently active field-group */
	activeFieldGroup?: number = -1

	/** All field groups for this document-class */
	fieldGroups?:  DocumentFieldGroup[] = []

	/** all Document-Class-Fields */
	documentClassFields?: DocumentField[] = []

	/** List of all locators **/
	locators?: DocumentLocator[] = [];

	/** Show Loading in Document-Class-Fields view? */
	documentClassLoading = false

	/** Should the Entry-Dialog for fields be shown? */
	showCurrentFieldDialog = false

	/** Should the Entry-Dialog for fields be shown? */
	showCurrentTableFieldDialog = false

	/** Entry-Data for Document-Class-Fields */
	documentClassFieldsEntry?: DocumentField = {
		documentClassId: this.documentClassId,
		fieldGroupId: this.activeFieldGroup,
		name: '',
		description: '',
		defaultValue: '',
		locatorId: 0,
		mandatory: false,
		readonly: false,
		sameLineAsPreviousField: false,
		hidden: false,
		forceValidation: false,
		externalName: '',
		alternatives: [],
		dataType: '',
	}

	/** One Entry for a field-group */
	entryFieldGroup?: DocumentFieldGroup = {
		documentClassId: this.documentClassId,
		name: '',
		description: '',
		type: 0,
		tableField: '',
		sortOrder: 1,
	}

	/** Should the Entry-Dialog for field-groups be shown?  */
	showCurrentFieldGroupDialog = false

	/** Currently active tab */
	activeTabIndex = 0

	/** Should the Delete-Dialog for Field-Groups be shown? */
	showDialogFieldGroup = false

	/** Message To Show in Entry-Dialogs */
	message = ''

	/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
	messageType = 'none'

	/** Text of the header in Entry-Dialog */
	headerText = ''

	/** Show the loading for Save-Button of Fields? */
	loadingSaveField = false;

	/** Show the loading for Save-Button of Field-Groups? */
	loadingSaveFieldGroup = false;

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	/** Show the fields layout */
	showFieldsLayout: boolean = false;

	/** Current invalid tab of fieldForm */
	currentInValidTab: number = 0;

	/** Current tab index of fieldForm dialog */
	currentDialogTabIndex: number = 0;

	mounted() {
		this.reloadData()
	}

	/** Is triggered when a tab is changed */
	onTabClick(event: { originalEvent: MouseEvent; index: number }) {
		if(this.fieldGroups) {
			if (event.index === this.fieldGroups.length) {
				this.initFieldGroupItem()
				this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.Group') })
				this.showCurrentFieldGroupDialog = true
			}
		}

		this.showFieldsLayout = false;
	}

	/**
	 * Opens the Edit-Dialog for Field-Groups
	 * @param {DocumentFieldGroup} rowData Data of the row to edit
	 */
	openEntryDialogFieldGroup(rowData: DocumentFieldGroup) {
		this.entryFieldGroup = {...rowData}
		this.headerText = this.$t('Squeeze.General.ChangeEntry', { entryName: this.$t('Squeeze.DocumentClasses.Group') })
		this.showCurrentFieldGroupDialog = true
	}

	/**
	 * Opens the Edit-Dialog for Field-Groups
	 * @param {DocumentFieldGroup} rowData Data of the row to delete
	 */
	openDeleteFieldGroup(rowData: DocumentFieldGroup) {
		this.entryFieldGroup = rowData
		this.showDialogFieldGroup = true
	}

	/** Triggered on update of attribute-form group */
	onUpdateFieldGroup(data: DocumentFieldGroup, valid: boolean) {
		this.isInvalid = valid;
		Object.assign(this.entryFieldGroup, data);
	}

	/** Triggered on update of attribute-form field */
	onUpdateField(data: DocumentField, valid: boolean, activeTabInValid: number) {
		this.currentInValidTab = activeTabInValid;
		this.isInvalid = valid;
		Object.assign(this.documentClassFieldsEntry, data);
	}

	onTabChange(activeTabIndex: number) {
		this.currentDialogTabIndex = activeTabIndex;
	}

	/**
	 * Opens the Edit-Dialog for Field-Groups
	 */
	deleteFieldGroup() {
		this.loadingSaveFieldGroup = true;
		if (this.entryFieldGroup) {
			this.documentClassService.deleteDocumentClassFieldGroupById(this.documentClassId, Number(this.entryFieldGroup.id)).then(() =>{
				this.reloadData()
			})
		}
		this.loadingSaveFieldGroup = false;
	}

	/**
	 *  Is triggered when a Field-Group triggers a save
	 */
	saveFieldGroup() {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		this.showErrorMessage = false;
		this.loadingSaveFieldGroup = true;
		if (this.entryFieldGroup && this.entryFieldGroup.id) {
			this.documentClassService.putDocumentClassFieldGroup(this.documentClassId, this.entryFieldGroup.id, this.entryFieldGroup).then(() => {
				this.reloadData()
				this.showCurrentFieldGroupDialog = false
			}).catch((err) => {
				this.message = err.statusText;
				this.messageType = 'error'
			}).finally(() => this.loadingSaveFieldGroup = false);
		} else {
			this.documentClassService.postFieldGroup(this.documentClassId, this.entryFieldGroup).then(() => {
				this.reloadData()
				this.showCurrentFieldGroupDialog = false
			}).catch((err) => {
				this.message = err.statusText;
				this.messageType = 'error'
			}).finally(() => this.loadingSaveFieldGroup = false);
		}
	}

	/**
	 *  Is triggered when an entry should be edited/inserted from the Document-Class-Fields
	 *  @param {DocumentField} rowData Row to Save
	 *  @param {number} fieldGroupId Field-Group that is currently open
	 */
	openCurrentFieldDialog(rowData: DocumentField, fieldGroupId: number) {
		if (rowData.id) {
			this.headerText = this.$t('Squeeze.General.ChangeEntry', { entryName: this.$t('Squeeze.DocumentClasses.Field') })
		}else{
			this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.Field') })
			rowData.documentClassId = this.documentClassId
			rowData.fieldGroupId = fieldGroupId
		}
		this.documentClassFieldsEntry = {...rowData}
		this.message = ""
		this.showCurrentFieldDialog = true
	}

	/**
	 *  Is triggered when an entry should be edited/inserted from the Document-Class-Fields
	 *  @param {DocumentField} rowData Row to Save
	 *  @param {number} fieldGroupId Field-Group that is currently open
	 */
	openCurrentTableFieldDialog(rowData: DocumentField, fieldGroupId: number) {
		if (rowData.id) {
			this.headerText = this.$t('Squeeze.General.ChangeEntry', { entryName: this.$t('Squeeze.DocumentClasses.Field') })
		}else{
			this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.Field') })
			rowData.documentClassId = this.documentClassId
			rowData.fieldGroupId = fieldGroupId
		}
		this.documentClassFieldsEntry = {...rowData}
		this.message = ""
		this.showCurrentTableFieldDialog = true
	}

	/** Is trigged when a checkbox in the Field-Table is clicked. When such a checkbox is clicked, simply save the entry
	 *  @param {DocumentField} rowData Row to Save
	 *  @param fieldName Name of the field that was clicked
	 */
	changeCheckboxField(rowData: DocumentField, fieldName: string) {
		switch(fieldName) {
		case 'mandatory':
		case 'forceValidation': {
			// If a field is mandatory or "force Validation", it shouldn't be hidden
			if (rowData.mandatory === true || rowData.forceValidation === true) {
				rowData.hidden = false;
			}
			break;
		}
		case 'hidden': {
			// If a field is hidden, forcing the Validation can cause a falsy behavior in the validation
			if (rowData.hidden) {
				rowData.mandatory = false;
				rowData.forceValidation = false;
			}
			break;
		}
		}

		this.documentClassFieldsEntry = {...rowData}
		this.saveField(false)
	}

	/** Saves a Field from the dialog and emits if the dialog should be kept open */
	saveFieldFromDialog(keepDialogOpen: boolean) {
		this.saveField(true, keepDialogOpen);
	}

	/**
	 * Saves a new field
	 * @params reloadTableData Is the Save triggered from outside the Entry-Dialog?
	 */
	saveField(reloadTableData: boolean, keepDialogOpen: boolean = false) {
		if (this.isInvalid && reloadTableData === true) {
			this.showErrorMessage = true;

			// check current inValid tab
			if(this.currentInValidTab === 1 && this.currentDialogTabIndex !== 1) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.DocumentClasses.ErrorInputAssistance'));
			} else if (this.currentInValidTab === 0 && this.currentDialogTabIndex !== 0) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.DocumentClasses.ErrorFieldAttributes'));
			}

			return;
		}

		this.loadingSaveField = true;
		if (this.documentClassFieldsEntry) {
			// If the Dropzone field has been emptied it's set to null by PrimeVue-Default. Set this value to an empty string
			if (this.documentClassFieldsEntry.subFieldName == null) {
				this.documentClassFieldsEntry.subFieldName = ""
			}

			// The Api can't save null values, therefore use default 0
			if (this.documentClassFieldsEntry.lookup?.tableId == null) {
				this.documentClassFieldsEntry.lookup!.tableId = 0;
			}

			if (this.documentClassFieldsEntry.lookup?.minInputLength == null) {
				this.documentClassFieldsEntry.lookup!.minInputLength = 1;
			}
		}

		let promise;

		if (this.documentClassFieldsEntry && this.documentClassFieldsEntry.id) {
			// Update existing
			promise = this.documentClassService.putDocumentClassField(this.documentClassId, Number(this.documentClassFieldsEntry.id), this.documentClassFieldsEntry)
		} else{
			const sortOrders = this.documentClassFields!.map(documentClass => documentClass.sortOrder);
			// Get highest sort order number and increase it by one
			if (sortOrders.length > 0) {
				const highestSortID = Math.max.apply(0, sortOrders as number[]) + 1;
				this.documentClassFieldsEntry!.sortOrder! = highestSortID;
			}
			// Create new
			promise = this.documentClassService.postDocumentClassField(this.documentClassId, this.documentClassFieldsEntry)
		}

		promise.then(() => {
			if (!keepDialogOpen) {
				this.showCurrentFieldDialog = false
			} else {
				if (this.documentClassFieldsEntry) {
					this.documentClassFieldsEntry.name = "";
					this.documentClassFieldsEntry.description = "";
				}
				if (this.fieldFormElement) {
					this.fieldFormElement.$el.querySelector('input').focus();
				}
				this.isInvalid = true;
			}

			if (reloadTableData) {
				this.getDocumentClassFields(this.documentClassId, false)
			}
		}).catch((err: Response) => {
			this.message = err.statusText
			this.messageType = "error"

			// If the data should not be reloaded, that means there is no Edit-Dialog, therefore show a toast
			if (!reloadTableData) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err);

				// Undo the current changes, if there is an error
				this.getDocumentClassFields(this.documentClassId, false)
			}
		}).finally(() => this.loadingSaveField = false)
	}

	deleteField(rowData: DocumentField) {
		this.loadingSaveField = true;
		this.documentClassService.deleteDocumentClassFieldById(this.documentClassId, Number(rowData.id)).then(() =>{
			this.showCurrentFieldDialog = false
			this.getDocumentClassFields(this.documentClassId, false)
		}).catch(err => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.DeleteError') + ": " + err);
		}).finally(() => {
			this.loadingSaveField = false;
		});
	}

	/**
	 * Reloads the the full dataset needed for field-groups
	 */
	reloadData() {
		const promise1 = this.locatorService.getAllLocators();
		const promise2 = this.documentClassService.getAllFieldGroups(this.documentClassId);

		// Wait until promises are finished
		Promise.all([promise1, promise2]).then(values => {
			this.locators = values[0];
			// Only show Head-Fieldgroups
			this.fieldGroups = values[1].filter(fieldGroup => fieldGroup.type === 0);

			// If there is no field-group, show create dialog for field-group
			if(this.fieldGroups && this.fieldGroups.length === 0) {
				this.initFieldGroupItem()
				this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.Group') })
				this.showCurrentFieldGroupDialog = true
			} else {
				this.getDocumentClassFields(this.documentClassId, true)
			}
		}).catch((err) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err);
			this.activeFieldGroup = -1
		});
	}

	/**
	 * Gets the Fields for a Document-Class and shows them
	 * @param {Number} documentClassID Id of the Document-Class to get the fields from
	 * @param {Boolean} showLoadingAnimation Should the loading animation be shown?
	 */
	getDocumentClassFields(documentClassID: number, showLoadingAnimation: boolean) {
		this.documentClassLoading = true;

		/* Only when loading is active, emptying the currently shown fields is advisable, otherwise there are some unnecessary hiccups in the layout.
		* Use-Case: When changing the Document-Class, seeing the old-document-class-fields is confusing, so the entries are emptied and reloaded.
		* When Inserting/Updating an entry, this animation feels to restless
		*/
		if (showLoadingAnimation === true) {
			this.documentClassFields = [];
		}

		this.documentClassService.getAllDocumentClassFields(documentClassID).then(data => {
			this.documentClassFields = data;
		}).catch(response => response.json().then((err: any) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);
		})).finally(() => this.documentClassLoading = false)
	}

	/**
	 * Resets the Default-Field-Group
	 */
	initFieldGroupItem() {
		this.message = ''
		this.messageType = 'none'

		this.entryFieldGroup = {
			documentClassId: this.documentClassId,
			name: '',
			description: '',
			type: 0,
			tableField: '',
			sortOrder: 1,
		}
	}

	/**
	 * Changes the order of document class fields
	 * @param documentClassFields
	 * @param fieldGroupId
	 * @param isFilterActive
	 */
	onChangeSortOrder(documentClassFields: number[], fieldGroupId: number, isFilterActive: boolean) {
		if (isFilterActive) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.ChangeError') + ": " + this.$t('Squeeze.DocumentClasses.ActiveFilter'));
			return;
		} else {
			this.documentClassLoading = true;

			this.documentClassService.putDocumentClassFieldOrder(this.documentClassId, fieldGroupId, {elements: documentClassFields})
				.then(() => {
					this.getDocumentClassFields(this.documentClassId, false);
				}).catch(response => response.json().then((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);
				})).finally(() => {
					this.documentClassLoading = false;
				})
		}
	}

	/** Opens the fields layout */
	openFieldsLayout() {
		this.showFieldsLayout = true;
	}

}

