
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from "primevue/checkbox";
import {TranslationCountry} from "@dex/squeeze-client-ts";
import {TableListSettings, TableSettings} from "@/singletons/TableSettings";
import {useRoute} from "vue-router";
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "LanguagesList",
	components: {
		DataTable, Column, Checkbox,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		allLanguages: {
			type: Array as PropType<TranslationCountry[]>,
			default: [],
		},
	},
	emits: [
		'onChangeActiveStatus',
		'onEntrySelect',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Triggered when the 'active' checkbox is clicked
		 * @param {TranslationCountry} data Data to edit
		 */
		const onChangeActiveStatus = (data: TranslationCountry) => {
			emit('onChangeActiveStatus', data);
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {TranslationCountry} row Row to edit
		 */
		const openEditDialog =(row: TranslationCountry) => {
			emit('onEntrySelect', row)
		}

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		return {
			tableListPagination,
			onChangeActiveStatus,
			openEditDialog,
			onChangePage,
		};
	},
});

