<template>
	<div>
		<BlockUI :blocked="documentClassLoading">
			<TabView v-model:activeIndex="activeTabIndex" @tab-click="onTabClick" >
				<template v-for="table in documentClassTables" v-bind:key="table.id">
					<TabPanel>
						<template #header>
							{{table.description}}&nbsp;
							<Button v-tooltip="$t('Squeeze.Locators.Edit')" @click="openCurrentTableDialog(table)" icon="mdi mdi-pencil-outline" class="p-button-rounded p-button-plain p-button-text reduceHeight" />
							<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" @click="openDialogDeleteTable(table)" icon="mdi mdi-delete-outline" class="p-button-rounded p-button-plain p-button-text reduceHeight" />
						</template>
						<DocumentClassTableColumns
							:rows="table.columns"
							:locators="locators"
							:loading="documentClassLoading"
							:tableId="table.id"
							@openEntryDialog="openCurrentTableColumnDialog"
							@deleteEntry="openDeleteTableColumn"
							@onChangeCheckbox="changeCheckboxTableColumn"
							@onChangeSortOrder="onChangeSortOrder"
						/>
					</TabPanel>
				</template>
				<TabPanel v-if="documentClassTables" >
					<template #header>
						{{$t('Squeeze.DocumentClasses.NewTable')}}&nbsp;
						<Button icon="mdi mdi-plus" class="p-button-rounded p-button-plain p-button-text reduceHeight" />
					</template>
				</TabPanel>
			</TabView>
		</BlockUI>
		<!-- Dialog for Tables -->
		<EntryDialog :show="showCurrentTableDialog"
					@onClose="showCurrentTableDialog = false, showErrorMessage= false, isInvalid = true"
					@onConfirm="saveTable(true)"
					:loading="loadingSaveTable"
					:message="message"
					:messageType="messageType"
					:headerText="headerText">
			<template #content>
				<TableForm
					:fieldEntry="documentClassTableEntry"
					:locators="getLineLocators(locators)"
					:tableField="true"
					:showErrorMessage="showErrorMessage"
					:tablesWithLocators="tablesWithLocators"
					:allDocumentClasses="allDocumentClasses"
					:activeTableId="activeTableId"
					:documentTables="documentTables"
					@update="onUpdateTable"
				/>
			</template>
		</EntryDialog>

		<!-- Dialog for Columns -->
		<EntryDialog :show="showCurrentTableColumnDialog"
					@onClose="showCurrentTableColumnDialog = false, showErrorMessage= false, isInvalid = true"
					@onConfirm="saveColumnFromDialog"
					:loading="loadingSaveColumn"
					:message="message"
					:messageType="messageType"
					:headerText="headerText"
					:showKeepDialogOpen="!documentClassTableColumnEntry.id"
					:width="'60rem'"
		>
			<template #content>
				<TableColumnForm
					ref="columnFormElement"
					:fieldEntry="documentClassTableColumnEntry"
					:locators="locators"
					:showErrorMessage="showErrorMessage"
					:documentClassId="documentClassId"
					:tableColumns="currentTableColumns"
					@update="onUpdateTableColumn"
					@onTabChange="onTabChange"
				/>
			</template>
		</EntryDialog>
		<!-- Delete dialog for Table -->
		<DialogDelete :showDialog="showDialogDeleteTable" @onClose="showDialogDeleteTable = false" @onConfirm="deleteTable(documentClassTableEntry)" />

		<!-- Delete dialog for Table-Column -->
		<DialogDelete :showDialog="showDeleteTableColumnDialog" @onClose="showDeleteTableColumnDialog = false" @onConfirm="deleteTableColumn()" />
	</div>
</template>

<script lang="ts">
/* eslint max-lines: off */
import {Options, Vue} from "vue-class-component";
import DocumentClassTableColumns from "@/apps/administration/components/documentclasses/DocumentClassTableColumns.vue";
import {DocumentClassDto, DocumentLocator, DocumentTable, DocumentTableColumn, LineItemLocatorDetails} from "@dex/squeeze-client-ts";
import TableBehaviourEnum = DocumentTable.TableBehaviourEnum;
import {ClientManager} from "@/singletons/ClientManager";
import DialogDelete from "@/components/DialogDelete.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import EntryDialog from "@/components/EntryDialog.vue";
import FieldGroupForm from "@/apps/administration/components/documentclasses/FieldGroupForm.vue";
import TableColumnForm from "@/apps/administration/components/documentclasses/TableColumnForm.vue";
import {ToastManager} from "@/singletons/ToastManager";
import BlockUI from "primevue/blockui";
import TableForm from "@/apps/administration/components/documentclasses/TableForm.vue";
import {ref} from "vue";

@Options({
	name: 'DocumentClassTableFieldsSubView',
	components: {
		DialogDelete, TabView, TabPanel, TableColumnForm, BlockUI,
		EntryDialog, FieldGroupForm, TableForm, DocumentClassTableColumns,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
})

export default class DocumentClassTableFieldsSubView extends Vue {

	documentClassId!: number;

	/** Component of the current form */
	columnFormElement: any = ref<any>();

	/** Service for getting the documentclass-data */
	documentClassService = ClientManager.getInstance().squeeze.documentClass;

	/** Service for getting the locator-data */
	locatorService = ClientManager.getInstance().squeeze.locator;

	/** all Document-Class-Fields */
	documentClassTables?: DocumentTable[] = []

	/** List of all locators **/
	locators?: DocumentLocator[] = [];

	/** Show Loading in Document-Class-Fields view? */
	documentClassLoading = false

	/** Should the Entry-Dialog for fields be shown? */
	showCurrentTableDialog = false

	/** Should the Entry-Dialog for a Column be shown? */
	showCurrentTableColumnDialog = false

	/** Should the Delete-Dialog for a Column be shown? */
	showDeleteTableColumnDialog = false;

	/** List of all current table Columns */
	currentTableColumns: any[] = [];

	/** Entry-Data for Table */
	documentClassTableEntry?: DocumentTable = {
		documentClassId: this.documentClassId,
		name: '',
		description: '',
		locatorId: 0,
		mandatory: false,
		readonly: false,
		hidden: false,
		forceValidation: false,
		externalName: '',
		fieldGroupId: 0,
		tableBehaviour: TableBehaviourEnum.Dynamic,
	}

	/** Entry Data for Table-Column */
	documentClassTableColumnEntry?: DocumentTableColumn = {
		name: '',
		description: '',
		headerLocatorId: 0,
		valueLocatorId: 0,
		mandatory: false,
		readonly: false,
		hidden: false,
		forceValidation: false,
		externalName: '',
	}

	/** Currently active tab */
	activeTabIndex = 0

	/** Should the Delete-Dialog for Field-Groups be shown? */
	showDialogDeleteTable = false

	/** Message To Show in Entry-Dialogs */
	message = ''

	/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
	messageType = 'none'

	/** Text of the header in Entry-Dialog */
	headerText = ''

	/** Show the loading for Save-Button of Tables? */
	loadingSaveTable = false;

	/** Show the loading for Save-Button of Column? */
	loadingSaveColumn = false;

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	/** Current invalid tab of tableForm */
	currentInValidTab: number = 0;

	/** Current tab index of tableForm dialog */
	currentDialogTabIndex: number = 0;

	/** list of tables with locator */
	tablesWithLocators: DocumentTable[] = [];

	/** Document Class API endpoint */
	documentClassApi = ClientManager.getInstance().squeeze.documentClass;

	/** All document classes */
	allDocumentClasses: DocumentClassDto[] = [];

	/** List of all tables */
	documentTables: DocumentTable[] = [];

	/** Currently active table in locator*/
	activeTableId: number = -1;

	mounted() {
		this.reloadData()
	}

	/** Is triggered when a tab is changed */
	onTabClick(event: any) {
		if(this.documentClassTables) {
			if (event.index === this.documentClassTables.length) {
				this.initFieldItem();
				this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.Table') })
				this.message = ""
				this.showCurrentTableDialog = true
			}
		}
	}

	/**
	 * Opens the Edit-Dialog for Tables
	 * @param rowData Data of the row to edit
	 */
	openDialogDeleteTable(rowData: DocumentTable) {
		this.documentClassTableEntry = {...rowData}
		this.headerText = this.$t('Squeeze.General.DeleteEntry', { entryName: this.$t('Squeeze.DocumentClasses.Table') })
		this.showDialogDeleteTable = true
	}

	/**
	 *  Is triggered when an entry should be edited/inserted from the Table
	 *  @param  rowData Row to Save
	 */
	openCurrentTableDialog(rowData: DocumentTable) {
		if (rowData.id) {
			this.headerText = this.$t('Squeeze.General.ChangeEntry', { entryName: this.$t('Squeeze.DocumentClasses.Table') }) +  ' (ID: ' + rowData.id + ')';
		} else {
			this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.Table') })
			rowData.documentClassId = this.documentClassId
		}
		this.documentClassTableEntry = {...rowData};
		this.message = "";
		this.showCurrentTableDialog = true;
		this.tablesWithLocators = [];
	}

	/** Triggered on update of attribute-form field */
	onUpdateTable(data: DocumentTable, valid: boolean) {
		if (data.locatorId) {
			this.locatorService.getLocatorTableRefs(data.locatorId!)
				.then(tables => {
					this.tablesWithLocators = tables.filter(table => table.id !== data.id);
				})

			this.locatorService.getLineItemLocatorDetails(data.locatorId!)
				.then((lineItemDetails: LineItemLocatorDetails) => {
					this.activeTableId = lineItemDetails.tableId!;
				})
			this.getAllDocumentClasses();
			this.getPositionTables();
		} else {
			this.tablesWithLocators = [];
			this.activeTableId = -1;
		}
		this.isInvalid = valid;
		Object.assign(this.documentClassTableEntry, data);
	}

	/** Triggered on update of attribute-form field */
	onUpdateTableColumn(data: DocumentTable, valid: boolean, activeTabInValid: number) {
		this.currentInValidTab = activeTabInValid;
		this.isInvalid = valid;
		Object.assign(this.documentClassTableColumnEntry, data);
	}

	/** Triggered on change tab */
	onTabChange(activeTabIndex: number) {
		this.currentDialogTabIndex = activeTabIndex;
	}

	/**
	 * Saves a new field
	 * @params reloadTableData Is the Save triggered froum outside the Entry-Dialog?
	 */
	saveTable(reloadTableData: boolean) {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		this.showErrorMessage = false;
		this.loadingSaveTable = true;
		if (this.documentClassTableEntry && this.documentClassTableEntry.id) {
			this.documentClassService.putDocumentClassTable(this.documentClassId, Number(this.documentClassTableEntry.id), this.documentClassTableEntry)
				.then(() =>{
					this.showCurrentTableDialog = false

					if (reloadTableData) {
						this.getDocumentClassTables(this.documentClassId, false)
					}
				}).catch((err) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);

					// Undo the current changes, if there is an error
					this.getDocumentClassTables(this.documentClassId, false)
				}).finally(() => this.loadingSaveTable = false)
		}else {
			this.documentClassService.postDocumentClassTable(this.documentClassId, this.documentClassTableEntry).then(() =>{
				this.showCurrentTableDialog = false

				if (reloadTableData) {
					this.getDocumentClassTables(this.documentClassId, false)
				}
			}).catch((err) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);

				// Undo the current changes, if there is an error
				this.getDocumentClassTables(this.documentClassId, false)
			}).finally(() => this.loadingSaveTable = false)
		}
	}

	/**
	 * Deletes a Table
	 * @param rowData Row-Data of the Table to delete
	 */
	deleteTable(rowData: DocumentTable) {
		this.loadingSaveTable = true;
		this.documentClassService.deleteDocumentClassTable(this.documentClassId, Number(rowData.id)).then(() =>{
			this.showCurrentTableDialog = false
			this.getDocumentClassTables(this.documentClassId, false)
		}).catch(err => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.DeleteError') + ": " + err);
		}).finally(() => {
			this.loadingSaveTable = false;
		})
	}

	/**
	 * Reloads the the full dataset needed for field-groups
	 */
	reloadData() {
		this.loadingSaveTable = true;
		this.documentClassLoading = true;
		const promise1 = this.locatorService.getAllLocators();

		// Wait until promises are finished
		Promise.all([promise1]).then(values => {
			this.locators = values[0];
			this.getDocumentClassTables(this.documentClassId, true);
		}).catch((err) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err);
		}).finally(() => {
			this.loadingSaveTable = false;
		})
	}

	/**
	 * Gets the Fields for a Document-Class and shows them
	 * @param {Number} documentClassID Id of the Document-Class to get the fields from
	 * @param {Boolean} showLoadingAnimation Should the loading animation be shown?
	 */
	getDocumentClassTables(documentClassID: number, showLoadingAnimation: boolean) {
		this.documentClassLoading = showLoadingAnimation;

		this.documentClassService.getAllDocumentClassTables(documentClassID)
			.then(data => {
				this.documentClassTables = data;

				// TODO: Tech Debt Fabian: Return Columns in Request for Tables?
				// Get all Columns for Table
				return Promise.all(this.documentClassTables.map(table => {
					if (table.id) {
						return this.documentClassService.getAllDocumentClassTableColumns(this.documentClassId, table.id)
							.then(cols => {
								table.columns = cols
							})
					}
				}))
			})
			.then(() => {
				this.documentClassLoading = false;
			})
			.catch((err) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err);
				this.documentClassLoading = false;
			});
	}

	/** Gets the Locators from Type 'Search for line items'
	 * @param locators List of Locators
	 */
	getLineLocators(locators: DocumentLocator[]): DocumentLocator[] {
		if (locators) {
			return locators.filter(locator => locator.locatorType === 8)
		}

		return []
	}

	/**
	 *  Is triggered when an entry should be edited/inserted from the Table
	 *  @param rowData Row to Save
	 */
	openCurrentTableColumnDialog(rowData: DocumentTableColumn) {
		if (rowData.id) {
			this.headerText = this.$t('Squeeze.General.ChangeEntry', { entryName: this.$t('Squeeze.DocumentClasses.TableColumn') })
		}else{
			this.headerText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.TableColumn') })
		}

		this.documentClassTableColumnEntry = {...rowData}
		this.message = ""
		if (this.documentClassTables && this.documentClassTableColumnEntry) {
			const currentTable = this.documentClassTables.find(table => table.id === this.documentClassTableColumnEntry!.tableId);
			if (currentTable) {
				this.currentTableColumns = currentTable.columns!;
			}
		}

		this.showCurrentTableColumnDialog = true;
	}

	/** Is trigged when a checkbox in the Field-Table is clicked. When such a checkbox is clicked, simply save the entry
	 *  @paramrowData Row to Save
	 */
	changeCheckboxTableColumn(rowData: DocumentTableColumn, fieldName: string) {
		switch(fieldName) {
		case 'mandatory':
		case 'forceValidation': {
			// If a field is mandatory or "force Validation", it shouldn't be hidden
			if (rowData.mandatory === true || rowData.forceValidation === true) {
				rowData.hidden = false;
			}
			break;
		}
		case 'hidden': {
			// If a field is hidden, forcing the Validation can cause a falsy behavior in the validation
			if (rowData.hidden) {
				rowData.mandatory = false;
				rowData.forceValidation = false;
			}
			break;
		}
		}

		this.documentClassTableColumnEntry = {...rowData}
		this.saveTableColumn(false)
	}


	/** Opens the Delete-Dialog for the Table-Colums */
	openDeleteTableColumn(rowData: DocumentTableColumn) {
		this.documentClassTableColumnEntry = {...rowData}
		this.showDeleteTableColumnDialog = true;
	}

	/** Saves a Field from the dialog and emits if the dialog should be kept open */
	saveColumnFromDialog(keepDialogOpen: boolean) {
		this.saveTableColumn(true, keepDialogOpen);
	}

	/**
	 * Saves a new field
	 * @params reloadTableData Is the Save triggered from outside the Entry-Dialog?
	 */
	saveTableColumn(reloadTableData: boolean, keepDialogOpen: boolean = false) {
		if (this.isInvalid && reloadTableData === true) {
			this.showErrorMessage = true;

			// check current inValid tab
			if(this.currentInValidTab === 1 && this.currentDialogTabIndex !== 1) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.DocumentClasses.ErrorInputAssistance'));
			} else if (this.currentInValidTab === 0 && this.currentDialogTabIndex !== 0) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.DocumentClasses.ErrorFieldAttributes'));
			}

			return;
		}

		if (this.documentClassTableColumnEntry) {
			// set a default value when entry is null
			if (!this.documentClassTableColumnEntry.headerLocatorId) {
				this.documentClassTableColumnEntry.headerLocatorId = 0;
			}
			if (!this.documentClassTableColumnEntry.valueLocatorId) {
				this.documentClassTableColumnEntry.valueLocatorId = 0;
			}

			// The Api can't save null values, therefore use default 0
			if (this.documentClassTableColumnEntry.lookup?.tableId == null) {
				this.documentClassTableColumnEntry.lookup!.tableId = 0;
			}

			if (this.documentClassTableColumnEntry.lookup?.minInputLength == null) {
				this.documentClassTableColumnEntry.lookup!.minInputLength = 1;
			}
		}

		this.showErrorMessage = false;
		this.loadingSaveColumn = true;

		if (this.documentClassTableColumnEntry && this.documentClassTableColumnEntry.tableId && this.documentClassTableColumnEntry.id) {
			this.documentClassService.putDocumentClassTableColumn(this.documentClassId, this.documentClassTableColumnEntry.tableId, this.documentClassTableColumnEntry.id, this.documentClassTableColumnEntry)
				.then(() => {
					this.showCurrentTableColumnDialog = false

					if (reloadTableData) {
						this.getDocumentClassTables(this.documentClassId, false)
					}
				})
				.catch(response => response.json().then((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);
				})).finally(() => this.loadingSaveColumn = false)
		}else if (this.documentClassTableColumnEntry && this.documentClassTableColumnEntry.tableId) {
			const table = this.documentClassTables!.find(table => table.id === this.documentClassTableColumnEntry!.tableId);
			if (table) {
				const sortOrders = table.columns!.map(column => column.sortOrder);
				// Get highest sort order number and increase it by one
				if (sortOrders.length > 0) {
					const highestSortID = Math.max.apply(0, sortOrders as number[]) + 1;
					this.documentClassTableColumnEntry!.sortOrder! = highestSortID;
				}
			}

			this.documentClassService.postDocumentClassTableColumn(this.documentClassId, this.documentClassTableColumnEntry.tableId, this.documentClassTableColumnEntry)
				.then(() => {
					if (!keepDialogOpen) {
						this.showCurrentTableColumnDialog = false;
					}else {
						this.documentClassTableColumnEntry!.name = "";
						this.documentClassTableColumnEntry!.description = "";
						this.columnFormElement!.$el.querySelector('input').focus();
						this.isInvalid = true;
					}

					if (reloadTableData) {
						this.getDocumentClassTables(this.documentClassId, false)
					}
				})
				.catch(response => response.json().then((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);
				})).finally(() => this.loadingSaveColumn = false)
		}
		else {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + this.$t('Squeeze.General.UnexpectedError'));
		}
	}

	/**
	 * Deletes a Table-Column
	 */
	deleteTableColumn() {
		this.loadingSaveColumn = true;
		if (this.documentClassTableColumnEntry && this.documentClassTableColumnEntry.tableId && this.documentClassTableColumnEntry.id) {
			this.documentClassService.deleteDocumentClassTableColumn(this.documentClassId, this.documentClassTableColumnEntry.tableId, this.documentClassTableColumnEntry.id)
				.then(() =>{
					this.getDocumentClassTables(this.documentClassId, true)
					this.showDeleteTableColumnDialog = false
				})
				.catch(err => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.DeleteError') + ": " + err);
				}).finally(() => {
					this.loadingSaveColumn = false;
				})
		}
	}

	/**
	 * Resets the Default-Field-Group
	 */
	initFieldItem() {
		this.message = ''
		this.messageType = 'none'

		this.documentClassTableEntry = {
			documentClassId: this.documentClassId,
			name: '',
			description: '',
			locatorId: 23, // "Positionen"
			mandatory: false,
			readonly: false,
			hidden: false,
			forceValidation: false,
			externalName: '',
			fieldGroupId: 0,
			rows: [],
		}
	}

	/**
	 * Changes the order of document class fields
	 * @param columns
	 * @param tableId
	 * @param isFilterActive
	 */
	onChangeSortOrder(columns: number[], tableId: number, isFilterActive: boolean) {
		if (isFilterActive) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.ChangeError') + ": " + this.$t('Squeeze.DocumentClasses.ActiveFilter'));
			return;
		} else {
			this.documentClassLoading = true;

			this.documentClassService.putDocumentClassTableColumnOrder(this.documentClassId, tableId, {elements: columns})
				.then(() => {
					this.getDocumentClassTables(this.documentClassId, false)
				}).catch(response => response.json().then((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.message);
				})).finally(() => this.documentClassLoading = false)
		}
	}

	getAllDocumentClasses() {
		if (this.allDocumentClasses.length === 0) {
			this.documentClassApi.getAllDocumentClasses()
				.then(data => {
					this.allDocumentClasses = data;
				})
				.catch(reason => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
				});
		}
	}

	/** Get all documentTables */
	getPositionTables() {
		if (this.documentTables.length === 0) {
			this.documentClassService.getDocumentClassTablesGlobal()
				.then(data => {
					this.documentTables = data;
				})
				.catch(reason => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
				});
		}
	}

}

</script>

<style scoped>

button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-plain.p-button-text.reduceHeight.reduceHeight{
	height: 1rem;
	width: 2rem;
}

/** Otherwise there will be scrollbar at the bottom */
.p-grid {
	margin-left: 0px;
	margin-right: 0px;
}

::v-deep(i.p-datatable-reorderablerow-handle.pi.pi-bars) {
	cursor: grab;
}

::v-deep(i.p-datatable-reorderablerow-handle.pi.pi-bars:active) {
	cursor: grabbing;
 }

::v-deep(.p-tabview-panel) {
	height: calc(100vh - 5.6rem - 8.5rem);
}

</style>
