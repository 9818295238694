
import {computed, defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {SearchFormDto} from "@/apps/squeeze/views/DocumentListView.vue";
import Dropdown from "primevue/dropdown";
import Listbox from 'primevue/listbox';
import {useI18n} from "vue-i18n";
import {DocumentSearchFilterWidget} from "@dex/squeeze-client-ts";
import {useStore} from "@/store";

export default defineComponent({
	name: "SearchForm",
	components: {
		InputText,
		Dropdown,
		Listbox,
	},
	props: {
		search: {
			type: Object as PropType<DocumentSearchFilterWidget>,
			default: {},
		},
		showErrorMessage: {
			type: Boolean,
		},
		allSearches: {
			type: Array as PropType<DocumentSearchFilterWidget[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showSearchesOnly: {
			type: Boolean,
			default: false,
		},
		searchId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['update', "searchSelected"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const store = useStore();
		/** Current Object of all input-fields */
		const value = reactive<SearchFormDto>({description: '', actionType: 'new', searchId: 0, global: false});

		/** Determines the required rules for validation */
		const rules = {
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** Array with list for Creating/Changing Searches*/
		const createNewSearch = computed(() => {
			const selection = [
				{
					id: "new",
					description: t('Squeeze.Queue.General.NewSearchList'),
				},
			]

			if (props.allSearches?.length > 0) {
				selection.push({
					id: "change",
					description: t('Squeeze.Queue.General.ChangeSearch'),
				})

				selection.push({
					id: "delete",
					description: t('Squeeze.Queue.General.DeleteSearch'),
				})
			}
			return selection;
		});

		/** Array with list for Creating/Changing Searches*/
		const privateOrGlobalSearch = computed(() => {
			return [
				{
					id: false,
					description: t('Squeeze.Queue.General.Personal'),
				},
				{
					id: true,
					description: t('Squeeze.Queue.General.Global'),
				},
			]
		});

		/** Array with list for Creating/Changing Searches*/
		const allSearchesGroupedFiltered = computed(() => {
			const privateSearches = props.allSearches?.filter(search => search.global === false);
			const selection = []

			if (privateSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Personal'),
					icon: 'mdi mdi-account',
					items: privateSearches,
				});
			}

			if (store.state.isAdminUser) {
				const globalSearches = props.allSearches?.filter(search => search.global === true);
				if (globalSearches.length > 0) {
					selection.push({
						label: t('Squeeze.Queue.General.Global'),
						icon: 'mdi mdi-account-group',
						items: globalSearches,
					})
				}
			}

			return selection;
		});

		/** Array with list for Creating/Changing Searches*/
		const allSearchesGrouped = computed(() => {
			const privateSearches = props.allSearches?.filter(search => search.global === false);
			const selection = []

			if (privateSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Personal'),
					icon: 'mdi mdi-account',
					items: privateSearches,
				});
			}

			const globalSearches = props.allSearches?.filter(search => search.global === true);
			if (globalSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Global'),
					icon: 'mdi mdi-account-group',
					items: globalSearches,
				})
			}

			return selection;
		});

		onMounted(async () => {
			Object.assign(value, props.search);
			if (props.showSearchesOnly) {
				value.searchId = 0;

				// Focus Search Field
				const list = document.querySelectorAll(".p-listbox-filter.p-inputtext.p-component");
				if (list && list[0] && (list[0] as any).focus) {
					(list[0] as any).focus();
				}
			}

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.search, () => {
			Object.assign(value, props.search);
		})

		/** Triggered when keyup in input-field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		const onChangeSaveType = async () => {
			if (value.actionType !== 'new' && props.allSearches?.length > 0) {
				const firstEntry = props.allSearches[0];

				if (firstEntry) {
					value.searchId = firstEntry.id!
					value.description = firstEntry.description!;
					value.global = firstEntry.global!;
				}
			} else {
				value.description = '';
				value.global = false;
			}
			await v$.value.$validate();
			update();
		}

		/** Triggered on change of selected search */
		const onChangeSearch = () => {
			const currentSearch = props.allSearches?.find(search => search.id === value.searchId);
			if (currentSearch) {
				value.description = currentSearch.description!;
				value.global = currentSearch.global!;
			}

			update();
		}

		/** Triggered on select of a search on list */
		const onChangeSearchList = () => {
			const currentSearch = props.allSearches?.find(search => search.id === value.searchId);
			if (currentSearch) {
				value.description = currentSearch.description!;
				value.global = currentSearch.global!;
			}
			emit("searchSelected", value);
		}

		return {
			value,
			props,
			v$,
			InputText,
			createNewSearch,
			privateOrGlobalSearch,
			allSearchesGroupedFiltered,
			allSearchesGrouped,
			update,
			onChangeSaveType,
			onChangeSearch,
			onChangeSearchList,
			store,
		}
	},
});
