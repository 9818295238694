<template>
	<div class="p-mb-2 p-p-2">
		<BlockUI :blocked="loading || saveLoading">
			<ValueFromRegExForm :valueFromRegEx="valueFromRegEx" :loading="loading" :masterDataTables="masterDataTables" :showErrorMessage="showErrorMessage" @update="onUpdate" />

			<div class="p-fluid p-formgrid p-grid p-jc-end p-p-2">
				<div class="p-field p-col-3">
					<Button :label="$t('Squeeze.General.Save')" type="button" icon="mdi mdi-content-save-outline" class="p-button" :loading="loading || saveLoading" @click="saveValueFromRegEx" />
				</div>
			</div>
		</BlockUI>
	</div>
</template>

<script lang="ts">
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/singletons/ToastManager";
import {useToast} from "primevue/usetoast";
import {MasterDataTable, ValueFromRegexDetails} from "@dex/squeeze-client-ts";
import BlockUI from "primevue/blockui";
import ValueFromRegExForm from "@/apps/administration/components/locators/tabs/ValueFromRegExForm.vue";

export default defineComponent({
	name: "ValueFromRegExView",
	components: {
		ValueFromRegExForm, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const saveLoading = ref(false);

		/** Show error validation-messages in form? */
		const showErrorMessage = ref(false);

		/** Is the Form of the Keywords invalid? */
		const isValidationInvalid = ref(true);

		/** List of all Master data tables */
		const masterDataTables = ref<MasterDataTable[]>([]);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Service for getting the master-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** List of all values from RegEx */
		const valueFromRegEx = reactive<ValueFromRegexDetails>({});

		/** Trigged on update of attribute-form */
		const onUpdate = (data: ValueFromRegexDetails, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(valueFromRegEx, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			locatorService.getValueFromRegexDetails(props.locatorId)
				.then((data: ValueFromRegexDetails) => {
					Object.assign(valueFromRegEx, data);
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all masterDataTables */
		const getMasterDataTables = () => {
			loading.value = true;
			masterDataService.getAllMasterDataTables()
				.then((data: MasterDataTable[]) => {
					masterDataTables.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all 'masterDataTables' and 'value from RegEx' of current locator on load */
		onMounted(() =>{
			reloadData();
			getMasterDataTables();
		})

		/** Saves an Value from RegEx */
		const saveValueFromRegEx = () => {
			if (isValidationInvalid.value) {
				showErrorMessage.value = true;
				return;
			}
			showErrorMessage.value = false;
			saveLoading.value = true;

			// make sure locatorId always right
			valueFromRegEx.locatorId = props.locatorId;

			locatorService.putValueFromRegexDetails(props.locatorId, valueFromRegEx)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					saveLoading.value = false;
				})
		}

		return {loading, saveLoading, showErrorMessage, isValidationInvalid, masterDataTables, valueFromRegEx, onUpdate, saveValueFromRegEx, getMasterDataTables};
	},
});

</script>

<style scoped>

</style>
