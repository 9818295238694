<template>
	<div class="p-mb-2 p-p-2">
		<BlockUI :blocked="loading">
			<RoleForm :role="role" :parentRoles="roles" :showErrorMessage="showValidationMessage" @update="onUpdate" />

			<div class="p-fluid p-formgrid p-grid p-jc-end p-p-2">
				<div class="p-field p-col-3">
					<Button :label="$t('Squeeze.General.Save')"
							type="button" icon="mdi mdi-content-save-outline"
							class="p-button" @click="saveRole" />
				</div>
			</div>
		</BlockUI>
	</div>
</template>

<script lang="ts">
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import {UserRole} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/singletons/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import RoleForm from "@/apps/administration/components/roles/RoleForm.vue";
import router from "@/router";

export default defineComponent({
	name: "RoleView",
	components: {
		RoleForm, BlockUI,
	},
	props: {
		roleId: {
			type: Number,
			default: null,
		},
	},
	emits: [],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Service for getting the role-data */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** Object of a master data table */
		const role = reactive<UserRole>({title: '', description: '', parentRoleId: undefined});

		/** Is the Form of the master data invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** List of all roles */
		const roles = ref<UserRole[]>([]);

		/** Triggered on update of attribute-form */
		const onUpdate = (data: UserRole, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(role, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			if (props.roleId) {
				roleService.getRoleById(props.roleId)
					.then((data: UserRole) => {
						Object.assign(role, data);
					})
					.catch((response: any) => response.json().then((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		const loadRules = () => {
			roleService.getRoles()
				.then(data => {
					roles.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			reloadData();
			loadRules();
		})

		/** Saves an role */
		const saveRole = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loading.value = true;

			if (!role.id) {
				roleService.addRole(role)
					.then(data => {
						// FIXME: I don't want to parse this json when the Client is fixed
						return data.json()
					})
					.then((data) => {
						router.push({ name: 'UserManagementRolesTabsView', params: { roleId: data.id }})
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
						role.id = Number(data.id);
					}).catch(response => response.json().then((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					})).finally(() => {
						loading.value = false;
					});
			} else {
				roleService.putRole(role.id, role).then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
			}
		}

		return {
			t,
			toast,
			loading,
			showValidationMessage,
			isValidationInvalid,
			role,
			roles,
			onUpdate,
			reloadData,
			saveRole,
		};
	},
});

</script>

<style scoped>

</style>
