<template>
	<div class="p-p-2 content">
		<MasterDataTableList :masterDataTables="masterDataTables" :loading="loading" @onClickDelete="openDeleteDialog" @onEntrySelect="onEntrySelect" />
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />
</template>

<script lang="ts">
import {
	defineComponent, onMounted, ref
} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import MasterDataTableList from "@/apps/administration/components/masterdata/MasterDataTableList.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import {ToastManager} from "@/singletons/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default defineComponent({
	name: "MasterDataListView",
	components: {
		MasterDataTableList, DialogDelete,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Api for master data */
		const masterDataApi = ClientManager.getInstance().squeeze.masterData;

		/** List of all Master data tables */
		const masterDataTables = ref<MasterDataTable[]>([]);

		/** Are the entries currently loading? */
		const loading = ref<boolean>(false);

		/** Should the delete dialog be shown? */
		const deleteDialog = ref<boolean>(false);

		/** Current master table entry */
		const masterDataTable = ref<MasterDataTable>({name: "", description: ''});

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			masterDataApi.getAllMasterDataTables().then(data => {
				masterDataTables.value = data;
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/**
		 * Gets all Batch-Classes on Mounted
		 */
		onMounted(() =>{
			reloadData();
		});

		/** Triggered when a row is selected */
		const onEntrySelect = (masterDataTableId: number) => {
			router.push({ name: 'MasterDataView', params: { masterDataTableId: masterDataTableId }})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: MasterDataTable) => {
			deleteDialog.value = true;
			masterDataTable.value = row;
		}

		/**
		 * Deletes and entry
		 */
		const deleteEntry = () => {
			loading.value = true;

			if (masterDataTable.value.id) {
				masterDataApi.deleteMasterDataTable(masterDataTable.value.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {
			masterDataTables, loading, deleteDialog, openDeleteDialog, deleteEntry, reloadData, onEntrySelect,
		}
	},
})
</script>

<style scoped>

</style>
