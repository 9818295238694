<template>
	<DataTable
		:value="jobRuns"
		:loading="loading"
		class="p-datatable-sm p-datatable-documents"
		columnResizeMode="fit"
		:lazy="true"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="paginationInfo.pageSize"
		:totalRecords="paginationInfo.total"
		:paginator="true"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		:autoLayout="true"
		:scrollable="true"
		scrollHeight="flex"
		@filter="onFilter($event.filters)"
		v-model:filters="filters"
		filterDisplay="row"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.System.JobRuns')}) }}
		</template>

		<!--<Column field="id" header="ID" style="min-width: 4rem; max-width: 4rem;" />-->
		<Column field="status" :header="$t('Squeeze.System.Status')" style="min-width: 15rem; max-width: 15rem;" filterField="status" :showFilterMenu="false">
			<template #body="slotProps">
				<div style="text-align: left!important; white-space:nowrap!important;">
					<span :class="'status-badge ' + slotProps.data.status.toLowerCase()" class="p-py-1">
						{{ $t("Squeeze.System.States." + slotProps.data.status.toUpperCase()) }}
					</span>
				</div>
			</template>
			<template #filter="{filterModel,filterCallback}">
				<Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="" class="p-column-filter filter-field" :showClear="true">
					<template #value="slotProps">
						<span v-if="slotProps.value">{{ $t("Squeeze.System.States." + slotProps.value.toUpperCase()) }}</span>
						<span v-else>{{ slotProps.placeholder }}</span>
					</template>
					<template #option="slotProps">
						<span :class="'status-badge ' + slotProps.option.toLowerCase()">{{ $t("Squeeze.System.States." + slotProps.option) }}</span>
					</template>
				</Dropdown>
			</template>
		</Column>
		<Column field="error" :header="$t('Squeeze.System.ErrorMessage')" style="min-width: 30rem; max-width: 30rem;">
			<template #body="slotProps">
				<div v-if="slotProps.data.error" class="column-cell" v-tooltip.bottom="JSON.parse(slotProps.data.error).message">
					{{ JSON.parse(slotProps.data.error).message }}
				</div>
				<div v-else>
					- -
				</div>
			</template>
		</Column>
		<Column field="startedAt" :header="$t('Squeeze.System.StartedAt')" style="min-width: 12rem;">
			<template #body="slotProps">
				<div v-if="slotProps.data.startedAt">
					{{ formatDate(slotProps.data.startedAt) }}
				</div>
				<div v-else>
					- -
				</div>
			</template>
		</Column>
		<Column field="finishedAt" :header="$t('Squeeze.System.FinishedAt')" style="min-width: 12rem;">
			<template #body="slotProps">
				<div v-if="slotProps.data.finishedAt">
					{{ formatDate(slotProps.data.finishedAt) }}
				</div>
				<div v-else>
					- -
				</div>
			</template>
		</Column>

		<template #paginatorLeft></template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {JobRun, PaginationDto} from "@dex/squeeze-client-ts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from 'primevue/dropdown';
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: 'JobRunsList',
	components: {
		DataTable,
		Column,
		Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All job runs */
		jobRuns: {
			type: Array as PropType<JobRun>,
			default: [],
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		filterOptions: {
			type: Object as PropType<DocumentFilterObject>,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onPage",
		"onFilter",
	],
	setup(props, {emit}) {
		const { locale } = useI18n({ useScope: 'global' });

		/** QueueStates for filter selection */
		const statuses =  ref<string[]>([
			'ERROR', 'CREATED', 'STARTED', 'FINISHED',
		]);

		/** Filters of list */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** Triggered when the next page is selected */
		const onPage = (event: PaginationDto) => {
			emit("onPage", event);
		}

		/** Triggered when the tableFilter is selected */
		const onFilter = (tableFilter: any) => {
			emit("onFilter", tableFilter);
		}

		/** Formats date string to desired locale string */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options) + ":" + date.getMilliseconds();
				}
			}
			return dateToFormat;
		}

		return {
			statuses, filters, onPage, onFilter, formatDate,
		}
	},
})
</script>

<style lang="scss" scoped>

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-dropdown.filter-field) {
	margin-left: 0.4rem;
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}

	.p-dropdown-label {
		padding: 0 1rem 0 0.4rem;
		color: var(--filter-field-color);
	}
}

.status-badge {
	&.error {
		border-color: var(--queuestate-error-primary);
		color: var(--queuestate-error-darker);
	}

	&.created {
		border-color: var(--queuestate-work-primary);
		color: var(--queuestate-work-darker);
	}

	&.started {
		border-color: var(--queuestate-suspend-primary);
		color: var(--queuestate-suspend-darker);
	}

	&.finished {
		border-color: var(--queuestate-finished-primary);
		color: var(--queuestate-finished-darker);
	}
}

</style>
<style scoped>

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

::v-deep(button.p-column-filter-menu-button.p-link) {
	display: none;
}

</style>
